import React, {Fragment, useState} from "react";
import {AccountContext} from "../context/AccountProvider";
import Cookies from "universal-cookie";
import {t} from "i18next";

import {AboutMenu} from "./AboutMenu";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Popover, Transition} from "@headlessui/react";

import {OtherMenu} from "./OtherMenu";

export const Header = ({showSearchModal, setShowSearchModal}) => {
    const navigate = useNavigate();

    const cookies = new Cookies();
    const {cookie, setCookie, account, setAccount} = React.useContext(AccountContext);

    const [isLanguageChangeDropdownOpen, setIsLanguageChangeDropdownOpen] = useState(false);
    const [isLanguageChangeDropdownHovered, setIsLanguageChangeDropdownHovered] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(
        cookies.get("locale") === "en"
            ? "English"
            : cookies.get("locale") === "mr"
                ? "मराठी"
                : "English"
    );

    const handleLanguageDropdown = (language) => {
        console.log("Language Selected: ", language);
        if (language === cookies.get("locale")) {
            setIsLanguageChangeDropdownHovered(false);
            setIsLanguageChangeDropdownOpen(false);
            return;
        }
        cookies.set("locale", language, {path: "/"});
        // setSelectedLanguage(language === "en" ? "English" : "मराठी");
        window.location.reload();
    };

    const handleLogout = () => {
        cookies.remove("email");
        setCookie(undefined);
        setAccount(undefined);
    };

    return (
        <>
            <nav
                className={`flex items-center justify-between h-[4vw] z-[90] 
                    rounded-full px-[1vw] py-[1vh] bg-white shadow-lg
                    fixed inset-x-[1vw] top-[1vw]`}
                aria-label="Global"
            >
                {/* Logo */}
                <div className="flex grow-0">
                    <Link
                        to="/home"
                        key="logo"
                        className="-m-1.5 p-1.5 flex items-center"
                    >
                        <img
                            className="h-[3.5vw] w-auto px-[1vw]"
                            src="/other_imgs/logo-joglekar kul-72x72.png"
                            alt="Joglekarkul Logo"
                        />
                        <h1 key="logo-name" className={"font-SOHNE_BOLD text-[1.25vw]"}>
                            {t("HEADER.JOGLEKARKUL")}
                        </h1>
                    </Link>
                </div>

                {/* Navigation Menu */}
                <div className="flex gap-x-12">
                    <AboutMenu/>
                    <Link
                        to={cookie ? "/kulavruksh" : "/login"}
                        key="Kulavruksh"
                        className="text-[1vw] flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.KULAVRUKSH")}
                    </Link>
                    <Link
                        to="/info"
                        key="Information"
                        className="text-[1vw] flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.INFORMATION")}
                    </Link>
                    <Link
                        to="/religious"
                        key="Religious"
                        className="text-[1vw] flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.RELIGIOUS")}
                    </Link>
                    <Link
                        to="/yellowPage"
                        key="YellowPage"
                        className="text-[1vw] flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.YELLOW PAGE")}
                    </Link>
                    <Link
                        to="/contact"
                        key="Contact"
                        className="text-[1vw] flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.CONTACT")}
                    </Link>

                    <OtherMenu/>

                    <Link
                        to="/search"
                        className="text-[1vw] flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        Search
                    </Link>

                </div>

                {/* Language Selection | Login/Register Button | Profile Button */}
                <div className="flex items-center gap-x-[1vw] xl:gap-x-[1.5vw]">
                    {/* Language Selection */}
                    {/*{window.location.pathname.includes("profile") && (*/}
                    <Popover className="relative">
                        {({}) => {
                            // To prevent the body from scrolling when the popover is isLanguageChangeDropdownOpen
                            document.body.classList.toggle(
                                "overflow-y-hidden",
                                isLanguageChangeDropdownOpen
                            );

                            return (
                                <>
                                    <button
                                        onMouseEnter={() =>
                                            setIsLanguageChangeDropdownHovered(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsLanguageChangeDropdownHovered(false)
                                        }
                                        className={`inline-flex items-center gap-x-1 outline-none
                                                        py-[1vh] text-[1vw] font-SOHNE_MEDIUM leading-6 text-gray-900`}
                                    >
                                        <span>{selectedLanguage}</span>
                                        <ChevronDownIcon
                                            className={`h-[1.25vw] w-[1.25vw] ${
                                                isLanguageChangeDropdownHovered ||
                                                isLanguageChangeDropdownOpen
                                                    ? "rotate-180"
                                                    : "rotate-0"
                                            } transition-all duration-150 ease-in-out`}
                                            aria-hidden="true"
                                        />
                                    </button>

                                    <Transition
                                        show={
                                            isLanguageChangeDropdownOpen ||
                                            isLanguageChangeDropdownHovered
                                        }
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            onMouseEnter={() =>
                                                setIsLanguageChangeDropdownOpen(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsLanguageChangeDropdownOpen(false)
                                            }
                                            className="absolute left-1/2 z-10 mt-[1vh] flex w-screen max-w-max -translate-x-1/2 rounded-3xl"
                                        >
                                            <div
                                                className={`bg-white flex flex-col overflow-hidden 
                                                                shadow-lg text-[0.75vw] leading-6 
                                                                rounded-lg xl:rounded-xl w-[7.5vw] 
                                                                ring-2 ring-orange-300/5`}
                                            >
                                                <button
                                                    onClick={() => handleLanguageDropdown("en")}
                                                    className="py-[1vh] font-SOHNE_REGULAR leading-normal
                                                                text-[1vw] text-gray-600 hover:bg-gray-200"
                                                >
                                                    English
                                                </button>
                                                <button
                                                    onClick={() => handleLanguageDropdown("mr")}
                                                    className="py-[1vh] font-SOHNE_REGULAR leading-normal
                                                                text-[1vw] text-gray-600 hover:bg-gray-200"
                                                >
                                                    मराठी
                                                </button>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            );
                        }}
                    </Popover>
                    {/*)}*/}

                    {account === undefined || cookie === undefined || cookie === null ? (
                        <>
                            {/* Login / Register Button */}
                            <button
                                onClick={() => navigate("/login")}
                                className="arrow-button inline-flex items-center rounded-full
                                    pl-[1vw] pr-[0.6vw] py-[1vh] shadow-xl
                                    bg-[#D26A53] hover:bg-[#D26A53]
                                    hover:ring-orange-300 hover:ring-offset-1 hover:ring-4
                                    transition-all duration-300 ease-in-out"
                            >
                                <p
                                    className={
                                        "font-SOHNE_MEDIUM leading-tight text-[1vw] text-white"
                                    }
                                >
                                    {t("HEADER.LOGIN")}
                                </p>
                                <svg
                                    viewBox="0 0 6 6"
                                    fill="none"
                                    id={"arrow-icon"}
                                    className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 1L5 3L3 5"
                                        id={"arrow-icon-tip"}
                                        stroke="white"
                                        strokeLinecap="square"
                                    />
                                    <path
                                        d="M1 3L4 3"
                                        id={"arrow-icon-line"}
                                        stroke="white"
                                        strokeLinecap="square"
                                    />
                                </svg>
                            </button>
                        </>
                    ) : (
                        <>
                            {/* Search Button */}
                            {window.location.pathname.toLowerCase().includes("kulavruksh") &&
                                account.outsider && (
                                    <div
                                        onClick={() => setShowSearchModal(!showSearchModal)}
                                        className={`flex flex-row items-center justify-between gap-x-2 xl:gap-x-4 cursor-pointer 
                                                bg-white rounded-full p-[1vh] ${!showSearchModal && "pr-[1vw]"}
                                                transition-all duration-500 ease
                                                border-2 border-[#EAEAEA] hover:shadow-xl`}
                                    >
                                        <div
                                            className={`h-[1.5vw] w-[1.5vw] p-[0.5vh] rounded-full 
                                                    bg-[#F5F5F5] flex items-center justify-center`}
                                        >
                                            <img
                                                className="h-full w-full"
                                                src="/assets/search-icon.png"
                                                alt="Search Icon"
                                            />
                                        </div>
                                        <p
                                            style={{
                                                userSelect: "none",
                                            }}
                                            className={
                                                "font-SOHNE_MEDIUM leading-tight text-[1vw] text-[#787878]"
                                            }
                                        >
                                            {t("HEADER.SEARCH")}
                                        </p>
                                    </div>
                                )}

                            {/* Logout Button */}
                            {(window.location.pathname.includes("personal-profile") ||
                                window.location.pathname.includes("home")) && (
                                <button
                                    onClick={handleLogout}
                                    className="arrow-button inline-flex items-center rounded-full
                                            pl-[1vw] pr-[0.6vw] py-[1vh] bg-[#D26A53]
                                            hover:bg-[#D26A53] hover:shadow-xl
                                            hover:ring-orange-300 hover:ring-offset-1 hover:ring-4
                                            transition-all duration-300 ease-in-out"
                                >
                                    <p
                                        className={
                                            "font-SOHNE_MEDIUM leading-tight text-base text-white"
                                        }
                                    >
                                        {t("HEADER.LOGOUT")}
                                    </p>
                                    <svg
                                        viewBox="0 0 6 6"
                                        fill="none"
                                        id={"arrow-icon"}
                                        className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 1L5 3L3 5"
                                            id={"arrow-icon-tip"}
                                            stroke="white"
                                            strokeLinecap="square"
                                        />
                                        <path
                                            d="M1 3L4 3"
                                            id={"arrow-icon-line"}
                                            stroke="white"
                                            strokeLinecap="square"
                                        />
                                    </svg>
                                </button>
                            )}

                            {/* Profile Button (PFP) */}
                            {
                                <Link
                                    className="lg:flex items-center"
                                    to={
                                        account.outsider
                                            ? ""
                                            : account.nodeId
                                                ? "/personal-profile"
                                                : "/kulavruksh"
                                    }
                                    onClick={() => {
                                        if (!account.outsider && !account.nodeId) {
                                            toast.info(t("HEADER.COMPLETE_PROFILE"));
                                        }
                                    }}
                                >
                                    {account.imageUrl ? (
                                        account.imageUrl.length <= 2 ? (
                                            <div
                                                className={`z-10 h-[5vh] w-auto aspect-square rounded-full overflow-hidden 
                                                                    flex items-center justify-center
                                                                    ${account.imageUrlBg}`}
                                            >
                                                <p className={"text-white font-SOHNE_MONO_BOLD"}>
                                                    {account.imageUrl.toUpperCase()}
                                                </p>
                                            </div>
                                        ) : (
                                            <img
                                                className="h-[5vh] w-auto aspect-square rounded-full"
                                                src={account.imageUrl}
                                                alt="User Profile Image"
                                                referrerPolicy={"no-referrer"}
                                            />
                                        )
                                    ) : (
                                        <img
                                            className="h-[5vh] w-auto aspect-square rounded-full bg-[#D26A53]"
                                            src={"/assets/default-pfp.png"}
                                            alt="Default PFP"
                                            referrerPolicy={"no-referrer"}
                                        />
                                    )}
                                </Link>
                            }
                        </>
                    )}
                </div>
            </nav>
        </>
    );
};
