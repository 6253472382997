import { Link } from "react-router-dom";

export const SquareDharmikLayout = ({ data }) => {
  return (
    <Link
      to={data.href || "#"}
      className="w-full sm:w-[40vw] md:w-[30vw] lg:w-[17vw] flex flex-col justify-center items-center"
    >
      <img
        src={data.img}
        alt={data.title}
        className="w-[350px] aspect-square object-contain shadow-2xl rounded-lg"
      />
      <h1 className="text-3xl font-SOHNE_EXTRA_BOLD mt-10 text-center  text-red-800">{data.title}</h1>
    </Link>
  );
};
