import React, {useContext, useEffect, useRef} from 'react';
import {getConnectedEdges, Handle, NodeToolbar, Position, useReactFlow} from 'reactflow';
import {AccountContext} from '../../context/AccountProvider';
import {NodeContext} from '../../context/NodeProvider';
import {t} from "i18next";
import {Link} from "react-router-dom";
import {deleteAccount} from "../../service/accountApi";
import {toast} from "react-toastify";

const MemberNode = ({data, isConnectable, id}) => {
    const instance = useReactFlow();

    const handleASourceRef = useRef(null);
    const handleATargetRef = useRef(null);
    const handleBSourceRef = useRef(null);
    const handleBTargetRef = useRef(null);
    const handleCSourceRef = useRef(null);
    const handleCTargetRef = useRef(null);
    const handleDSourceRef = useRef(null);
    const handleDTargetRef = useRef(null);

    const {account} = useContext(AccountContext);
    const {selectedNode, relativeNode} = useContext(NodeContext);

    function formatDate(dateString) {
        // Parse the ISO date string into a Date object
        const date = new Date(dateString);

        // Get the day of the month
        const day = date.getUTCDate();

        // Add the appropriate ordinal suffix to the day
        const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' :
            day % 10 === 2 && day !== 12 ? 'nd' :
                day % 10 === 3 && day !== 13 ? 'rd' : 'th');

        // Get the month as a short string
        const month = date.toLocaleString('en-US', {month: 'short'});

        // Get the year
        const year = date.getUTCFullYear();

        // Format the final output
        return `${dayWithSuffix} ${month}, ${year}`;
    }

    useEffect(() => {
        const observers = [];

        const targetToSourceMapping = {
            "a-target": handleASourceRef,
            "b-target": handleBSourceRef,
            "c-target": handleCSourceRef,
            "d-target": handleDSourceRef,
        };

        // Function to observe a single handle
        const observeHandle = (handleRef, handleId) => {
            if (handleRef.current) {
                const observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (mutation.attributeName === "class") {
                            // Fire your function here
                            // console.log(`Class list changed for ${handleId}:`, handleRef.current.className);
                            // I want to pass the handleRef or the source handle and target handle both to the function
                            if (targetToSourceMapping[handleId] && targetToSourceMapping[handleId].current) {
                                onHandleClassChange(targetToSourceMapping[handleId], handleRef.current.className);
                            }
                        }
                    });
                });

                observer.observe(handleRef.current, {attributes: true, attributeFilter: ["class"]});
                observers.push(observer);
            }
        };

        // List of refs and their IDs
        const handleRefs = [
            {ref: handleASourceRef, id: "a-source"},
            {ref: handleATargetRef, id: "a-target"},
            {ref: handleBSourceRef, id: "b-source"},
            {ref: handleBTargetRef, id: "b-target"},
            {ref: handleCSourceRef, id: "c-source"},
            {ref: handleCTargetRef, id: "c-target"},
            {ref: handleDSourceRef, id: "d-source"},
            {ref: handleDTargetRef, id: "d-target"},
        ];

        // Observe each handle
        handleRefs.forEach(({ref, id}) => observeHandle(ref, id));

        return () => {
            // Disconnect all observers on cleanup
            observers.forEach((observer) => observer.disconnect());
        };
    }, []);

    const onHandleClassChange = (sourceHandleRef, newClass) => {
        if (sourceHandleRef.current) {
            if (newClass.includes("react-flow__handle-connecting") || newClass.includes("react-flow__handle-valid")) {
                sourceHandleRef.current.style.background = '#FF6060';
            } else {
                sourceHandleRef.current.style.background = '#90EE90';
            }
        }
    }

    const handleDeleteNode = async () => {
        // Delete from database
        const deleteNodeResponse = await deleteAccount(data.accountId, id);

        if (deleteNodeResponse.success) {
            // Delete the node from react-flow
            instance.deleteElements({nodes: [{id: id}]});

            // Delete the relations connected to this node from react-flow
            // Get all the edges connected to this node
            // There is no getElements method in react-flow

            instance.deleteElements({edges: getConnectedEdges([data], instance.getEdges())});

            toast.dismiss();
            toast.success(deleteNodeResponse.message, {
                autoClose: 3000,
            });
        }
    }

    return (
        <div
            className={`
                group rounded-lg shadow-lg 
                px-[1vh] py-[1vh] 
                flex flex-row items-center justify-between 
                ${selectedNode?._id === id ? "border-4 border-[#FFB485]" : "border-4 border-cyan-100"}
                transition-all duration-100 ease-in-out
            `}
            style={{backgroundColor: data.gender === "Male" ? "#0075EA" : "#A020F0",}}
        >
            {
                account.nodeId === id &&
                <img src='/assets/sideArrow.png'
                     alt="member image"
                     className="absolute left-4 rotate-90 overflow h-5 w-5 -top-8"
                />
            }
            {
                relativeNode.includes(id) &&
                <img src='/assets/down-arrow.png'
                     alt="member image"
                     className="absolute right-2 animate-bounce overflow h-10 w-10 -top-20"
                />
            }

            {
                data.imageUrl.length <= 2
                    ? (
                        <div
                            className={`z-10 w-[7vh] h-[7vh] mr-[1vw] overflow-hidden flex items-center justify-center rounded-lg border-2 border-white ${data.imageUrlBg}`}
                        >
                            <p className={"text-white font-SOHNE_MONO_BOLD text-[1vw]"}>
                                {data.imageUrl.toUpperCase()}
                            </p>
                        </div>
                    )
                    : (
                        <div
                            className="z-10 w-[7vh] h-[7vh] mr-[1vw] overflow-hidden flex items-center justify-center rounded-lg bg-slate-500 border-2 border-white">
                            <img
                                src={!(data.imageUrl === '') ? data.imageUrl : 'assets/default-pfp.png'}
                                referrerPolicy={"no-referrer"}
                                alt="Member Profile Picture"
                            />
                        </div>
                    )
            }

            <div className="flex flex-col justify-between mr-[0.5vw]">
                <div className={"flex items-center justify-between gap-2"}>
                    <p className="text-[1.5vh] font-SOHNE_MEDIUM text-white leading-relaxed">
                        {data.firstName + ' ' + data.middleName}
                    </p>
                    {
                        data.accountId === account.familyTreeAdminId &&
                        <img
                            className={"w-[1.5vw] aspect-auto"}
                            src={'assets/admin-icon-2.png'}
                            referrerPolicy={"no-referrer"}
                            alt="Admin"
                        />
                    }
                    {
                        data.deceased &&
                        <img
                            className={"w-[1.25vw] aspect-auto"}
                            src={'assets/deceased-icon-filled-3.png'}
                            referrerPolicy={"no-referrer"}
                            alt="Deceased"
                        />
                    }
                </div>
                <p className="text-[1.5vh] font-SOHNE_MEDIUM text-white leading-relaxed">
                    {data.firstNameDevanagariScript + ' ' + data.middleNameDevanagariScript}
                </p>
                <p className="text-[1.5vh] font-SOHNE_MEDIUM text-white">
                    {t("SCREEN.MEMBER_NODE.DOB_PREFIX")}&nbsp;
                    <span>{formatDate(data.dateOfBirth)}</span>
                </p>
            </div>

            <NodeToolbar
                position={Position.Bottom}
                className="rounded-lg flex flex-row items-center justify-between"
            >
                <div className="flex content-center">
                    {/* Profile Button */}
                    <Link
                        className="flex flex-row items-center justify-between
                        mr-[0.5vw] px-[0.75vw] py-[0.75vh]
                        bg-green-400 rounded-lg
                        hover:bg-green-600
                        focus:ring-4 focus:outline-none focus:ring-blue-300
                        transition-all duration-200 ease-in-out"
                        to={data.accountId === account._id ? "/personal-profile" : `/public-profile/${data.accountId}`}
                    >
                        <img
                            src="/assets/profile-button-icon-filled-dark.png"
                            alt="Edit"
                            className="w-[1vw] h-[1vw]"
                        />
                        <p className={"ml-1 text-[1vw] font-SOHNE_MEDIUM"}>
                            {t("SCREEN.MEMBER_NODE.PROFILE")}
                        </p>
                    </Link>

                    {/* Delete Node Button */}
                    {account.nodeId !== id && account._id === account.familyTreeAdminId &&
                        <button
                            onClick={handleDeleteNode}
                            className="flex flex-row items-center justify-between
                                ml-[0.5vw] px-[0.75vw] py-[0.75vh] focus:outline-none
                                bg-red-500 rounded-lg border border-gray-200
                                hover:bg-red-700 hover:text-blue-700
                                focus:z-10 focus:ring-4 focus:ring-gray-100
                                transition-all duration-200 ease-in-out"
                        >
                            <img
                                src="/assets/delete-icon-filled-light.png"
                                alt="Delete"
                                className="w-[1vw] h-[1vw]"
                            />
                            <p className={"ml-1 text-[1vw] font-SOHNE_MEDIUM text-white"}>
                                {t("SCREEN.MEMBER_NODE.DELETE")}
                            </p>
                        </button>
                    }
                </div>
            </NodeToolbar>

            <Handle
                ref={handleATargetRef}
                type="target"
                style={{background: '#90EE90', width: 10, height: 10}}
                id="a"
                position={Position.Top}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleASourceRef}
                type="source"
                style={{background: '#90EE90', width: 10, height: 10}}
                id="a"
                position={Position.Top}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleBTargetRef}
                type="target"
                position={Position.Left}
                id="b"
                style={{background: '#90EE90', width: 10, height: 10}}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleBSourceRef}
                type="source"
                position={Position.Left}
                id="b"
                style={{background: '#90EE90', width: 10, height: 10}}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleCTargetRef}
                type="target"
                position={Position.Right}
                id="c"
                style={{background: '#90EE90', width: 10, height: 10}}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleCSourceRef}
                type="source"
                position={Position.Right}
                id="c"
                style={{background: '#90EE90', width: 10, height: 10}}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleDTargetRef}
                type="target"
                position={Position.Bottom}
                id="d"
                style={{background: '#90EE90', width: 10, height: 10}}
                isConnectable={isConnectable}
            />
            <Handle
                ref={handleDSourceRef}
                type="source"
                position={Position.Bottom}
                id="d"
                style={{background: '#90EE90', width: 10, height: 10}}
                isConnectable={isConnectable}
            />
        </div>
    );
};
export default MemberNode;
