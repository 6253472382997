import * as React from "react";
import { Header } from "../components/Header";

export const KulvrutantSamiti = () => {
  return (
    <>
      <div>
        <Header />
      </div>
      <div className="mt-[72px] flex items-center justify-center">
        <img
          src="/other_imgs/kulvrtant_samiti.jpg"
          alt="kulvrtant_samiti info img" className="w-1/2 h-2/3 mt-10"
        />
      </div>
    </>
  );
};
