import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { useEffect } from 'react';

export const Chapter10 = () => {
		// Scroll to Top function
		const scrollToTop = () => {
			window.scrollTo({ top: 0, behavior: "smooth" });
		};
		useEffect(() => {
				// Scroll to top when the page is rendered
				window.scrollTo(0, 0);
			}, []);
	return (
		<div>
			<Header />
			<div className="mt-16  mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg  md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					चित्पावनांचे स्वभावगुण व रंगरूप धार्मिक
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">परशुराम</span> व
						महालक्ष्मी देवी ही दोन चित्पावनांची आराध्यदैवते असली तरी
						सर्वच देवांच्या बाबतीत भक्तिभाव दिसून येतो. ‘‘चित्पावन
						िंहदूंच्या बहतेक सर्व दैवतांची पूजा जरी त्यांच्यात होत
						असली तरी त्यांचा मुख्य देव शिव असून, मूळ गावातील शिवाचे
						रूप व स्थान (वेळणेश्वर, व्याघ्रेश्वर इत्यादी) त्याचे
						प्रमुख दैवत असते‌’’ - (डॉ.इरावती कर्वे.) चित्तपावन
						व्यवसायामुळे कुठेही राहात असले तरी मूळ गावाबद्दल आणि
						त्या गावांच्या शिवालयावर त्यांचे असीम प्रेम व श्रद्धा
						असते. (विनोबा भावे,) स्वामी स्वरूपानंद, दासगणु महाराज,
						सोनोपंत दांडेकर, पांडुरंगशास्त्री आठवले आदी चित्पावन
						संतपुरुष सर्वमान्य आहेत. ‘‘डोळस‌’’ धर्मपरायणता व
						पापभीरुता हे चित्पावनांचे स्वभावधर्म आहेत. धर्मपरायणता
						डोळस असल्याने जीवनपद्धती आधुनिक विज्ञाननिष्ठ जीवनाशी
						सुसंगत करणे चित्पावनांना सहज सुलभतेने जमते. चित्पावनांचे
						स्वभावगुण.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">कुठलीही </span>स्थलांतरित
						जमात ही कष्टाळू, परिस्थितीशी झुंज घेणारी, काटकसरीने
						वागणारी, बोलण्यात व वागण्यात अघळपघळपणा न ठेवणारी, सावध
						आणि शिस्तबद्ध असते. चित्पावनांना स्थलांतर करून वस्ती
						करावी लागणारा चिपळूण व दक्षिणकोकण हा भूभाग नापीक आणि
						केोणतेही विशेष व्यापार-उद्योग नसणारा होता. त्यामुळे हे
						निर्देशित गुण त्यांच्यामध्ये अधिक प्रकर्षाने प्रकट झाले.
						चित्पावनांच्या ह्या काटकसर आदी गुणांचा स्नेहथट्टेमध्ये
						विनोदासाठी अधूनमधून वापर होत असला तरी ते सदगुण आहेत,
						अवगुण नाहीत. चित्पावनी स्वभावांची विविध वैशिष्ट्येे व
						बारकावे पु.ल.देशपांडे ह्यांनी ‘‘अन्तू बरवा‌’’ ह्या
						प्रसिद्ध व्यक्तिचित्रणामध्ये फार सुंदर रीतीने टिपून
						शब्दांकित केले आहेत. चित्पावन चिक्कूपणा जरूर करतील; पण
						उधारी सहसा करणार नाहीत आणि उधारी मुळीच बुडवणार नाहीत असे
						म्हटले जाते. आता सधनतेमुळे देशावरल्या बहतेक
						चित्पावनांमध्ये काटकसर आदी गुणविशेषांचे प्रमाण कमी झाले
						आहे. चिपळूण परिसरातील आगमनानंतर गका शतकाच्या आतच काटकसरी
						स्वभावामुळे शेतीमधील उत्पन्नामधून धनसंचय होऊन बऱ्याच
						चित्पावन कुटुंबांमध्ये सावकारी व्यवसाय निर्माण झाला व
						वाढला. सचोटी व वािसार्हता ह्या गुणांमध्ये सराफी
						व्यवसायात सुध्दा चित्पावन लोकप्रिय झाले. प्रत्येक
						क्षेत्रात चित्पावनांनी आपल्या कर्तृत्वाने उवल नावलौकिक
						मिळवला आहे. चितपावनांचा रंग आणि डोळे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">चित्पावनांच्या</span>{" "}
						गोरेपणाचा अनावश्यक जास्त गाजावाजा आणि त्यांच्या
						डोळ्याच्या घारेपणाचा अनावश्यक बाऊ करण्यात आला आहे.
						कुठल्याही भूभागावरील पांढरपेशी जमात त्या भूभागातील
						कष्टजीवी जमातीपेक्षा अधिक गोरी असते. बुबुळाचा रंग आणि
						शरीराचा रंग ह्याचा समसमांतर संबंध असतो. शरीराचा रंग हा
						वातावरणातील सूर्यप्रकाश, धूळ, राहण्याची पद्धत, व्यवसाय
						ह्यामुळे बदलत असतो. अनुवांशिकता अभ्यासण्याकरिता
						वातावरणाचा परिणाम न होणाऱ्या स्थिर बाबींचे मूल्यमापन
						करणे अधिक श्रेयस्कर आहे. त्या दृष्टीने डोक्याचा घेर,
						चेहऱ्याची लांबी-रुंदी, नाकाची व ओठाची ठेवण, केसाचा
						प्रकार ह्या गोष्टी अधिक उपयुक्त आहेत. शरीराचा व बुबुळाचा
						रंग ह्यांची उपयुक्तता तुलनेने फारच कमी आहे. बुबुळ हे सतत
						गका द्रवपदार्थाने ओले राहात असते व त्वचेपेक्षा बुबुळ
						अधिक सुरक्षित जागी वसले आहे. त्यामुळे त्वचेच्या रंगाच्या
						तुलनेने बुबुळाचा रंग कमी प्रमाणात बदलतो. ‘‘चित्पावन
						शाकाहारी आहेत. त्याच्या डोक्याची लांबी सारस्वत व कऱ्हाडे
						ह्यांच्यापेक्षा कमी आहे व रुंदी लांबीच्या प्रमाणात मध्यम
						मापाची असते. कोकणपट्टीतील इतर जातींपेक्षा चित्पावनात
						घाऱ्या डोळ्यांचे प्रमाण अधिक असते. (१०टक्क्यापेक्षा थोडे
						अधिक)’’ - (हिन्दू समाज-गक अन्वयार्थ, इरावती कर्वे. १९८५,
						पृष्ठ २२.)
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">
							विदर्भ-मराठवाड्यात{" "}
						</span>
						तीन-चार पिढ्‌यांपूर्वी स्थायिक झालेल्या चित्पावनांचा
						गोरेपणा व डोळ्यांचा घारेपणा व चिक्कूपणा कमी होऊ लागला
						आहे. ऑस्ट्रेलियामध्ये गेल्या दोन-तीन शतकांत स्थलांतरित
						झालेल्या युरोपियनांमध्ये सूर्यप्रकाशामुळे होणाऱ्या
						त्वचेच्या कर्करोगाचे प्रमाण फार जास्त आहे.
						चित्पावनांमध्ये मात्र हा कर्करोग जवळपास आढळतच नाही,
						ह्यावरून चित्पावनांचे भारतातील प्राचीनत्व सिद्ध होते.
					</p>
				</div>
				<div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
				<hr />
			</div>
		</div>
	);
};
