export const circleInfo1 = [
	{
		id: 1,
		title: "फोटो गॅलरी",
		img: "/kulvratant_landingPage/image-gallery.png",
		href: "/photo_gallary",
	},
	{
		id: 2,
		title: "आठवणी",
		img: "/kulvratant_landingPage/image-memories.png",
		href: "/memories",
	},
	{
		id: 3,
		title: "यलो पेजेस",
		img: "/kulvratant_landingPage/image-yellowpage.png",
		href: "/yellowPage",
	},
	{
		id: 4,
		title: "एकमेका सहाय्य करू",
		img: "/kulvratant_landingPage/image-help-each-other.png",
		href: '/help_each_other'
	},
	{ id: 5, 
	  title: "अभिमान", 
	  img: "/kulvratant_landingPage/image-pride.png",
	  href: '/pride' 
	},
];

export const circleInfo2 = [
	{
		id: 6,
		title: "आरती",
		img: "/kulvratant_landingPage/image_aarti.png",
		href: '/aarti'
	},
	{
		id: 7,
		title: "  मंत्रोप्चार ",
		img: "/kulvratant_landingPage/image-mantropchar.png",
		href: '/mantropchar'
	},
	{
		id: 8,
		title: "अनुरूप जोडीदार",
		img: "/kulvratant_landingPage/image-metromonial.png",
		href:'/anuroop_jodidar'
	},
	{
		id: 9,
		title: "सहाय्यक",
		img: "/kulvratant_landingPage/image-assistant-sahayak.png",
	},
	{
		id: 10,
		title: "सभासद",
		img: "/kulvratant_landingPage/image-sabhasad.png",
		href: '/membership'
	},
];