import {Link} from 'react-router-dom'

const Circles = ({ data }) => {

	return (
		<Link to={data.href} className="w-[17vw] flex flex-col justify-center items-center">
			<img
				src={data.img}
				alt={data.name}
				className="w-full aspect-square object-contain shadow-2xl rounded-full" // Circular shape with shadow
			/>
			<h1 className="text-lg text-center mt-4 text-red-800">{data.title}</h1>
		</Link>
	);
};

export default Circles;
