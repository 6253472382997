import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { useEffect } from 'react';
export const Chapter19 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	useEffect(() => {
			// Scroll to top when the page is rendered
			window.scrollTo(0, 0);
		}, []);
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 md:w-[1300px] max-w-screen-lg text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					दिवंगत उल्लेखनीय व्यक्तींचा परिचय
				</h1>
				<h3 className="text-center text-xl sm:text-xl md:text-2xl  ">
					हतात्मा वासुदेव भगवंत जोगळेकर
				</h3>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<div className="flex justify-around md:text-2xl sm:text-xl text-xl">
						<h2> जन्म - इ.स.१७९७ </h2>
						<h2>निधन - ५/१२/१८५७</h2>
					</div>
					<h2 className="text-center mt-5">
						घराणे - नरवण - त्र्यंबकेश्वर
					</h2>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">वासुदेव </span>भगवंत हे
						जोगळेकरांच्यातले पहिले स्वातंत्र्यसैनिक : दि.१/१२/१८५७
						रोजी स्वातंत्र्यसेनानी तात्या टोपे त्र्यंबकेश्वरी
						वासुदेव भगवंत यांना गुप्तपणे भेटले. त्या काळात
						नरवण-त्र्यंबकेश्वरसारख्या मानमरातब असलेल्या व
						त्र्यंबकेश्वर देवस्थानचे विश्वस्त आणि जहागीरदार असलेल्या
						जोगळेकर घराण्यातील व्यक्तीने असल्या कार्यात भाग घेणे हे
						फार धाडसाचे कृत्य होते. त्र्यंबकेश्वर - रहिवासी
						श्री.गंगाधरपंत जाईल यांचे घरात गुप्तपणे ठेवलेली शस्त्रे
						वासुदेव भगवंतांनी मोठ्या हिकमतीने त्यांच्याकडून मिळवली.
						ठाकर व भिल्ल जमातीमधील योग्य अशा धाडसी तरुणांना हाताशी
						धरून दि.५/१२/१८५७ रोजी मध्यरात्री गौतम तलावाजवळ असलेल्या
						सरकारी कचेरीवर धाड घातली व स्वातंत्र्यलढ्याला आवश्यक असा
						खजिना लुटला. तत्कालीन ब्रिटिश सुरक्षा अधिकाऱ्यांनी
						त्यांच्या पद्धतीने चौकशी केली व गक भिल्ल, पांडू यास
						पकडले; पण ब्रिटिशांना ही खात्री होती की, या घटनेमागे
						कोणीतरी बुद्धिवान व्यक्ती असणार. म्हणून त्यांनी पांडू
						भिल्लास ‘त्यांच्या‌’ पद्धतीने बोलते केले व त्याचेकडून
						वासुदेव भगवंत यांचे नांव मिळवले. लगोलग वासुदेव भगवंत
						यांना पोलिसांनी अटक करून बंदोबस्तात रस्त्यावरून कचेरीत
						आणण्यात आले. त्या काळी अशा घटनांची ‘ज्या‌’ प्रमाणे चौकशी
						होत असे तशी ती झाली.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">वासुदेवरावांच्या </span>{" "}
						बरोबर असलेल्या कित्येक लोकांना शिक्षा झाल्या असत्या;
						परंतु वासुदेवरावांनी सारा दोष अत्यंत धैर्याने स्वत:वर
						घेतला व इतरांचा बचाव त्या परिस्थितीतही केला. लोकांना
						चिथावणी देणे, सरकारी खजिना लुटणे असे आरोप ठेवून
						त्यांच्यासह गकूण सात जणांना फाशीची शिक्षा सुनावण्यात
						आली. त्याच दिवशी त्यांचा गकुलता अल्पवयीन मुलगा त्यांना
						भेटावयास गेला व रडू लागला. तेव्हा वासुदेवराव त्याला
						म्हणाले की, ‘गोपाळा, असा भित्र्यासारखा रडतोस काय? डोळे
						पूस. तुझा पिता रोगाने जर्जर होऊन हंथरुणाला खिळून अथवा
						कोणत्याही दुष्कृत्याबद्दल त्याला मरावे लागत आहे असे
						नाही. तेव्हा धैर्याने या प्रसंगाला सामोरे जा.’ असे
						अखेरचे मार्गदर्शन त्यांनी पुत्राला केले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">प्रथेप्रमाणे</span>{" "}
						वासुदेवरावांना फाशीच्या आधी त्यांची शेवटची इच्छा
						विचारण्यात आली, तेव्हा ‘पेशव्यांनी दिलेली त्र्यंबकेश्वर
						देवस्थानच्या व्यवस्थापनाची (विश्वस्तपदाची) जबाबदारी अशीच
						पुढे आमच्या घराण्याकडे असावी‌’ अशी शिवसेवेची अंतिम इच्छा
						वासुदेवरावांनी प्रकट केली व ती मान्य करण्यात आली.
						लोकांमध्ये दहशत निर्माण व्हावी या हेतूने श्री
						त्र्यंबकेश्वर मंदिराच्या मागे असलेल्या गौतम तळ्याजवळील
						कचेरीसमोर गंगाधरपंत जाईल यांच्या मळ्यातील अंब्याच्या
						झाडाला फास लावून वासुदेव भगवंत यांना सोमवार दि.७/१२/१८५७
						रोजी जाहीररीत्या फाशी देण्यात आले. येवढ्यावरच समाधान न
						मानता नंतर त्यांचेवर गोळ्या झाडण्यात आल्या.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">त्र्यंबकेश्वर</span>{" "}
						देवस्थानच्या रिवाजानुसार दर सोमवारी देवाची पालखी गावातून
						फिरवली जाते (स्वारी). ही स्वारीची प्रथा त्या सोमवारी
						प्रथम स्वयंस्फूर्तीने बंद ठेवण्यात आली होती.
					</p>
					<p className="mb-5 leading-relaxed ">
						{" "}
						<span className="pl-6 sm:pl-8">ज्या </span>आंब्याच्या
						झाडाला हतात्मा वासुदेव भगवंतांना फासावर लटकवण्यात आले ते
						झाड पुढे कित्येक वर्षे फाशीचे झाड म्हणून ओळखले जात असे,
						व त्यावरची फळे कोणीही खात नसत. इ.स.१९०८ साली हे झाड
						तोडण्यात आले तेव्हा त्यात रुतलेल्या बंदुकीच्या गोळ्या
						सापडल्या.
					</p>
					<hr />
					<h3 className="text-center mt-5 text-xl sm:text-xl md:text-2xl  ">
						पद्मश्री दिगंबर वासुदेव जोगळेकर
					</h3>
					<div className="flex justify-around md:text-2xl sm:text-xl text-xl">
						<h2> जन्म - इ.स.१७९७ </h2>
						<h2>निधन - ५/१२/१८५७</h2>
					</div>
					<p className="text-center mt-5">
						घराणे - हेदवी - आवळस - मुळशी <br /> जलशास्त्रातील गक
						उत्तुंग व्यक्तिमत्व
					</p>
					<p className="mb-5 leading-relaxed ">
						{" "}
						<span className="pl-6 sm:pl-8">श्री </span>दिगंबर
						वासुदेव तथा डी.व्ही. जोगळेकर यांचे पुणे जिल्ह्यातील
						जुन्नर येथे मराठी शाळेत शिक्षण झाल्यानंतर त्यांचे पुढील
						शालेय शिक्षण धुळ्याच्या गरुड प्रशाळेत झाले. पुण्याच्या
						फर्गसन महविद्यालयामधून मुंबई विद्यापीठीची इंटर सायन्सची
						परीक्षा प्रथमश्रेणीत उत्तीर्ण करून नंतर त्यांनी
						पुण्याच्या अभियांत्रिकी महाविद्यालयातून वर्ष १९२१ मध्ये
						मुंबई विद्यापीठाची बी.ई. (स्थापत्य) पदवी प्राप्त केली.
					</p>
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8">वर्ष</span> १९२१ मध्ये
						मुंबई सरकारच्या स्पेशल इरिगेशन डिव्हिजनमध्ये त्यांनी
						शासकीय सेवेस प्रारंभ केला. मुठा उजव्या कालव्यावर त्यांनी
						काम केले, पुण्याच्या सांडपाण्याच्या विल्हेवाटीविषयक
						संशोधनही त्यांनी केले. तसेच १९२३ मध्ये त्यांच्याकडे
						कोपरगाव भागाच्या विकासाची जबाबदारी आली. त्याअंतर्गत
						त्यांनी गोदावरी उजव्या व डाव्या कालव्यांच्या
						लाभक्षेत्राचे मृदा-वर्गीकरण केले. क्षारयुक्त व पाणथळ
						जमिनी सुधारण्यासाठी जलनि:सारण योजनांचेही काम गोदावरी
						कालव्यांवर त्यांनी यशस्वीपणे केले. त्यानंतर १९२६ मध्ये
						नीरा उजवा कालवा व खडकवासला या क्षेत्रांत जलशास्त्रविषयक
						संशोधनाचे कामही त्यांनी केले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> वर्ष</span> १९४५ मध्ये
						त्यांच्याकडे केंद्रीय जल व शक्तिअन्वेषण केंद्र
						(सीडब्लूपीआरगस), खडकवासला-पुणे या संशोधन संस्थेचे
						प्रभारी संचालक म्हणून जबाबदारी सोपवण्यात आली. वर्ष १९४८
						मध्ये ते पूर्णत: संचालक झाले. वर्ष १९५२ मध्ये निवृत्त
						झाल्यानंतरही १९५७ पर्यंत त्यांना संचालकपद पुन्हा देऊ
						करण्यात आले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> वर्ष</span> १९४५ मध्ये
						त्यांच्याकडे केंद्रीय जल व शक्तिअन्वेषण केंद्र
						(सीडब्लूपीआरगस), खडकवासला-पुणे या संशोधन संस्थेचे
						प्रभारी संचालक म्हणून जबाबदारी सोपवण्यात आली. वर्ष १९४८
						मध्ये ते पूर्णत: संचालक झाले. वर्ष १९५२ मध्ये निवृत्त
						झाल्यानंतरही १९५७ पर्यंत त्यांना संचालकपद पुन्हा देऊ
						करण्यात आले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> श्री</span>.जोगळेकर
						यांनी भाकरा (पंजाब), दामोदर खोरे योजना, मयूरकाशी
						(प.बंगाल), हिराकूड (ओरिसा), मातानिआल (उ.प्र.), गांधीसागर
						(म.प्र.), निजामसागर (आंध प्रदेश), तुंगभद्रा (कर्नाटक),
						निम्न भवानी (तामिळनाडू), शिवाजीसागर (कोयना-महाराष्ट्र),
						वुल्लर सरोवर (काश्मीर) अशा विविध धरणांमधील समस्यांचा
						अभ्यास केला.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> सीडब्लूपीआरगस</span>{" "}
						मध्ये श्री जोगळेकर यांनी जलशास्त्राचे मूलभूत संशोधन व
						अनेक जलशास्त्रविषयक समस्यांचा हैड्रॉलिक मॉडेलच्या
						साहाय्याने अभ्यास केला. या विविध विषयांचे वैविध्य
						पाहण्यासारखे आहे. वेव्ह व्हेलॉसिटी, गअर गन्ट्रेनमेंट,
						फोटोइलॅस्टिक स्टडीज, भाकरा धरणाची क्ले हील, सॉइल
						मेकॅनिक्स, काळी मृदा, मृद्संधारणाचे, धरणांचे जिओफिजिकल
						अन्वेषण, पाऊस व अपवाह संबंध इ.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">ते</span> अनेक संस्थांचे
						सदस्य होते. त्यांत केंद्रीय िंसचाई तथा शक्तिमंडल
						(सीबीआयपी), इन्स्टिट्यूट ऑफ इंजीनिअर्स (इंडिया),
						इंटरनॅशनल सोसायटी ऑफ सॉइल मेकॅनिक्स ॲण्ड फाऊंडेशन
						इंजीनिअरींग, इन्स्टिट्यूट ऑफ इंजीनिअर्सची पूरविषयक
						समिती, इंटरनॅशनल असोसिगशन ऑफ हैडॉलिक रीसर्च यांचा समावेश
						होता. इंटरनॅशनल असोसिगशन ऑफ हैड्रॉलिक रीसर्चचे ते १९६०
						पासून ४ वर्षे उपाध्यक्ष व त्याच्या तांत्रिक पत्रिकेचे
						संपादकीय सल्लागारही होते. सीबीआयपीची व्होल्यूट
						सायफनविषयक विशेष समिती, भारतीय मानक संस्थान (आय.गस.आय.)
						ची फ्लुइड फ्लो मेजरमेंट उपसमिती, गंगा-ब्रह्मपुत्रा नदी
						आयोग, देशान्तर्गत जलवाहतूक समिती, स्थापत्यशास्त्र व
						जलशास्त्रविषयक संशोधन समिती इ. चेही ते सदस्य होते.
						पानशेत-खडकवासला धरणांच्या फुटीच्या चौकशीचा आयोग म्हणूनही
						त्यांजकडे दायित्व देण्यात आले होते.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> त्यांनी</span> इंटरनॅशनल
						असोसिगशन ऑफ हैड्रॉलिक रीसर्चच्या फ्रान्समधील ग्रेनोबल
						येथील ऑगस्ट १९४९ मधील तिसऱ्या, हॉलंडमधील हेग येथील ऑगस्ट
						१९५० मधील सहाव्या व कॅनडातील मॉन्ट्रियल येथील आठव्या
						परिषदांमध्ये सदस्यांचे नेतृत्व केले होते. इंटरनॅशनल
						स्टॅण्डर्डायझेशन ऑर्गनायझेशन च्या दिल्ली फेब्रुवारी १९६१
						मधील बैठकीतही त्यांचा सहभाग होता. त्यांनी दशोदेशींच्या
						जलशास्त्र-संशोधन शाळांचे दौरे केले होते व अहवाल सादर
						केले होते.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">श्री </span>जोगळेकरांना
						त्यांच्या जलशास्त्र-संशोधनाबद्दल अनेक पारितोषिके व
						सन्मान प्राप्त झाले. पंचमजॉर्जच्या राज्यारोहणाच्या
						रजत-महोत्सवानिमित्ताने दि. ६ मे १९३५ ला त्यांना रजत पदक
						मिळाले. सहाव्या जॉर्जच्या राज्यारोहणा निमित्ताने दि. १२
						मे १९३७ ला त्यांना पुन्हा रजत पदक मिळाले. दि. १ मे १९३८
						ला भारताच्या व्हाईसरॉयांनी व्यक्तिगत सन्मान म्हणून
						त्यांना रावसाहेब पदवीने गौरविले. दि. ७ सप्टें. १९५५ ला
						राष्ट्रपतींनी त्यांना पद्मश्रीचा सन्मान दिला.
						इन्स्टिट्यूट ऑफ इंजीनिअर्स (इंडिया) च्या पत्रिकेत
						प्रसिद्ध झालेल्या यूज ऑफ हैडॉलिक मॉडेल्स फॉर रिव्हर
						ट्रेिंनग, फ्लडकंट्रोल ॲण्ड इंजीनिअरींग वक्सर् या
						सर्वेात्कृष्ट लेखाबद्दल भारत सरकारच्या िंसचन
						मंत्रालयाच्या वतीने पंतप्रधान पं. नेहरूंच्या हस्ते दि. ५
						फेब्रु. १९६० ला सुवर्णपदक व रोख रक्कम प्रदान करण्यात
						आली. असेच सीबीआयपीचे सुवर्णपदक त्यांना भारत सरकारच्या
						िंसचन मंत्रालयाच्या वतीने दि. २१ नोव्हें. १९६० ला
						देण्यात आले.
					</p>

					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">श्री</span>
						.डी.व्ही.जोगळेकरांनी विविध अभियांत्रिकी महाविद्यालया
						ंमध्ये जलशास्त्र संशोधनविषयक व्याख्याने दिली. पुणे
						आकाशवाणी केंद्रावरही त्यांची भाषणे प्रसारित करण्यात आली.
					</p>
					<p className="mb-5 leading-relaxed ">
						{" "}
						<span className="pl-6 sm:pl-8"> त्यांनी</span>{" "}
						जलशास्त्रविषयक विपुल लेखन केले. ते लेखन मुंबई
						पीडब्ल्यूडी टेक्निकल पेपरमध्ये तसेच इंटरनॅशनल असोसिगशन
						ऑफ हैड्रॉलिक रीसर्च, इंटरनॅशनल नेव्हिगेशन कॉन्फरन्स,
						इंटरनॅशनल कॉन्फरन्स ऑन कोस्टल इंजीनिअरींग, आंतरराष्ट्रीय
						िंसचन व नि:सारण आयोग इ. च्या पत्रिका, व सीबीआयपीची
						इरिगेशन ॲण्ड पॉवर पत्रिका, इन्स्टिट्यूशन ऑफ इंजीनिअर्स
						(इंडिया), केंद्रीय जलआयोगाची भगीरथ पत्रिका, नॅशनल
						ॲकेडेमी ऑफ सायन्सेस, पुण्याच्या दीपिका व चित्रमय जगत या
						पत्रिका आदि पत्रिकांमध्ये प्रसिद्ध झाले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">या</span> लिखाणातील
						विषयांचे वैविध्य पाहण्यासारखे आहे : कालवा अस्तरण,
						स्टण्डिग वेव्ह फ्लूम, नियंत्रित िंसचन, कालव्यांमधील गाळ,
						डिसिपेशन ऑफ गनर्जी, िंसचनाची गट (ब्लॉक)
						प्रणालीवितरिकांचे संकल्पन, स्पेशल इरिगेशन डिव्हिजनची जून
						१९१६ मध्ये स्थापना झाल्यापासूनचे संशोधनकार्य. पुण्याच्या
						सांडपाण्याची विल्हेवाट, बंदरामधील गाळ, जलनि:सारणविषयक
						नियमसंहिता, सीडब्लूपीआरगस च्या उपलब्धी इत्यादी, इत्यादी.
						त्यांचे दि. १२ डिसेंबर १९७५ ला निधन झाले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">अशा</span> जलशास्त्रातील
						उत्तुंग व्यक्तिमत्वास व देशाच्या सुपुत्रास त्याच्या
						जन्मशताब्दीच्या निमित्ताने अभिवादन{" "}
					</p>
					<hr />
					<h3 className="text-center mt-5 text-xl sm:text-xl md:text-2xl  ">
						{" "}
						जोगळेकर कुलातील सर्वश्रेष्ठ लेखक <br />
						सदाशिव आत्माराम जोगळेकर
					</h3>
					<div className="flex justify-around md:text-2xl sm:text-xl text-xl">
						<h2> जन्म - इ.स.१७९७ </h2>
						<h2>निधन - ५/१२/१८५७</h2>
					</div>
					<p className="text-center mt-5">घराणे - हेदवी - मोकादम</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> जोगळेकर-कुलाच्या</span>{" "}
						आजवरच्या इतिहासातील - सर्वश्रेष्ठ संशोधक-लेखक म्हणून
						स.आ.तथा भाऊसाहेब जोगळेकरांचा उल्लेख करावा लागेल. केवळ
						लेखनकर्तृत्वच नव्हे, तर भाऊसाहेबांचे सगळे जीवनच
						लोकविलक्षण होते. जे ६५ वर्षांचे आयुष्य त्यांना मिळाले
						त्याचे त्यांनी सोने केले असेच म्हटले पाहिजे.
					</p>
					<p className="mb-5 leading-relaxed ">
						गम्‌.ग., गल्‌गल्‌.बी. या पदव्या प्राप्त केलेल्या
						भाऊसाहेबांनी पुण्यात वकिलीचा व्यवसाय केला. मुख्यत:
						सरकारी वकील म्हणून या क्षेत्रात ते वावरले. अखंड वाचन,
						कायद्याचा सूक्ष्म अभ्यास आणि बिनतोड युक्तिवाद यांमुळे
						त्यांची छाप सर्वांवर पडत असे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">गक</span> कुटुंबवत्सल
						रसिक गृहस्थ म्हणून भाऊसाहेब सर्वांस माहीत होते.
						प्रवासाची, तऱ्हेतऱ्हेच्या दुर्मिळ वस्तू जमविण्याची
						त्यांना विलक्षण आवड होती. पैसा सचोटीने मिळवावा आणि
						सढळपणाने तो खर्च करावा, भरपूर कष्ट करावेत आणि जीवनाचा
						आनंद सर्वांगांनी लुटावा ही त्यांची जीवनदृष्टी होती.
						चित्र, शिल्प, संगीत अशा प्रमुख ललितकलांची आवड त्यांनी
						जोपासली होती. बागकाम करण्यातही ते दिवसाचे अनेक तास खर्च
						करीत.
					</p>
					<p className="mb-5 leading-relaxed ">
						{" "}
						<span className="pl-6 sm:pl-8"> साहित्याकडे</span> ते
						वळले ते काहीसे योगायोगाने. १९२० ते ३० या दशकात मराठीत
						अनेक रहस्यकथा (िंकवा गुप्तहेरकथा) दर महिन्याला प्रकाशित
						होत असत. ‘गक आणा माला‌’ िंकवा ‘चार आणे माला‌’ या नावाने
						त्या ओळखल्या जात. भाऊसाहेबांनी त्या काळात पन्नासच्यावर
						गुप्तहेरकथा लिहिल्या. त्यांनी निर्माण केलेला ‘रामराव‌’
						हा गुप्तहेर वाचकवर्गात विशेष प्रिय होता. अशा पुस्तकावर
						लेखकाचे नाव छापले जात नसे. त्यामुळे सातत्याने हे लेखन
						करूनही भाऊसाहेबांना लेखक म्हणून प्रसिद्धी मिळाली नाही.
						अर्थलाभ मात्र झाला, त्यामुळेच ते मनासारखी हौसमौज करू
						शकले आणि रसिकतेने जीवन जगू शकले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">तरुण</span> वयात भाऊसाहेब
						जोंसेफ मॅझिनीच्या जीवनकार्यामुळे प्रभावित झाले होते.
						गुप्तहेरकथांमुळे त्यांना प्रसिद्धी मिळाली नसली तरी
						‘मानवी कर्तव्ये‌’, ‘जोंसेफ मॅझिनी‌’ व ‘स्वातंत्र्याची
						मूलतत्त्वे‌’ या त्यांच्या सुरुवातीच्या (स्वतंत्र व
						अनुवादित) पुस्तकांमुळे गक ध्येयवादी लेखक म्हणून त्यांचे
						नाव झाले होते. ‘यशवंत‌’ मासिकाचे जाणकार संपादक म्हणूनही
						ते मान्यता पावले होते. अल्पकाल टिकलेल्या या मासिकाने
						मराठी नियतकालिक-सृष्टीत आपला ठसा उमटविला आहे. अनेक नव्या
						लेखकांना ‘यशवंत‌’ ने प्रथम संधी दिली. या मासिकाच्या
						कथास्पर्धेतच य.गो.जोशी यांना प्रथमक्रमांकाचे बक्षीस
						मिळाले. हे जोशी नंतर कथाकार म्हणून विख्यात झालेच, शिवाय
						त्यांनी स.आ.जोगळेकर आणि शं.वा.दांडेकर यांच्या सहकार्याने
						१९४७ मध्ये ‘प्रसाद‌’ मासिक सुरू केले. (या मासिकाने
						नुकतीच पन्नास वर्षांची वाटचाल पूर्ण केली आहे). प्रसाद
						मासिकामुळे भाऊसाहेब संशोधनात्मक लेखन नियमितपणे करू
						लागले. घारापुरी, सह्याद्री व महाराष्ट्र परिचय हे त्यांचे
						महत्त्वाचे ग्रंथ प्रथमत: प्रसाद मासिकामध्ये लेखमालेच्या
						स्वरूपात प्रकाशिक झाले आहेत. ‘गाथासप्तशती‌’ या असामान्य
						संपादित ग्रंथाचे प्रकाशनही ‘प्रसाद प्रकाशन‌’ या संस्थेचे
						आहे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> श्रेष्ठ </span>चित्रकार
						दीनानाथ दलाल हे भाऊसाहेबांचे घनिष्ठ मित्र होते.
						त्यांच्या ‘दीपावली‌’ वार्षिकासाठी भाऊसाहेब नियमितपणे कथा
						लिहीत असत. या कथा रसिकतेला आवाहन करणाऱ्या शृंगारकथा
						आहेत. विस्ताराने त्या दीर्घही आहेत. ‘अहिल्या‌’, ‘यथा
						काष्ठं च‌’, ‘प्रवृत्तिरेषा भूतानाम्‌’ यांसारख्या
						त्यांच्या कथा मराठी कथावाङ्मयाचे भूषण ठरल्या आहेत.
						नाट्यपूर्ण घटना, वेधक व्यक्तिचित्रण, अनुरूप
						वातावरणनिर्मिती आणि रसाळ निवेदनशैली यांमुळे त्यांची
						प्रत्येक कथा ही अजोड कलाकृती ठरली आहे. त्यांच्या नावावर
						‘शृंगारनायिका‌’ या शीर्षकाचा कथासंग्रहही आहे. या कथा
						मात्र तुलनेने सौम्य व प्रापंचिक समस्या मांडणाऱ्या आहेत.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> कथालेखक</span> म्हणून
						मोठी योग्यता असली तरी भाऊसाहेबांचे नाव मराठी
						साहित्यविश्वात चिरस्थायी झाले आहे ते त्यांच्या
						संशोधनात्मक साहित्यामुळे. विधायक वृत्तीचा संशोधक
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> ‘सह्याद्री‌’</span> या
						ग्रंथामुळे संशोधक म्हणून भाऊसाहेब प्रसिध्दीला आले.
						सह्याद्री हा केवळ गक पर्वत नाही, तर तो महाराष्ट्राचा
						निर्माता आहे. महाराष्ट्राची व मराठी समाजाची जी जी
						वैशिष्ट्ये आहेत त्यांचे मूळ सह्याद्री पर्वतात आहे.
						सह्याद्रीवरील गडकोट ही येथल्या पराक्रमाची खूण आहे, तर
						त्यातील लेणी हे कला व संस्कृतीचे रूप आहे. परशुराम हा
						विष्णूचा अवतार सह्याद्रीच्या आश्रयानेच झाला आहे, तर
						महाराष्ट्राचा लोकोत्तर राजा शिवाजी हाही सह्याद्रीच्या
						सान्निध्यातच वाढला. ज्योतिर्लिंग हे सह्याद्रीचे वैशिष्टय
						आहे. सह्याद्रीच्या कणखरपणामुळे बौध्दांची, िंहदूची व
						जैनांची महत्त्वाची लेणी सह्याद्रीतच आहेत. सह्याद्रीने
						महाराष्ट्राच्या भूमीची घडण केलेली आहे. इतकेच नव्हे तर
						महाराष्ट्राच्या मनोभूमीचीही बांह्यत: खडबडीत, पण
						अंतर्यामी गकनिष्ठ, बांह्यत: निर्जल, अंतर्यामी वत्सल
						सह्याद्री हे या वृत्तीचे आदिकारण म्हणूनच सह्याद्री
						परमपवित्र आहे, हिन्दुस्थानातील पर्वतात अद्वितीय आहे.
					</p>

					<h2>महाराष्ट्र - परिचय</h2>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">१९५४</span> मध्ये ‘प्रसाद
						प्रकाशन‌’ या संस्थेने ‘महाराष्ट्र परिचय‌’ हा बृहद्-ग्रंथ
						प्रकाशित केला. िंच.ग.कर्वे, य.गो.जोशी यांच्याबरोबर
						स.आ.जोगळेकर हे या ग्रंथाचे संपादक आहेत. महाराष्ट्राची
						सर्वांगीण माहिती देणारा हा गक अपूर्व कोश आहे. संपादकांनी
						त्या वेळी उपलब्ध असलेल्या अनेकांचे लेखनसाहाय्य घेतले आहे
						हे खरे, तथापि महाराष्ट्राचा इतिहास, संस्कृती,
						महाराष्ट्रातील समाजजीवन व त्यातील स्थित्यंतरे यासंबंधीचे
						विवेचन कर्वे व जोगळेकर यांच्या हातचे आहे. ज्ञानकोशकार
						केतकर यांनी ‘प्राचीन महाराष्ट्र‌’ हा संशोधनाचा मोठा
						उद्योग सुरू केला होता; पण त्यांच्या हातून ते काम पूर्ण
						झाले नाही. ‘महाराष्ट्र-परिचय‌’ च्या रूपाने ते काम पूर्ण
						करण्याचे श्रेय कर्वे-जोगळेकर यांच्याकडे जाते.
						शृंगारनायिका
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">सुप्रसिध्द</span>{" "}
						चित्रकार दीनानाथ दलाल व भाऊसाहेब जोगळेकर यांच्यात
						विलक्षण स्नेहभाव होता. दोघेही रसिक व मर्मज्ञ कलावंत
						होते. रंगरेषांच्या आधारे चित्रकार दलाल तर
						शब्दयोजनेद्वारा भाऊसाहेब भावदर्शन घडविण्यात अत्यंत
						निष्णात होते. या दोघांच्या अतूट स्नेहाचे स्मारक म्हणून
						‘शृंगारनायिका‌’ या अभिनव ग्रंथाकडे पाहता येते. संस्कृत
						परंपरेने साहित्यशास्त्रात व कामशास्त्रात नायिकावर्णन
						करण्यात येते. त्यातही अष्टनायिकांना विशेष प्राधान्य आहे.
						वेगवेगळ्या कारणांसाठी नायिकांचे इतर अनेक भेद मानले जात
						असले तरी, अष्टनायिका ही संज्ञा रूढ व लोकप्रिय आहे. या
						विषयाकडे संशोधकाच्या शास्त्रशुद्ध आणि रसिकाच्या निरोगी
						दृष्टीने पाहिले पाहिजे या जाणिवेतून दलाल-जोगळेकर यांनी
						शृंगारनायिका या पुस्तकाची निर्मिती केली. यात नायिकांच्या
						रंगाकृती व अनेक रेखाकृती दलालांच्या आहेत. तर
						त्यासंबंधीचे मार्मिक विवेचन भाऊसाहेबांचे आहे. मानवी
						जीवनाच्या सातत्याला व स्त्री-पुरुषांतील साहचर्याला
						शृंगाराचे आधिष्ठान आवश्यक व उपकारक आहे. या संबंधातले उथळ
						समज दूर करून निकोप वृत्तीने आणि अभिजात रसिकतेने
						शृंगारनायिकांचे महत्त्व विशद करण्याचा हा प्रयत्न
						त्यामुळेच स्वागतार्ह ठरतो. भाऊसाहेबांच्या कथावाङ्मयात
						शृंगारकथांना जसे लक्षणीय स्थान आहे त्याप्रमाणे त्यांच्या
						विवेचक ग्रंथामध्ये शृंगारनायिका या ग्रंथाची गणना करावी
						लागेल. मराठीत तरी हे गकमेव-अद्वितीय असे पुस्तक आहे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">भाऊसाहेब</span>{" "}
						जोगळेकरांनी १९५६ मध्ये प्रकाशित केलेला ‘‘गाथासप्तशती‌’’
						हा ग्रंथ म्हणजे त्यांनी मराठी भाषेला अर्पण केलेली भरजरी
						पैठणीच आहे असे म्हटले पाहिजे. प्राचीन भारतात
						‘महाराष्ट्री प्राकृत‌’ ही गक महत्त्वाची लोकभाषा होती,
						सरस काव्यग्रंथांनी ती समृद्ध होती. लोकाश्रयाप्रमाणे तिला
						राजाश्रयही होता. सातवाहन वंशातील हाल हा गक रसिक व
						अभिरुचिसंपन्न राजा होता. तो स्वत: कवी होताच; पण
						इतरांच्या काव्याचा गुणग्राहकतेने संग्रह करणारा होता.
						त्याने निवडक अशा सातशे रचनांचा संग्रह करून त्यातून
						‘‘गाथासप्तशती‌’’ हा अप्रतिम ग्रंथ सिद्ध केला. साहित्य व
						संस्कृती यांच्या बाबतीत प्राचीन काळात संस्कृतचे वर्चस्व
						असल्यामुळे या श्रेष्ठ प्राकृत ग्रंथाची तशी उपेक्षाच
						झाली; पण काव्यशास्त्रातील जाणकारांना या ग्रंथाची थोरवी
						माहीत होती. या ग्रंथाकडे भाऊसाहेब काहीसे योगायोगाने
						वळले. या ग्रंथाचे सौंदर्य लक्षात आल्यावर त्याचे संपादन
						आपण केलेच पाहिजे असा त्यांच्या मनाने ध्यास घेतला.
						त्यासाठी प्रौढ वयात ते प्राकृत भाषा शिकले. अनेक आनुषंगिक
						शास्त्रांचे अध्ययन केले. आयुष्यातली तीस-बत्तीस वर्षे या
						ग्रंथाची तयारी करण्यासाठी त्यांनी खर्ची घातली. त्याचे
						फलित म्हणजे सुमारे ११५० पृष्ठांचा हा ग्रंथराज त्यांच्या
						हातून सिद्धीस गेला. योजलेल्या कामासाठी गखादा संशोधक किती
						कष्ट उपसतो त्याचा मराठीतला हा अभिमानास्पद नमुना होय.
						रसिकता, सौंदर्यलोलुपता, महाराष्ट्रनिष्ठा, लोकसंस्कृतीचा
						अभिमान तसेच चिकित्सक व चोखंदळ वृत्ती या सगळ्यांचा उत्तम
						मेळ जमून आल्यामुळे या ग्रंथाची महती उत्तरोत्तर वाढतच
						जाणार आहे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> गाथासप्तशती</span> या
						ग्रंथाची पूर्वार्ध व उत्तरार्ध अशी विभागणी करण्यात आली
						आहे. (कोषवाङ्मयाला अनुसरून त्याला ‘प्रस्तावनाखंड‌’ व
						‘शरीरखंड‌’ अशी नावे देण्यात आली आहेत.)
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> उत्तरार्धात</span> सातशे
						गाथांचा (म्हणजे प्रत्येकी दोन पंक्तींच्या स्फुट
						कवितांचा) जो संग्रह करण्यात आला आहे, त्यात प्रथमत:
						महाराष्ट्री प्राकृत भाषेतील मूळ गाथा, त्यानंतर त्याची
						संस्कृत छाया (म्हणजे संस्कृतमध्ये होणारा अनुवाद),
						त्यानंतर प्रत्येक गाथेचा काटेकोरपणे केलेला मराठी अनुवाद
						आणि शेवटी आवश्यक त्या त्या ठिकाणी गाथेतून सूचित होणारा
						आशय अशी मांडणी भाऊसाहेबांनी केली आहे. प्राकृत भाषेचा
						परिचय आज फारसा कुणाला होत नाही आणि संस्कृतचा अभ्यासही
						अल्प प्रमाणातच होत असतो. अशा स्थितीत भाऊसाहेबांचे हे
						कार्य विशेषच मोलाचे ठरते. संस्कृत-प्राकृताचा गंध
						नसलेल्या सर्वसाधारण वाचकाने केवळ मराठी अनुवाद व त्यावरील
						भाष्य वाचायचे ठरविले तरीही त्याला या असामान्य काव्याचा
						आस्वाद घेता येईल. मात्र सप्तशती म्हणजे मोजून सातशे असे
						मात्र नाही. कारण अनेक पोथ्यांमधून अधिक गाथाही आढळतात.
						अशा जास्तीच्या ३०६ म्हणजे गकूण गक हजार सहा गाथांचा
						परामर्श भाऊसाहेबांनी घेतला आहे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">या</span> ग्रंथातील मूळ
						गाथांचा विभाग आस्वाद्य आणि रमणीय आहेच, पण त्याची ४४०
						पृष्ठांची प्रदीर्घ प्रस्तावना संशोधक भाऊसाहेबांच्या सर्व
						गुणवैशिष्ट्यांचे दर्शन घडविणारी आहे. दोन हजार
						वर्षांपूर्वीच्या महाराष्ट्राचे लोकजीवन कसे होते ते
						त्याच्या सर्व तपशिलासकट येथे प्रकट झाले आहे.
						महाराष्ट्रातील नद्या, पर्वत, वनश्री, पशु, पक्षी, धार्मिक
						जीवन, सामाजिक जीवन, तत्कालीन लोकांचा आहार, विहार, खेळ,
						सण व उत्सव, वस्त्रे-प्रावरणे, अलंकार, आरोग्य,
						जाती-जमाती, आर्थिक जीवन, शेती व शेतकरी जीवन, त्या
						काळातील स्त्री-पुरुषांमधील प्रेमजीवन, त्यातला निरोगीपणा
						या सगळ्या विषयांचे इतके माहितीपूर्ण व साधार वर्णन
						ग्रंथकाराने केले आहे की, यासाठी त्याने उपसलेले कष्ट किती
						अपार आहेत या जाणिवेने आश्चर्य वाटल्याशिवाय राहात नाही.
						केवळ राजे-महाराजे नव्हेत, तर सर्वसामान्य माणूस आपल्या
						विवेचनाचा केंद्रबिंदू मानणारा भाऊसाहेबांसारखा अभ्यासक
						आणि त्याला भरभरून खाद्य पुरविणारा गाथासप्तशतीसारखा
						अपूर्व ग्रंथ यांचा समसमासंयोग जुळून आल्याची प्रचीती
						वाचकास सर्वत्र येत राहते. ‘सह्याद्री‌’ आणि
						‘गाथासप्तशती‌’ ग्रंथांबद्दल महाराष्ट्राने भाऊसाहेबांचे
						ऋणी असले पाहिजे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">आयुष्याच्या</span> शेवटी
						भाऊसाहेबांनी आणखी गक मोठा प्रकल्प हाती घेतला होता व तो
						पुराही केला होता. प्राचीन काळातली मानवी संस्कृती
						नद्यांच्या आश्रयाने बहरली आहे, हे लक्षात घेऊन त्यांनी
						‘गोदावरी‌’ (अर्थात महाराष्ट्र संस्कृती) या ग्रंथाची
						निर्मिती केली होती. सातवाहन, चक्रधर, ज्ञानेश्वर,
						देवगिरीच्या यादवांची राजवट, गकनाथ आणि रामदास हे सर्वजण
						गोदावरीच्या काठी असलेल्या पैठण परिसरातले. महाराष्ट्र
						संस्कृती या सर्वांमुळे घडली. महाराष्ट्रभक्त असलेल्या
						भाऊसाहेबांना ‘गोदावरी‌’ वर ग्रंथ लिहावासा वाटणे
						स्वाभाविक होते. ग्रंथ पूर्ण झालाही; पण १२ जुलै १९६१ च्या
						पानशेत प्रलयात त्यांच्या सर्व कष्टांवर पाणी पडले आणि
						सगळे चिखलमय होऊन गेले. भाऊसाहेबांच्या त्या थोर ग्रंथाला
						आपण कायमचे मुकलो.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> गकाच</span> वेळी मर्मज्ञ
						रसिक आणि साक्षेपी संशोधक असणारा भाऊसाहेबांसारखा
						प्रतिभावंत आणि ज्ञानवंत लेखक आपल्या कुळात जन्मला याचा
						कुणाही जोगळेकराला अभिमान वाटेल. त्यांच्या दोन्ही
						कन्यांनी (श्रीमती प्रतिभा काळे व श्रीमती मंगला बर्वे)
						वडिलांची रसिकता आपल्या लेखनाने जोपासली आणि त्यांचे जामात
						कै.अच्युत बर्वे हे तर मराठीतले आघाडीचे कथा-कादंबरीकार
						म्हणून मान्यता पावले.
					</p>
					<h1 className="flex items-end justify-end">
						गं.ना.जोगळेकर.
					</h1>
				</div>
				<div
					className="flex items-end justify-end my-5"
					onClick={scrollToTop}
					aria-label="scroll to up"
				>
					<ChevronUpIcon className="size-8 bg-orange-600 rounded-full text-white" />
				</div>
				<hr />
			</div>
		</div>
	);
};
