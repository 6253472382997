export const relationHandle = {
    father: {
        sourceHandle: "d",
        targetHandle: "a",
        color: '#8BCD50',
        type: "V",
    },
    mother: {
        sourceHandle: "d",
        targetHandle: "a",
        color: '#CB8E84',
        type: "V",
    },
    brother: {
        sourceHandle: "c",
        targetHandle: "b",
        color: '#F3BC50',
        type: "H",
    },
    sister: {
        sourceHandle: "b",
        targetHandle: "c",
        color: '#FA53A0',
        type: "H",
    },
    son: {
        sourceHandle: "a",
        targetHandle: "d",
        color: '#01DEE6',
        type: "V",
    },
    daughter: {
        sourceHandle: "a",
        targetHandle: "d",
        color: 'pink',
        type: "V",
    },
    husband: {
        sourceHandle: "c",
        targetHandle: "b",
        color: '#25C0C0',
        type: "H"
    },
    wife: {
        sourceHandle: "b",
        targetHandle: "c",
        color: '#FF66E9',
        type: "H",
    },
    "husband-wife": {
        sourceHandle: "c",
        targetHandle: "b",
        color: '#25C0C0',
        type: "H"
    },
    "wife-husband": {
        sourceHandle: "b",
        targetHandle: "c",
        color: '#25C0C0',
        type: "H"
    },
    "father-in-law": {
        sourceHandle: "d",
        targetHandle: "a",
        color: '#8BCD50',
        type: "V",
    },
    "mother-in-law": {
        sourceHandle: "d",
        targetHandle: "a",
        color: '#CB8E84',
        type: "V",
    },
    "son-in-law": {
        sourceHandle: "a",
        targetHandle: "d",
        color: '#01DEE6',
        type: "V",
    },
    "daughter-in-law": {
        sourceHandle: "a",
        targetHandle: "d",
        color: 'pink',
        type: "V",
    },
    "brother-in-law": {
        sourceHandle: "c",
        targetHandle: "b",
        color: '#F3BC50',
        type: "H",
    },
    "sister-in-law": {
        sourceHandle: "b",
        targetHandle: "c",
        color: '#FA53A0',
        type: "H",
    },
    "adopted-daughter": {
        sourceHandle: "a",
        targetHandle: "d",
        color: 'pink',
        type: "V"
    },
    "adopted-son": {
        sourceHandle: "a",
        targetHandle: "d",
        color: '#01DEE6',
        type: "V",
    },
}

// export const twodeg_relation = [
//     "uncle", "aunt", "nephew", "niece",
//     "grandfather", "grandmother", "grandson", "granddaughter",
//     "wife", "husband", "sister", "brother",
//     "mother", "father", "daughter", "son",
//     "adoped-son", "adoped-daughter", "sister-in-law", "brother-in-law",
//     "mother-in-law", "father-in-law", "daughter-in-law", "son-in-law"
// ];
//
// export const reverse_relation_def = {
//     female: {
//         "father": "daughter",
//         "mother": "daughter",
//         "daughter": "mother",
//         "son": "mother",
//         "sister": "sister",
//         "brother": "sister",
//         "husband": "wife",
//     },
//     male: {
//         "father": "son",
//         "mother": "son",
//         "daughter": "father",
//         "son": "father",
//         "sister": "brother",
//         "brother": "brother",
//         "wife": "husband",
//     }
// }
