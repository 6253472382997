import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { useEffect } from 'react';
export const Chapter18 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	useEffect(() => {
			// Scroll to top when the page is rendered
			window.scrollTo(0, 0);
		}, []);
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					जोगळेकर कुलांसाठी कुळाचार
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed text-justify">
						जोगळेकर कुटुंबे चित्पावन ब्राह्मण समाजापैकी गक भाग
						असल्याने, चित्पावनांचे प्रमुख कुळाचार जोगळेकर
						कुटुंबीयांत चालत राहिलेले आढळतात. जोगळेकर कुटुंबीय, हे
						अत्रि गोत्रीय चित्पावन ब्राह्मण म्हणून काल ओघांत जे
						कुळाचार जपत आले आहेत ते कुळाचार खाली नमूद केले आहेत. ते
						कुळाचार मिळालेल्या माहितीवरून अभ्यास केल्यावर, त्यांची
						टक्केवारी अशी दिसते.
					</p>
					<p>
						१. बोडण (वार्षिक व नैमित्तिक मिळून) सुमारे ५२% <br />
						२. नवरात्र व देवी बसविणे (उकडीची, खड्याची, मुखवट्याची)
						२५% <br />
						३. गणपती (दीड, तीन, पाच, गौरी विसर्जनाबरोबर िंकवा १०
						दिवसांचा) १५% <br />
						४. गोंधळ ५% <br /> ५. इतर ३%
					</p>
					<p className="mb-5 leading-relaxed mt-3">
						<span className="pl-6 sm:pl-8"> वरील</span> माहितीवरून
						असे आढळते की, जोगळेकर कुटुंबीयामध्ये ‘बोडण‌’ हा कुळाचार
						प्रामुख्याने आहे. ‘बोडण‌’ भरणे केव्हापासून सुरू झाले
						याचा लेखी पुरावा सापडत नसला, तरी ‘बोडण‌’ हा कुळाचार
						प्राचीन आहे असे म्हटल्यास वावगे वाटण्यासारखे नाही.
					</p>
					<h1 className="text-center font-bold my-5">
						प्रमुख कुळाचार - बोडण
					</h1>
					<p className="mb-5 leading-relaxed mt-3">
						{" "}
						‘बोडण‌’ हा शब्द संस्कृत भाषेतील ‘वर्धन‌’ या शब्दापासून
						आलेला आहे असे वाटते. कुटुंबाची वृद्धी म्हणजे ‘वर्धन‌’
						झाल्यावर म्हणजेच वंश-वाढ झाल्यावर, बोडण भरून देवीला
						म्हणजेच शक्तीला तृप्त करण्याची कुळाची ही रीत चालत आलेली
						दिसते. गौरीहारचे वेळी पुजलेली अन्नपूर्णादेवी, नववधू
						आपल्याबरोबर पतिगृही नेते आणि ह्या देवीच्या कृपादृष्टीने
						संसारांतील संकटे दूर होऊन वंशवृध्दी होते ह्या समजुतीला
						अनुसरून वंशवृध्दी झाल्यावर, बोडण भरण्याची पध्दत, कुळाचार
						म्हणून रुढ झाली असावी.
					</p>
					<p className="mb-5 leading-relaxed mt-3">
						बोडण हे मंगळवारी, शुक्रवारी म्हणजे देवीच्या वारी िंकवा
						रविवारी भरले जाते. चातुर्मास चैत्र व पौष महिने सोडून इतर
						महिन्यांतच, म्हणजे दसरा दिवाळी या मुख्य सणानंतर बोडण
						भरतात. काही कुटुंबात नवरात्रात बोडण भरण्याचा, तर काही
						कुटुंबात दरवर्षी बोडण भरण्याचा कुळाचार आहे. बहुतेक
						कुटुंबांत विवाह िंकवा मंगलकार्याचे किंवा मुलाच्या
						जन्मानंतरचे बोडण भरण्याची रीत आहे. मुलीच्या जन्माचे बोडण
						भरीत नाही; पण अलीकडे ‘अपत्य‌’ जन्मानंतर म्हणजे मुलगा
						िंकवा मुलगी दोन्हींच्या जन्मानंतर ‘बोडण‌’ भरण्याची रीत
						काही कुटुंबीयांनी अंगिकारलेली दिसते. ठरल्यावेळी काही
						अडचणी उद्भवल्यास बोडण न भरता आल्यास पुढे सोयीप्रमाणे गक
						िंकवा राहिलेली अनेक बोडणे भरता येतात. हल्ली, कुलदेवता
						योगेश्वरीदेवीच्या दर्शनाला जाऊन तेथेच बोडण भरण्याची सोय
						तेथील उपाध्याय / गुरुजींकडे करण्यात आलेली आहे. गर्भवती
						स्त्री घरात असल्यास बोडण भरीत नाहीत, तसेच बोडण
						भरण्याच्या वेळी कुठल्याही गर्भवती स्त्रीने त्यात भाग
						घ्यावयाचा नसतो. पारंपरिक, ‘बोडण‌’ भरण्याची प्रथा,
						थोड्याफार फरकाने, गकाच पद्धतीने चालू असल्याचे दिसते.
					</p>
					<h1 className="text-center font-bold my-5">
						बोडण भरण्याची पद्धत व विधी
					</h1>
					<p className="mb-5 leading-relaxed mt-3">
						घरातील मुख्य सुवासिनी स्त्री, कुटुंबाबाहेरील तीन
						सुवासिनी व गक नाक टोचलेली व दहाचे आत वय असलेली कुमारिका
						मिळून गकूण पाच जणींनी हे बोडण भरावयाचे असते. सहावी गक
						सुवासिनी स्त्री मदतीसाठी असते. बोडणाचे ठरलेल्या योग्य
						दिवशी मुख्य स्त्रीने डोक्यावरून न्हाऊन सर्व स्वयंपाक
						सोवळ्याने करावयाचा असतो. स्वयंपाकांत वरण-भात, खीर, पुरण,
						वडा व दह्यातील कोंिंशबीर गवढे असावेच लागते. पुजेची जागा
						स्वच्छ सारवलेली िंकवा पुसलेली असावी. त्या जागेवर,
						बोडणाची विशिष्ट पद्धतीची, रांगोळी काढून त्यावर स्वच्छ
						पाट ठेवावा. त्यावर अक्षता ठेवून त्यावर कल्हई नसलेली
						तांब्याची िंकवा पितळेची मोठी परात ठेवावी. त्यानंतर
						सुवासिनी व कुमारिका सर्वजणी मिळून तुळशीची पूजा करतात,
						तुळशीपूजा आटोपल्यावर, त्यांच्या पायावर दूध व कोमट पाणी
						घालून, त्यांचे ओवाळून व स्वागत करून घरात घेतात. घरातल्या
						मुख्य सुवासिनीने, पूजेला बसताना पूर्वदिशेकडे तोंड करून
						बसावे व कुमारिकेला उजव्या हाताला बसवावे. बाकीच्या सर्व
						सुवासिनींना भोवताली पाटावर बसवावे. हळद घातलेल्या कणकेची
						बैठक व लोड असे बसण्याचे व टेकण्याचे आसन देवीसाठी मुख्य
						सुवासिनी तयार करते. देवीसाठी कणकेची लेणी (अलंकार) तयार
						करतात. घरची मुख्य सुवासिनी अन्नपूर्णा देवीची आसनावर
						स्थापना करते. त्याआधी गणपती (सुपारी)ला पंचामृती स्नान
						घालून, परातीत विड्याच्या पानावर ठेवून पूजा करते. मग
						देवीला पंचामृती स्नान घालून, कणकेचे अलंकार,
						हळद-कुंकू-फुले वाहून पूजा करतात. केळीच्या पानावर पाच
						नैवेद्य तयार करून ते परातीत ठेवतात व सहावा नैवेद्य
						परातीबाहेर कुमारिकेसाठी ठेवतात, तो कुमारिकेने भोजनास
						घ्यावयाचा असतो. कणकेचे पाच दिवे लावून सर्वजणी देवीला
						भंडारा (कुंकू लावलेल्या गोल लाट्या) वाहतात व दिव्यांनी
						आरती करतात व नंतर पाचही दिवे परातीत नैवेद्यावर ठेवतात.
						सर्वजणी हाताची आेंजळ परातीवर धरतात व त्यावर सहावी
						सुवासिनी पंचामृत घालते. दिव्यांवर पंचामृत पडल्यावर दिवे
						शांत होतात. त्यानंतर सर्व नैवेद्य परातीत कालवितात. त्याच
						वेळी सोन्याची साखळी गुंडाळलेल्या पळीने पंचामृताचे पदार्थ
						परातीत ओतत राहतात व कालविताना देवी तृप्त झाली का ? काही
						हवे आहे का ? असे कुमारिकेला विचारतात. तिने होय
						म्हणेपर्यंत परातीत दही-दूध घालीत राहतात. देवी तृप्त
						झाल्यावर घरातील सर्व लहान-थोर मंडळी देवीस नमस्कार करतात
						व कुमारिकेकडून अंगारा लावून घेतात. सर्वजणी, पुरणांनी
						देवीला स्वच्छ करतात व धुऊन हळद-कुंकू वाहून, तोंडाला साखर
						लावून देवीला परत घरच्या देवांत नेऊन ठेवतात. कालविलेल्या
						बोडणाचा कोणताही भाग अपवित्र ठिकाणी पडू नये म्हणून
						सुवासिनी आपले हात त्याच परातीत, हाताला पुरण लावून स्वच्छ
						धुतात व सर्व कालवण, गर्भवती नसलेल्या गायीला घालतात िंकवा
						नदीत िंकवा वाहत्या पाण्यात विसर्जित करतात. कुमारिका व
						सुवासिनींना जेवायला घालून, ओटी भरून बोडणाची िंकवा बोडण
						भरण्याच्या व्रताची सांगता करतात.
					</p>
					<h1 className="text-center font-bold my-5">बोडणाची आरती</h1>
					<p className="mb-5 text-center text-xl">
						आम्हा कुळीची जोगेश्वरी तू
						<br /> सुख संसारी तुजला पूजिते <br /> माय भवानी तुजला
						नमिते <br /> अंबिके तुझे बोडण भरिते ॥धृ॥ <br /> शुद्ध
						गोमये भूमी सारवुनी <br />
						रंगवल्लिका त्यावरी रेखुनी <br />
						परातीमध्ये तुज बैसविते ॥१॥ अंबिके.... <br />
						सुवासिनींना कुमारिकेते
						<br />
						पाचारुनिया गृही आणिते
						<br /> हळदी-कुंकुमा त्यांसी लाविते ॥२॥ अंबिके.... <br />{" "}
						पंचामृति तुज करुनी पूजनी <br />
						धूपदीप कर्पुरा लावुनी पिष्ट दागिने तुजला वाहते ॥३॥
						अंबिके....
						<br />
						पाकसिद्धिसह करुनि तयारी <br /> वडे तळुनिया पुरण आणि खिरी
						<br />
						नैवेद्य मग तुज दाखविते ॥४॥ अंबिके.... <br />
						पंचनैवेद्य जवळ ठेवुनी <br />
						पिष्टायदिवे त्यावर लावुनी <br /> सुवासिनींसह तुज कालविते
						॥५॥ अंबिके.... <br />
						देई गृही या सुख-सौभाग्य <br /> देई बालका आयु-आरोग्य
						<br /> हीच प्रार्थना तव पदी करिते ॥६॥ अंबिके....
					</p>
					<h1 className="text-center font-bold my-5">
						कुळाचार - ‘गोंधळ‌’
					</h1>
					<p className="mb-5 leading-relaxed mt-3">
						चित्पावन कोकणस्थ ब्राह्मणांचा बोडणाव्यतिरिक्त आणखी गक
						कुळाचार - ‘गोंधळ‌’. घरांतील लग्नकार्य / मंगलकार्य
						निर्विघ्नपणे पार पडल्यावर देवीची स्तुती व पूजा करून,
						देवीला प्रसन्न करून घेण्यासाठी ‘गोंधळ‌’ घालण्याचा हा
						प्रघात आहे. अंबाबाई, भवानी, रेणुकादेवी या कुलदेवतांच्या
						कृपा-स्मरणाचा हा कुळाचार आहे. हा कुळाचार पार पाडण्यास
						पारंपरिक ‘गोंधळींना‌’ बोलावण्यात येतेे. नवदांपत्याला
						आशीर्वाद मागण्यासाठी त्यांच्या हातून पाटावर देवीची
						स्थापना करवून, पूजा करवून घेण्याचे काम गोंधळी करतो. हा
						कार्यक्रम रात्री िंकवा पूर्वप्रहरी सुमारे दोन तास चालतो
						व या विधीमध्ये प्रथम गण नंतर देवीचे स्तवन व पोवाडे गाऊन
						देवीला पाचारण करण्यात येते. शेवटी देवीची आरती होऊन
						कुळाचार संपतो. असा गोंधळ घातल्याने देवदेवता प्रसन्न होऊन
						नवदांपत्याला आशीर्वाद देतात अशी भावना यात आहे.
						मराठवाड्यात देशस्थ ब्राह्मणात हा प्रघात असून काही
						चित्पावन घराण्यांनी कोकण सोडून देशावर आल्यावर तेथे
						राहिल्यावर उचललेला असावा असे दिसते.
					</p>
					<h1 className="text-center font-bold my-5">गौरी-गणपती</h1>
					<p className="mb-5 leading-relaxed mt-3">
						गौरी-गणपतीचे सणवार भाद्रपद महिन्यात येतात. गणपती -
						भाद्रपद शुद्ध चतुर्थीला म्हणजेच गणेश चतुर्थीला गणपतीच्या
						मूर्तीला वाजतगाजत आणून त्याची स्थापना करतात. घरी गणपतीची
						स्थापना करावयाच्या आधी यथाशक्ती त्या जागेचे सुशोभीकरण
						करण्यात येते. रोज सकाळी व संध्याकाळी पूजा आरती व
						मंत्रजागर करतात. थोरांपासून लहानांपर्यंत सर्वच ह्या गणेश
						उत्सवात सहभागी होतात. हा उत्सव प्रत्येक कुटुंबाच्या
						रूढीप्रमाणे दीड, पाच, सात िंकवा दहा दिवसांचा असतो. गणेश
						उत्सवात सर्व कुटुंबीय अत्यंत प्रसन्न वातावरणात असतात.
						आप्तेष्टांना व मित्रांना गणेशदर्शनाकरिता बोलाविले जाते.
						शेवटच्या दिवशी मूर्तीचे विहिरीत, नदीत, सागरात यथासांग
						पूजा करून विसर्जन करतात. जोगळेकर घराण्यापैकी बऱ्याच
						कुटुंबांत गणेशोत्सव रूढीप्रमाणे साजरा केला जातो. ज्या
						जोगळेकर कुटुंबाचा गणपतीपुळे येथील गणपती कुलस्वामी आहे
						त्या कुटुंबात घरी गणेशाची स्थापना करीत नाहीत असे
						निदर्शनास आले आहे. गौरी - भाद्रपद शुद्ध अष्टमीला गौरी
						घरी आणून तिचे पूजन करतात. चित्पावन घराण्यात खड्यांच्या
						गौरी असतात. इतर काही घराण्यात उकडीच्या िंकवा
						मुखवट्याच्या गौरी बसवितात. भाद्रपद शुद्ध नवमीला गौरी
						जेवतात, त्यांना तांदुळाचे धिरडे आणि नारळीचे घाटले यांचा
						नैवेद्य असतो. गौरीसाठी सोवळ्यात स्वयंपाक असतो. पूजा-आरती
						यथासांग करतात. भाद्रपद शुद्ध दशमीला गौरीचे विसर्जन करून
						ह्या कुळाचाराची सांगता करतात.
					</p>
					<h1 className="text-center font-bold my-5">
						घटस्थापना - नवरात्रोत्सव
					</h1>
					<p className="mb-5 leading-relaxed mt-3">
						{" "}
						घटस्थापना दुर्गादेवी अवताराचा सण आहे. अश्विन शुद्ध
						प्रतिपदेला या सणाला / कुळाचाराचा प्रारंभ म्हणजे
						घटस्थापना. रोज देवीपुढे झेण्डू फुलांची माळ सोडतात, अखंड
						नंदादीप तेवत ठेवतात. रोज पूजा आरती नैवेद्य वगैरे
						प्रत्येक कुटुंबीयातील रूढी / चालीरीतीप्रमाणे करतात.
						अष्टमीला देवीची खणा-नारळाने ओटी भरतात. देवीच्या वारी
						म्हणजे मंगळवार / शुक्रवार व अष्टमीस सप्तशतीचा पाठ
						म्हणतात. रीतीनुसार नवव्या दिवशी सायंकाळी िंकवा
						विजयादशमीला नवरात्र उठविण्यात येते व कुळाचार संपतो.
					</p>
					<h1 className="text-center font-bold my-5">
						आरती योगेश्वरीची
					</h1>
					<p className="mb-5 text-center text-xl">
						जयदेवी जयदेवी जय योगेश्वरीमाते <br />
						जय योगेश्वरीमाते
						<br />
						कुलस्वामिनी जगदंबे करुणामृतसरिते
						<br />
						धर्माला ये ग्लानी, स्थिति केविलवाणी <br />
						असुरे पीडित जनता, त्राता तिज न कुणी
						<br />
						साद घाली तुज अंबे धाव धाव जननी <br />
						धावा पडता श्रवणी, घेशी धाव झणी
						<br />
						जयदेवी जयदेवी जय योगेश्वरीमाते ॥१॥
						<br />
						असुराते निर्दाळुनि, धर्मा रक्षियले
						<br />
						सज्जनपालन केले, दुर्जन मर्दियले
						<br />
						विश्रांतीस्तव निर्जन स्थाना शोधियले
						<br />
						आंबग्रामानिकटी वास्तव्या केले
						<br />
						जयदेवी जयदेवी जय योगेश्वरीमाते ॥२॥
						<br />
						वेदपुराणे थकली गाता तुज जेथे
						<br />
						काय पामरे गावे वदतव महिम्याते
						<br />
						दिगंत कीर्ती तव ही त्रिभुवनि दुमदुमते
						<br />
						भावे भजता भक्ते पावशि झणि त्याते
						<br />
						जयदेवी जयदेवी जय योगेश्वरीमाते ॥३॥
						<br />
						दु:सह भव हा तोडी या भव पाशाते
						<br />
						जनन-मरण हे तेची चुकवी झणि माते
						<br />
						मागत काहि न माते तव पदकमलाते
						<br />
						द्यावा तव पदि आश्रय नत अज्ञाताते <br />
						जयदेवी जयदेवी जय योगेश्वरीमाते ॥४॥
						<br />
					</p>
					<div className="flex items-end justify-between font-bold ">
						<div>
							<h1>भाद्रपद शुध्द शके १८८७ </h1>
							<h1> दि. ३०.८.१९६५ </h1>
						</div>
						<div>
							<h1>रचयिता ‘अज्ञात‌’</h1>
							<h1>प्रेषक :- द.ज.जोगळेकर </h1>
						</div>
					</div>
					<h1 className="mt-5 text-xl"> अमरावती (विदर्भ)</h1>
				</div>
				<div
					onClick={scrollToTop}
					className="flex items-end justify-end my-5"
				>
					<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
				</div>
				<hr />
			</div>
		</div>
	);
};
