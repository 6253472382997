import * as React from "react";
import { Header } from "../components/Header";
import { SquareDharmikLayout } from "../pages/SqaureDharmikLayout";

export const Religious = () => {
  const dharmikData1 = [
    {
      img: "/other_imgs/thumbnail-dharmik-ambajogai.png",
      title: "योगेश्वरी देवी, अंबाजोगाई",
      href: "/ambajogai",
    },
    {
      img: "/other_imgs/thumbnail-dharmik-vyadeshwar.png",
      title: "व्याडेश्वर, गुहागर",
      href: "/vyadeshwar",
    },
    {
      img: "/other_imgs/thumbnail-dharmik-joglekar-kulachar.png",
      title: "जोगळेकर कुलाचार",
      href: "/kulchar",
    },
  ];

  const dharmikData2 = [
    {
      img: "/other_imgs/thumbnail-dharmik-mantropchar.png",
      title: "  मंत्रोप्चार ",
      href: "/mantropchar",
    },
    {
      img: "/other_imgs/thumbnail-dharmik-aarti.png",
      title: "आरती",
      href: "/aarti",
    },
  ];

  return (
    <>
      <div className="w-full bg-white  rounded-lg flex flex-col items-center justify-center">
        <Header />
        <img
          src="/banners/banner-dharmik.jpg"
          alt="Information Banner"
          className="w-full object-cover shadow-lg"
        />
      </div>
      <div className="flex flex-wrap gap-4 items-center justify-center mt-10">
        {dharmikData1.map((data, i) => (
          <SquareDharmikLayout key={i} data={data} />
        ))}
      </div>
      <div className="flex flex-wrap gap-4 items-center justify-center mt-8 mb-20">
        {dharmikData2.map((data, i) => (
          <SquareDharmikLayout key={i + dharmikData1.length} data={data} />
        ))}
      </div>
    </>
  );
};
