import {Header} from "../components/Header"

export const Contact = () => {
    return (
        <>
            <div className="w-full h-[100vh] bg-white flex flex-col items-center justify-center">
                <Header/>
                <img
                    src="/banners/banner-contact.jpg"
                    alt="Information Banner"
                    className="w-full h-full object-cover shadow-lg"
                />
            </div>
            <div className="flex items-center justify-between text-center mt-10 px-16">
                <div>
                    <img src="/other_imgs/image-suggestion.png" alt="suggesstion_img" className="w-96 h-96"/>
                    <h2 className=" font-SOHNE_BOLD mt-5 text-red-700 text-2xl">सूचना</h2>
                </div>
                <div>
                    <img src="/other_imgs/image-query.png" alt="question-img" className="w-96 h-96"/>
                    <h2 className=" font-SOHNE_BOLD mt-5 text-red-700 text-2xl">प्रश्न?</h2>
                </div>
                <div>
                    <img src="/other_imgs/image-update-kulvruksh.png" alt="kulvrush img" className="w-96 h-96"/>
                    <h2 className=" font-SOHNE_BOLD mt-5 text-red-700 text-2xl"> कुलवृक्ष माहिती अपडेट करा</h2>
                </div>
            </div>
            <div className="text-center mt-10 font-SOHNE_REGULAR mb-16">
                <h1 className="font-bold mb-5 text-2xl">जोगळेकर कुलवृत्तान्त समिती द्वारा </h1>
                <p className="font-SOHNE_REGULAR text-xl mb-5"><span className="font-bold">श्री. विजय केशव जोगळेकर - अध्यक्ष</span>
                    <br/>
                    १२१०/क , शिवाजीनगर , 'शिवगंगा', ऑफ आपटे रोड पुणे ४११००४ </p>
                <h2 className="font-bold text-2xl mb-5">भ्रमणध्वनी :- ९४२१० ५३२२३</h2>
                <span className="text-xl mb-5">इ-मेल :- info@joglekarkul.com</span>
            </div>
        </>
    )

}
