
import { Header } from "../components/Header"
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { useEffect } from 'react';
export const Chapter21 = () => {
    // Scroll to Top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    useEffect(() => {
            // Scroll to top when the page is rendered
            window.scrollTo(0, 0);
        }, []);
    return (
        <>
            <div>
                <Header />
                <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
                    <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
                        स्पष्टीकरण - टीपा
                    </h1>
                    <p className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
                        वेदाध्ययनाची परंपरा आता खंडित झाली असल्यामुळे या संदर्भातले अनेक शब्द वाचकांना अपरिचित वाटतील. म्हणून त्यांचा अर्थ येथे देत आहोत.
                    </p>
                    <div>
                        <ol className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
                            <li>
                                १) अग्निहोत्र - सकाळ - संध्याकाळ होमहवन करून अग्नी अखंड प्रज्वलित ठेवण्याची क्रिया. व्रतस्थपणे असे विहित कर्म करणारा तो अग्निहोत्री.
                            </li>
                            <li>
                                २) घनपाठी - ‘घन’ नावाची वेदाची विकृती पठण करणारा. जटा, माला, शिखा, रेखा, ध्वज, दंड, रथ व घन अशा वेदपठणाच्या विकृती आहेत. घनपाठी हा वेदपठणातील सर्वो अधिकारी मानला जातो. क्रमपाठी, जटापाठी हेही मोठ्या योग्यतेचे वेदाभ्यासक होत.
                            </li>
                            <li>
                                ३) दशग्रंथी - वेद व त्याची उपांगे अशा दहा ग्रंथांचे पठण केलेला वेदविद्यापारंगत. हे दहा ग्रंथ पुढील प्रमाणे : संहिता, ब्राह्मण, आरण्यक, शिक्षा, कल्प, व्याकरण, निघंटु, निरुक्त, छंद व ज्योतिष.
                            </li>
                            <li>
                                ४) याज्ञिक - लग्न, मुंज यांसारखी धर्मकृत्ये करण्याचे ज्ञान संपादन केलेला. धर्मकृत्याची अशी शाखा ती याज्ञिकी.
                            </li>
                            <li>
                                ५) वर्षासन - प्रति वर्षाला ठरावीक मानधन देऊन केलेली वार्षिक नेमणूक.
                            </li>
                            <li>
                                ६) वैदिक - वेदांचे पद्धतशीर अध्ययन केलेला पंडित वेदाध्ययनाची अशी वृत्ती ती वैदिकी.
                            </li>
                            <li>
                                ७) श्रौतविद्या - वेदांचे सशास्त्र संपादिलेले ज्ञान िंकवा वेदांना अनुसरून केलेली कृती.<br />
                            </li>
                            <li>
                                ८) स्वाहाकार - देवतांना अग्नीच्या द्वारे हविर्भाग देणे.<br />
                            </li>
                        </ol>
                        <p className="mb-5 leading-relaxed text-justify mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
                            (ही कृती करणारा त्या क्षेत्रातील अधिकारी असावा लागतो).<br />
                            गं.ना.जोगळेकर.
                        </p>
                    </div>

                    <div className="text-center  mt-5 text-lg sm:text-xl ">
                        <h1 className="font-bold">
                            श्री देव्यपराधक्षमापनस्तोत्रम्
                        </h1>
                        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
                            <h2 className="mb-5">॥ श्री गणेशाय नम:॥ </h2>
                            <p>
                                न मन्त्रं नो यंत्रं तदपि च न जाने स्तुतिमहो<br />
                                न चाह्वानं ध्यानं तदपि च न जाने स्तुतिकथा: <br />
                                न जाने मुद्रास्ते तदपि च न जाने विलपनं<br />
                                परं जाने मातस्त्वदनुसरणं क्लेशहरणम्॥१॥<br />
                                विधेरज्ञानेन द्रविणविरहेणालसतया<br />
                                विधेयाशक्यत्वात्तव चरणयोर्या च्युतिरभूत्<br />
                                तदेतत्क्षन्तव्यं जननि सकलोद्धारिणि शिवे<br />
                                कुपुत्रो जायेत क्वचिदपि कुमाता न भवति ॥२॥<br />
                                पृथिव्यां पुत्रास्ते जननि बहव: सन्ति सरला:<br />
                                परं तेषां मध्ये विरलतरलोऽहं तव सुत:<br />
                                मदीयोऽयं त्याग: समुचितमिदं नो तव शिवे<br />
                                कुपुत्रो जायेत क्वचिदपि कुमाता न भवति॥३॥<br />
                                जनन्मातर्मातस्तव चरणसेवा न रचिता<br />
                                न वा दत्तं देवि द्रविणमपि भूयस्तव मया  <br />
                                तथापि त्वं स्नेहं मयि निरुपमं यत्प्रकुरुषे <br />
                                कुपुत्रो जायेत क्वचिदपि कुमाता न भवति॥४॥<br />
                                परित्यक्त्वा देवान्विविधसेवाकुलतया <br />
                                मया पञ्चाशीतेरधिकमपनीते तु वयिअि<br />
                                इदानीं चेन्मातस्तव यदि कृपा नापि भविता <br />
                                निरालम्बो लम्बोदरजननि कं यामि शरणम् ॥५॥<br />
                                श्वपाको जल्पाको भवति मधुपाकोपमगिरा<br />
                                निरातङ्को रङ्को विहरति चिरं कोटिकनकै:<br />
                                तवापर्णे कर्णे विशति मनुवर्णे फलमिदं <br />
                                जन: को जानीते जननि जपनीयं जपविधौ ॥६॥<br />
                                चिताभस्मालेपो गरलमशनं दिक्पटधरो<br />
                                जटाधारी कण्ठे भुजगपतिहारी पशुपति:<br />
                                कपाली भूतेशो भजति जगदीशैकपदवीं <br />
                                भवानि त्वत्पाणिग्रहणपरिपाटीफलमिदम्॥७॥<br />
                                न मोक्षस्याकांक्षा न च विभववात्र्छापि च न मे<br />
                                न विज्ञानापेक्षा शशिमुखि सुखेच्छापि न पुन:<br />
                                अतस्वां संयाचे जननि जननं यातु मम वै<br />
                                मृडानी रुद्राणी शिवशिव भवानीति जपत:॥८॥<br />
                                नाराधितासि विधिना विविधोपचारै:<br />
                                िंक रुक्षचिन्तनपरैर्नं कृतं वचोभि: <br />
                                श्यामे त्वतेव यदि किञ्चन मय्यनाथे <br />
                                धत्से कृपामुचितमम्ब परं तदैव ॥९॥<br />
                                आपत्सु मग्न: स्मरणं त्वदीयं  करोमि दुर्गे करुणार्णवेशि<br />
                                नैतच्छठत्वं मम भावयेथा: क्षुधातृषार्ता जननीं स्मरन्ति ॥१०॥<br />
                                जगदम्ब विचित्रमत्र िंक परिपूर्णा करुणाऽस्ति चेन्मयि<br />
                                अपराधपरम्परावृतं  न हि माता समुपेक्षते सुतम् ॥११॥<br />
                                मत्सम: पातकी नास्ति  पापध्नी त्वत्समा नहि<br />
                                गवं ज्ञात्वा महादेवि यथा योग्यं तथा कुरु॥१२॥<br />
                                इति श्रीमत्परमहंसपरिव्राजकाचार्य श्रीमच्छंङ्कराचार्यविरचितं देव्यपराधक्षमापन स्तोत्रम् संपूर्णम्॥<br />


                            </p>
                        </div>
                        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
                            <h2 className="text-center text-2xl font-bold text-red-600 mb-4">
                                ॥ श्री गुरुस्वामीसमर्थ ॥
                            </h2>
                            <h1 className="text-center text-xl font-semibold =mb-6">
                                श्री गणेशाय नमः <br />
                                श्री योगेश्वर्यै नमः
                            </h1>

                            <section className="mb-6">
                                <p className="text-lg font-semibold ">देव्युवाच:</p>
                                <p className="">
                                    भगवन् सर्वमाख्यातं मंत्रयंत्रादिकं त्वया। <br />
                                    योगिन्याः कवचं देव, न कुत्रापि प्रकाशितम् ॥१॥ <br />
                                    श्रोतुमिच्छाम्यहं देव, कृपापात्रं तवास्म्यहम्। <br />
                                    कथयस्व महादेव, यद्यहं तव वल्लभा ॥२॥
                                </p>
                            </section>

                            <section className="mb-6">
                                <h3 className="text-lg font-semibold ">ईश्वर उवाच:</h3>
                                <p className="">
                                    श्रृणु देवि महाविद्यां, सर्वदेवप्रपूजिताम्। <br />
                                    यस्याः कटाक्षमात्रेण, त्रैलोक्यविजयी हरिः ॥३॥ <br />
                                    सृष्टिकर्ता भवेद् ब्रह्मा, संहर्ताऽहं तथैव च। <br />
                                    यस्याः स्मरणमात्रेण, देवादेवत्वमाप्नुयुः ॥४॥ <br />
                                    रहस्यं श्रृणु वक्ष्यामि, योगेश्या प्राणवल्लभे। <br />
                                    त्रैलोक्यविजयं नाम, कवचं मंत्रविग्रहम् ॥५॥
                                </p>
                            </section>

                            <section className="mb-6">
                                <h3 className="text-lg font-semibold">
                                    अस्य श्री योगेश्वरी कवचस्य
                                </h3>
                                <ul className=" ">
                                    <li>महादेव ऋषिः।</li>
                                    <li>अनुष्टुप् छंदः।</li>
                                    <li>श्री योगेश्वरी देवता।</li>
                                    <li>गें बीजं।</li>
                                    <li>ऱ्हीं शक्ति।</li>
                                    <li>श्रीं कीलकम्।</li>
                                    <li>मम योगेश्वरी प्रसाद-सिद्ध्यर्थे जपे विनियोगः।</li>
                                </ul>
                            </section>

                            <section className="mb-6">
                                <h3 className="text-lg font-semibold ">अथ न्यासः</h3>
                                <ul className=" ">
                                    <li>श्री महादेव ऋषये नमः शिरसि।</li>
                                    <li>अनुष्टुप् छंदसे नमः मुखे।</li>
                                    <li>श्री योगेश्वरी देवतायै नमः हृदये।</li>
                                    <li>गें बीजाय नमः दक्षिणस्तने।</li>
                                    <li>ऱ्हीं शक्तये नमः वामस्तने।</li>
                                    <li>श्रीं कीलकाय नमः नाभौ।</li>
                                    <li>गें अंगुष्ठाभ्यं नमः।</li>
                                    <li>ऱ्हीं तर्जनीभ्यां नमः।</li>
                                    <li>श्रीं मध्यमाभ्यां नमः।</li>
                                    <li>गें अनामिकाभ्यां नमः।</li>
                                    <li>ऱ्हीं कनिष्ठिकाभ्यां नमः।</li>
                                    <li>श्रीं करतलकरपृष्ठाभ्यां नमः।</li>
                                </ul>
                            </section>

                            <section className="mb-6">
                                <h3 className="text-lg font-semibold ">अथ ध्यानम्</h3>
                                <p className="">
                                    खड्गपात्रं च मुसलं, लांगलं बिभ्रती आ। <br />
                                    आख्याता रक्तचामुंडा, देवी योगेश्वरी तथा। <br />
                                    इति ध्यात्वा पंचोपचारैः संपूज्य कवचं पठेत्।
                                </p>
                            </section>

                            <section className="mb-6">
                                <h3 className="text-lg font-semibold ">महामंत्र:</h3>
                                <p className="">ॐ ऱ्हीं नमो भगवति रक्तचामुंडे योगेश्वरी।<br />योगिनीं ऱ्हीं स्वाहा।</p>
                            </section>

                            <section className="mb-6">
                                <h3 className="text-lg font-semibold ">ध्यान मंत्र:</h3>
                                <p className="">
                                    ॐ देवीं भक्तजनप्रियाम् सुवदनाम्। <br />
                                    खड्गं च पाशं मुसलम्। <br />
                                    सुवर्णालङ्कृत लांगलं बिभ्रतीम्। <br />
                                    इत्यादि।
                                </p>
                            </section>

                            <p className="text-sm ">
                                यह ग्रंथ और कवच भगवान रुद्रयामल से लिया गया है। इसे पढ़ने, सुनने या जप करने से समस्त सिद्धियाँ प्राप्त होती हैं।
                            </p>
                        </div>
                        <div className=" min-h-screen font-SOHNE_REGULAR flex flex-col items-center py-8">
                            <div className=" p-6 w-full max-w-4xl mx-4">
                                <h1 className="text-2xl font-bold text-center mb-6">
                                    श्री योगेश्वरी सहस्र नाम अर्चना
                                </h1>

                                <p className="text-lg  mb-4">
                                    <span className="font-semibold ">श्री गणेशायनमः</span>
                                </p>

                                <h2 className="text-xl font-semibold  mb-4">
                                    अथ श्री योगेश्वरी सहस्त्र नाम प्रारंभ
                                </h2>

                                <div className="space-y-4">
                                    <p className="text-base">
                                        ॐ या तुरीया परादेवी दोषत्रयविवर्जिता |<br />
                                        सदानंदतनु: शांतासैवाहमहमेवसा || १ ||
                                    </p>

                                    <p className="text-base">
                                        यस्या: संस्मरणादेव क्षीयंते भवभीतय: |<br />
                                        तां नमामि जगद्धात्रीं योगिनीं परयोगिनीम् || २ ||
                                    </p>

                                    <p className="text-base">
                                        महदादि जगद्यस्या जातं रुाभुजंगवत् |<br />
                                        सा अंबापुरसंस्थाना पातु योगेश्वरेश्वरी || ३ ||
                                    </p>

                                    <p className="text-base">
                                        सदािनंदरूपाय प्रतीचेनंत रूपिणे |<br />
                                        नमो वेदांतवेद्याय महसेमिततेजसे || ४ ||
                                    </p>

                                    <h3 className="text-lg font-semibold  mt-6 mb-4">
                                        श्री ईश्वर उवाच
                                    </h3>

                                    <p className="text-base">
                                        यो नित्यं पूजयेद्देवीं योगिनी योगवित्तमां |<br />
                                        तस्यायु: पुत्रसौख्यं च विद्यादात्री भवत्यसौ || ५ ||
                                    </p>

                                    <p className="text-base">
                                        यो देवीभक्तिसंयुक्तस्तस्य लक्ष्मीश्च िंककरी |<br />
                                        राजानो वश्यतां यांति स्त्रियो वै मदविव्हला: || ६ ||
                                    </p>

                                    <p className="text-base">
                                        यो भवानीं महामायां पूजयेन्नित्य मादृत: |<br />
                                        गेहिकं च सुखं प्राप्य परे ब्रह्मणि लीयते || ७ ||
                                    </p>

                                    <h3 className="text-lg font-semibold  mt-6 mb-4">
                                        श्रीविष्णु उवाच
                                    </h3>

                                    <p className="text-base">
                                        देव देव महादेव नीलकंठ उमापते |<br />
                                        रहस्यं प्रष्टुमिच्छामि संशयोऽस्ति महामते || ८ ||
                                    </p>

                                    <p className="text-base">
                                        चराचरस्य कर्ता त्वं संहर्ता पालकस्तथा |<br />
                                        कस्या देव्यास्त्वया शंभो क्रियतेस्तुतिरन्वहम् || ९ ||
                                    </p>

                                    <p className="text-base">
                                        जप्यते परमो मंत्रो ध्यायते िंक त्वया प्रभो |<br />
                                        वद शंभो महादेव त्वत: का परदेवता || १० ||
                                    </p>

                                    <p className="text-base">
                                        प्रसन्नो यदि देवेश परमेश पुरातनम् |<br />
                                        रहस्यं परया देव्या कृपया कथय प्रभो || ११ ||
                                    </p>

                                    <p className="text-base">
                                        विनायासं विनाजाप्यं विनाध्यानं विनार्चनम् |<br />
                                        प्राणायामं विनाहोमं विनानित्योदितक्रियाम् || १२ ||
                                    </p>

                                    <p className="text-base">
                                        विनादानं विनागंधं विनापुष्पं विनाबलिम् |<br />
                                        विनाभूतादिशुिंद्ध च यथा देवी प्रसीदति || १३ ||
                                    </p>

                                    <p className="text-base">
                                        अिति पुष्टस्तदा शंभुर्विष्णुना प्रभविष्णुना |<br />
                                        प्रोवाच भगवान्देवी विकसन्नेत्रपंकज: || १४ ||
                                    </p>

                                    <h3 className="text-lg font-semibold  mt-6 mb-4">
                                        श्रीशिव उवाच
                                    </h3>

                                    <p className="text-base">
                                        साधु साधु सुरश्रेष्ठ पुष्टवानासि सांप्रतं |<br />
                                        षण्मुखस्यापि यद्गोप्यं रहस्यं तद्वदामि ते || १५ ||
                                    </p>

                                    <p className="text-base">
                                        पुरा युगक्षये लोकान्कर्तुमिच्छु: सुरासुरम् |<br />
                                        गुणत्रयमयी शक्तिश्चिद्रूपाद्या व्यवस्थिता || १६ ||
                                    </p>

                                    <p className="text-base">
                                        तस्यामहं समुत्पन्नो मत्तस्त्वं जगत:पिता |<br />
                                        त्वत्तोब्रह्मा समुद्भूतो लोककर्ता महाविभु: || १७ ||
                                    </p>

                                    <h3 className="text-lg font-semibold mt-6 mb-4">
                                        **अस्य श्री योगेश्वरी सहस्र नामस्तोत्रमंत्रस्य**
                                    </h3>

                                    <p className="text-base">
                                        श्रीमहादेव ऋषि: अनुष्टुप् छंद: श्री योगेश्वरी देवता मम सकलकामनासिध्यर्थ अंबापुरवासिनी प्रीत्यर्थ सहस्रनाम स्तोत्रजपे विनियोग:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=" font-SOHNE_REGULAR p-8 rounded-xl max-w-4xl mx-auto">

                            <div className="space-y-4 text-lg ">
                                <p>ध्येयेदंबाजयाख्यां त्रिदशपरिणतां सिद्धिकामो नरेंद्र: ॥१॥</p>
                                <p>अथ सहस्र नाम स्तवनम् - ॐ योगिनी योगमाया च योगपीठस्थितिप्रिया योगिनी योगदीक्षाच योगरूपाच योगिनी ॥१॥</p>
                                <p>योगगम्या योगरता योगीहृदयवासिनी योगस्थिता योगयुता योगमार्गरता सदा ॥२॥</p>
                                <p>योगेश्वरी योगनिद्रा योगदात्री सरस्वती तपोयुक्ता तप:प्रीति: तप:सिद्धिप्रदापरा ॥३॥</p>
                                <p>निशुंभशुंभसंहर्त्री रक्तबीजविनाशिनी मधुकैटभहंत्री च महिषासुरघातिनी ॥४॥</p>
                                <p>शारदेंदुप्रतीकाशा चंद्रकोटिप्रकाशिनी महामाया महाकाली महामारी क्षुधातृषा ॥५॥</p>
                                <p>निद्रातृष्णा चैकवरा काल रात्रि र्दुरत्यया महाविद्या महावाणी भारतीवाक् सरस्वती ॥६॥</p>
                                <p>आर्या ब्राह्मी महाधेनुर्वेदगर्भाचिधीश्वरी करालाविकरालाख्या अतिकालातिदीपका ॥७॥</p>
                                <p>गकिंलगा योगिनीच डाकिनीभैरवी तथा महाभैरवकेंद्राक्षी त्वसितांगी सुरेश्वरी ॥८॥</p>
                                <p>शांति चंद्रार्धमाकर्षी कलाकांति: कलानिधी: सर्व संक्षोभिणी शक्ति: सर्वाल्हाद करी प्रिया ॥९॥</p>
                                <p>सर्वाकर्षिणिका शक्ति: सर्वविद्राविणी तथा सर्वसंमोहिनीशक्ति: सर्वस्तंभनकारिणी ॥१०॥</p>
                                <p>सर्वजुंभनिका नाम शक्ति: सर्वत्रशंकरी महासौभाग्यगंभीरा पीनवृत्तघनस्तनी ॥११॥</p>
                                <p>रत्नपीठ विनिक्षिप्ता साधकेप्सितभूषणा नानाशस्त्रधरादिव्या वसतीर्हषितानना ॥१२॥</p>
                                <p>खड्ग पात्र धरादेवी दिव्यवस्त्रा च योगिनी सर्वसिद्धिप्रदादेवी सर्वसंपत्प्रदानता ॥१३॥</p>
                                <p>सर्वप्रियंकरी चैव सर्वमंगलकारिणी सा वैष्णवी सैव शैवी महारौद्री शिवा क्षमा ॥१४॥</p>
                                <p>कौमारी पार्वती सैव सर्वमंगलदायिनी ब्राह्मी माहेश्वरी चैव कौमारी वैष्णवी परा॥१५॥</p>
                                <p>वाराही चैव माहेंद्री चामुंडा सर्वदेवता अणिमा महिमा सिद्धिर्लघिमा शिवरूपिका ॥१६॥</p>
                                <p>वशित्वसिद्धि: प्राकाम्या भक्तिरिच्छाष्टमी परा सर्वाकर्षणिकाशक्ति: सर्वाल्हादकरी प्रिया ॥१७॥</p>
                                <p>सर्वसंमोहिनीशक्ति: सर्वस्तंभनकारिणी सर्वजृंभणिकानाम शक्ति: सर्व वंशकरी ॥१८॥</p>
                                <p>सर्वार्थरंजिनीशक्ति: सर्वमोदनकारिणी सर्वार्थसाधकीशक्ति: सर्वसंपत्तिपूरकी ॥१९॥</p>
                                <p>सर्वमंत्रमयीशक्ति: सर्वद्वंद्वक्षयंकरी सर्वकामप्रदादेवी सर्वदु:खप्रमोचनी ॥२०॥</p>
                                <p>सर्वमृत्युप्रशमनी सर्वविघ्ननिवारिणी सर्वांगसुंदरादेवी सर्वसौभाग्यदायिनी ॥२१॥</p>
                                <p>सर्वरक्षाकरीदेवी अक्षवर्णविराजिता सर्वस्याद्या विशालाक्षी नित्या बुद्धि:स्वरूपिणी ॥२२॥</p>
                                <p>श्वेतपर्वतसंकाशा श्वेतवस्त्रा महासती नीलहस्ता रक्तमध्या सुश्वेतस्तनमंडला ॥२३॥</p>
                                <p>रक्तपादा नीलजंघा सुचित्रजघना विभो चित्रमाल्यांबरधरा चित्रगंधानुलेपना ॥२४॥</p>
                                <p>जपाकुसुमवर्णाभा रक्तांबरविभूषणा रक्तायुधा रक्तनेत्रा रक्तकुंचितमूर्घजा ॥२५॥</p>
                                <p>सर्वस्याद्या महालक्ष्मी नित्या बुद्धिस्वरूपिणी चतूर्भुजा रक्तदंता जगद्व्याप्यव्यवस्थिता ॥२६॥</p>
                                <p>नीलांजनचयप्रख्या महादंष्ट्रा महानना विस्तीर्णलोचनादेवी वृत्तपीनपयोधरा ॥२७॥</p>
                                <p>गकवीरा कालरात्रि: सैवोक्ता कामदा स्तुता भीमा देवीति संपूज्या चैत्रपुत्रप्रदायिनी ॥२८॥</p>
                                <p>या सात्त्विकगुणा प्रोक्ता या विशिष्टसरस्वती सा देवकार्यवसति स्वरूपमपरं दधौ ॥२९॥</p>
                                <p>देवस्तुता तदा गौरी स्वदेहात्तरुणीं सृजत् ख्याता वै कौशिकी देवी तत: कृष्णाभवत्सती ॥३०॥</p>
                                <p>हिमाचलकृतस्थाना कालिकेति च विश्रुता महासरस्वतीदेवी शुंभासुरनिबर्हिणी ॥३१॥</p>
                                <p>श्वेतपर्वतसंकाशा श्वेतवस्त्राविभूषणा नानारत्नसमाकीर्णा वेदविद्याविनोदिनी ॥३२॥</p>
                                <p>शस्त्रव्रातसमायुक्ता भारती सा सरस्वती वागीश्वरी पीतवर्णा सैवोक्ता कामदालया ॥३३॥</p>
                                <p>कृष्णवर्णा महालंबा नीलोत्पलविलोचनी गंभीरनाभिस्त्रिवली विभूषिततनूदरी ॥३४॥</p>
                                <p>सुकर्कशा चंद्रभासा वृतपीनपयोधरा चतुर्भुजाविशालाक्षी कामिनी पद्मलोचना॥३५॥</p>
                                <p>शाकंभरी समाख्याता शताक्षी वनशंकरी त्रिपुरा विजया भीमा तारा त्रैलोक्यसुंदरी ॥३६॥</p>
                                <p>शांभवी त्रिजगन्माता स्वरात्रिपुरसुंदरी कामाक्षी कमलाक्षी च धृति स्त्रिपुरतापिनी ॥३७॥</p>
                                <p>जया जयंती शिवदा जलेशी चरणप्रिया गजवक्रा त्रिनेत्रा च शंखिनी चापराीजता ॥३८॥</p>
                                <p>महिषघ्नी शुभानंदा स्वाहा शिवासना विद्युािव्हा त्रिवक्रा च चतुर्वक्रा सदाशिवा कोटराक्षी शिखिरवा त्रिपदा सर्वमंगला मयुरवदना सिद्धि: बुद्धि: काकरवासती ॥३९॥</p>
                                <p>हंकारा तालकेशी च सर्वतारा च सुंदरी सर्पास्या च महाजिव्हा पाशपाणिर्गरुत्मती ॥४०॥</p>
                                <p>पद्मावती सुकेशी च पद्मकेशी क्षमावती पद्मावती सुरमुखी पद्मवक्रा षडानना ॥४१॥</p>
                                <p>त्रिवर्गफलदा माया रक्षोघ्नी पद्मवासिनी प्रणवेशी महोल्काभा विघ्नेशीस्तंभनी खला ॥४२॥</p>
                                <p>मातृकावर्णरूपा च अक्षरोारिणी गुहा अजपा मोहिनी श्यामा जपरूपा बलोत्कटा ॥४३॥</p>
                                <p>वाराही वैष्णवी जंभा वार्ताली दैत्यतापिनी क्षेमंकरी सिद्धिकरी बहुमाया सुरेश्वरी ॥४४॥</p>
                                <p>छिन्नमूर्धा छिन्नकेशी दानवेंद्रक्षयंकरी शाकंभरी मोक्षलक्ष्मीर्जभिनी बगलमुखी ॥४५॥</p>
                                <p>अश्वारूढा महाक्लिन्ना नारिंसही गजेश्वरी सिद्धेश्वरी विश्वदुर्गा चामुंडा शववाहना॥४६॥</p>
                                <p>ज्वालामुखी कराली च त्रिपटा खेचरेश्वरी शृंभघ्नी दैत्यदर्पघ्नी िंवध्याचलनिवासिनी ॥४७॥</p>
                                <p>योगिनी च विशालाक्षी तथा त्रिपुरभैरवी मातंगिनी करालाक्षी गजारूढा महेश्वरी ॥४८॥</p>
                                <p>पार्वती कमलालक्ष्मी: श्वेताचलनिभात्द्युमा कात्यायनी शंखरवा घुर्घुरा िंसहवाहिनी ॥४९॥</p>
                                <p>नारायणीश्वरी चंडी घंटाली देवसुंदरी विरूपावामनी कुब्जा कर्णकुब्जा घनस्तनी ॥५०॥</p>
                                <p>नीला शाकंभरी दुर्गा सर्वदुर्गार्तिहारिणी दंष्ट्रांकितमुखा भीमा नीलपत्रशिरोधरा ॥५१॥</p>
                                <p>महिषघ्नी महादेवी कुमारी िंसहवाहिनी दानवांस्तर्जयंती च सर्वकामदुधा शिवा ॥५२॥</p>
                                <p>कन्या कुमारिका चैव देवेशी त्रिपुरा तथा कल्याणी रोहिणी चैव कालिका चंडिका परा ॥५३॥</p>
                                <p>शांभवी चैव दुर्गा च सुभद्रा च यशस्विनी कालात्मिका कलातीता कारुण्यहृदयाशिवा ॥५४॥</p>
                                <p>कारुण्यजननी नित्या कल्याणी करुणाकरा कामाधारा कामरूपा कालदंडस्वरूपिणी ॥५५॥</p>
                                <p>कामदा करुणाधारा कालिका कामदा शुभा चंडवीरा चंडमाया चंडमुंडविनाशिनी ॥५६॥</p>
                                <p>चंडिका शक्तिरत्युग्रा चंडिका चंडविग्रहा गजानना िंसहमखी गृध्रास्या च महेश्वरी ॥५७॥</p>
                                <p>उष्ट्रग्रीवा हयग्रीवा कालरात्रिर्निशाचरी कंकाली रौद्रचीत्कारी फेत्कारी भूतडामरी ॥५८॥</p>
                                <p>वाराही शरभास्या च शताक्षी मांसभोजनी कंकाली रौद्रचीत्कारी फेत्कारी भूतडामरी ॥५९॥</p>
                                <p>उलूकिका शिवारावा धूम्राक्षी चित्रनादिनी उर्ध्वकेशी भद्रकेशी शवहस्तांत्रमालिनी ॥६०॥</p>
                                <p>कपालहस्ता रक्ताशी श्येनी रुधिरदायिनी  खड्गिनी दीर्घलंबोष्ठी पाशहस्ता बलाकिनी ॥६१॥</p>
                                <p>काकतुंडा पाशहस्ता धूर्जटी विषभक्षिणी पशुघ्नी पापहंत्री च मयूरी विकटानना ॥६२॥</p>
                                <p>भयविध्वंसिनी चैव प्रेतास्या प्रेतवाहिनी कोटराक्षी लसािव्हा अष्टवक्रा सुरप्रिया ॥६३॥</p>
                                <p>व्यात्तास्या धूमनि:श्वासा त्रिपुरा भुवनेश्वरी बृहत्तृंडा दंडहस्ता प्रचंडा चंडविक्रमा ॥६४॥</p>
                                <p>स्थूलकेशी बृहत्कुक्षी यमदूती करालिनी दशवक्रा दशपदा दशहस्ता विलासिनी ॥६५॥</p>
                                <p>अनाद्यंत स्वरुपा च क्रोधरुपा मनोगति: मन:श्रुतिस्मृति घ्राणचक्षुस्त्वग्रसनात्मिका ॥६६॥</p>
                                <p>योगिनीमानसंस्थाच योगसिद्धीप्रदायिका  उग्रिणी उग्ररूपा च उग्रतारा व उग्रिणी॥६७॥</p>
                                <p>उग्ररुपधरा चैव उग्रेशी उग्रवासिनी  भीमा च भीमकेशी च भीममूर्तिश्च भामिनी ॥६८॥</p>
                                <p>भीमा च भीमरूपा च भीमरूपा जगन्मया  खड्गिन्यभयहस्ता च घंटाडमरूधारिणी ॥६९॥</p>
                                <p>पाशिनी नागहस्ता च योनिन्यंकुशधारिणी  यज्ञा च यज्ञमूर्तिश्च दशयज्ञविनाशिनी ॥७०॥</p>
                                <p>यज्ञदीक्षा धरादेवी यज्ञसिद्धि प्रदायिनी हिरण्यबाहचरणा शरणागतपालिनी ॥७१॥</p>
                                <p>अनाम्न्यनेक नाम्नीच निर्गुणाच गुणात्मिका नमो जगत् प्रतिष्ठाच सर्व कल्याण मूर्तिनी ॥७२॥</p>
                                <p>ब्रम्हादिसुर वंद्या च गंगाधरजटास्थिता महामोहा महादीप्ति: सिद्धिर्विद्या च योगिनी ॥७३॥</p>
                                <p>योगिनी चंडिका सिद्धा सिद्धसिद्धा शिवप्रिया शरयूर्गोमती भीमा गौतमी नर्मदा मही॥७४॥</p>
                                <p>भागीरथी च कावेरी त्रिवेणी गंडकी शरा सुषुप्तिर्जागृतििंनद्रा स्वप्नातुर्या च चक्रिणी ॥७५॥</p>
                                <p>अहिल्यारुंधती चैव तारा मंदोदरी तथा देवी पद्मावती चैव त्रिपुरेशस्वरूपिणी॥७६॥</p>
                                <p>गकवीरा महादेवी कनकाद्याश्चदेवता शूलिनी परिघास्त्रा च खड्गिन्याबाह्यदेवता ॥७७॥</p>
                                <p>कौबेरी धनदा याम्याग्नेयी वायुतनुर्निशा अीशानी नैऋति: सौम्या माहेंद्री वारुणी तथा ॥७८॥</p>
                                <p>सर्वर्षि:पूजनीयांघ्रि: सर्वयंत्राधिदेवता सप्तघातुमयीमूर्ति: सप्तघात्वंतराश्रया ॥७९॥</p>
                                <p>देहपुष्टिर्मनस्तुष्टिरत्न्पुष्ठिर्बलोद्धता तपोनिष्ठा तयोयुक्ता तपस:सिद्धिदायिनी ॥८०॥</p>
                                <p>तपस्विनी तप:सिद्धि: तापसी च तप:प्रिया औषधी वैद्यमाता च द्रव्य:शक्ति:प्रभाविनी ॥८१॥</p>
                                <p>वेद विद्याच विद्याच सुकुला कुलपूजिता जालंधरशिर:छेत्री महार्षिहितकारिणी ॥८२॥</p>
                                <p>योगिनीर्तिमहायोगा कालरात्रिर्महारवा अमोहाच प्रगल्भाच गायत्रीहरवल्लभा ॥८३॥</p>
                                <p>विप्राख्या व्योमकाराच मुनिविप्र प्रियासती जगत्कीर्ति जगत्कारी जगश्वासा जगनिधि: ॥८४॥</p>
                                <p>जगत्प्राणा जगद्दंष्ट्रा जगािव्हा जगद्रिआ जगचक्षुर्जगत्घ्राणा जगच्छोत्रा जगन्मुखा ॥८५॥</p>
                                <p>जगछत्रा जगद्वक्रा जगत्भर्त्रीजगत्पिता जगत् पत्नी जगन्माता जगद्धात्री जगत्सुहृत् ॥८६॥</p>
                                <p>जगद्धात्री जगत्प्राणा जगद्योनिर्जगन्मती सर्वस्तंभी महामाया जगद्दीक्षाजया तथा॥८७॥</p>
                                <p>भक्तैकलक्ष्या द्विविधा त्रिविधा च चतुर्विधा इंद्राक्षी पंचरूपा च सहस्त्ररुपधारिणी ॥८८॥</p>
                                <p>मूलदिवासिनी चैव अंबापूर निवासिनी नवकुंभा नवरूचि:कामज्वाला नवानना ॥८९॥</p>
                                <p>गर्भज्वाला तथा बाला चक्षुर्ज्वाला नवांबरा नवरुपा नवकला नवनाडी नवानना ॥९०॥</p>
                                <p>नवक्रीडा नवविधा नवयोगिनिका तथा वेदविद्यामहाविद्या विद्यादात्री विशारदा ॥९१॥</p>
                                <p>कुमारी युवती बाला कुमारीव्रतचरिणी कुमारी भक्तसुखिनी कुमारीरुपधारिणी ॥९२॥</p>
                                <p>भवानी विष्णुजननी ब्रह्मादिजननी परा गणेशजननी शक्ति: कुमारजननीशुभा ॥९३॥</p>
                                <p>भाग्याश्रया भगवती भक्ताभीष्टप्रदायिनी भगात्मिका भगाधारा रूपिणी भगमालिनी॥९४॥</p>
                                <p>भवरोगहरा भव्या सुभ्रु:पर्वतमंगला शर्वाणी चपलापांगी चारुचंद्राकलापरा ॥९५॥</p>
                                <p>विशालाक्षी विश्वमाता विश्ववंद्या विलासिनी शुभप्रदा शुभावार्ता वृत्तपीनपयोधरा॥९६॥</p>
                                <p>अंबासंसारमथिनी मृडानी सर्वमंगला विष्णुसंसेविता शुद्धाब्रह्मदिसुरसेविता ॥९७॥</p>
                                <p>परमानंदशक्तिश्च रमानंदरूपिणी परमानंदजननी परमान्नप्रदायिनी ॥९८॥</p>
                                <p>परोपकारनिरता परमाभक्तवत्सला आनंदभैरवी बाला भैरवी बटुभैरवी ॥९९॥</p>
                                <p>श्मशान भैरवी काली भैरवी रुरुभैरवी ॥१००॥</p>
                                <p>पूर्णचंद्राभवदना पूर्णचंद्रनिभांशुका शुभलक्षणसंपन्ना शुभानंतगुणार्णवा ॥१०१॥</p>
                            </div>
                        </div>
                        <div className="container font-SOHNE_REGULAR mx-auto p-4">
                            <div className=" font-SOHNE_REGULAR p-6 ">

                                <p className="text-lg leading-relaxed mb-2">
                                    शुभसौभाग्यनिलया शुभाचाररता प्रिया सुखसंभोगभावना सर्व सौख्यानिरूपिणी ॥२॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    अवलंबा तथा वाग्मी प्रवरा वाद्यवादिनी घूर्णादिपट्टला कोपादुतीर्णकुटिलानना ॥३॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    पापदा पापनाशा च ब्रम्हाग्नीशापमोचनी सर्वातीता च उच्छिष्टा चांडाली परिघायुधा ॥४॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    ॐकारी वेदकारी च ऱ्हींकारी सकलागमा यंकारी चर्चिता चर्ची चर्चिता चक्ररूपिणी ॥५॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    महाव्याधवरारोहा धनुर्बाणधरा धरा लंबिनी च पिपासा च क्षुधा संदेशिका तथा ॥६॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    भुक्तिदा मुक्ति देवी सिद्धिदा शुभदायिनी सिद्धिदा बुद्धिदा माता वर्मिणी फलदायिनी ॥७॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    चन्डिका चन्डमथिनी चंडदर्पनिवारिणी चंडमार्तंडनयना चंद्राग्निनयना सती ॥८॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    सर्वांगसुंदरी रक्ता रक्तवस्त्रोत्तरीयका जपापावकिंसदुरा रक्तचंदनधरिणी ॥९॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    कर्पूरागरुकस्तूरीकुंकुमद्रवलेपिनी विचित्ररत्नपृथिवी कल्मषांध्रीतलास्थिता ॥१०॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    भगात्मिका भगाधारा रूपिणी भगमालिनी िंलगाभिर्धायिनी िंलगप्रियिंलगनिवासिनी ॥११॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    भगिंलगस्वरूपा च भगिंलगसुखावहा स्वयंभुकुसुमप्रीता स्वयंभूकुसुमार्चिता ॥१२॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    स्वयंभुकुसुमस्नाता स्वयंभूपुष्पतर्पिता स्वयंभूपुष्पतिलका स्वयंभूपुष्पधारिणी ॥१३॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    पुंडीककरा पुण्या पुण्यदा पुण्यरूपिणी पुण्यज्ञेया पुण्यवंद्या पुण्यमूर्ति: पुरातना ॥१४॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    अनवद्या वेदवेद्या वेदवेदांतरूपिणी मायातीता सुष्टमाया माया धर्मात्मवंदिता ॥१५॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    असृष्टा संगरहिता सृष्टिहेतु: कपर्दिनी वृषारूढा शूलहस्ता स्थितिसंहारकारिणी ॥१६॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    मंदस्थिति: शुद्धरूपा शुद्धचित्तमुनिस्तुता महाभाग्यवती दक्षा दक्षाध्वरविनाशिनी ॥१७॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    अपर्णानन्यशरणा भक्ताभीष्टफलप्रदा नित्या सुंदरसर्वांगी सदािनंदलक्षणा ॥१८॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    कामजा केलिका केली कर्षा कर्बुरकालजा गिरिजागर्वजा गोत्रा अकुला कुलजा तथा ॥१९॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    दिनजा दिनमाता च वेदजा वेदसंभृता क्रोधजा कुटजा धारा परमा बलगर्विता ॥२०॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    सर्वलोकोत्तरा भावा सर्वकालोद्भवात्मिका कुंडगोलोद्भवा प्रीता कुंडगोलोद्भवात्मिका ॥२१॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    कुंदपुष्पसदाप्रीती पुष्पगोलसदारति: शुक्रमूर्ति: शुक्रदेहा शुक्रपुजितमूर्तिनी ॥२२॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    विदेहा विमला शुक्रा चौंडा कर्नाटकी तथा त्रिमाता उत्कला  मौंडी विरेखा वीरवंदिता ॥२३॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    शामला गौरवी पीना मागधेश्वर वंदिता पार्वती कर्मनाशा च कैलासवासिका तथा ॥२४॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    शालिग्रामशिला माली शार्दूला िंपगकेशिनी नारदा शारदा चैव रेणुका गगनेश्वरी ॥२५॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    धेनुरूपा रुक्मिणी च गोपिका यमुनाश्रया सुकंठा कोकिला मेना चिरानंदा शिवात्मिका ॥२६॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    कंदर्पकोटिलावण्या सुंदरा सुंदरस्तनी विश्वपक्षा विश्वरक्षा विश्वनाथ प्रियासती ॥२७॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    योगिनी योगयुक्ता च योगांगध्यानशालिनी योगपट्टधरा मुक्ता मुक्तानांपरमागति: ॥२८॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    कुरुक्षेत्रवती काशि मथुरा कांत्यवंतिका अयोध्या द्वारका माया तीर्था तीर्थकरीप्रिया ॥२९॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    त्रिपुष्करा प्रमेया च कोशस्था कोशवासिनी  कुशावर्ता कौशिकी च कोशांना कोशवर्धिनी ॥३०॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    पद्मकोशा कोशदाक्षी कुसुंभकुसुमप्रिया तुलाकोटि च काकुत्स्था कुक्कुटा च रवाश्रया ॥३१॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    ॐ ऱ्हीं यं यां रुद्रदैवत्यायै योगेश्वरर्येस्वाहा पुत्रदा पौत्रदा पौत्री दिव्यदा दिव्यभोगदा आशापूर्णा चिरंजीवी लंकाभयविवर्धिनी ॥३२॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    स्त्रुक् स्त्रुवा सामिधेनी च सुश्रद्धा श्राद्धदेवता माता मातामही तृप्ति: पितुर्माता पितामही ॥३३॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    स्नुषा दौहित्रिणी पुत्री लोकक्रीडाभिनंदिनी पोषिणीशोषिणीशक्तिर्दीर्घकेशी सुलोमशा ॥३४॥
                                </p>
                                <p className="text-lg leading-relaxed mb-2">
                                    सप्ताब्धिसंश्रयानित्या सप्तद्विपाब्धिमेखला
                                    सूर्यदीप्तिर्वज्रशक्तिर्मदोन्मत्ता च िंपगला ॥३५॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    सुचक्रा चक्रमध्यस्था चक्रकोणनिवासीनी सर्वमंत्रमयी विद्या सर्वमंत्राक्षरावरा ॥३६॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    सर्वज्ञदा विश्वमाता भक्तानुग्रहकारिणी विश्वप्रिया प्राणशक्तिरनंतगुणनामधी: ॥३७॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    पंचाशद्विष्णुशक्तिश्च पंचाशन्मातृकामयी द्विपंचाशद्वपुश्रेणी त्रिषष्ट्यक्षरसंश्रया ॥३८॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    चतु:षष्टिमहासिद्विर्योगिनी वृंदवन्दिनी चतु:षड्वर्णनिर्णेयी चतु:षष्टिफलानिधि: ॥३९॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    अष्टषष्टिमहातीर्थक्षेत्रभैरववासिनी चतुर्नवतिमंत्रात्मा षण्णवत्याधिकप्रिया ॥४०॥
                                </p>
                                <p className="text-lg leading-relaxed  mb-2">
                                    सहस्त्रपत्रनिलया सहस्त्रफणिभूषणा सहस्त्रनामसंस्तोत्रा सहस्त्राक्षबलापहा ॥४१॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    प्रकाशाख्या विशालाक्ष्या प्रकाशिकविमर्शका निर्वाणचरणादेवी चतुश्चरणसंज्ञका ॥४२॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    चर्तुविज्ञानशक्त्याढ्या सुभगा च क्रियायुता स्मरेशा शांतिदा इच्छा इच्छाशक्तिसमान्विता ॥४३॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    निशांबरा च राजन्यपूजिता च निशाचरी सुंदरी चौर्ध्वकेशी च कामदा मुक्तकेशिका ॥४४॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    मानिनीतिसमाख्याता वीराणांजयदायिनी यामलीति समाख्याता नासाग्रिंबदुमालिनी ॥४५॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    या कंका च करालांगी चंद्रकाला च संश्रया चक्रिणी शंखिनी रौद्रा गकपादा त्रिलोचना ॥४६॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    भीषणी भैरवी भीमा चंद्रहासा मनोरमा विश्वरूपा महादेवी घोररूपा प्रकाशिका ॥४७॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    कपालमालिकायुक्ता मूलपीठस्थिता रमा योगिनी विष्णुरुपा च सर्वदेवर्षिपूजिता ॥४८॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    सर्वतीर्थपरादेवी तीर्थदक्षिणत:- स्थिता श्रीसदाशिववाच:- दिव्यनाम सहस्त्रं ते योगेश्वरर्यामयेरितं ॥४९॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    य: पठेत्पाठयेद्वापि स मुक्तो नात्र संशय: अष्टभ्यां भूतपौर्णम्यां नवम्यां दर्शभौमयो: ॥५०॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    अयनेषूपरागे च पुण्यकाले विशेषत: सर्वसौभाग्यसिध्यर्थ जपनीयं प्रयत्नत: ॥५१॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    सर्र्वाभीष्टकरं पुण्यं नित्यमंगलदायकं इयं नामावली तुभ्यं मयाद्य समुदीरिता ॥५२॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    गोपनीया प्रयत्नेन नाख्येया च कदाचन भक्ताय ज्येष्ठपुत्राय देयं शिष्याय धीमते ॥५३॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    आवहंतीतिमंत्रेण युक्तान्येतानि सादरं योजपेत्सततं भक्त्या सकामांल्लभतेध्रुवम् ॥५४॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    कार्याण्यावाहनादीनि देव्याशुविरतात्मभि: आवहंतीति मंत्रेण प्रत्येकं च यथाक्रमम् ॥५५॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    कर्तव्यं तर्पणं चापि तेन मंत्रेण मूलवत् तदन्वितैश्च होमोपि कर्तव्यं स्तैश्च मूलत: ॥५६॥
                                </p>

                                <p className="text-lg leading-relaxed  mb-2">
                                    गतानि दिव्यनामानिश्रुत्वा ध्यात्वाऽपियोनर:  ध्यात्वा देवीं च सततं सर्वकामार्थसिद्धये ॥५७॥
                                </p>
                                <p className="text-lg mb-3 leading-relaxed">
                                    गतापप्रसादेन नित्यतृप्तो वसाम्यहं संतुष्टहृदयो नित्यं वसाम्यत्रार्चयं शिवाम् ॥५८॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    स्वापकाले प्रबोधे च यात्राकाले विशेषत: तस्य सर्वभयं नास्ति रणे च विजयी भवेत् ॥५९॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    राजद्वारे सभास्थाने विवादे विप्लवे तथा चोरव्याघ्रभयं नास्ति संग्रामे जयवर्धनम् ॥६०॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    क्षयापस्मारकुष्ठादितापज्वरनिवारणम् महाज्वरं तथात्युग्रं शीतज्वरनिवारणम् ॥६१॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    दोषादिसंन्निपातं च रोगाणां हंति वर्चिआ भूतप्रेतपिशाचाश्च रक्षां कुर्वतिसर्वश: ॥६२॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    जपेत्सहस्त्रनामाख्यं योगिन्या: सर्व कामदम् यं यं िंचतयते कामं तं तं प्राप्नोति निश्चितम् ॥६३॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    त्रिकालमेककालं वा श्रद्धया प्रयत: पठेत् सर्वान् रिपून्क्षणााित्वा य: पुमांछ्रियमाप्नुयात् ॥६४॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    डाकिनी शाकिनी चैव वेतालब्रह्माराक्षिआं कूष्मांडादिभयं सर्व नश्यति स्मरणात्तत: ॥६५॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    वने रणे महाघोरे कारागृहनियंत्रके सर्वसंकटनाशार्थ स्तोत्रपाठ:सुसिद्धये ॥६६॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    वंध्या वा काकवंध्या वा मृतवंध्या च यांगना श्रुत्वा स्तोत्रमिदं पुत्रांल्लभते चिरजीविन: ॥६७॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    स्वयंभुकुसुमै: शुक्रै: सुगंधिकुसुमान्वितै: कुंकुमागरूकस्तुरीिंसदूरादिभिरर्चयेत् ॥६८॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    फलपुष्पादिभिर्युक्तै: मध्वाजै: पायसान्वितै: पक्वानै: षड्रसैर्भेाज्यै: स्वाद्वंनैश्च चतुर्विधै: ॥६९॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    कुमारीं पूजयेत्भक्त्या ब्राह्मणांश्च सुवासिनी: शक्तितो दक्षिणां दत्वा वासोलंकारभूषणै:॥७०॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    अनेन विधिना पूज्या देव्या: संतुष्टकारकम् सहस्रनामपाठात्तु कार्यसिद्धिर्नसंशय: ॥७१॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    रमाकांत सुराधीश प्रोक्तं गुह्यतरं मया नासूयकाय वक्तव्यं परशिष्याय नो वदेत् ॥७२॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    देवीभक्ताय वक्तव्यं मम भक्ताय माधव तव भक्ताय वक्तव्यं न मूर्खायाततायिने ॥७३॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    सत्यं सत्यं पुन: सत्यं उद्धृत्य भुजमुच्यते नानया सदृशी विद्या न देव्या योगिनी परा॥७४॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    अीति श्रीरुद्रयामले उत्तरखंडे देवीचरित्रे विष्णुशंकरसंवादे योगेश्वरीसहस्रनामस्तोत्र संपूर्णम् ॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">ॐ, ॐ, ॐ,</p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    ‘‘ऋग् मंत्रौ’’ ॐ आवहन्त्यरुणीरित्यस्य गौतमो वामदेव ऋषि: िलगोक्ता श्रीदेवता त्रिष्टुप् छंद्: जपे विनियोग: आवहन्त्यरुणीर्ज्येातिषागान्महीतिऋक् अष्टक ३ अ. ५ वर्ग १४ आवहन्ती पौष्यावार्याणीति ऋक् ॥१॥ अष्टक १ अध्याय ८ वर्ग ३
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    ‘‘यजुर्मत्र’’ ॐ आवहन्ती वितन्वाना कुर्वाणा चीरमात्मन: वासाँसि मम गावश्च अन्नपानेच सर्वदा ततो मे श्रियमावह॥१॥
                                </p>
                            </div>
                        </div>
                        <div className="min-h-screen p-4 ">
                            <h1 className="text-3xl font-bold text-center  mb-6">आरती योगेश्वरीची</h1>

                            <div className="space-y-6 font-SOHNE_REGULAR">
                                <p className="text-lg mb-3 leading-relaxed">
                                    जयदेवी जयदेवी जय योगेश्वरीमाते <br />
                                    जय योगेश्वरीमाते
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    कुलस्वामिनी जगदंबे करुणामृतसरिते <br />
                                    धर्माला ये ग्लानी, स्थिति केविलवाणी <br />
                                    असुरे पीडित जनता, त्राता तिज न कुणी <br />
                                    साद घाली तुज अंबे धाव धाव जननी <br />
                                    धावा पडता श्रवणी, घेशी धाव झणी <br />
                                    जयदेवी जयदेवी जय योगेश्वरीमाते ॥१॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    असुराते निर्दाळुनि, धर्मा रक्षियले <br />
                                    सज्जनपालन केले, दुर्जन मर्दियले <br />
                                    विश्रांतीस्तव निर्जन स्थाना शोधियले <br />
                                    आंबग्रामानिकटी वास्तव्या केले <br />
                                    जयदेवी जयदेवी जय योगेश्वरीमाते ॥२॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    वेदपुराणे थकली गाता तुज जेथे <br />
                                    काय पामरे गावे वदतव महिम्याते <br />
                                    दिगंत कीर्ती तव ही त्रिभुवनि दुमदुमते <br />
                                    भावे भजता भक्ते पावशि झणि त्याते <br />
                                    जयदेवी जयदेवी जय योगेश्वरीमाते ॥३॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    दु:सह भव हा तोडी या भव पाशाते <br />
                                    जनन-मरण हे तेची चुकवी झणि माते <br />
                                    मागत काहि न माते तव पदकमलाते <br />
                                    द्यावा तव पदि आश्रय नत अज्ञाताते <br />
                                    जयदेवी जयदेवी जय योगेश्वरीमाते ॥४॥
                                </p>
                            </div>
                        </div>
                        <div className="min-h-screen p-4">
                            <h1 className="text-3xl font-bold text-center mb-6">बोडणाची आरती</h1>

                            <div className="space-y-6 font-SOHNE_REGULAR">
                                <p className="text-lg mb-3 leading-relaxed">
                                    आम्हा कुळीची जोगेश्वरी तू <br />
                                    सुख संसारी तुजला पूजिते <br />
                                    माय भवानी तुजला नमिते <br />
                                    अंबिके तुझे बोडण भरिते <br />
                                    ॥धृ॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    शुद्ध गोमये भूमी सारवुनी <br />
                                    रंगवल्लिका त्यावरी रेखुनी <br />
                                    परातीमध्ये तुज बैसविते <br />
                                    ॥१॥ अंबिके....
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    सुवासिनींना कुमारिकेते <br />
                                    पाचारुनिया गृही आणिते <br />
                                    हळदी-कुंकुमा त्यांसी लाविते <br />
                                    ॥२॥ अंबिके....
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    पंचामृति तुज करुनी पूजनी <br />
                                    धूपदीप कर्पुरा लावुनी <br />
                                    पिष्ट दागिने तुजला वाहते <br />
                                    ॥३॥ अंबिके....
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    पाकसिद्धिसह करुनि तयारी <br />
                                    वडे तळुनिया पुरण आणि खिरी <br />
                                    नैवेद्य मग तुज दाखविते <br />
                                    ॥४॥ अंबिके....
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    पंचनैवेद्य जवळ ठेवुनी <br />
                                    पिष्टायदिवे त्यावर लावुनी <br />
                                    सुवासिनींसह तुज कालविते <br />
                                    ॥५॥ अंबिके....
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    देई गृही या सुख-सौभाग्य <br />
                                    देई बालका आयु-आरोग्य <br />
                                    हीच प्रार्थना तव पदी करिते <br />
                                    ॥६॥ अंबिके....
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    रूप तुझे काय वर्णू जैसा शोभतो भानू <br />
                                    बैसली िंसहासनी अंबा भक्ति कामधेनू <br />
                                    इच्छिले फळ देसी तुज ध्याति ग जन <br />
                                    जयदेवी आदिरूपे परशुरामाचे माते <br />
                                    आरती ओवाळेन महंमाय रेणुके.... जयदेवी.... ॥१॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    नऊ दिवस नवरात्र माझ्या अंबेचे व्रत <br />
                                    याचक जन येती महोत्सव थोर होती <br />
                                    रेणुका नाम तुझे तुज ध्याति ग जन <br />
                                    जयदेवी आदिरूपे परशुरामाचे माते <br />
                                    आरती ओवाळेन महंमाय रेणुके.... जयदेवी.... ॥२॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    मातापुरादी मूळ तुज तुळजापूर <br />
                                    नांदती सप्तश्रृंग दैत्य धाक हा थोर <br />
                                    पाठक गंगाधर विनवितो जोडोनी कर <br />
                                    जयदेवी आदिरूपे परशुरामाचे माते <br />
                                    आरती ओवाळेन महंमाय रेणुके.... जयदेवी.... ॥३॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    अहो दक्षिण देशामध्ये गक म्या तुळजापूर देखिले हो <br />
                                    तुळजापूर देखिले <br />
                                    राज्य करी तुळजा, राज्य करी तुळजा, <br />
                                    साधुसंताचे माहेर हो <br />
                                    दर्शनाला येती ब्रह्मा विष्णु महेश्वर हो, जयजय अष्टभुजे.... ॥१॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    तुकाई दैत्यावर, तुकाई दैत्यावर <br />
                                    सोडी महंकाळ दरबार हो <br />
                                    जयजय अष्टभुजे, जयजय अष्टभुजे अष्ट आयुधे तुज शोभती हो <br />
                                    काय वर्णू रूप, काय वर्णू रूप तुझे <br />
                                    सहज गुण शोभती हो, जयजय अष्टभुजे.... ॥२॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    व्याघ्रावर बैसोनी महिषासुराशी युद्ध करी <br />
                                    नाटक महिषासुर, नाटक महिषासुर <br />
                                    संगं परिच्या माया धरी हो पळत पळत गेली सप्तश्रृंग गडावरी हो <br />
                                    हंकरीला त्रिशूळ, हंकरीला त्रिशूळ हो <br />
                                    मारीला तयाच्या उदरी हे, जय जय अष्टभुजे ॥३॥
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    मारीला महिषासूर, तुकाई झाली यशवंत हो <br />
                                    तेहतीस कोटी देव, तेहतीस कोटी देव <br />
                                    चरणालगती समस्तक हो <br />
                                    त्रिबक शंभो म्हणे, अंबा झाली यशवंत हो जयजय अष्टभुजे ॥४॥
                                </p>
                                <p className="text-lg mb-3 leading-relaxed">
                                    हरहर गणपती भास्कर अंबा करु आरती....जयदेव जयदेव ॥ <br />
                                    मेघ: श्यामा सुंदर पीतांबर कासे <br />
                                    शंख गदाधर आयुधे चतुर्भुज विलसे <br />
                                    श्रीवत्स मणि कौस्तुभ कंठी शोभतसे <br />
                                    सर्वांतर यामित्वे सर्वा कंठी वसतसे....जयदेव जयदेव ॥<br />
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    पंचवदन शशीभूषण शिवकर्पूर गौर <br />
                                    दशहस्ते आयुधे भस्म जटाधर <br />
                                    गका बाणे दग्ध केले ते त्रिपूर <br />
                                    गेसा शंभो गाती नरवर सुरवर....जयदेव जयदेव ॥<br />
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    गौरीशंकर याचे जे क्रीडा सदन <br />
                                    शेंदूर भाळी भव्य शोभे गजवदन <br />
                                    रक्तचंदन अंगी रक्तांबर वर्ण <br />
                                    सर्वा भरणी ती सुशोभित श्री विघ्नहरण....जयदेव जयदेव ॥<br />
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    तमनाशन सुखकर्ता नेत्र ब्रह्महरणा <br />
                                    संधीपासून विप्र गाती तवचरणा <br />
                                    विद्युत द्युति मणिमय देती तुझिया आभरणा <br />
                                    सर्वा भरणी ती योगे तुझिया भवतरणा....जयदेव जयदेव ॥<br />
                                </p>

                                <p className="text-lg mb-3 leading-relaxed">
                                    आद्य: शक्ती त्रिगुणात्मा श्री परमेरी <br />
                                    पंचमहाभूतांचा मंडप विस्तारी <br />
                                    चतुर्भुज प्रजा जंगम स्थावरी <br />
                                    खेळाखेळून गेशा निजदासा तरी....जयदेव जयदेव ॥<br />
                                </p>
                            </div>
                        </div>
                        <div className="container font-SOHNE_REGULAR mx-auto p-4">
                            {/* आरती Section */}
                            <section className="mb-8">
                                <h2 className="text-2xl font-bold text-center mb-4">आरती</h2>
                                <div className="space-y-4 text-lg">
                                    <p>धन्य अंबापूर महिमा विचित्र पार्वती अवतार योगिनी क्षेत्र दंतातुर मर्दोनि केलें चरित्र सिद्धाचें स्थळ तें महा पवित्र जयदेवी जयदेवी जययोगेश्वरी महिमा न कळे तूझा वर्णिता थोरी जयदेवी ॥१॥</p>
                                    <p>पतित पावन सर्व तीर्थ महाद्वारीं माया मोचन सकळ माया निवारी साधका सिद्धि वानेच्या तीरीं तेथील महिता वर्णू न शके वैखरी जय। ॥२॥</p>
                                    <p>सिद्ध िंलग स्थळ परम पावन नरिंसह क्षेत्र तेथे नृिंसह वदन मूळ पीठ रेणागिरी नांदे आपण संताचे माहेर गोदेवी स्थान जय। ॥३॥</p>
                                    <p>महा रुद्र जेथे भैरव अवतार काळभैरव त्याचा महिमा अपार नागझरी तीर्थ तीर्थाचें सार मार्जन करितां दोष होती संहार जय। ॥४॥</p>
                                    <p>अनंतरूप शक्ति तुज योग्य माते योगेश्वरी नाम त्रिभुवन विख्याते व्यापक सकळां देहीं अनंत गुण भरिते नीलकंठ ओवाळू कैवल्य माते जय देवी - ॥५॥</p>
                                </div>
                            </section>

                            {/* आरती 2 Section */}
                            <section className="mb-8">
                                <h2 className="text-2xl font-bold text-center mb-4">आरती २</h2>
                                <div className="space-y-4 text-lg">
                                    <p>चिच्छक्ते चित्रूपिणी चिदानंद सदने, चिद्विलासचिद्वत्ने चिदाकाशभुवने चिदाभास संविन्मयचित्सदने चिछयने चिदाकार सन्मिय चित्सुख चिद्वदने जयदेवी जयदेवी जयत्रिपुरेश्वरि विद्ये करूणाकरि भवहारिणी योगेश्वरि विद्ये॥१॥</p>
                                    <p>पूर्णब्रह्मपरस्पर परात्परे परंज्योति: परमेश्वरी परमेश्वपरिपूर्णे श्री ऱ्हीं क्लीं परमंत्रत्रिकोणसंपूर्णे त्वंपद तत्पदपरतर असिपद मुद्धरणे जयदेवी जयदेवी। ॥२॥</p>
                                    <p>कमले कमल दलाक्षिके जयकमलकरे कमलानन कमलासन कमलवरे श्रीवरे कमलालय कमलादिक कमलवर श्रीवरे बालात्मज अधमा ते पालय गुणचतुरे जयदेवी जयदेवी। ॥३॥</p>
                                </div>
                            </section>

                            {/* प्रार्थना Section */}
                            <section className="mb-8">
                                <h2 className="text-2xl font-bold text-center mb-4">प्रार्थना</h2>
                                <div className="space-y-4 text-lg">
                                    <p>अपराध शतं कृत्वा जगदंबेति चोरेत् यां गिंत समवाप्नोति न तां ब्रह्मादय: सुरा: ॥१॥</p>
                                    <p>सापराधो स्मि शरणं प्राप्तस्वां जगदंबिके इदानीमनुकंप्य़ोऽहं यथेच्छति तथा कुरु ॥२॥</p>
                                    <p>कायदे जय परात्परे पूर्ण चिन्मये भगवती महामंगला लये आणि अीश्वरि विश्व व्यापिके शरण तूज मी पाव अंबिके ॥१॥</p>
                                    <p>दीन वत्सले पाय दाखवी त्रिपुर भैरवी या भवार्णवीं वारी ताप तूं मोद दायिके शरण. ॥२॥</p>
                                    <p>मोह काननीं टाकिलें मला मार्ग चूकलों ध्यातसे तुला तव कृपेंविणे भय कसें चुके शरण. ॥३॥</p>
                                    <p>आश्रया नसे आणिका जनीं बालकासि कां टाकिलें वनी धांव घे कडे माय अंबिके शरण.॥४॥</p>
                                    <p>विषय जाल हे भोवती अति कामक्रोध हे व्याघ्र गर्जती रक्षि संकटी भ्रांति नाशके शरण. ॥५॥</p>
                                    <p>परम मूढ मी जाण निश्चयें धड मला तुझें नाम ही नये म्हणवितों तुझा भक्त कालिके शरण. ॥६॥</p>
                                    <p>हेचि मागणे मानि हे मनी हृदय ग्रंथिका छेदि योगिनी प्रकट व्हावया ज्ञानदीपिके शरण. ॥७॥</p>
                                    <p>श्री दयानिधे त्रिपुर सुंदरी सर्व साक्षिणी भेट लौकरी गोसविनंदन विनवि चंडिके शरण. ॥८॥</p>
                                    <p>ॐ, ॐ, ॐ</p>
                                </div>
                            </section>
                        </div>
                        <div className="container font-SOHNE_REGULAR mx-auto p-4">
                            {/* ChaturShashti Yogini Aarti Section */}
                            <section className="mb-8">
                                <h2 className="text-2xl font-bold text-center mb-4">अथ श्री चतु:षष्ठी योगिनीनाम</h2>
                                <h3 className="text-xl font-semibold text-center mb-6">‘‘श्री स्कंदउवाच’’</h3>
                                <div className="space-y-4 text-lg">
                                    <p>नामधेयानि यक्ष्यामि योगिनी नां घटोभ्द आकर्ण्य यानि पापानी क्षयंती भवनां क्षणात् ॥१॥</p>
                                    <p>गजानन िंसहमुखी गृध्रास्या काकतुंडिका उष्ट्रग्रीवा हयग्रीवा वाराही शरभानना ॥२॥</p>
                                    <p>उलूकिका शिवारावा मयूरी विकटानना अष्टवक्रा कोटराक्षी कुब्जा विकटलोचना ॥३॥</p>
                                    <p>शुष्कोदरी ललाज्जिव्हाश्वदंष्ट्रा वानरानना ऋक्षाक्षी केकराक्षी च बृहत्तुंडासुराप्रिया ॥४॥</p>
                                    <p>कपालहस्तारक्ताक्षी शुंकी श्येनी कपोतिका पाशहस्ता दंडहस्ता प्रचंडा चंडविक्रमा ॥५॥</p>
                                    <p>शिशुघ्नी पापहंत्रीच काली रुधिरपायिनी वसाधया गर्भभक्षा शवहस्तांऽत्रमालिनी ॥६॥</p>
                                    <p>स्थूल केशी बृहत्कुक्षि: सर्पास्या प्रेतवाहना दंदशूककरा क्रौंची मृगशीर्पा वृषानना ॥७॥</p>
                                    <p>व्यात्तास्या धूमनि: श्वासा व्योमैकचरणोर्ध्वदृक् तापनी शोषणीदृष्टि: कोटरी स्थूलनासिका ॥८॥</p>
                                    <p>विद्युत्प्रभा बलाकास्या मार्जारी कटपूतना अट्टाट्टहासा कामाक्षी मृगाक्षी मृगलोचना॥९॥</p>
                                </div>
                            </section>
                        </div>
                        <div className="container font-SOHNE_REGULAR mx-auto p-4">
                            {/* ChaturShashti Yogini Aarti Section */}
                            <section className="mb-8">
                                <h2 className="text-2xl font-bold text-center mb-4">अथ श्री चतु:षष्ठी योगिनीनाम</h2>
                                <h3 className="text-xl font-semibold text-center mb-6">‘‘श्री स्कंदउवाच’’</h3>
                                <div className="space-y-4 text-lg">
                                    <p>नामधेयानि यक्ष्यामि योगिनी नां घटोभ्द आकर्ण्य यानि पापानी क्षयंती भवनां क्षणात् ॥१॥</p>
                                    <p>गजानन िंसहमुखी गृध्रास्या काकतुंडिका उष्ट्रग्रीवा हयग्रीवा वाराही शरभानना ॥२॥</p>
                                    <p>उलूकिका शिवारावा मयूरी विकटानना अष्टवक्रा कोटराक्षी कुब्जा विकटलोचना ॥३॥</p>
                                    <p>शुष्कोदरी ललाज्जिव्हाश्वदंष्ट्रा वानरानना ऋक्षाक्षी केकराक्षी च बृहत्तुंडासुराप्रिया ॥४॥</p>
                                    <p>कपालहस्तारक्ताक्षी शुंकी श्येनी कपोतिका पाशहस्ता दंडहस्ता प्रचंडा चंडविक्रमा ॥५॥</p>
                                    <p>शिशुघ्नी पापहंत्रीच काली रुधिरपायिनी वसाधया गर्भभक्षा शवहस्तांऽत्रमालिनी ॥६॥</p>
                                    <p>स्थूल केशी बृहत्कुक्षि: सर्पास्या प्रेतवाहना दंदशूककरा क्रौंची मृगशीर्पा वृषानना ॥७॥</p>
                                    <p>व्यात्तास्या धूमनि: श्वासा व्योमैकचरणोर्ध्वदृक् तापनी शोषणीदृष्टि: कोटरी स्थूलनासिका ॥८॥</p>
                                    <p>विद्युत्प्रभा बलाकास्या मार्जारी कटपूतना अट्टाट्टहासा कामाक्षी मृगाक्षी मृगलोचना॥९॥</p>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div
                    onClick={scrollToTop}
                    className="flex items-end justify-end my-5"
                >
                    <ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
                </div>
                <hr />
                </div>
              
            </div>
        </>
    )
}