import * as React from "react";
import {Header} from "../components/Header";
import {t} from "i18next";

export const YellowPage = () => {
    return (
        <>
            <div
                className={
                    "w-[100vw] h-[100vh] bg-white flex flex-col items-center justify-center"
                }
            >
                <Header/>
                <img
                    src="/banners/banner-yellow-page.jpg"
                    alt=""
                    className="w-full h-full object-cover shadow-lg"
                />
                <h1 className={"text-[5vw] font-SOHNE_MONO_BOLD"}>{t("CONSTANTS.COMING_SOON")}</h1>
            </div>

        </>
    );
};
