import {Header} from "../components/Header";
import {t} from "i18next";
import React, {useState} from "react";
import {Modal} from "../components/Modal";

export const YogeshwariDeviAmbajogai = () => {
    const [showTimetableModal, setShowTimetableModal] = useState(false);

    return (
        <>
            <div className="w-full  flex flex-col items-center justify-center">
                <Header/>

                <img
                    src="/other_imgs/banner-dharmik-inside.jpg"
                    alt="Information Banner"
                    className="w-full h-[30vh] object-cover shadow-lg"
                />
            </div>
            <h1 className="text-center font-bold text-3xl mt-10 text-red-800">
                योगेश्वरी देवी, अंबाजोगाई
            </h1>

            <div className="flex justify-between items-center p-6">
                {/* Left Section - Image */}
                <div className="w-full lg:w-1/2 p-4">
                    <div className="flex items-center justify-center">
                        <img
                            src="/other_imgs/thumbnail-dharmik-ambajogai.png"
                            alt="Temple Idol"
                            className=" shadow-md w-[550px]  h-[550px] object-cover"
                        />
                    </div>
                    <p className="mt-10 leading-relaxed px-5 text-justify">
                        मराठवाड्यातील आंबेजोगाईची अंबापुरवासिनी श्री योगेश्वरी देवी ही बहतेक
                        सर्वच चित्तपावन कोकणस्थ ब्राह्मणांची कुलदेवता आहे. त्यामुळे
                        महाराष्ट्रातील सर्व भागांतून या देवीच्या दर्शनास भक्तगण येत असतात.
                        अत्रिगोत्रीय चित्पावन जोगळेकर कुटुंबीयांची तर ही दंतासुरमर्दिनी,
                        राजराजेश्वरीदेवी, विशेष शक्ती असलेली कुलस्वामिनीच आहे. ह्या श्री
                        योगेश्वरी देवीच्या देवस्थानाच्या स्थापनेचा काळ सुमारे दीड हजार
                        वर्षांपूर्वींचा मानला जातो. आंबेजोगाई शहराच्या मध्यभागातून वाहणाऱ्या
                        जयंती नदीच्या पश्चिमकाठावर श्री योगेश्वरी देवीचे, हेमाडपंती रचनेचे,
                        उत्तराभिमुख मंदिर वसलेले आहे. श्री योगेश्वर देवीचे वार्षिक उत्सव,
                        अश्विन व मार्गशीर्ष महिन्यात साजरे केले जातात. अष्टमी व नवमी
                        तिथीच्या संधिकालावर शतचंडी हवनावर पूर्णाहती होते. मार्गशीर्ष
                        महिन्यांत शुध्द सप्तमीपासून घटस्थापना होऊन पौर्णिमेच्या दिवशी शतचंडी
                        यज्ञाची पूर्णाहती होते. मार्गशीर्ष पौर्णिमा हा श्री योगेश्वरी देवीचा
                        अवतारदिवस आहे. या दिवशी देवीला भोग चढवितात. जोगळेकर कुटुंबीय
                        व्यक्तींना, कुलस्वामिनीचे आयुष्यात गकदा तरी दर्शन घेणे आवश्यक आहे
                        असे मानतात. लग्नानंतर जोडप्याने कुलस्वामिनीचे दर्शन घेण्याची प्रथा
                        आहे. ह्या आंबेजोगाईच्या श्री योगेश्वरी देवीच्या दर्शनाला िंकवा
                        धर्मकृत्यांकरिता आंबेजोगाईस जाणाऱ्यांनी ह्या देवस्थानाचे
                        क्षेत्रउपाध्याय यांची मदत घ्यावी. १. क्षेत्र उपाध्याय भिकाजी काशिनाथ
                        पाठकशास्त्री. २. क्षेत्र उपाध्याय वसंत मार्तंड औसेकर-पाठक.
                    </p>
                </div>

                {/* Right Section - Form and Buttons */}
                <div className="w-full lg:w-1/2 p-4">
                    <div className="flex flex-col items-center mb-6">
                        {/* LIVE DARSHAN Button */}
                        <button
                            className="bg-red-600 text-white font-bold text-xl px-6 py-2 rounded-xl mb-4 shadow hover:bg-red-700 flex items-center justify-center w-80 h-16">
                            <img
                                src="/other_imgs/icon-live-recording-dot.png"
                                className="w-8 h-8 mr-16"
                            />
                            {t("YOGESHWARI_DEVI_PAGE.LIVE_DARSHAN")}
                        </button>

                        {/* TEMPLE TIME TABLE Button */}
                        <button
                            className="bg-red-600 font-bold text-xl text-white  px-6 py-2 rounded-xl shadow hover:bg-red-700 flex items-center justify-center w-80 h-16"
                            onClick={() => setShowTimetableModal(true)}
                        >
                            <img
                                src="/other_imgs/icon-temple-timetable.png"
                                className="w-10 h-10 mr-10 object-contain"
                            />
                            {t("YOGESHWARI_DEVI_PAGE.TIMETABLE")}
                        </button>
                    </div>
                    {/* form detail = guruji detail */}
                    <form className=" p-6 space-y-4">
                        <div>
                            <label className="block text-red-700 font-SOHNE_EXTRA_BOLD text-xl mb-2">
                                {t("YOGESHWARI_DEVI_PAGE.PUJA_AND_GURUJI_DETAILS.TITLE")}
                            </label>
                            <div className="flex space-x-4">
                                <select
                                    className="w-1/2 p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
                                    <option>{t("YOGESHWARI_DEVI_PAGE.PUJA_AND_GURUJI_DETAILS.GURUJI_PLACEHOLDER")}</option>
                                </select>
                                <select
                                    className="w-1/2 p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
                                    <option>{t("YOGESHWARI_DEVI_PAGE.PUJA_AND_GURUJI_DETAILS.PUJA_PLACEHOLDER")}</option>
                                </select>
                            </div>
                        </div>

                        {/* form details */}
                        <label className="block text-red-700 font-SOHNE_EXTRA_BOLD text-xl mb-2">
                            {t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.TITLE")}
                        </label>
                        <div className="grid grid-cols-2 gap-4">
                            <input
                                type="text"
                                placeholder={t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.NAME")}
                                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                            />
                            <input
                                type="text"
                                placeholder={t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.SURNAME")}
                                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                            />
                            <input
                                type="text"
                                placeholder={t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.GOTRA")}
                                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                            />
                            <input
                                type="text"
                                placeholder={t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.MOBILE_NUMBER")}
                                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                            />
                            <div className="flex flex-col ">
                                <label className="font-SOHNE_REGULAR text-lg  text-red-700 mb-3">
                                    {t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.BOOKING_DATE")}
                                </label>
                                <input
                                    type="date"
                                    placeholder="Booking Date"
                                    className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="font-SOHNE_REGULAR text-lg  text-red-700 mb-3">
                                    {t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.BOOKING_TIME")}
                                </label>
                                <input
                                    type="time"
                                    placeholder="Booking Time"
                                    className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                                />
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <button
                                className="bg-gradient-to-tr from-green-900 to-lime-600 font-bold text-xl mt-10 text-white px-6 py-2 rounded-xl shadow flex items-center justify-center w-80 h-16">
                                <img
                                    src="/other_imgs/icon-whatsapp.png"
                                    className="w-10 h-10 mr-10 object-contain"
                                />
                                {t("YOGESHWARI_DEVI_PAGE.FORM_DETAILS.SEND_BUTTON")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Modal isOpen={showTimetableModal}>
                <div
                    className={`flex flex-col max-h-[50vh] w-[30vw] bg-white rounded-xl`}
                >
                    {/* TimeTable Modal Header */}
                    <div className={"mt-[2vh] mx-[3vh] flex flex-row items-center justify-between"}>
                        {/* Modal Title */}
                        <h2 className="text-[1.5vw] font-SOHNE_BOLD text-gray-900">
                            {t(`YOGESHWARI_DEVI_PAGE.TIMETABLE`)}
                        </h2>

                        {/* Close Button */}
                        <button
                            onClick={() => setShowTimetableModal(false)}
                            className="w-[1.75vw] h-[1.75vw]
                            text-sm text-gray-400 hover:text-gray-900
                            flex items-center justify-center bg-[#EAEAEA] rounded-md shadow-lg
                            hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                            transition duration-300 ease-out"
                        >
                            <img
                                className={"w-[75%] h-[75%]"}
                                src={"/assets/close-icon.png"}
                                alt={"Close"}
                                title={"Close"}
                            />
                        </button>
                    </div>

                    {/* Divider */}
                    <div className={"h-[0.2vh] bg-[#ECECEC] rounded-full mt-[1.5vh] mx-[3vh]"}/>

                    <div className={"w-full p-[3vh] pt-[1.5vh]"}>
                        <img
                            src="/assets/yogeshwari_devi_temple_timetable.png"
                            className="w-full aspect-auto mr-10 object-contain"
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};
