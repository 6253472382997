import { useState } from "react";
import { Header } from "./Header";
import { SearchIcon } from "lucide-react";
import { mockData } from "../utils/mockUserData";
import { countryCodes } from "../data/CountryCode";
import ProfileCard from "./ProfileCard";

export const Search = () => {
	const [familyTree, setFamilyTree] = useState("");
	const [bloodGroup, setBloodGroup] = useState("");
	const [status, setStatus] = useState("");
	const [gender, setGender] = useState("");
	const [selectedCountry, setSelectedCountry] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [city, setCity] = useState("");
	const [ageRange, setAgeRange] = useState("0-100");

	// Age range options
	const ageRanges = [
		{ label: "Age Range", value: "0-100" },
		{ label: "18-25", value: "18-25" },
		{ label: "26-35", value: "26-35" },
		{ label: "36-45", value: "36-45" },
		{ label: "46-55", value: "46-55" },
		{ label: "56-65", value: "56-65" },
		{ label: "66+", value: "66-100" },
	];

	// Parse min and max age
	const [minAge, maxAge] = ageRange.split("-").map(Number);

	// Filter data based on all criteria
	const filterData = () => {
		return mockData.filter((item) => {
			return (
				(bloodGroup ? item.bloodGroup === bloodGroup : true) &&
				(status ? item.maritalStatus === status : true) &&
				(gender ? item.gender.toLowerCase() === gender.toLowerCase() : true) &&
				(selectedCountry ? item.country === selectedCountry : true) &&
				(name ? item.firstName.toLowerCase().includes(name.toLowerCase()) : true) &&
				(email ? item.email.toLowerCase().includes(email.toLowerCase()) : true) &&
				(city ? item.city.toLowerCase().includes(city.toLowerCase()) : true) &&
				item.age >= minAge &&
				item.age <= maxAge &&
				(familyTree ? item.familyTreeName.toLowerCase().includes(familyTree.toLowerCase()) : true)
			);
		});
	};

	// Filtered data
	const filteredResults = filterData();
	return (
		<>
			<div className="w-[100vw] h-[100vh] bg-[#F7F7F7] flex items-center justify-center">
				<Header />
				<div
					className="fixed pt-[6vw] pb-[6vw] px-[1vw] gap-[1vw] 
                    min-h-full max-h-full min-w-full max-w-full"
				>
					<div className="relative">
						{/* Search Icon */}
						<img
							src={"/assets/account_search.png"}
							alt={"Account_search"}
							title={"Account_Search"}
							className="absolute left-[1vw] top-[50%] translate-y-[-50%] w-[1.5vw] h-[1.5vw] text-gray-500 rounded-lg"
						/>

						{/* Input */}
						<input
							placeholder="Search"
							className="w-full h-[3vw] rounded-full pl-[3.5vw] pr-[1vw] py-[1vw] shadow-xl font-SOHNE_BOLD hover:outline-none focus:outline-none"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					{/* Filters container */}
					<div className="flex pt-[1vw] gap-[1vw] w-full h-[calc(100vh-10vw)]">
						{/* Filters Table */}
						<div
							className="w-2/5 p-[1.25vw] rounded-lg bg-white shadow-xl 
              flex flex-col overflow-y-auto overflow-x-hidden h-full"
						>
							<h1 className="text-xl font-SOHNE_BOLD text-gray-500">
								Filters
							</h1>
							<hr className="mt-2" />
							{/*  filters content */}
							<div>
								<div className="w-full py-[1vw]">
								<div className="relative">
				{/* Search Icon */}
				<SearchIcon className="absolute left-[1vw] top-[50%] translate-y-[-50%] w-[1.5vw] h-[1.5vw] text-gray-500" />

				{/* Input for Family Tree Search */}
				<input
					placeholder="Search using family tree name"
					value={familyTree} // Bind to the familyTree state
					onChange={(e) => setFamilyTree(e.target.value)} // Update familyTree state
					className="w-full h-[3vw] rounded-lg pl-[3.5vw] pr-[1vw] py-[1vw] shadow-xl font-SOHNE_BOLD hover:outline-none focus:outline-none"
				/>

				{/* Dropdown Options */}
				{familyTree && (
					<ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg max-h-[10vw] overflow-y-auto">
						{mockData
							.filter((item) =>
								item.familyTreeName.toLowerCase().includes(familyTree.toLowerCase())
							)
							.map((item, index) => (
								<li
									key={index}
									onClick={() => setFamilyTree(item.familyTreeName)} // Set selected value
									className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-SOHNE_BOLD text-gray-700"
								>
									{item.familyTreeName}
								</li>
							))}
						{/* No Results Found */}
						{mockData.filter((item) =>
							item.familyTreeName.toLowerCase().includes(familyTree.toLowerCase())
						).length === 0 && (
							<li className="px-4 py-2 text-gray-500">No results found</li>
						)}
					</ul>
				)}
			</div>
								</div>

								<div className="flex gap-4 items-center mt-2">
									{/* Date of Birth Section */}
									<div className="flex flex-col">
										<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
											Date of Birth
										</label>
										<div className="flex items-center gap-4">
											<select
												value={ageRange}
												onChange={(e) =>
													setAgeRange(e.target.value)
												}
												className="border rounded-md p-2 w-32 focus:outline-none shadow-lg"
											>
												{ageRanges.map((range) => (
													<option
														key={range.value}
														value={range.value}
													>
														{range.label}
													</option>
												))}
											</select>

											<input
												type="text"
												placeholder="-"
												className="w-[3rem] h-[2.5rem] border border-gray-300 rounded-lg text-center focus:outline-none shadow-lg"
											/>
											<span className="text-gray-500">
												to
											</span>
											<input
												type="text"
												placeholder="-"
												className="w-[3rem] h-[2.5rem] border border-gray-300 rounded-lg text-center shadow-lg focus:outline:none"
											/>
										</div>
									</div>

									{/* Gender Section */}
									<div className="flex flex-col">
										<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
											Gender
										</label>
										<select
											className="w-[12rem] h-[2.5rem] border border-gray-300 rounded-lg px-2 shadow-lg focus:outline-none"
											value={gender}
											onChange={(e) =>
												setGender(e.target.value)
											}
										>
											<option value="">Select</option>
											<option value="Male">Male</option>
											<option value="Female">
												Female
											</option>
										</select>
									</div>
								</div>
								{/* Blood-Group || Status || pin-code */}
								<div className="flex gap-5 items-center mt-3">
									{/* Group Section */}
									<div className="flex flex-col">
										<label className="flex items-center text-md font-SOHNE_BOLD text-gray-600 mb-1">
											<img
												src={
													"/assets/blood_drop_icon.png"
												}
												className={
													"w-[1vw] aspect-auto"
												}
												alt={"blood_group"}
												title={"blood_group"}
											/>
											Group
										</label>
										<select
											className="w-[7rem] h-[2.5rem] border border-gray-300 rounded-lg px-2 shadow-lg focus:outline-none"
											value={bloodGroup}
											onChange={(e) =>
												setBloodGroup(e.target.value)
											}
										>
											<option value="">Select</option>
											<option value="A+">A+</option>
											<option value="O-">O-</option>
											<option value="B+">B+</option>
											<option value="AB+">AB+</option>
										</select>
									</div>

									{/* Status Section */}
									<div className="flex flex-col">
										<label className="flex items-center text-md font-SOHNE_BOLD  text-gray-600 mb-1">
											<img
												src={
													"/assets/marriage-icon.png"
												}
												className={
													"w-[1vw] aspect-auto"
												}
												alt={"Marriage"}
												title={"Marriage"}
											/>
											Status
										</label>
										<select
											className="w-[8rem] h-[2.5rem] border border-gray-300 rounded-lg px-2 shadow-lg focus:outline-none"
											value={status}
											onChange={(e) =>
												setStatus(e.target.value)
											}
										>
											<option value="">Select</option>
											<option value="Minor">Minor</option>
											<option value="Single">
												Single
											</option>
											<option value="Married">
												Married
											</option>
											<option value="Unmarried">
												Unmarried
											</option>
											<option value="Widower">
												Widower
											</option>
											<option value="Divorced">
												Divorced
											</option>
										</select>
									</div>

									{/* Pin Code Section */}
									<div className="flex flex-col">
										<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
											Pin Code
										</label>
										<div className="flex gap-2">
											{[...Array(6)].map((_, index) => (
												<input
													key={index}
													type="text"
													maxLength="1"
													placeholder="-"
													className="w-[2rem] h-[2.5rem] text-center border border-gray-300 rounded-lg shadow-lg focus:outline-none"
												/>
											))}
										</div>
									</div>
								</div>
								<div className="flex flex-col gap-6 mt-3">
									{/* City, State, Country */}
									<div className="flex gap-6">
										{/* City */}
										<div className="flex flex-col w-1/3">
											<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
												City
											</label>
											<div className="relative">
												<span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
													<SearchIcon />
												</span>
												<input
													type="text"
													placeholder="Search"
													className="w-full h-[2.5rem] pl-10 border border-gray-300 rounded-lg shadow-lg focus:outline-none"
													value={city}
													onChange={(e) =>
														setCity(e.target.value)
													}
												/>
											</div>
										</div>

										{/* State */}
										<div className="flex flex-col w-1/3">
											<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
												State
											</label>
											<div className="relative">
												<span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
													<SearchIcon />
												</span>
												<input
													type="text"
													placeholder="Search"
													className="w-full h-[2.5rem] pl-10 border border-gray-300 rounded-lg shadow-lg focus:outline-none"
												/>
											</div>
										</div>

										{/* Country */}
										<div className="flex flex-col w-1/3">
											<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
												Country
											</label>
											<select
												className="w-full h-[2.5rem] border border-gray-300 rounded-lg pl-3 focus:outline-none shadow-lg"
												value={selectedCountry}
												onChange={(e) =>
													setSelectedCountry(
														e.target.value
													)
												}
											>
												<option value="" disabled>
													Select a country
												</option>
												{countryCodes.map((country) => (
													<option
														key={
															country.countryCode
														}
														value={
															country.countryCode
														}
													>
														{country.countryName}
													</option>
												))}
											</select>
										</div>
									</div>

									{/* Mobile Number */}
									<div className="flex flex-col">
										<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
											Mobile Number
										</label>
										<div className="flex items-center border border-gray-300 rounded-lg h-[2.5rem] focus:outline-none shadow-lg  ">
											<span className="px-3 text-gray-600">
												+91
											</span>
											<input
												type="text"
												placeholder="Search using mobile number"
												className=" h-full border-none focus:outline-none px-3"
											/>
										</div>
									</div>

									{/* Email */}
									<div className="flex flex-col">
										<label className="text-md font-SOHNE_BOLD text-gray-600 mb-1">
											Email
										</label>
										<div className="relative">
											<span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
												<SearchIcon />
											</span>
											<input
												type="text"
												placeholder="Search using email id"
												className="w-full h-[2.5rem] pl-10 border border-gray-300 rounded-lg shadow-lg focus:outline-none"
												value={email}
												onChange={(e) =>
													setEmail(e.target.value)
												}
											/>
										</div>
									</div>
									<div className="mt-2">
										{/* Quick Access Title */}
										<h3 className="text-lg font-SOHNE_BOLD text-gray-700 mb-4">
											Quick Access
										</h3>

										{/* Quick Access Options */}
										<div className="grid grid-cols-2 gap-4">
											{/* First Row */}
											<label className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-lg border">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													Unmarried Women
												</span>
											</label>
											<label className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-lg border">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													Unmarried Men
												</span>
											</label>
										</div>
										<div className="grid grid-cols-3 gap-2 mt-3">
											{/* Second Row */}
											<label className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-lg border">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													SasurVashin
												</span>
											</label>
											<label className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-lg border">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													MaherVashin
												</span>
											</label>

											<label className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-lg border">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													Adopted
												</span>
											</label>
										</div>
										{/* third row */}
										<div className="flex flex-col w-full my-3">
											<label className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-lg border">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													Shatayushi Vyakti (more than
													100 years age)
												</span>
											</label>
											<label className="flex items-center gap-2 mt-3 bg-white p-3 rounded-lg shadow-lg border col-span-2">
												<input
													type="checkbox"
													className="form-checkbox text-blue-500"
												/>
												<span className="text-md font-SOHNE_REGULAR text-gray-600">
													Dirghayushi Vyakti (more
													than 75 years age)
												</span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Results Table */}
						<div className="w-2/3 p-[1.25vw] rounded-lg bg-white shadow-xl flex flex-col overflow-x-hidden h-full">
							{/* Checking if the field is empty to show GaneshJi photo */}
							{filteredResults.length === 0 && (
								<div className="flex items-center justify-center h-full">
									<img
										src="/assets/ganeshji-logo.png"
										className="w-[12.5vw] aspect-auto"
										alt="GaneshJi"
										title="GaneshJi"
									/>
								</div>
							)}

							{/* Display results or no results message */}
							{filteredResults.length > 0 ? (
								<div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
									{filteredResults.map((profile, index) => (
										<ProfileCard
											key={index}
											profileImg={profile.image}
											firstName={profile.firstName}
											lastName={profile.lastName}
											familyTreeName={
												profile.familyTreeName
											}
											firstNameMarathi={
												profile.firstNameMarathi
											}
											lastNameMarathi={
												profile.lastNameMarathi
											}
										/>
									))}
								</div>
							) : (
								<div className="flex items-start justify-center h-full">
									<p className="text-xl text-gray-600">
										No results found
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
