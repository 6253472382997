import {t} from "i18next";
import {Modal} from "../components/Modal";
import React, {useEffect, useState} from "react";
import {getListOfFamilyTreesByLocation} from "../service/accountApi";
import {toast} from "react-toastify";
import Cookies from "universal-cookie";

export const FamilyTreeListModal = (props) => {
    const cookies = new Cookies();
    const locale = cookies.get("locale");

    const {showFamilyTreeListModal, setShowFamilyTreeListModal, selectedLocation} = props;
    const [loading, setLoading] = useState(true);
    const [familyTrees, setFamilyTrees] = useState([]);

    useEffect(() => {
        console.log("showFamilyTreeListModal: ", showFamilyTreeListModal);
        if (!showFamilyTreeListModal) {
            const timeout = setTimeout(() => {
                console.log("Clearing Family Tree List!");
                setFamilyTrees([]);
            }, 400);

            return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [showFamilyTreeListModal]);

    useEffect(() => {
        const getFamilyTreeByLocation = async () => {
            if (showFamilyTreeListModal && selectedLocation.value !== "") {
                console.log("Selected Location: ", selectedLocation);

                const getFamilyTreeByLocationResponse = await getListOfFamilyTreesByLocation(selectedLocation.value);
                console.log("Get Family Tree By Location Response: ", getFamilyTreeByLocationResponse);

                if (getFamilyTreeByLocationResponse.success) {
                    if (getFamilyTreeByLocationResponse.data.length > 0) {
                        setFamilyTrees(getFamilyTreeByLocationResponse.data);
                    } else {
                        toast.info("No Family Trees found for the selected location!");
                    }
                }

                setLoading(false);
            }
        }

        getFamilyTreeByLocation();
    }, [selectedLocation, showFamilyTreeListModal]);

    return (
        <Modal zIndex={"z-[100]"} isOpen={showFamilyTreeListModal}>
            <div
                className={`flex flex-col max-h-[50vh] w-[30vw] bg-white rounded-xl`}
            >
                {/* Family Tree List Modal Header */}
                <div className={"mt-[2vh] mx-[3vh] flex flex-row items-center justify-between"}>
                    {/* Modal Title */}
                    <h2 className="text-[1.5vw] font-SOHNE_BOLD text-gray-900">
                        {t("KULAVRUKSH_LIST_MODAL.TITLE", {
                            location: t(`CONSTANTS.MAP_LOCATIONS.${selectedLocation.label}`)
                        })}
                    </h2>

                    {/* Close Button */}
                    <button
                        onClick={() => setShowFamilyTreeListModal(false)}
                        className="w-[1.75vw] h-[1.75vw]
                            text-sm text-gray-400 hover:text-gray-900
                            flex items-center justify-center bg-[#EAEAEA] rounded-md shadow-lg
                            hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                            transition duration-300 ease-out"
                    >
                        <img
                            className={"w-[75%] h-[75%]"}
                            src={"/assets/close-icon.png"}
                            alt={"Close"}
                            title={"Close"}
                        />
                    </button>
                </div>

                {/* Divider */}
                <div className={"h-[0.2vh] bg-[#ECECEC] rounded-full mt-[1.5vh] mx-[3vh]"}/>

                {/* Family Tree List */}
                {
                    loading
                        ? (
                            <div className="flex items-center justify-center h-fit mx-[3vh] py-[5vh]">
                                <p className={"text-[1.5vw] font-SOHNE_MONO_BOLD"}>
                                    Loading...
                                </p>
                            </div>
                        ) : (
                            familyTrees.length > 0
                                ? (
                                    <div
                                        className="flex flex-col gap-y-[2vh] h-fit
                                            mx-[3vh] pt-[1vh] pb-[3vh]
                                            overflow-y-scroll"
                                    >
                                        {
                                            familyTrees.map((familyTree, index) => (
                                                <button
                                                    key={index}
                                                    className="w-full rounded-md
                                                        px-[1.5vh] py-[1vh]
                                                        border-2 border-[#EAEAEA]
                                                        hover:shadow-lg transition duration-300 ease-out"
                                                    onClick={() => {
                                                        console.log("Family Tree Data: ", familyTree);
                                                        if (familyTree.familyTreePdfUrl) {
                                                            window.open(familyTree.familyTreePdfUrl, "_blank");
                                                        } else {
                                                            toast.dismiss();
                                                            toast.info("PDF is not available for this Family Tree!");
                                                        }
                                                    }}
                                                >
                                                    <p className={"font-SOHNE_MONO_MEDIUM text-[1vw] text-left"}>
                                                        {(locale === "en" ? familyTree.familyTreeName : familyTree.familyTreeNameDevanagariScript) + "-" + familyTree.sequenceNumber}
                                                    </p>
                                                </button>
                                            ))
                                        }
                                    </div>
                                )
                                : (
                                    <div
                                        className="flex items-center justify-center flex-wrap
                                            h-fit mx-[3vh] pt-[2vh] pb-[3vh]"
                                    >
                                        <p className={"text-[1.5vw] font-SOHNE_MONO_MEDIUM text-wrap text-center"}>
                                            No family trees exist for {t(`CONSTANTS.MAP_LOCATIONS.${selectedLocation.label}`)}!
                                        </p>
                                    </div>
                                )

                        )
                }
            </div>
        </Modal>
    )
}
