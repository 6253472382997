const familyTreeNamesList = [
    {
        english: 'Adur-Palshet',
        devnagariScript: 'अदुर-पालशेत'

    },
    {
        english: 'Indore-Dewas-Karjat',
        devnagariScript: 'इंदोर-देवास-कर्जत'
    },
    {
        english: 'Kotawde-Akola',
        devnagariScript: 'कोतवडे-अकोला'
    },
    {
        english: 'Kotawde-Agadi',
        devnagariScript: 'कोतवडे-आगडी'
    },
    {
        english: 'Kotawde-Kalyan',
        devnagariScript: 'कोतवडे-कल्याण'
    },
    {
        english: 'Kotawde-Kiwale',
        devnagariScript: 'कोतवडे-किवळे'
    },
    {
        english: 'Kotawde-Gokarna-Mahabaleshwar',
        devnagariScript: 'कोतवडे-गोकर्णा-महाबळेश्वर'
    },
    {
        english: 'Kotawde-Pune-Kala Houd',
        devnagariScript: 'कोतवडे-पुणे-काळा हौद'
    },
    {
        english: 'Kotawde-Pune',
        devnagariScript: 'कोतवडे-पुणे'
    },
    {
        english: 'Kotawde-Bhiwandi (Dixit)',
        devnagariScript: 'कोतवडे-भिवंडी (दीक्षित)'
    },
    {
        english: 'Kotawde-Mirjole',
        devnagariScript: 'कोतवडे-मिर्जोले'
    },
    {
        english: 'Kotawde-Mithgawane-Mumbai',
        devnagariScript: 'कोतवडे-मिठगवाणे-मुंबई'
    },
    {
        english: 'Kotawde-Mumbai',
        devnagariScript: 'कोतवडे-मुंबई'
    },
    {
        english: 'Kotawde-Ratnagiri',
        devnagariScript: 'कोतवडे-रत्नागिरी'
    },
    {
        english: 'Kotawde-Reel-Kespuri',
        devnagariScript: 'कोतवडे-रीळ-केसपुरी'
    },
    {
        english: 'Kotawde-Waravade-Reel',
        devnagariScript: 'कोतवडे-वरवाडे-रीळ'
    },
    {
        english: 'Kotawde-Vathar-Kiroli',
        devnagariScript: 'कोतवडे-वाठार-किरोळी'
    },
    {
        english: 'Kotawde-Bijapur-Kirloskarwadi',
        devnagariScript: 'कोतवडे-बिजापूर-किर्लोस्करवाडी'
    },
    {
        english: 'Kotawde-Bijapur-Pune',
        devnagariScript: 'कोतवडे-बिजापूर-पुणे'
    },
    {
        english: 'Kotawde-Sakharpa',
        devnagariScript: 'कोतवडे-साखरपा'
    },
    {
        english: 'Kotawde-Hangal',
        devnagariScript: 'कोतवडे-हंगल'
    },
    {
        english: 'Kotawde-Hubli-Pune',
        devnagariScript: 'कोतवडे-हुबळी-पुणे'
    },
    {
        english: 'Dhokamale-Awas',
        devnagariScript: 'ढोकामले-आवास'
    },
    {
        english: 'Dhokamale-Kotawde',
        devnagariScript: 'ढोकामले-कोतवडे'
    },
    {
        english: 'Dhokamale-Jamkhandi',
        devnagariScript: 'ढोकामले-जमखंडी'
    },
    {
        english: 'Dhokamale-Nagpur',
        devnagariScript: 'ढोकामले-नागपूर'
    },
    {
        english: 'Dhokamale-Nachne',
        devnagariScript: 'ढोकामले-नाचणे'
    },
    {
        english: 'Dhokamale-Nevere-Mumbai',
        devnagariScript: 'ढोकामले-नेवरे-मुंबई'
    },
    {
        english: 'Dhokamale-Pune',
        devnagariScript: 'ढोकामले-पुणे'
    },
    {
        english: 'Dhokamale-Baroda',
        devnagariScript: 'ढोकामले-बरोडा'
    },
    {
        english: 'Dhokamale-Mumbai',
        devnagariScript: 'ढोकामले-मुंबई'
    },
    {
        english: 'Dhokamale-Waravade-Mumbai',
        devnagariScript: 'ढोकामले-वरवाडे-मुंबई'
    },
    {
        english: 'Naravan-Ahmedabad',
        devnagariScript: 'नरवण-अहमदाबाद'
    },
    {
        english: 'Naravan-Trimbakeshwar',
        devnagariScript: 'नरवण-त्र्यंबकेश्वर'
    },
    {
        english: 'Naravan-Neral',
        devnagariScript: 'नरवण-नेरळ'
    },
    {
        english: 'Naravan-Parita',
        devnagariScript: 'नरवण-परिता'
    },
    {
        english: 'Naravan-Pune (Nene-Ghat)',
        devnagariScript: 'नरवण-पुणे (नेणे-घाट)'
    },
    {
        english: 'Nevere-Dhokamale-Mumbai',
        devnagariScript: 'नेवरे-ढोकामले-मुंबई'
    },
    {
        english: 'Satara-Chaul',
        devnagariScript: 'सातारा-चौल'
    },
    {
        english: 'Hedavi-Agashi',
        devnagariScript: 'हेदवी-आगाशी'
    },
    {
        english: 'Hedavi-Aayni-Mete',
        devnagariScript: 'हेदवी-आयनी-मेटे'
    },
    {
        english: 'Hedavi-Avlas-Ghadashi',
        devnagariScript: 'हेदवी-अवलास-घडाशी'
    },
    {
        english: 'Hedavi-Indore',
        devnagariScript: 'हेदवी-इंदोर'
    },
    {
        english: 'Hedavi-Karchunde',
        devnagariScript: 'हेदवी-करचुंडे'
    },
    {
        english: 'Hedavi-Kalyan',
        devnagariScript: 'हेदवी-कल्याण'
    },
    {
        english: 'Hedavi-Kalki',
        devnagariScript: 'हेदवी-कल्की'
    },
    {
        english: 'Hedavi-Kase-Burambad',
        devnagariScript: 'हेदवी-कसे-बुरंबाद'
    },
    {
        english: 'Hedavi-Ambernath',
        devnagariScript: 'हेदवी-अंबरनाथ'
    },
    {
        english: 'Hedavi-Gajrath-Navi Mumbai',
        devnagariScript: 'हेदवी-गजरथ-नवी मुंबई'
    },
    {
        english: 'Hedavi-Chafal-Mumbai',
        devnagariScript: 'हेदवी-चाफळ-मुंबई'
    },
    {
        english: 'Hedavi-Jamkhandi',
        devnagariScript: 'हेदवी-जमखंडी'
    },
    {
        english: 'Hedavi-Jambulpada',
        devnagariScript: 'हेदवी-जांबुळपाडा'
    },
    {
        english: 'Hedavi-Jalgaon',
        devnagariScript: 'हेदवी-जळगाव'
    },
    {
        english: 'Hedavi-Junnar',
        devnagariScript: 'हेदवी-जुन्नर'
    },
    {
        english: 'Hedavi-Thane',
        devnagariScript: 'हेदवी-ठाणे'
    },
    {
        english: 'Hedavi-Tasgaon',
        devnagariScript: 'हेदवी-तासगाव'
    },
    {
        english: 'Hedavi-Third-Mahajan',
        devnagariScript: 'हेदवी-थर्ड-महाजन'
    },
    {
        english: 'Hedavi-Thal-Bhiwandi',
        devnagariScript: 'हेदवी-थळ-भिवंडी'
    },
    {
        english: 'Hedavi-Dapoli-Akshi Nagaon',
        devnagariScript: 'हेदवी-दापोली-अक्षी नागाव'
    },
    {
        english: 'Hedavi-Second-Mahajan',
        devnagariScript: 'हेदवी-सेकंड-महाजन'
    },
    {
        english: 'Hedavi-Jabalpur-Nagpur',
        devnagariScript: 'हेदवी-जबलपूर-नागपूर'
    },
    {
        english: 'Hedavi-Nagpur',
        devnagariScript: 'हेदवी-नागपूर'
    },
    {
        english: 'Hedavi-Nagaon',
        devnagariScript: 'हेदवी-नागाव'
    },
    {
        english: 'Hedavi-Nagaon-Badhwai (M.P.)',
        devnagariScript: 'हेदवी-नागाव-बढवाई (एम।पी।)'
    },
    {
        english: 'Hedavi-Nagaon-Mumbai',
        devnagariScript: 'हेदवी-नागाव-मुंबई'
    },
    {
        english: 'Hedavi-Nashik',
        devnagariScript: 'हेदवी-नाशिक'
    },
    {
        english: 'Hedavi-Pune',
        devnagariScript: 'हेदवी-पुणे'
    },
    {
        english: 'Hedavi-Pune-Cuttack',
        devnagariScript: 'हेदवी-पुणे-कटक'
    },
    {
        english: 'Hedavi-Poud-Mulshi',
        devnagariScript: 'हेदवी-पौड-मुळशी'
    },
    {
        english: 'Hedavi-Baroda',
        devnagariScript: 'हेदवी-बरोडा'
    },
    {
        english: 'Hedavi-Banaras-Mumbai',
        devnagariScript: 'हेदवी-बनारस-मुंबई'
    },
    {
        english: 'Hedavi-Bhilawadi',
        devnagariScript: 'हेदवी-भिलवडी'
    },
    {
        english: 'Hedavi-Bhiwandi (Dixit)',
        devnagariScript: 'हेदवी-भिवंडी (दीक्षित)'
    },
    {
        english: 'Hedavi-Mumbai',
        devnagariScript: 'हेदवी-मुंबई'
    },
    {
        english: 'Hedavi-Mokadam',
        devnagariScript: 'हेदवी-मोकादम'
    },
    {
        english: 'Hedavi-Maisal',
        devnagariScript: 'हेदवी-मैसल'
    },
    {
        english: 'Hedavi-Reel-Mangaon',
        devnagariScript: 'हेदवी-रीळ-माणगाव'
    },
    {
        english: 'Hedavi-Wardha',
        devnagariScript: 'हेदवी-वर्धा'
    },
    {
        english: 'Hedavi-Warsaw-Mumbai',
        devnagariScript: 'हेदवी-वारसा-मुंबई'
    },
    {
        english: 'Hedavi-Bijapur-Buldhana',
        devnagariScript: 'हेदवी-बिजापूर-बुलढाणा'
    },
    {
        english: 'Hedavi-Bijapur-Pune',
        devnagariScript: 'हेदवी-बिजापूर-पुणे'
    },
    {
        english: 'Hedavi-Vita',
        devnagariScript: 'हेदवी-विटा'
    },
    {
        english: 'Hedavi-Sakhari-Agar',
        devnagariScript: 'हेदवी-साखरी-अगर'
    },
    {
        english: 'Hedavi-Supe-Baramati',
        devnagariScript: 'हेदवी-सुपे-बारामती'
    },
    {
        english: 'Palshet',
        devnagariScript: 'पालशेत'
    },
    {
        english: 'Bilaspur',
        devnagariScript: 'बिलासपूर'
    },
    {
        english: 'Miraj',
        devnagariScript: 'मिरज'
    },
    {
        english: 'Mumbai',
        devnagariScript: 'मुंबई'
    },
    {
        english: 'Roha-Mumbai',
        devnagariScript: 'रोहा-मुंबई'
    }
];

export const familyTreeNames = [
    'Adur-Palshet',
    'Indore-Dewas-Karjat',
    'Kotawde-Akola',
    'Kotawde-Agadi',
    'Kotawde-Kalyan',
    'Kotawde-Kiwale',
    'Kotawde-Gokarna-Mahabaleshwar',
    'Kotawde-Pune-Kala Houd',
    'Kotawde-Pune',
    'Kotawde-Bhiwandi (Dixit)',
    'Kotawde-Mirjole',
    'Kotawde-Mithgawane-Mumbai',
    'Kotawde-Mumbai',
    'Kotawde-Ratnagiri',
    'Kotawde-Reel-Kespuri',
    'Kotawde-Waravade-Reel',
    'Kotawde-Vathar-Kiroli',
    'Kotawde-Bijapur-Kirloskarwadi',
    'Kotawde-Bijapur-Pune',
    'Kotawde-Sakharpa',
    'Kotawde-Hangal',
    'Kotawde-Hubli-Pune',
    'Dhokamale-Awas',
    'Dhokamale-Kotawde',
    'Dhokamale-Jamkhandi',
    'Dhokamale-Nagpur',
    'Dhokamale-Nachne',
    'Dhokamale-Nevere-Mumbai',
    'Dhokamale-Pune',
    'Dhokamale-Baroda',
    'Dhokamale-Mumbai',
    'Dhokamale-Waravade-Mumbai',
    'Naravan-Ahmedabad',
    'Naravan-Trimbakeshwar',
    'Naravan-Neral',
    'Naravan-Parita',
    'Naravan-Pune (Nene-Ghat)',
    'Nevere-Dhokamale-Mumbai',
    'Satara-Chaul',
    'Hedavi-Agashi',
    'Hedavi-Aayni-Mete',
    'Hedavi-Avlas-Ghadashi',
    'Hedavi-Indore',
    'Hedavi-Karchunde',
    'Hedavi-Kalyan',
    'Hedavi-Kalki',
    'Hedavi-Kase-Burambad',
    'Hedavi-Ambernath',
    'Hedavi-Gajrath-Navi Mumbai',
    'Hedavi-Chafal-Mumbai',
    'Hedavi-Jamkhandi',
    'Hedavi-Jambulpada',
    'Hedavi-Jalgaon',
    'Hedavi-Junnar',
    'Hedavi-Thane',
    'Hedavi-Tasgaon',
    'Hedavi-Third-Mahajan',
    'Hedavi-Thal-Bhiwandi',
    'Hedavi-Dapoli-Akshi Nagaon',
    'Hedavi-Second-Mahajan',
    'Hedavi-Jabalpur-Nagpur',
    'Hedavi-Nagpur',
    'Hedavi-Nagaon',
    'Hedavi-Nagaon-Badhwai (M.P.)',
    'Hedavi-Nagaon-Mumbai',
    'Hedavi-Nashik',
    'Hedavi-Pune',
    'Hedavi-Pune-Cuttack',
    'Hedavi-Poud-Mulshi',
    'Hedavi-Baroda',
    'Hedavi-Banaras-Mumbai',
    'Hedavi-Bhilawadi',
    'Hedavi-Bhiwandi (Dixit)',
    'Hedavi-Mumbai',
    'Hedavi-Mokadam',
    'Hedavi-Maisal',
    'Hedavi-Reel-Mangaon',
    'Hedavi-Wardha',
    'Hedavi-Warsaw-Mumbai',
    'Hedavi-Bijapur-Buldhana',
    'Hedavi-Bijapur-Pune',
    'Hedavi-Vita',
    'Hedavi-Sakhari-Agar',
    'Hedavi-Supe-Baramati',
    'Palshet',
    'Bilaspur',
    'Miraj',
    'Mumbai',
    'Roha-Mumbai'
];
