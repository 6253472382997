import React, {Fragment, useContext, useEffect, useState} from "react";
import {AccountContext} from "../context/AccountProvider";
import Cookies from "universal-cookie";
import {t} from "i18next";
import {Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {generateOtp, matchOtp} from "../service/userOtpApi";
import {toast} from "react-toastify";
import {addAccount} from "../service/accountApi";
import {translateTextToDevnagri} from "../service/translateApi";

export const Register = () => {
    const cookies = new Cookies();
    const pfpBgColors = ["bg-[#0F1F2E]", "bg-[#FF8C42]", "bg-[#FF3C38]", "bg-[#A23E48]"];

    const {setAccount, setCookie} = useContext(AccountContext);

    const [profile, setProfile] = useState({
        email: '',

        firstName: '',
        middleName: '',
        lastName: '',

        firstNameDevanagariScript: '',
        middleNameDevanagariScript: '',
        lastNameDevanagariScript: '',

        gender: 'Male',

        phone: {
            charCode: 'IN',
            dialingCode: '+91',
            countryName: 'India',
            number: ''
        },

        maritalStatus: 'Single',
        sideOfFamily: '',

        imageUrl: '',
        imageUrlBg: pfpBgColors[Math.floor(Math.random() * pfpBgColors.length)]
    });
    const [error, setError] = useState({});

    const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
    const [isSelectingGender, setIsSelectingGender] = useState(false);

    const [isMaritalStatusDropdownOpen, setIsMaritalStatusDropdownOpen] = useState(false);
    const [isSelectingMaritalStatus, setIsSelectingMaritalStatus] = useState(false);

    const [isJoglekarMaherVashin, setIsJoglekarMaheVashin] = useState(false);

    const [otpData, setOtpData] = useState({});
    const [otp, setOtp] = useState("");

    const [isSendEmailOtpButtonDisabled, setIsSendEmailOtpButtonDisabled] = useState(true);
    const [isSendEmailOtpSuccess, setIsSendEmailOtpSuccess] = useState(false);

    const [isVerifyOtpButtonDisabled, setIsVerifyOtpButtonDisabled] = useState(true);
    const [isVerifyEmailOtpSuccess, setIsVerifyEmailOtpSuccess] = useState(false);

    const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState(true);

    const translateToDevnagri = (e) => {
        if (e.target.value) {
            translateTextToDevnagri(e.target.value).then((res) => {
                console.log("(CreateProfile.jsx) Translation Response: ", res);
                setProfile({
                    ...profile,
                    [e.target.name + 'DevanagariScript']: res.result
                });
                setError({
                    ...error,
                    [e.target.name + 'DevanagariScript']: ""
                });
            });
        }
    };

    const handleGenderSelect = (gender) => {
        setIsSelectingGender(false);
        setIsGenderDropdownOpen(false);
        setProfile({
            ...profile,
            gender: gender
        });
    }

    const maritalStatusLabel = (maritalStatus) => {
        switch (maritalStatus) {
            case 'Single':
                return t("REGISTER_PAGE.MARITAL_STATUS.SINGLE");
            case 'Married':
                return t("REGISTER_PAGE.MARITAL_STATUS.MARRIED");
            case 'Widowed':
                return profile.gender === "Male"
                    ? t("REGISTER_PAGE.MARITAL_STATUS.WIDOWER")
                    : t("REGISTER_PAGE.MARITAL_STATUS.WIDOWED");
            case 'Divorced':
                return t("REGISTER_PAGE.MARITAL_STATUS.DIVORCED");
            case 'Minor':
                return t("REGISTER_PAGE.MARITAL_STATUS.MINOR");
            default:
                return t("REGISTER_PAGE.MARITAL_STATUS.SINGLE");
        }
    }

    const handleMaritalStatusSelect = (maritalStatus) => {
        setIsSelectingMaritalStatus(false);
        setIsMaritalStatusDropdownOpen(false);
        setProfile({
            ...profile,
            maritalStatus: maritalStatus
        });
    }

    useEffect(() => {
        console.log("(CreateProfile) (Changed) Gender: ", profile.gender);
        console.log("(CreateProfile) (Changed) Marital Status: ", profile.maritalStatus);
        if (profile.gender === 'Female' && (profile.maritalStatus === 'Single' || profile.maritalStatus === 'Minor')) {
            setProfile({
                ...profile,
                sideOfFamily: 'MaherVashin'
            })
        }
    }, [profile.gender, profile.maritalStatus]);

    useEffect(() => {
        if (validateEmail(profile.email)) {
            setIsSendEmailOtpButtonDisabled(false);
        } else {
            setIsSendEmailOtpButtonDisabled(true);
        }
    }, [profile.email]);

    useEffect(() => {
        if (otp.length === 6 && otp) {
            setIsVerifyOtpButtonDisabled(false);
        } else {
            setIsVerifyOtpButtonDisabled(true);
        }
    }, [otp]);

    useEffect(() => {
        if (profile.firstName !== '' && profile.middleName !== '' && profile.lastName !== '' && isVerifyEmailOtpSuccess) {
            setIsRegisterButtonDisabled(false);
        } else {
            setIsRegisterButtonDisabled(true);
        }
    }, [profile.firstName, profile.middleName, profile.lastName]);

    const isNumberValid = (phone) => {
        let reg = /^\d+$/;
        return reg.test(phone);
    }

    const validatePhoneNumber = (e) => {
        if ((!isNumberValid(e.target.value)) || e.target.value.length !== 10) {
            setError({
                ...error,
                [e.target.name]: t("PROFILE_FORM.PHONE_NUMBER.INVALID")
            });
        }
    }

    const validateEmail = (email) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    const validateEmailId = (e) => {
        if (e.target.value !== "" && !validateEmail(e.target.value)) {
            setError({
                ...error,
                [e.target.name]: t("REGISTER_PAGE.EMAIL_INPUT.ERROR.INVALID_INPUT")
            });
        } else if (e.target.value === "") {
            setIsSendEmailOtpButtonDisabled(true);

            if (Object.keys(error).includes(e.target.name)) {
                setError({
                    ...error,
                    [e.target.name]: ""
                });
            }
        } else if (e.target.value !== "" && validateEmail(e.target.value)) {
            setIsSendEmailOtpButtonDisabled(false);

            if (Object.keys(error).includes(e.target.name)) {
                setError({
                    ...error,
                    [e.target.name]: ""
                });
            }
        }
    }

    const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        });

        if (Object.keys(error).includes(e.target.name) || e.target.value === "") {
            setError({
                ...error,
                [e.target.name]: ""
            });
        }
    }

    const sendOtpOnEmailButtonClick = () => {
        if (profile.firstName === '' || profile.middleName === '' || profile.lastName === '') {
            setError({
                ...error,
                "firstName": t("REGISTER_PAGE.NAME.ERROR.FIRST_NAME"),
                "middleName": t("REGISTER_PAGE.NAME.ERROR.MIDDLE_NAME"),
                "lastName": t("REGISTER_PAGE.NAME.ERROR.LAST_NAME")
            });
            return;
        }

        setIsSendEmailOtpButtonDisabled(true);
        generateOtp(profile.email, "email", "register")
            .then((result) => {
                console.log("(Register.jsx) Send OTP Result: ", result);
                setOtpData(result.data);
                setIsSendEmailOtpSuccess(true);
                if (Object.keys(error).includes("email")) {
                    setError({
                        ...error,
                        "email": ""
                    });
                }
                toast.dismiss();
                toast.success(result.message, {
                    autoClose: 60000,
                    closeOnClick: false
                });
            })
            .catch((error) => {
                console.log("(Register.jsx) Error while sending OTP: ", error);
                toast.dismiss();
                toast.error(error.data.message);
            })
            .finally(() => {
                setIsSendEmailOtpButtonDisabled(false);
            });
    }

    const verifyEmailOtpButtonClick = () => {
        setIsVerifyOtpButtonDisabled(true);
        if (otpData && otpData.userId && otp) {
            matchOtp(otpData.userId, otp, "register")
                .then((result) => {
                    console.log("(Register.jsx) Match OTP Result: ", result);
                    if (result.success) {
                        setIsVerifyEmailOtpSuccess(true);
                        setIsRegisterButtonDisabled(false);
                        toast.dismiss();
                        toast.success(result.message);
                    }
                })
                .catch((error) => {
                    console.log("(Register.jsx) Error while matching OTP: ", error);
                    toast.dismiss();
                    toast.error(error.data.message);
                })
                .finally(() => {
                    setIsVerifyOtpButtonDisabled(false);
                });
        }
    }

    const addNewAccount = () => {
        const accountData = {
            ...profile,
            imageUrl: profile.imageUrl || (profile.firstName[0] + profile.middleName[0]),
            sideOfFamily: isJoglekarMaherVashin ? 'MaherVashin' : '',
            outsider: profile.lastName.toLowerCase() !== 'joglekar' && !isJoglekarMaherVashin
        }

        console.log("(Register.jsx) New Account to be Added: ", accountData);

        addAccount(accountData)
            .then((response) => {
                console.log("(Register.jsx) Account Added: ", response);
                if (response.success) {
                    console.log("(Register.jsx) Account Added Email: ", response.data.email);
                    toast.dismiss();
                    cookies.set('email', response.data.email);
                    setAccount(prevState => ({
                        ...prevState,
                        ...response.data
                    }));
                    setCookie(cookies.get('email'));
                }
            })
            .catch((error) => {
                console.log("(Register.jsx) Error While Adding New Account: ", error);
                toast.dismiss();
                toast.error(error.data.message);
            });
    }

    return (
        <>
            <div className="h-[100vh] w-full flex flex-row items-center justify-center">
                <div className={"flex-1 h-full"}>
                    <img
                        src="/kulvratant_landingPage/banner_amhi_joglekar 1920.jpg"
                        alt=""
                        className="h-full object-cover shadow-lg"
                    />
                </div>
                <div className="flex flex-1 flex-col items-center justify-center">
                    <h1 className="text-center leading-[1.25] mb-[1.5vh] text-[3vw] text-transparent font-SOHNE_EXTRA_BOLD bg-clip-text bg-yellow-400 bg-gradient-to-br from-yellow-400 to-red-500">
                        {t("REGISTER_PAGE.GREETING")}
                    </h1>

                    <div className="w-[30vw] flex flex-col gap-y-2 items-center">
                        {/* Full Name */}
                        <fieldset>
                            <div className="mb-[1.5vh] flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                    htmlFor="firstName"
                                >
                                    {t("REGISTER_PAGE.NAME.LABEL")}
                                </label>
                                <div className="flex justify-between gap-4">
                                    {/* First Name */}
                                    <div className="flex-1 flex flex-col gap-1">
                                        <input
                                            id="firstName"
                                            className={`w-full font-SOHNE_MEDIUM inline-flex 
                                                min-h-[5vh] max-h-[5vh] rounded-full px-[1vw] text-[1vw]
                                                leading-none outline-none border border-gray-300
                                                focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40]
                                                transition-all duration-300 ease-in-out`}
                                            name='firstName'
                                            placeholder={t("REGISTER_PAGE.NAME.PLACEHOLDER.FIRST_NAME")}
                                            value={profile.firstName}
                                            onChange={handleFormData}
                                            onBlur={translateToDevnagri}
                                        />
                                        {
                                            error['firstName'] &&
                                            <span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                                {error['firstName']}
                                            </span>
                                        }
                                    </div>

                                    {/* Middle Name */}
                                    <div className="flex-1 flex flex-col gap-1">
                                        <input
                                            id="middleName"
                                            className={`w-full font-SOHNE_MEDIUM inline-flex 
                                                min-h-[5vh] max-h-[5vh] rounded-full px-[1vw] text-[1vw]
                                                leading-none outline-none border border-gray-300
                                                focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40]
                                                transition-all duration-300 ease-in-out`}
                                            name='middleName'
                                            placeholder={t("REGISTER_PAGE.NAME.PLACEHOLDER.MIDDLE_NAME")}
                                            value={profile.middleName}
                                            onChange={handleFormData}
                                            onBlur={translateToDevnagri}
                                        />
                                        {
                                            error['middleName'] &&
                                            <span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                                {error['middleName']}
                                            </span>
                                        }
                                    </div>

                                    {/* Last Name */}
                                    <div className="flex-1 flex flex-col gap-1">
                                        <input
                                            className={`w-full font-SOHNE_MEDIUM inline-flex 
                                                min-h-[5vh] max-h-[5vh] rounded-full px-[1vw] text-[1vw]
                                                leading-none outline-none border border-gray-300
                                                focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40]
                                                transition-all duration-300 ease-in-out`}
                                            id="lastName"
                                            name='lastName'
                                            placeholder={t("REGISTER_PAGE.NAME.PLACEHOLDER.LAST_NAME")}
                                            value={profile.lastName}
                                            onChange={handleFormData}
                                            onBlur={translateToDevnagri}
                                        />
                                        {
                                            error['lastName'] &&
                                            <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                                {error['lastName']}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        {/* Gender | Marital Status */}
                        <div className="mb-[1.5vh] w-full flex justify-between gap-4">
                            {/* Gender */}
                            <div className="w-full flex-1 flex flex-col gap-2">
                                <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="gender">
                                    {t("REGISTER_PAGE.GENDER.LABEL")}
                                </label>

                                <div className="relative w-full flex flex-col">
                                    <button
                                        onBlur={() => {
                                            if (!isSelectingGender) {
                                                setIsGenderDropdownOpen(false);
                                            }
                                        }}
                                        className={`flex justify-between items-center gap-1 
                                            min-h-[5vh] max-h-[5vh] px-[1vw] rounded-full 
                                            border border-gray-300
                                            ${isGenderDropdownOpen && 'focus:ring-2 focus:ring-offset-1 focus:ring-[#5F0F40]'}
                                            transition-all duration-300 ease-in-out`}
                                        onClick={() => setIsGenderDropdownOpen(!isGenderDropdownOpen)}
                                    >
                                        <div className={"flex-1 flex flex-row items-center gap-2"}>
                                            <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                {
                                                    profile.gender === "Male"
                                                        ? t("REGISTER_PAGE.GENDER.MALE")
                                                        : t("REGISTER_PAGE.GENDER.FEMALE")
                                                }
                                            </p>
                                            <img
                                                className={"w-[1.5vw] aspect-auto"}
                                                src={profile.gender === "Male" ? "assets/male-gender-icon.png" : "assets/female-gender-icon.png"}
                                                alt={profile.gender === "Male" ? "Male" : "Female"}
                                            />
                                        </div>
                                        <ChevronDownIcon
                                            className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isGenderDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
                                            aria-hidden="true"
                                            stroke="#292D32"
                                            strokeWidth={"0.1vh"}
                                        />
                                    </button>

                                    {/* Gender Dropdown */}
                                    <div className={"relative mt-[0.5vh]"}>
                                        <Transition show={isGenderDropdownOpen}>
                                            <div
                                                className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                        origin-top-right rounded-md shadow-2xl 
                                                        border border-gray-700 overflow-y-scroll
                                                        data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                        data-[enter]:duration-100 data-[enter]:ease-out 
                                                        data-[leave]:duration-75 data-[leave]:ease-in`}
                                            >
                                                <button
                                                    className="w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center gap-1"
                                                    onClick={() => handleGenderSelect("Male")}
                                                    onMouseDown={() => setIsSelectingGender(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("REGISTER_PAGE.GENDER.MALE")}
                                                    </p>
                                                    <img
                                                        className={"w-[1.5vw] aspect-auto"}
                                                        src={"assets/male-gender-icon.png"}
                                                        alt={"Male"}
                                                    />
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center gap-1"
                                                    onClick={() => handleGenderSelect("Female")}
                                                    onMouseDown={() => setIsSelectingGender(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("REGISTER_PAGE.GENDER.FEMALE")}
                                                    </p>
                                                    <img
                                                        className={"w-[1.5vw] aspect-auto"}
                                                        src={"assets/female-gender-icon.png"}
                                                        alt={"Female"}
                                                    />
                                                </button>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>

                            {/* Marital Status */}
                            <div className={`w-full flex-1 flex flex-col gap-2`}>
                                <label
                                    className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                    htmlFor="maritalStatus"
                                >
                                    <img
                                        src={"assets/marriage-icon.png"}
                                        className={"w-[1.5vw] aspect-auto"}
                                        alt={"Marriage"}
                                        title={"Marriage"}
                                    />
                                    {t("REGISTER_PAGE.MARITAL_STATUS.LABEL")}
                                </label>
                                <div className="relative w-full flex flex-col">
                                    <button
                                        onBlur={() => {
                                            if (!isSelectingMaritalStatus) {
                                                setIsMaritalStatusDropdownOpen(false);
                                            }
                                        }}
                                        className={`flex justify-between items-center gap-1 
                                            min-h-[5vh] max-h-[5vh] px-[1vw] rounded-full 
                                            border border-gray-300
                                            ${isMaritalStatusDropdownOpen && 'focus:ring-2 focus:ring-offset-1 focus:ring-[#5F0F40]'}
                                            transition-all duration-300 ease-in-out`}
                                        onClick={() => setIsMaritalStatusDropdownOpen(!isMaritalStatusDropdownOpen)}
                                    >
                                        <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                            {maritalStatusLabel(profile.maritalStatus)}
                                        </p>
                                        <ChevronDownIcon
                                            className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isMaritalStatusDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
                                            aria-hidden="true"
                                            stroke="#292D32"
                                            strokeWidth={"0.1vh"}
                                        />
                                    </button>

                                    {/* Marital Status Dropdown */}
                                    <div className={"relative mt-[0.5vh]"}>
                                        <Transition show={isMaritalStatusDropdownOpen}>
                                            <div
                                                className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                    origin-top-right rounded-md shadow-2xl 
                                                    border border-gray-700 overflow-y-scroll
                                                    data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                    data-[enter]:duration-100 data-[enter]:ease-out 
                                                    data-[leave]:duration-75 data-[leave]:ease-in`}
                                            >
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
                                                    onClick={() => handleMaritalStatusSelect("Minor")}
                                                    onMouseDown={() => setIsSelectingMaritalStatus(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("REGISTER_PAGE.MARITAL_STATUS.MINOR")}
                                                    </p>
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
                                                    onClick={() => handleMaritalStatusSelect("Single")}
                                                    onMouseDown={() => setIsSelectingMaritalStatus(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("REGISTER_PAGE.MARITAL_STATUS.SINGLE")}
                                                    </p>
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
                                                    onClick={() => handleMaritalStatusSelect("Married")}
                                                    onMouseDown={() => setIsSelectingMaritalStatus(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("REGISTER_PAGE.MARITAL_STATUS.MARRIED")}
                                                    </p>
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
                                                    onClick={() => handleMaritalStatusSelect("Divorced")}
                                                    onMouseDown={() => setIsSelectingMaritalStatus(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {
                                                            profile.gender === "Male"
                                                                ? t("REGISTER_PAGE.MARITAL_STATUS.WIDOWER")
                                                                : t("REGISTER_PAGE.MARITAL_STATUS.WIDOWED")
                                                        }
                                                    </p>
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
                                                    onClick={() => handleMaritalStatusSelect("Widowed")}
                                                    onMouseDown={() => setIsSelectingMaritalStatus(true)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("REGISTER_PAGE.MARITAL_STATUS.DIVORCED")}
                                                    </p>
                                                </button>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Is MaherVashin */}
                        {
                            profile.lastName.toLowerCase() !== 'joglekar' && profile.gender === 'Female'
                            && (profile.maritalStatus === 'Married' || profile.maritalStatus === 'Divorced' || profile.maritalStatus === 'Widowed') && (
                                <fieldset className={"mb-[1.5vh] w-full flex flex-col gap-2"}>
                                    <label className="w-full flex flex-row items-center justify-center gap-2">
                                        <input
                                            type="checkbox"
                                            name='isJoglekarMaherVashin'
                                            checked={isJoglekarMaherVashin}
                                            onChange={() => setIsJoglekarMaheVashin(!isJoglekarMaherVashin)}
                                        />
                                        <p className="font-SOHNE_REGULAR text-[1vw]">
                                            {t("REGISTER_PAGE.IS_MAHER_VASHIN")}
                                        </p>
                                    </label>
                                </fieldset>
                            )
                        }

                        {/* Email */}
                        <div className={"mb-[1.5vh] w-full flex-1 flex flex-col gap-2"}>
                            <label
                                htmlFor="email"
                                className="block text-[1vw] font-SOHNE_REGULAR leading-6 text-gray-900"
                            >
                                {t("REGISTER_PAGE.EMAIL_INPUT.LABEL")}
                            </label>
                            <div className="flex gap-2 mt-[0.5vh]">
                                <input
                                    disabled={isVerifyEmailOtpSuccess}
                                    type="email"
                                    name="email"
                                    value={profile.email}
                                    onChange={handleFormData}
                                    id="email"
                                    className={`flex-1 font-SOHNE_LIGHT rounded-full 
                                        min-h-[5vh] max-h-[5vh] px-[1.25vw] shadow-sm
                                        text-[1vw] leading-tight text-gray-900 
                                        border border-gray-300 outline-none
                                        focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40] 
                                        transition-all duration-300 ease-in-out`}
                                    onBlur={validateEmailId}
                                />
                                <button
                                    disabled={isSendEmailOtpButtonDisabled || isVerifyEmailOtpSuccess}
                                    onClick={sendOtpOnEmailButtonClick}
                                    className={`${!isSendEmailOtpButtonDisabled && 'arrow-button'}
                                        inline-flex items-center bg-[#D26A53] shadow-xl
                                        min-h-[5vh] max-h-[5vh] pl-[1.25vw]
                                        text-[1vw] text-white leading-tight font-SOHNE_MEDIUM rounded-full
                                        hover:ring-[#5F0F40] hover:ring-offset-1 hover:ring-4 
                                        ${isVerifyEmailOtpSuccess
                                        ? 'pr-[1.25vw] disabled:cursor-default disabled:bg-green-800'
                                        : 'pr-[0.75vw] disabled:cursor-not-allowed disabled:bg-slate-400/50'}
                                        disabled:ring-0 disabled:ring-offset-0
                                        transition-all duration-300 ease-in-out`}
                                >
                                    {
                                        isVerifyEmailOtpSuccess
                                            ? t("REGISTER_PAGE.EMAIL_INPUT.OTP_VERIFIED")
                                            : t("REGISTER_PAGE.EMAIL_INPUT.SEND_OTP")
                                    }
                                    {
                                        !isVerifyEmailOtpSuccess && (
                                            <svg
                                                viewBox="0 0 6 6"
                                                fill="none"
                                                id={"arrow-icon"}
                                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3 1L5 3L3 5"
                                                    id={"arrow-icon-tip"}
                                                    stroke="white"
                                                    strokeLinecap="square"
                                                />
                                                <path
                                                    d="M1 3L4 3"
                                                    id={"arrow-icon-line"}
                                                    stroke="white"
                                                    strokeLinecap="square"
                                                />
                                            </svg>
                                        )
                                    }
                                </button>
                            </div>

                            {error['email'] && (
                                <p className="text-sm text-red-600" id="email-error">
                                    {error['email']}
                                </p>
                            )}
                        </div>

                        {/* Email OTP Input */}
                        {isSendEmailOtpSuccess && (!isVerifyEmailOtpSuccess) && (
                            <div className="mb-[3vh] w-full flex-1 flex flex-col gap-2">
                                <label
                                    htmlFor="email"
                                    className="font-SOHNE_MEDIUM block text-[1vw] leading-6 text-gray-900"
                                >
                                    {t("REGISTER_PAGE.EMAIL_INPUT.ENTER_OTP")}
                                </label>
                                <div className="flex gap-2 mt-[0.5vh]">
                                    <input
                                        type="text"
                                        name="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        id="otp"
                                        className={`flex-1 font-SOHNE_LIGHT rounded-full 
                                            min-h-[5vh] max-h-[5vh] px-[1.25vw] shadow-sm 
                                            text-[1vw] leading-tight text-gray-900
                                            border border-gray-300 outline-none 
                                            focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40]
                                            transition-all duration-300 ease-in-out`}
                                    />
                                    <button
                                        disabled={isVerifyOtpButtonDisabled}
                                        onClick={verifyEmailOtpButtonClick}
                                        className={`${isVerifyOtpButtonDisabled ? '' : 'arrow-button'} 
                                            inline-flex items-center rounded-full 
                                            min-h-[5vh] max-h-[5vh] bg-[#D26A53] pl-[1.25vw] pr-[0.75vw] 
                                            text-[1vw] text-white leading-tight font-SOHNE_MEDIUM
                                            hover:ring-[#5F0F40] hover:ring-offset-1 hover:ring-4 
                                            disabled:cursor-not-allowed disabled:bg-slate-400/50 
                                            disabled:ring-0 disabled:ring-offset-0
                                            transition-all duration-300 ease-in-out`}
                                    >
                                        {t("REGISTER_PAGE.EMAIL_INPUT.VERIFY_OTP")}
                                        <svg
                                            viewBox="0 0 6 6"
                                            fill="none"
                                            id={"arrow-icon"}
                                            className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3 1L5 3L3 5"
                                                id={"arrow-icon-tip"}
                                                stroke="white"
                                                strokeLinecap="square"
                                            />
                                            <path
                                                d="M1 3L4 3"
                                                id={"arrow-icon-line"}
                                                stroke="white"
                                                strokeLinecap="square"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Create Account */}
                        <button
                            disabled={isRegisterButtonDisabled}
                            onClick={addNewAccount}
                            className={`${!(isRegisterButtonDisabled) && 'arrow-button'} 
                                w-full min-h-[5vh] max-h-[5vh] rounded-full
                                flex-1 inline-flex items-center justify-center
                                shadow-xl bg-[#D26A53] hover:bg-[#D26A53]
                                hover:ring-[#5F0F40] hover:ring-offset-1 hover:ring-4
                                disabled:cursor-not-allowed disabled:bg-slate-400/50
                                disabled:ring-0 disabled:ring-offset-0
                                transition-all duration-300 ease-in-out`}
                        >
                            <p className={"font-SOHNE_MEDIUM text-[1vw] leading-tight text-white"}>
                                {t("REGISTER_PAGE.SUBMIT")}
                            </p>
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="white"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="white"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
