import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import carouselImg from "../../utils/carouselImg";
import { Link } from "react-router-dom";

const Carousel = () => {
	const settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		// speed: 3000,
		autoplaySpeed: 2000,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<div className="m-10 p-10">
			<Slider {...settings}>
				{carouselImg.map((i) => {
					return (
						<Link to={i.url}>
						<div className="" key={i.id}>
							<img
								src={i.img}
								alt="imgs"
								className="w-[20vw] aspect-square object-contain"
							/>
						</div>
						</Link>
					);
				})}
			</Slider>
		</div>
	);
};

export default Carousel;
