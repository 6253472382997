import React, { useState } from "react";
import youtube from "../../utils/youtube";
import { Modal } from "../Modal";
import { IoMdClose } from "react-icons/io";

const YoutubeCard = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({ title: "", url: "" });

  const openModal = (video) => {
    if (video.url) {
      setSelectedVideo(video);
      setModalOpen(true);
    } else {
      alert("Video not available");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedVideo({ title: "", url: "" });
  };

  return (
    <>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 m-8 p-4">
        {youtube.map((link) => (
          <div key={link.id} className="my-4 px-4">
            <div
              className="bg-white shadow-2xl rounded-lg cursor-pointer"
              onClick={() => openModal(link)}
            >
              <img
                src={link.img}
                alt={link.title}
                className="rounded-lg object-contain w-full"
              />
            </div>
            <div className="text-center mt-4 font-bold text-xl">
              <h1>{link.title}</h1>
              <p className="text-red-600 text-md mt-2">{link.content}</p>
            </div>
          </div>
        ))}
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="relative w-[100%] h-[100%] p-4 bg-white rounded-lg">
          <button
            className="absolute top-2 right-2 text-gray-700 rounded-full p-2 shadow-md"
            onClick={closeModal}
          >
            <IoMdClose />
          </button>
          <h2 className="text-center text-2xl font-bold text-red-600 mb-4">
            {selectedVideo.title}
          </h2>
          {selectedVideo.url ? (
            <iframe
              width="100%"
              height="350"
              src={selectedVideo.url}
              title="YouTube video"
              frameBorder="0"
              className="rounded-lg"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <div className="text-center text-lg font-semibold">
              Video not available
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default YoutubeCard;
