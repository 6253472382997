import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import ReactFlow, {
    Background,
    ConnectionMode,
    Controls,
    getConnectedEdges,
    MiniMap,
    Panel,
    SelectionMode,
    useEdgesState,
    useNodesState,
    useReactFlow,
    useViewport,
} from 'reactflow';
import 'reactflow/dist/style.css';

import {AccountContext} from '../context/AccountProvider';

import {NodeContext} from '../context/NodeProvider';
import MemberNode from './Nodes/MemberNode';
import RelationEdge from './Edges/RelationEdge';
import {getFamilyTreeById, getMemberById, updateMember, updateMultipleMembers} from '../service/memberApi';

import {t} from "i18next";

import {AddNodeForm} from "../components/Nodes/AddNodeform";
import {AddEdgeForm} from "./Edges/AddEdgeForm";
import {toast} from "react-toastify";
import {Modal} from "../components/Modal";
import {getUsers, getUsersInFamilyTree} from "../service/accountApi";
import {Transition, TransitionChild} from "@headlessui/react";
import {JoglekarKulMap} from "../components/JoglekarKulMap";
import Cookies from "universal-cookie";
import {FamilyTreeListModal} from "../components/FamilyTreeListModal";
import ELK from 'elkjs/lib/elk.bundled.js';

const elk = new ELK();

// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
//
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html
const elkOptions = {
    'elk.algorithm': 'layered',
    'org.eclipse.elk.spacing': 'compact',
    'org.eclipse.elk.layered.crossingMinimization.strategy': 'LAYER_SWEEP',
    'org.eclipse.elk.layered.nodePlacement.strategy': 'BRANDES_KOEPF',
    'elk.layered.spacing.nodeNodeBetweenLayers': '250',
    'elk.spacing.nodeNode': '150',
    'org.eclipse.elk.portConstraints': 'FIXED_ORDER',
    "elk.edgeRouting": "ORTHOGONAL"
};


const nodeTypes = {Member: MemberNode};
const edgeTypes = {relation: RelationEdge};

export const FamilyTreeBuilder = (props) => {
    const instance = useReactFlow();
    const {x, y, zoom} = useViewport();

    const {cookie, account} = useContext(AccountContext);
    const {setSelectedNode} = useContext(NodeContext);

    const cookies = new Cookies();
    const locale = cookies.get("locale");

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [newlyConnectedEdge, setNewlyConnectedEdge] = useState({});
    const [rfInstance, setRfInstance] = useState(null);

    const [showAddNodeForm, setShowAddNodeForm] = useState(false);
    const [showEditNodeForm, setShowEditNodeForm] = useState(false);
    const [showAddEdgeForm, setShowAddEdgeForm] = useState(false);

    const [newlyAddedNodeId, setNewlyAddedNodeId] = useState('');

    const [showSearchModal, setShowSearchModal] = useState(false);

    const searchInputRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const [showMapModal, setShowMapModal] = useState(false);

    const [showFamilyTreeListModal, setShowFamilyTreeListModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({
        label: "",
        value: ""
    });

    const getLayoutedElements = async (nodes, edges, options = {}) => {
        const graph = {
            id: 'root',
            children: nodes.map((node) => ({
                id: node.id,
                width: instance.getNode(node.id).width, // Width of the node
                height: instance.getNode(node.id).height, // Height of the node
                fixed: true
            })),
            edges: edges.map((edge) => ({
                id: edge.id,
                sources: [edge.source],
                targets: [edge.target],
            })),
        };

        // const graph = {
        //     id: 'root',
        //     layoutOptions: options,
        //     children: nodes,
        //     //     .map((node) => ({
        //     //     ...node,
        //     //     // Adjust the target and source handle positions based on the layout
        //     //     // direction.
        //     //     // targetPosition: isHorizontal ? 'left' : 'top',
        //     //     // sourcePosition: isHorizontal ? 'right' : 'bottom',
        //     //     targetPosition: 'top',
        //     //     sourcePosition: 'bottom',
        //     //
        //     //     // Hardcode a width and height for elk to use when layouting.
        //     //     width: 150,
        //     //     height: 50,
        //     // })),
        //     edges: edges,
        // };

        try {
            const layoutedGraph = await elk.layout(graph, {layoutOptions: options});
            console.log("Layouted Graph in getLayoutedElements: ", layoutedGraph);
            return layoutedGraph;
        } catch (error) {
            console.log("Error while applying layout: ", error);
        }
        // return elk
        //     .layout(graph, {layoutOptions: options})
        //     .then((layoutedGraph) => ({
        //         nodes: layoutedGraph.children.map((node) => ({
        //             ...node,
        //             // React Flow expects a position property on the node instead of `x`
        //             // and `y` fields.
        //             position: {x: node.x, y: node.y},
        //         })),
        //
        //         edges: layoutedGraph.edges,
        //     }))
        //     .catch(console.error);
    };

    const onLayout = useCallback(async () => {
        console.log("Inside onLayout!");
        // const opts = {'elk.direction': "RIGHT", ...elkOptions};
        // const ns = nodes;
        // const es = edges;
        //
        // getLayoutedElements(ns, es, opts).then(
        //     ({nodes: layoutedNodes, edges: layoutedEdges}) => {
        //         setNodes(layoutedNodes);
        //         setEdges(layoutedEdges);
        //
        //         window.requestAnimationFrame(() => instance.fitView());
        //     },
        // );
        const layoutedGraph = await getLayoutedElements(nodes, edges, elkOptions);

        const updatedNodes = nodes.map((node) => {
            const layoutNode = layoutedGraph.children.find((n) => n.id === node.id);
            return {
                ...node,
                position: {x: layoutNode.x, y: layoutNode.y},
                positionAbsolute: {x: layoutNode.x, y: layoutNode.y},
            };
        });

        instance.setNodes(updatedNodes);
        // instance.setEdges(layoutedGraph.edges);
    }, [nodes, edges]);

    // Calculate the initial layout on mount.
    // useLayoutEffect(() => {
    //     console.log("Inside Use Layout Effect!");
    //     onLayout();
    // }, []);

    const restoreFlowFromDB = async () => {
        const familyTree = await getFamilyTreeById(account.familyTreeId);
        console.log('(Screen) Family Tree: ', familyTree);

        // familyTree.createdNodes.map((node) => {
        //     node.data.showEditNodeForm = showEditNodeForm;
        //     node.data.setShowEditNodeForm = setShowEditNodeForm;
        //     // node.data.deleteNode = deleteMemberNode;
        // });

        familyTree.memberNodes.map((node) => {
            node.data.showEditNodeForm = showEditNodeForm;
            node.data.setShowEditNodeForm = setShowEditNodeForm;
            // node.data.deleteNode = deleteMemberNode;
        });

        familyTree.memberRelations.map((edge) => {
            edge.style = {stroke: 'lightgray', strokeWidth: 2};
        });

        console.log("(Screen) Family Tree Nodes: ", familyTree.memberNodes);
        console.log("(Screen) Family Tree Relations: ", familyTree.memberRelations);

        setNodes([...familyTree.memberNodes]);
        setEdges(familyTree.memberRelations || []);
        instance.setViewport({x: 0, y: 0, zoom: 1});
    };

    useEffect(() => {
        instance.setViewport({x: x, y: y, zoom: zoom});
        restoreFlowFromDB();
    }, []);

    const getViewportCenter = () => {
        const {width, height} = document.body.getBoundingClientRect(); // Or use container dimensions

        console.log("X: ", x);
        console.log("Y: ", y);

        // Calculate the center in pixels
        const centerX = (width / 2 - x) / zoom;
        const centerY = (height / 2 - y) / zoom;

        return {centerX, centerY};
    };

    // On Edge Connect
    const onConnect = useCallback(async (params) => {
        console.log("Relationship Connect Params: ", params);
        const sourceData = await getMemberById(params.source);
        const targetData = await getMemberById(params.target);
        setNewlyConnectedEdge({params: params, sourceData: sourceData, targetData: targetData});
        setShowAddEdgeForm(true);
    }, [setEdges]);

    const onNodeClick = (event, node) => {
        const connectedEdges = getConnectedEdges([node], edges);
        console.log("Connected Edges: ", connectedEdges);
        console.log('Clicked Node: ', node);
        console.log('Clicked Node Event: ', event);
        setSelectedNode(node);
    };

    const onNodeDragStop = useCallback((event, node) => {
        event.preventDefault();
        const position = node.position;
        updateMember(node.id, {position: position}).then((response) => {
            console.log('response:', response);
        });
    }, [rfInstance]);

    const onMultipleNodeSelectionDragStop = useCallback((event, nodes) => {
        event.preventDefault();
        const updatedMembers = nodes.map((node) => {
            return {
                id: node.id,
                position: node.position
            };
        })

        updateMultipleMembers(updatedMembers)
            .then((response) => {
                console.log('Update multiple members response: ', response);

                if (!response.success) {
                    toast.dismiss();
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                console.log("Update multiple members error: ", error);
                toast.dismiss();
                toast.error(error.message);
            });
    }, [rfInstance]);

    useEffect(() => {
        const newNode = nodes.find((node) => node.id === newlyAddedNodeId);

        if (newlyAddedNodeId !== "" && newNode && newNode.height && newNode.width) {
            console.log("(Changed) Nodes Data: ", nodes);
            console.log("Newly Added Node Data: ", instance.getNode(newlyAddedNodeId));

            const newlyAddedNodeData = instance.getNode(newlyAddedNodeId);

            setSelectedNode(newlyAddedNodeData);
            instance.setCenter(
                getViewportCenter().centerX + (newlyAddedNodeData.width / 2),
                getViewportCenter().centerY + (newlyAddedNodeData.height / 2),
                {
                    zoom: 1.5,
                    duration: 500
                }
            );
            setNewlyAddedNodeId("");
        }
    }, [nodes]);

    useEffect(() => {
        if (searchQuery === "") {
            setSearchResults([]);
        } else {
            console.log("Cookie: ", cookie);
            console.log("Account: ", account);
            if (!cookie || account?.outsider) {
                getUsers(searchQuery)
                    .then((response) => {
                        if (response.success) {
                            setSearchResults(response.data);
                        }
                    })
                    .catch((error) => {
                        console.log("Get Users Error: ", error);
                    });
            } else {
                getUsersInFamilyTree(account.familyTreeId, searchQuery)
                    .then((response) => {
                        if (response.success) {
                            setSearchResults(response.data);
                        }
                    })
                    .catch((error) => {
                        console.log("Get Users Error: ", error);
                    });
            }
        }
    }, [searchQuery]);

    const handleClearButtonClick = () => {
        setSearchQuery("");
        setSearchResults([]);
        searchInputRef.current?.focus();
    };

    const handleNodeClick = (nodeId) => {
        if (cookie && !account.outsider) {
            console.log("Instance: ", instance);
            console.log("Node ID: ", nodeId);
            console.log("Node Data: ", instance.getNode(nodeId));
            const nodeClickedData = instance.getNode(nodeId);

            setShowSearchModal(false);
            setSelectedNode(nodeClickedData);
            instance.setCenter(
                nodeClickedData.position.x + (nodeClickedData.width / 2),
                nodeClickedData.position.y + (nodeClickedData.height / 2),
                {
                    zoom: 1.5,
                    duration: 1000
                }
            );
        }
    }

    useEffect(() => {
        console.log("showSearchModal: ", showSearchModal);
        if (showSearchModal) {
            const timeout = setTimeout(() => {
                console.log("Search Input Ref: ", searchInputRef);
                searchInputRef.current?.focus();
            }, 0);

            return () => clearTimeout(timeout); // Cleanup timeout
        } else {
            const timeout = setTimeout(() => {
                console.log("Clearing Search Query and Results!");
                setSearchQuery("");
                setSearchResults([]);
            }, 250);

            return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [showSearchModal]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if Ctrl + F (or Command + F on Mac) is pressed
            if ((event.ctrlKey || event.metaKey) && event.key === "f") {
                if (!showSearchModal) {
                    event.preventDefault();
                    console.log("Open search modal triggered!");
                    setShowSearchModal(true);
                }
            }

            // Check if Esc key is pressed
            if (event.key === "Escape") {
                console.log("Escape key pressed!");
                if (showSearchModal) {
                    event.preventDefault();
                    console.log("Close search modal triggered!");
                    setShowSearchModal(false);
                }
            }
        };

        // Add the event listener
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [showSearchModal]);

    useEffect(() => {
        if (!showAddEdgeForm) {
            const timeout = setTimeout(() => {
                setNewlyConnectedEdge({});
            }, 100);

            return () => clearTimeout(timeout);
        }
    }, [showAddEdgeForm]);

    return (
        <>
            <AddNodeForm
                showAddNodeForm={showAddNodeForm}
                setShowAddNodeForm={setShowAddNodeForm}
                restoreFlowFromDB={restoreFlowFromDB}
                setNewlyAddedNodeId={setNewlyAddedNodeId}
            />

            <AddEdgeForm
                showAddEdgeForm={showAddEdgeForm}
                setShowAddEdgeForm={setShowAddEdgeForm}
                currentEdge={newlyConnectedEdge}
                edges={edges}
            />

            <div style={{width: '100vw', height: '100vh'}}>
                <ReactFlow
                    minZoom={0.01}
                    onlyRenderVisibleElements={true}
                    connectionMode={ConnectionMode.Strict}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onNodeClick={onNodeClick}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    selectNodesOnDrag={true}
                    onInit={setRfInstance}
                    onNodeDragStop={onNodeDragStop}
                    selectionOnDrag={true}
                    selectionMode={SelectionMode.Partial}
                    onSelectionDragStop={onMultipleNodeSelectionDragStop}
                    fitView="auto"
                    onPaneClick={() => {
                        console.log("Background Clicked!");
                        setSelectedNode(null);
                    }}
                    className={"bg-[#F7F7F7]"}
                    isValidConnection={(connection) => {
                        return connection.source !== connection.target;
                    }}
                >
                    {/* Family Tree Name | Search Button */}
                    <Panel position="vertical-center" className={"top-[5vw]"}>
                        <div className={"flex flex-row items-center justify-center gap-x-2"}>
                            {/* Family Tree Name */}
                            <div
                                className="flex items-center justify-center flex-col
                                bg-white px-[1vw] py-[1vh] rounded-xl shadow-lg
                                text-[1vw] font-SOHNE_MONO_BOLD text-gray-900
                                border border-gray-300"
                            >
                                <h2>{locale === "en" ? account.familyTreeName : account.familyTreeNameDevanagariScript ? account.familyTreeNameDevanagariScript : account.familyTreeName}</h2>
                            </div>

                            {/* Search Bar */}
                            <div
                                onClick={() => setShowSearchModal(true)}
                                className={`flex flex-row items-center justify-between 
                                    gap-x-2 xl:gap-x-4 cursor-pointer 
                                    bg-white p-[1vh] pr-[2vh] rounded-xl 
                                    shadow-lg hover:shadow-xl  
                                    border border-gray-300
                                    transition-all duration-500 ease`}
                            >
                                <div
                                    className={`h-[1.5vw] w-[1.5vw] rounded-lg 
                                        flex items-center justify-center`}
                                >
                                    <img
                                        className="h-full w-full"
                                        src="/assets/search-icon-dark.svg"
                                        alt="Search Icon"
                                    />
                                </div>
                                <p
                                    style={{
                                        userSelect: "none",
                                    }}
                                    className={"font-SOHNE_MONO_BOLD text-gray-900 leading-tight text-[1vw]"}
                                >
                                    {t("HEADER.SEARCH")}
                                </p>
                            </div>
                        </div>
                    </Panel>

                    {/* Add Member Button | Map Button */}
                    <Panel position="bottom-center">
                        <div className="flex flex-row items-center justify-center gap-x-2">
                            {/* Add Member Button */}
                            <button
                                className="flex flex-row items-center justify-center rounded-xl
                                        m-2 px-4 py-2 border-2 border-white gap-x-2
                                        text-base text-white font-SOHNE_MEDIUM shadow-xl shadow-blue-600/75
                                        transition-transform duration-200 ease-in-out hover:scale-110"
                                style={{
                                    backgroundImage: "url('/assets/map-button-bg-1.png')",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "repeat",
                                }}
                                onClick={async () => {
                                    toast.dismiss();
                                    // await onLayout();
                                    setShowAddNodeForm(true);
                                }}
                            >
                                <img
                                    className={"w-[1.25rem] aspect-auto"}
                                    src={"/assets/add-member-icon.svg"}
                                    alt={"Add Member"}
                                />
                                {t("SCREEN.MEMBER")}
                            </button>

                            {/* Map Button */}
                            <button
                                className="flex flex-row items-center justify-center rounded-xl
                                        m-2 px-4 py-2 gap-x-2 border-2 border-white
                                        text-base text-white font-SOHNE_MEDIUM shadow-xl shadow-blue-600/75
                                        transition-transform duration-200 ease-in-out hover:scale-110"
                                style={{
                                    backgroundImage: "url('/assets/map-button-bg-2.png')",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                }}
                                onClick={() => {
                                    toast.dismiss();
                                    setShowMapModal(true);
                                }}
                            >
                                <img
                                    className={"w-[1.25rem] aspect-auto"}
                                    src={"/assets/map-button-icon.png"}
                                    alt={"Map"}
                                />
                                {t("SCREEN.MAP")}
                            </button>
                        </div>
                    </Panel>

                    <Controls/>
                    <MiniMap
                        zoomable={true}
                        pannable={true}
                        maskColor={"rgba(41, 45, 50, 0.31)"}
                        nodeColor={"#787878"}
                        nodeStrokeColor={"#FFFFFF"}
                        nodeStrokeWidth={1}
                    />
                    <Background variant='dots' gap={16} size={1}/>
                </ReactFlow>

                {/* Search Modal */}
                <Modal zIndex={"z-[100]"} isOpen={showSearchModal}>
                    <div
                        className={`flex flex-col gap-y-3 max-h-[65vh] w-full bg-white rounded-xl
                            ${searchResults.length === 0 && 'pb-[3vh]'}
                        `}
                    >
                        {/* Search Modal Header */}
                        <div className={"mt-[2vh] mx-[3vh] flex flex-row items-center justify-between"}>
                            {/* Modal Title */}
                            <h2 className="text-[1.5vw] font-SOHNE_BOLD text-gray-900">
                                {t("SEARCH_MODAL.MEMBER_TITLE")}
                            </h2>

                            {/* Close Button */}
                            <button
                                onClick={() => setShowSearchModal(false)}
                                className="w-[1.75vw] h-[1.75vw]
                                        text-sm text-gray-400 hover:text-gray-900
                                        flex items-center justify-center bg-[#EAEAEA] rounded-md shadow-lg
                                        hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                                        transition duration-300 ease-out"
                            >
                                <img
                                    className={"w-[75%] h-[75%]"}
                                    src={"/assets/close-icon.png"}
                                    alt={"Close"}
                                    title={"Close"}
                                />
                            </button>
                        </div>

                        {/* Search Bar */}
                        <div
                            className={`h-[6vh] flex items-center justify-between gap-x-2
                                rounded-full p-[1vh] mx-[3vh] bg-white border border-[#EAEAEA]`}
                        >
                            <div
                                className={`flex items-center justify-center rounded-full 
                                    h-[2vw] w-[2vw] p-[0.5vh] `}
                            >
                                <img
                                    className="h-full w-full"
                                    src="/assets/search-icon-dark.svg"
                                    alt="Search"
                                />
                            </div>

                            <input
                                ref={searchInputRef}
                                id="searchQueryInput"
                                className="w-full flex-1 h-full font-SOHNE_MEDIUM text-[1vw] leading-none outline-none"
                                name="Search Query"
                                placeholder={t("SEARCH_MODAL.SEARCH_PLACEHOLDER")}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />

                            {/* Clear Button */}
                            {searchQuery && (
                                <button
                                    onClick={handleClearButtonClick}
                                    className={`flex items-center justify-center rounded-full 
                                        h-[1.75vw] w-[1.75vw] p-[0.5vh] 
                                        hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                                        transition duration-300 ease-out`}
                                >
                                    <img
                                        className="h-full w-full"
                                        src="/assets/close-icon.png"
                                        alt="Clear Search"
                                    />
                                </button>
                            )}
                        </div>

                        {/* Search Results */}
                        <div
                            className={`flex flex-1 flex-wrap justify-between 
                                gap-2 overflow-y-scroll mr-[3vh] pl-[3vh] pr-0.5
                                transition duration-500 ease-in-out 
                                ${searchResults.length !== 0 ? 'pb-[3vh] opacity-100 translate-y-0' : 'opacity-0 -translate-y-5'}
                            `}
                        >
                            {
                                searchResults.length !== 0 && (
                                    searchResults.map((data, index) => (
                                        <button
                                            className={`${(!cookie || account?.outsider) ? 'cursor-default' : 'cursor-pointer'}
                                            min-w-[49%] max-w-[49%] flex-1 flex items-center gap-x-2 rounded-xl 
                                            hover:shadow-lg px-[1vh] py-[1vh] border-2 border-[#EAEAEA] bg-white
                                            transition duration-150 ease-in-out`}
                                            key={index}
                                            onClick={() => handleNodeClick(data.nodeId)}
                                        >
                                            {/* Profile Picture */}
                                            {data.imageUrl.length <= 2 ? (
                                                <div
                                                    className={`z-10 w-[8vh] h-[8vh] overflow-hidden 
                                                                flex items-center justify-center rounded-lg 
                                                                shadow-xl border-2 border-[#F5F5F5] ${data.imageUrlBg}`}
                                                >
                                                    <p
                                                        className={
                                                            "text-[1vw] text-white font-SOHNE_MONO_BOLD"
                                                        }
                                                    >
                                                        {data.imageUrl.toUpperCase()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div
                                                    className="z-10 w-[8vh] h-[8vh] overflow-hidden flex items-center justify-center rounded-lg bg-slate-500 border-2 border-white">
                                                    <img
                                                        src={
                                                            !(data.imageUrl === "")
                                                                ? data.imageUrl
                                                                : "/assets/default-pfp.png"
                                                        }
                                                        referrerPolicy={"no-referrer"}
                                                        alt="Member Profile Picture"
                                                    />
                                                </div>
                                            )}

                                            {/* Firstname | Middlename | Family Tree Name */}
                                            <div className="h-full flex-1 flex flex-col items-start justify-between">
                                                <p className="text-[1.75vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                                                    {data.firstName + " " + data.middleName}
                                                </p>
                                                <p className="text-[1.5vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                                                    {data.firstNameDevanagariScript +
                                                        " " +
                                                        data.middleNameDevanagariScript}
                                                </p>
                                                <div className="py-[0.25vh] px-[0.5vw] bg-[#F3BC50] rounded-full">
                                                    <p className="text-[1.25vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                                                        {data.familyTreeName}
                                                    </p>
                                                </div>
                                            </div>

                                            {/*/!* Edit | Delete Button *!/*/}
                                            {/*{*/}
                                            {/*    cookie !== undefined && data.familyTreeAdminId === account._id && (*/}
                                            {/*        <div className={"flex flex-col items-center justify-between h-full"}>*/}
                                            {/*            <button*/}
                                            {/*                className={`flex items-center justify-center rounded-md */}
                                            {/*            h-[1.75vw] w-[1.75vw] p-[0.5vh] bg-[#F5F5F5]*/}
                                            {/*            hover:shadow-lg transition-all duration-300 ease-in-out`}*/}
                                            {/*            >*/}
                                            {/*                <img*/}
                                            {/*                    className="h-full w-full"*/}
                                            {/*                    src="/assets/edit-profile-icon.png"*/}
                                            {/*                    alt="Edit"*/}
                                            {/*                />*/}
                                            {/*            </button>*/}
                                            {/*            <button*/}
                                            {/*                className={`flex items-center justify-center rounded-md */}
                                            {/*            h-[1.75vw] w-[1.75vw] p-[0.5vh] bg-[#F5F5F5]*/}
                                            {/*            hover:shadow-lg transition-all duration-300 ease-in-out`}*/}
                                            {/*            >*/}
                                            {/*                <img*/}
                                            {/*                    className="h-full w-full"*/}
                                            {/*                    src="/assets/delete-icon.png"*/}
                                            {/*                    alt="Edit"*/}
                                            {/*                />*/}
                                            {/*            </button>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                        </button>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </Modal>

                {/* Map Modal */}
                <Transition show={showMapModal}>
                    <div
                        className={`min-h-[100vh] max-h-[100vh] w-screen flex items-center justify-center 
                            fixed inset-0 z-[100]
                            overflow-y-scroll no-scrollbar bg-black/75 backdrop-blur-sm
                            data-[closed]:opacity-0
                            data-[enter]:duration-75 data-[enter]:data-[closed]:ease-out
                            data-[leave]:duration-300 data-[leave]:data-[closed]:ease-out`}
                    >
                        <TransitionChild>
                            <div
                                className="h-[calc(100vh-2vw)] w-[98vw] bg-white
                                transform rounded-xl flex items-center justify-center
                                data-[enter]:duration-100 data-[enter]:data-[closed]:scale-75
                                data-[leave]:duration-300 data-[leave]:data-[closed]:scale-95"
                            >
                                <div className={"rounded-xl h-full w-full flex flex-col"}>
                                    {/* Map Modal Header */}
                                    <div
                                        className="flex flex-row items-center justify-between
                                            py-[2vh] px-[3vh] bg-white rounded-t-xl"
                                    >
                                        {/* Modal Title */}
                                        <h2 className="text-[1.5vw] font-SOHNE_BOLD text-gray-900">
                                            {t("MAP_MODAL.TITLE")}
                                        </h2>

                                        {/* Close Button */}
                                        <button
                                            onClick={() => setShowMapModal(false)}
                                            className="w-[1.75vw] h-[1.75vw]
                                                text-sm text-gray-400 hover:text-gray-900
                                                flex items-center justify-center bg-[#EAEAEA] rounded-md shadow-lg
                                                hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                                                transition duration-300 ease-out"
                                        >
                                            <img
                                                className={"w-[75%] h-[75%]"}
                                                src={"/assets/close-icon.png"}
                                                alt={"Close"}
                                                title={"Close"}
                                            />
                                        </button>
                                    </div>

                                    {/* JoglekarKul Map */}
                                    <div className={"relative w-[100%] h-full flex-1 rounded-b-xl"}>
                                        <JoglekarKulMap
                                            isModal={true}
                                            setShowFamilyTreeListModal={setShowFamilyTreeListModal}
                                            setSelectedLocation={setSelectedLocation}
                                        />
                                    </div>
                                </div>
                            </div>
                        </TransitionChild>
                    </div>
                </Transition>

                {/* Family Tree List Modal */}
                <FamilyTreeListModal
                    showFamilyTreeListModal={showFamilyTreeListModal}
                    setShowFamilyTreeListModal={setShowFamilyTreeListModal}
                    selectedLocation={selectedLocation}
                />
            </div>
        </>
    );
}
