import React, {useContext, useEffect} from "react";
import {AccountContext} from "./context/AccountProvider";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {LandingPageScreen} from "./pages/LandingPageScreen";
import {Login} from "./pages/Login";
import {Information} from "./pages/Information";
import {PersonalProfile} from "./pages/PersonalProfile";
import {PublicProfile} from "./pages/PublicProfile";
import {Kulavruksh} from "./pages/Kulavruksh";
import {Register} from "./pages/Register";
import {Chapter1} from "./pages/Chapter1";
import {Chapter2} from "./pages/Chapter2";
import {Chapter3} from "./pages/Chapter3";
import {Chapter4} from "./pages/Chapter4";
import {Chapter5} from "./pages/Chapter5";
import {Chapter6} from "./pages/Chapter6";
import {Chapter7} from "./pages/Chapter7";
import {Chapter8} from "./pages/Chapter8";
import {Chapter9} from "./pages/Chapter9";
import {Chapter10} from "./pages/Chapter10";
import {Chapter11} from "./pages/Chapter11";
import {Chapter12} from "./pages/Chapter12";
import {Chapter13} from "./pages/Chapter13";
import {Chapter14} from "./pages/Chapter14";
import {Chapter15} from "./pages/Chapter15";
import {Chapter16} from "./pages/Chapter16";
import {Chapter17} from "./pages/Chapter17";
import {Chapter18} from "./pages/Chapter18";
import {Chapter19} from "./pages/Chapter19";
import {Search} from "./components/Search";
import {YellowPage} from "./pages/YellowPage";
import {PhotoGallary} from "./pages/PhotoGallary";
import {BestWishes} from "./pages/BestWishes";
import {RemembranceDay} from "./pages/RemembranceDay";
import {Pride} from "./pages/Pride";
import {Religious} from "./pages/Religious";
import {Memories} from "./pages/Memories";
import {NewEvent} from "./pages/NewEvent";
import {HelpEachOther} from "./pages/HelpEachOther";
import {HappyToShare} from "./pages/HappyToShare";
import {AnurupJodidar} from "./pages/AnurupJodidar";
import {Membership} from "./pages/Membership";
import {Chapter21} from "./pages/Chapter21";
import {Contact} from "./pages/Contact";
import {Chapter20} from "./pages/Chapter20";
import {YogeshwariDeviAmbajogai} from "./pages/YogeshwariDeviAmbajogai";
import {Arti} from "./pages/Arti";
import {Mantropchar} from "./pages/Mantropchar";
import {Kulchar} from "./pages/Kulchar";
import {Vyadeshwar} from "./pages/Vyadeshwar";
import {KulvrutantSamiti} from "./pages/KulvrtantSamiti";


export default function App() {
    const {cookie, account} = useContext(AccountContext);

    useEffect(() => {
        console.log("(App) Cookie in useEffect: ", cookie);
    }, []);

    if (cookie === undefined || cookie === null) {
        console.log("Cookie is null!");
        return (
            <BrowserRouter basename={"/"}>
                <Routes>
                    <Route path="*" element={<Navigate to="/home" replace/>}/>
                    <Route path="/home" element={<LandingPageScreen/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/info" element={<Information/>}/>
                    <Route
                        path="/kulavruksh"
                        element={<Kulavruksh/>}
                    />
                    <Route path="/yellowpage" element={<YellowPage/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path='/photo_gallary' element={<PhotoGallary/>}/>
                    <Route path="/search" element={<Search/>}/>
                    <Route path="/best_wishes" element={<BestWishes/>}/>
                    <Route path="/remembrance_day" element={<RemembranceDay/>}/>
                    <Route path='/pride' element={<Pride/>}/>
                    <Route path="/religious" element={<Religious/>}/>
                    <Route path="/memories" element={<Memories/>}/>
                    <Route path='/new-event' element={<NewEvent/>}/>
                    <Route path='/help_each_other' element={<HelpEachOther/>}/>
                    <Route path='/happy_to_share' element={<HappyToShare/>}/>
                    <Route path='/anuroop_jodidar' element={<AnurupJodidar/>}/>
                    <Route path='/membership' element={<Membership/>}/>
                    <Route path="/ambajogai" element={<YogeshwariDeviAmbajogai/>}/>
                    <Route path='/aarti' element={<Arti/>}/>
                    <Route path="/mantropchar" element={<Mantropchar/>}/>
                    <Route path='/kulchar' element={<Kulchar/>}/>
                    <Route path="/vyadeshwar" element={<Vyadeshwar/>}/>
                    <Route path="/kulvratant_samiti" element={<KulvrutantSamiti/>}/>

                    {/* Chapter routes */}
                    <Route path="/chapter1" element={<Chapter1/>}/>
                    <Route path="/chapter2" element={<Chapter2/>}/>
                    <Route path="/chapter3" element={<Chapter3/>}/>
                    <Route path="/chapter4" element={<Chapter4/>}/>
                    <Route path="/chapter5" element={<Chapter5/>}/>
                    <Route path="/chapter6" element={<Chapter6/>}/>
                    <Route path="/chapter7" element={<Chapter7/>}/>
                    <Route path="/chapter8" element={<Chapter8/>}/>
                    <Route path="/chapter9" element={<Chapter9/>}/>
                    <Route path="/chapter10" element={<Chapter10/>}/>
                    <Route path="/chapter11" element={<Chapter11/>}/>
                    <Route path="/chapter12" element={<Chapter12/>}/>
                    <Route path="/chapter13" element={<Chapter13/>}/>
                    <Route path="/chapter14" element={<Chapter14/>}/>
                    <Route path="/chapter15" element={<Chapter15/>}/>
                    <Route path="/chapter16" element={<Chapter16/>}/>
                    <Route path="/chapter17" element={<Chapter17/>}/>
                    <Route path="/chapter18" element={<Chapter18/>}/>
                    <Route path="/chapter19" element={<Chapter19/>}/>
                    <Route path="/chapter20" element={<Chapter20/>}/>
                    <Route path="/chapter21" element={<Chapter21/>}/>
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to="/home" replace/>}/>
                <Route path="/home" element={<LandingPageScreen/>}/>
                <Route
                    path="/kulavruksh"
                    element={<Kulavruksh/>}
                />
                <Route path="/photo_gallary" element={<PhotoGallary/>}/>
                <Route path="/info" element={<Information/>}/>
                <Route path="/personal-profile" element={<PersonalProfile/>}/>
                <Route path="/public-profile/:id" element={<PublicProfile/>}/>

                <Route path="/yellowpage" element={<YellowPage/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path='/photo_gallary' element={<PhotoGallary/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/best_wishes" element={<BestWishes/>}/>
                <Route path="/remembrance_day" element={<RemembranceDay/>}/>
                <Route path='/pride' element={<Pride/>}/>
                <Route path="/religious" element={<Religious/>}/>
                <Route path="/memories" element={<Memories/>}/>
                <Route path='/new-event' element={<NewEvent/>}/>
                <Route path='/help_each_other' element={<HelpEachOther/>}/>
                <Route path='/happy_to_share' element={<HappyToShare/>}/>
                <Route path='/anuroop_jodidar' element={<AnurupJodidar/>}/>
                <Route path='/membership' element={<Membership/>}/>
                <Route path="/ambajogai" element={<YogeshwariDeviAmbajogai/>}/>
                <Route path='/aarti' element={<Arti/>}/>
                <Route path="/mantropchar" element={<Mantropchar/>}/>
                <Route path='/kulchar' element={<Kulchar/>}/>
                <Route path="/vyadeshwar" element={<Vyadeshwar/>}/>
                <Route path="/kulvratant_samiti" element={<KulvrutantSamiti/>}/>

                {/* Chapter Routes */}
                <Route path="/chapter1" element={<Chapter1/>}/>
                <Route path="/chapter2" element={<Chapter2/>}/>
                <Route path="/chapter3" element={<Chapter3/>}/>
                <Route path="/chapter4" element={<Chapter4/>}/>
                <Route path="/chapter5" element={<Chapter5/>}/>
                <Route path="/chapter6" element={<Chapter6/>}/>
                <Route path="/chapter7" element={<Chapter7/>}/>
                <Route path="/chapter8" element={<Chapter8/>}/>
                <Route path="/chapter9" element={<Chapter9/>}/>
                <Route path="/chapter10" element={<Chapter10/>}/>
                <Route path="/chapter11" element={<Chapter11/>}/>
                <Route path="/chapter12" element={<Chapter12/>}/>
                <Route path="/chapter13" element={<Chapter13/>}/>
                <Route path="/chapter14" element={<Chapter14/>}/>
                <Route path="/chapter15" element={<Chapter15/>}/>
                <Route path="/chapter16" element={<Chapter16/>}/>
                <Route path="/chapter17" element={<Chapter17/>}/>
                <Route path="/chapter18" element={<Chapter18/>}/>
                <Route path="/chapter19" element={<Chapter19/>}/>
                <Route path="/chapter20" element={<Chapter20/>}/>
                <Route path="/chapter21" element={<Chapter21/>}/>
            </Routes>
        </BrowserRouter>
    );
}
