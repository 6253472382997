// chapterData.js
export const chapters = [
	{
		img: '/other_imgs/thumbnail-info-kokanasth-konkan-chitpawan.png',
		title: 'कोकणस्थ कोकण चित्पावन आणि जोगळेकर',
		link: "/chapter8"
	},
	{
		img: '/other_imgs/thumbnail-info-gauravshali-ithihas.png',
		title: 'चित्पावन : गक गौरवशाली इतिहास',
		link: "/chapter9"
	},
	{
	img: '/other_imgs/thumbnail-info-swabhav-gun.png',
	title: 'चित्पावनांचे स्वभावगुण व रंगरूप धार्मिक',
	link: "/chapter10"
},
{
	img: '/other_imgs/thumbnail-info-gotre-pravare-kulguru.png',
	title: 'गोत्रे, प्रवरे कुलगुरु / कुलऋषि / महर्षि',
	link: "/chapter13"
},
{
	img: '/other_imgs/thumbnail-info-atikulache-kulguru.png',
	title: 'अत्रिकुलाचे कुलगुरु - अत्रिऋषी',
	link: "/chapter11"
},
{

	img: '/other_imgs/thumbnail-info-guru-parshuram.png',
	title: 'कुलगुरु - श्री. परशुराम',
	link: "/chapter12"

}, 
{
	img: '/other_imgs/thumbnail-info-kul-gaon.png',
	title: 'जोगळेकर कुलांची मूळ गावे',
	link: "/chapter16"
},
{
	img: '/other_imgs/thumbnail-info-kul-naav.png',
	title: 'चित्पावनांची कुलनामे - उपनावे - आडनावे',
	link: "/chapter14"
},
{
	img: '/other_imgs/thumbnail-info-joglekar-kul-naav.png',
	title: '	जोगळेकर - कुलनाम',
	link: "/chapter15"
},
{
	img: '/other_imgs/thumbnail-info-divyangat-vyakti.png',
	title: 'दिवंगत उल्लेखनीय व्यक्तींचा परिचय',
	link: "/chapter19"
},

{
	img: '/other_imgs/thumbnail-info-kulnaav-dev.png',
	title: 'जोगळेकर कुटुंबीयांची महत्त्वाची, कुलस्वामी व कुलदेवतांची देवस्थाने',
	link: "/chapter17"
},
{
	img: '/other_imgs/thumbnail-info-kulasathi-kulachar.png',
	title: 'जोगळेकर कुलांसाठी कुळाचार',
	link: "/chapter18"
},
 {
	img: '/other_imgs/thumbnail-info-vaidik-vidhwan.png',
	title: ' जोगळेकर कुलातील वैदिक विद्वानांचा परिचय',
	link: "/chapter20"
}, {
	img: '/other_imgs/thumbnail-info-aarti-stotra.png',
	title: 'आरती, स्तोत्र ',
	link: "/chapter21"
}

];
