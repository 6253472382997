import {t} from "i18next";
import {Wrapper} from "@googlemaps/react-wrapper";
// import {Map} from "../components/Map";
import React, {useEffect, useRef, useState} from "react";

const Map = React.memo((props) => {
    const {markers, isModal, handleLocationClick} = props;
    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const markersRef = useRef([]);

    useEffect(() => {
        if (!mapInstance.current && mapRef.current) {
            // Initialize the map only once
            const mapOptions = {
                center: {lat: 18.085, lng: 74.68},
                minZoom: 0,
                zoom: 0,
                restriction: {
                    strictBounds: true,
                    // Ratnagiri Bounds
                    // latLngBounds: {
                    //     north: 17.803779954599658,
                    //     south: 16.95143556756816,
                    //     east: 73.8002989319785,
                    //     west: 73.01031669906395,
                    // },

                    // Maharashtra Bounds
                    latLngBounds: {
                        north: 22.02, // Northernmost latitude
                        south: 15.35, // Southernmost latitude
                        east: 80.66,  // Easternmost longitude
                        west: 68.7,   // Westernmost longitude
                    },

                    // India Bounds
                    // latLngBounds: {
                    //     north: 37.6,   // Northernmost latitude
                    //     south: 8.4,    // Southernmost latitude
                    //     east: 97.25,   // Easternmost longitude
                    //     west: 68.7,    // Westernmost longitude
                    // }
                },
                mapId: "b4ab27703f061d07",
                maxZoom: 13,
                disableDefaultUI: true,
            };

            mapInstance.current = new window.google.maps.Map(mapRef.current, mapOptions);
        }
    }, []);

    useEffect(() => {
        // Clear existing markers
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];

        // Add new markers
        markers.forEach((marker) => {
            const markerContainer = document.createElement("div");
            markerContainer.style.position = "relative";
            markerContainer.style.backgroundColor = "#FFFFFF";
            markerContainer.style.color = "black";
            markerContainer.style.paddingBlock = "1vh";
            markerContainer.style.paddingInline = "1vw";
            markerContainer.style.borderRadius = "8px";
            markerContainer.style.borderWidth = "1px";
            markerContainer.style.borderColor = "#4285F4";
            markerContainer.style.textAlign = "center";
            markerContainer.style.transform = "translate(-50%, -100%)";

            const markerText = document.createElement("div");
            markerText.innerText = t(`CONSTANTS.MAP_LOCATIONS.${marker.label}`);
            markerContainer.appendChild(markerText);

            const arrowDiv = document.createElement("div");
            arrowDiv.style.content = '""';
            arrowDiv.style.position = "absolute";
            arrowDiv.style.left = "50%";
            arrowDiv.style.top = "100%";
            arrowDiv.style.transform = "translate(-50%, 0)";
            arrowDiv.style.width = "0";
            arrowDiv.style.height = "0";
            arrowDiv.style.borderLeft = "10px solid transparent";
            arrowDiv.style.borderRight = "8px solid transparent";
            arrowDiv.style.borderTop = "8px solid #4285F4";
            markerContainer.appendChild(arrowDiv);

            const advancedMarker = new window.google.maps.marker.AdvancedMarkerElement({
                position: marker.position,
                map: mapInstance.current,
                title: marker.name,
                content: markerContainer,
            });

            advancedMarker.addListener("gmp-click", () => {
                handleLocationClick({label: marker.label, value: marker.name});
            });

            markersRef.current.push(advancedMarker);
        });
    }, [markers]);

    return <div ref={mapRef} className={`w-[100%] h-[100%] ${isModal && 'rounded-b-xl'}`}/>;
});

export const JoglekarKulMap = (props) => {
    const {isModal, setShowFamilyTreeListModal, setSelectedLocation} = props;

    const [markers, setMarkers] = useState([
        {
            id: 1,
            position: {
                lat: 17.395494999840615,
                lng: 73.20433247307791
            },
            name: "Adur",
            label: "ADUR"
        },
        // {
        //     id: 2,
        //     position: {
        //         lat: 22.72367036561104,
        //         lng: 75.85687169702136
        //     },
        //     name: "Indore",
        //     label: "INDORE"
        // },
        {
            id: 3,
            position: {
                lat: 17.092212265135032,
                lng: 73.32073678433994
            },
            name: "Kotawde",
            label: "KOTAWDE"
        },
        {
            id: 4,
            position: {
                lat: 17.09598617216336,
                lng: 73.29718116942423
            },
            name: "Dhokamale",
            label: "DHOKAMALE"
        },
        {
            id: 5,
            position: {
                lat: 17.33814136093412,
                lng: 73.23693738464397
            },
            name: "Narvan",
            label: "NARVAN"
        },
        {
            id: 6,
            position: {
                lat: 17.111228067984825,
                lng: 73.30530084957726
            },
            name: "Nevere",
            label: "NEVERE"
        },
        {
            id: 7,
            position: {
                lat: 17.681402284870494,
                lng: 74.01808862737106
            },
            name: "Satara",
            label: "SATARA"
        },
        {
            id: 8,
            position: {
                lat: 17.364572977222437,
                lng: 73.22693872338048
            },
            name: "Hedavi",
            label: "HEDAVI"
        },
        {
            id: 9,
            position: {
                lat: 17.44282229468008,
                lng: 73.20857022951766
            },
            name: "Palshet",
            label: "PALSHET"
        },
        // {
        //     id: 10,
        //     position: {
        //         lat: 22.08040936799145,
        //         lng: 82.14082930458613
        //     },
        //     name: "Bilaspur",
        //     label: "BILASPUR"
        // },
        {
            id: 11,
            position: {
                lat: 16.823061352537888,
                lng: 74.6508816862145
            },
            name: "Miraj",
            label: "MIRAJ"
        },
        {
            id: 12,
            position: {
                lat: 19.084124246409445,
                lng: 72.87567604958777
            },
            name: "Mumbai",
            label: "MUMBAI"
        },
        {
            id: 13,
            position: {
                lat: 18.4392028033194,
                lng: 73.11669840207726
            },
            name: "Roha",
            label: "ROHA"
        },
        {
            id: 14,
            position: {
                lat: 17.733779954599658,
                lng: 73.19116480905993
            },
            name: "Jalgaon",
            label: "JALGAON"
        },
        {
            id: 15,
            position: {
                lat: 17.48417274432721,
                lng: 73.19853913173824
            },
            name: "Guhaghar",
            label: "GUHAGHAR"
        },
        {
            id: 16,
            position: {
                lat: 17.527244928927328,
                lng: 73.39152567749244
            },
            name: "Gondhale",
            label: "GONDALE"
        },
        {
            id: 17,
            position: {
                lat: 17.382717354390984,
                lng: 73.23862183267788
            },
            name: "Sakhari Agar",
            label: "SAKHARI_AGAR"
        },
        {
            id: 18,
            position: {
                lat: 17.296222582830733,
                lng: 73.5040192937026
            },
            name: "Burambad",
            label: "BURAMBAD"
        },
        {
            id: 19,
            position: {
                lat: 17.295554783714774,
                lng: 73.49151912186494
            },
            name: "Makhjan",
            label: "MAKHJAN"
        },
        {
            id: 20,
            position: {
                lat: 17.212328642993473,
                lng: 73.25597083323215
            },
            name: "Waravade",
            label: "WARAVADE"
        },
        {
            id: 21,
            position: {
                lat: 17.060313446356243,
                lng: 73.29602356569164
            },
            name: "Basni",
            label: "BASNI"
        },
        {
            id: 22,
            position: {
                lat: 17.024561760378507,
                lng: 73.33209974598347
            },
            name: "Mirjole",
            label: "MIRJOLE"
        },
        {
            id: 23,
            position: {
                lat: 16.99143556756816,
                lng: 73.6902989319785
            },
            name: "Sakharpa",
            label: "SAKHARPA"
        },
    ]);
    const [distantLocations, setDistantLocations] = useState([
        // {
        //     label: "MUMBAI",
        //     value: "Mumbai"
        // },
        // {
        //     label: "ROHA",
        //     value: "Roha"
        // },
        // {
        //     label: "SATARA",
        //     value: "Satara"
        // },
        // {
        //     label: "MIRAJ",
        //     value: "Miraj"
        // },
        {
            label: "INDORE",
            value: "Indore"
        },
        {
            label: "BILASPUR",
            value: "Bilaspur"
        }
    ]);

    const handleLocationClick = (location) => {
        console.log("Selected Location: ", location);
        setSelectedLocation(location);
        setShowFamilyTreeListModal(true);
    }

    return (
        <>
            <div className={`relative w-[100%] h-full flex-1 ${isModal && 'rounded-b-xl'}`}>
                {/* Distant Locations */}
                <div
                    className={`z-[10] absolute w-[20vw] h-fit left-0 top-0 bottom-0
                    bg-transparent pl-[1vw]  ${isModal ? 'pt-[1vw]' : 'pt-[6vw]'} flex flex-row flex-wrap gap-2`}
                >
                    {
                        distantLocations.map((location, index) => (
                            <button
                                key={index}
                                className="w-fit h-fit flex items-center justify-center flex-col
                                bg-white px-[1vw] py-[1vh] rounded-xl shadow-lg
                                text-[1vw] font-SOHNE_MONO_BOLD text-gray-900
                                border border-gray-300"
                                onClick={() => handleLocationClick(location)}
                            >
                                <h2>{t(`CONSTANTS.MAP_LOCATIONS.${location.label}`)}</h2>
                            </button>
                        ))
                    }
                </div>

                {/* Map */}
                <Wrapper
                    apiKey={"AIzaSyCU4hcv0JFdAE4b88q0YRdCDFI_LoCdTWI"}
                    version={"beta"}
                    libraries={["marker"]} // Include the marker library
                >
                    <div className={"w-[100%] h-full rounded-b-xl"}>
                        <Map
                            markers={markers}
                            isModal={isModal}
                            handleLocationClick={handleLocationClick}
                        />
                    </div>
                </Wrapper>
            </div>
        </>
    )
}
