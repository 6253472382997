const carouselImg = [
	{
		id: 1,
		img: "/kulvratant_landingPage/image-shubecha.png",
		url: "/best_wishes"
	},
	{
		id: 2,
		img: "/kulvratant_landingPage/image-kalavnyas-anand-hot-aahe.png",
		url: '/happy_to_share'
	},
	{
		id: 3,
		img: "/kulvratant_landingPage/image-smuran-din.png",
		url: '/remembrance_day'
	},
	{
		id: 4,
		img: "/kulvratant_landingPage/image-dharmik.png",
		url: '/religious'
	}, {
		id: 5,
		img: "/kulvratant_landingPage/image-batmya.png",
		url: '/new-event'
	},
];
export default carouselImg;
