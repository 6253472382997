import { Link } from "react-router-dom";

const DetailCard = ({ info }) => {
	return (
	  <Link to={info.url}>
		<div className="bg-white shadow-2xl rounded-xl border-2 border-pink-800 p-6 relative h-full flex flex-col">
		  {/* Title */}
		  <h1 className="text-center text-xl text-pink-800 font-semibold mb-4">
			{info.title}
		  </h1>
  
		  {/* Paragraphs */}
		  <p className="text-justify font-SOHNE_REGULAR text-gray-700 mb-2 px-10 flex-grow">{info.para1}</p>
		  
  
		 
		</div>
	  </Link>
	);
  };
  
  export default DetailCard;