import * as React from "react";
import { Header } from "../components/Header";
import { chapters } from "../utils/ChaptersData";
import { Link } from "react-router-dom";

export const Information = () => {
  return (
    <>
      {/* Banner Section */}
      <div className="w-full h-[100vh] bg-white  flex flex-col items-center justify-center">
        <Header />
        <img
          src="/banners/banner-mahiti.jpg"
          alt="Information Banner"
          className="w-full h-full object-cover shadow-lg"
        />
      </div>

      {/* Chapters Section */}
      <div className="py-10">
        <div className="mx-5 px-4 sm:px-6">
          <div>
            <ul className="grid gap-6 md:grid-cols-3 lg:grid-cols-3 mb-20">
              {chapters.map((chapter, index) => (
                <li
                  key={index}
                  className="flex items-center border-2 border-red-800 shadow-lg rounded-lg p-6 
                  bg-yellow-500 h-[170px] w-full max-w-[450px] hover:scale-105 transition-all duration-300"
                >
                  <Link to={chapter.link} className="flex items-center w-full">
                    <img
                      src={chapter.img}
                      alt={chapter.title}
                      className="w-36 h-36 object-cover rounded-md mr-6"
                    />
                    <div className="text-lg font-semibold text-maroon hover:text-red-800">
                      {chapter.title}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
