import {Fragment, useState} from "react";
import {Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {
    CalendarDaysIcon,
    ClipboardDocumentCheckIcon,
    ClockIcon,
    PencilSquareIcon,
    StarIcon,
    UserGroupIcon,
} from "@heroicons/react/24/outline";

import {t} from "i18next";
import {Link} from "react-router-dom";

export const AboutMenu = () => {
    const optionsData = [
        {
            title: t("HEADER.ABOUT_MENU.KULVRUTANT_SAMIITI.TITLE"),
            icon: (
                <UserGroupIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: "/kulvratant_samiti",
        },
        {
            title: t("HEADER.ABOUT_MENU.ANNUAL_REPORT_2023-24.TITLE"),
            icon: (
                <CalendarDaysIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: "https://jogkulstorageuat.blob.core.windows.net/siteassets/myFolder/joglekar-annual-report-2024-2025.pdf",
        },
        {
            title: t("HEADER.ABOUT_MENU.KULVRITTAN_COMMITTEE.TITLE"),
            icon: (
                <ClipboardDocumentCheckIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: "/Chapter5",
        },
        {
            title: t("HEADER.ABOUT_MENU.STATUS_REGULATION.TITLE"),

            icon: (
                <PencilSquareIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: "/Chapter6",
        },

        {
            title: t("HEADER.ABOUT_MENU.HISTORY.TITLE"),
            icon: (
                <ClockIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: "/Chapter7",
        },
        {
            title: t("HEADER.ABOUT_MENU.MEMBERSHIP.TITLE"),
            icon: (
                <StarIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/membership'
        },
    ];

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);

    return (
        <Popover className="relative">
            {({}) => {
                // To prevent the body from scrolling when the popover is open
                document.body.classList.toggle("overflow-y-hidden", open);

                return (
                    <>
                        <button
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            className={`inline-flex items-center gap-x-1 outline-none
                                    py-[1vh] text-[1vw] font-SOHNE_MEDIUM leading-6 text-gray-900`}
                        >
                            <span>{t("HEADER.ABOUT_MENU.TITLE")}</span>
                            <ChevronDownIcon
                                className={`h-[1.25vw] w-[1.25vw] ${
                                    hover || open ? "rotate-180" : "rotate-0"
                                } transition-all duration-150 ease-in-out`}
                                aria-hidden="true"
                            />
                        </button>

                        <Transition
                            show={open || hover}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                onMouseEnter={() => setOpen(true)}
                                onMouseLeave={() => setOpen(false)}
                                className="absolute left-1/2 z-10 mt-[1vh] flex w-screen max-w-max -translate-x-1/2 rounded-3xl"
                            >
                                <div
                                    className={`bg-white w-fit min-w-[25vw] max-w-[25vw] 
                                            xl:min-w-[25vw] xl:max-w-[27.5vw] 
                                            flex-auto overflow-hidden shadow-lg  
                                            text-[0.75vw] leading-6 
                                            rounded-3xl xl:rounded-[2rem] 
                                            ring-2 ring-orange-300/5`}
                                >
                                    {/* Menu Options List*/}
                                    <div className="h-[40vh] no-scrollbar overflow-y-auto p-[0.5vw]">
                                        {optionsData.map((option, index) => (
                                            <div
                                                key={index}
                                                className="group relative flex gap-x-4 p-[1.5vh]
                                                        hover:bg-gray-200 rounded-2xl xl:rounded-3xl
                                                        transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]"
                                            >
                                                <div
                                                    className={`flex flex-none items-center justify-center 
                                                                h-[6vh] w-auto aspect-square bg-gray-50 p-[1.5vh]
                                                                rounded-xl xl:rounded-2xl group-hover:bg-orange-400 
                                                                transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]`}
                                                >
                                                    {option.icon}
                                                </div>
                                                <div
                                                    className={"flex flex-col"}
                                                >
                                                    <Link
                                                        to={option.url}
                                                        className="font-SOHNE_MEDIUM leading-normal text-[1vw] text-gray-900"
                                                    >
                                                        {option.title}
                                                        <span className="absolute inset-0"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                );
            }}
        </Popover>
    );
};
