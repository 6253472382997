import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { useEffect } from 'react';

export const Chapter8 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	useEffect(() => {
        // Scroll to top when the page is rendered
        window.scrollTo(0, 0);
    }, []);
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg mx-auto md:w-[1300px] text-justify ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl  md:mt-[120px]">
					कोकणस्थ
				</h1>
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline ">
					{" "}
					कोकण - चित्पावन - आणि जोगळेकर
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed ">
						{" "}
						<span className="pl-6 sm:pl-8">जोगळेकर</span>
						कुलवृत्तान्त प्रसिद्ध करण्याच्या निमित्ताने पुष्कळ
						गोष्टींची चर्चा झाली, संशोधन झाले, अभ्यास झाला. काही
						गोष्टी नव्याने उजेडात आल्या तर काही जुन्या, ज्ञात
						बाबींना उजाळा मिळाला. त्याचाच गक भाग म्हणजे कोकण आणि
						कोकणस्थाविषयी खोलात जाऊन केलेला विचार. जोगळेकर म्हणजे
						कोकणस्थ ब्राह्मणांपैकी गक कुल. तेव्हा
						जोगळेकरांसंबंधीच्या समग्र माहितीमधे कोकणस्थ आणि
						पर्यायाने कोकणभूमीसंबंधी माहिती करून घेणे औचित्याचे
						ठरावे. ब्राह्मणसमाजात कोकणस्थ या पोटजातीसंबंधी फार
						प्रवाद आहेत. त्यांच्या गुणावगुणांची जरा जास्तीच चर्चा
						होते असे लक्षात येते, िंकबहना त्या गुणावगुणांकडे दूषित
						नजरेने पाहिले जाते. तेव्हा कोकणस्थांचे सिद्ध झालेले
						स्वभाव आणि त्याकडे पाहण्याची इतरांची दृष्टी यामागे काही
						गेतिहासिक, भौगोलिक कारणे आहेत िंकवा कसे याचा मागोवा घेणे
						आवश्यक आहे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">सह्याद्री </span>आणि अरबी
						समुद्र यांच्यामधल्या अरुंद पट्टीला ‘कोकण‌’ म्हटले जाते.
						कोक म्हणजे ‘बाक असलेला‌’ िंकवा ‘वेडावाकडा‌’ आणि अण
						म्हणजे ‘प्रदेश‌’ - यावरून ‘कोकण‌’ शब्द सिद्ध झालेला
						असावा. कोण म्हणजे डोंगरमाथा. हाही शब्द ‘कोकण‌’ हे नांव
						पडण्याला कारणीभूत असावा. परशुरामाची आई ‘कुंकणा‌’
						हिच्यावरून ‘कोकण‌’ नांव पडले असल्याचीही आख्यायिका आहे.
						कोकणप्रांताशी परशुरामाचे नांव अनेक आख्यायिकांमधून जोडले
						गेले आहे. परशुराम ही व्यक्ती काल्पनिक नसून गेतिहासिक
						असावी असे समजण्याला आधार आहेत. परशुरामाने २१ वेळा पृथ्वी
						नि:क्षत्रिय करून नंतर ती कश्यप ऋषींना दान देऊन टाकली आणि
						मग समुद्राकडून स्वत:साठी जमीन मागून घेतली, ही आख्यायिका
						सर्वांना माहीतच आहे. त्यावरून असा अर्थ काढावा की
						परशुरामाने काही जणांना हाताशी घेऊन आत्यंतिक परिश्रमांनी
						समुद्र मागे हटविला आणि वापरण्यायोग्य जमीन मिळवली.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> परशुरामाने</span>{" "}
						मदतीकरता ही जी माणसे मिळवली त्यांच्यासंबंधीही काही
						आख्यायिका आहेत. सह्याद्रीखंडात जी आख्यायिका सापडते ती
						अशी - क्षत्रियांचा संहार केल्यामुळे परशुराम पतित झाला
						होता, म्हणून ब्राह्मणंनी त्याचे पौरोहित्य करायचे
						नाकारले. त्याच सुमारास सह्यपर्वताच्या पायथ्याशी पश्चिम
						किनाऱ्यावर वाहून आलेली १४ परदेशी माणसांची शवे परशुरामाला
						आढळली. त्याने त्या प्रेतांना चितेच्या अग्नीत शुद्ध करून
						मग सजीव केले आणि ब्राह्मणांची विद्या शिकवली.
						रक्तपाताच्या पापापासून मुक्त व्हायला जे विधी करावे
						लागतात ते करण्यासाठी त्या ब्राह्मणांनी परशुरामाला साह्य
						केले. पुढे त्यांना वसाहत करण्यासाठी परशुरामाने पश्चिम
						सागराकडून काही भूमी मिळवून दिली व त्यांच्या वसाहतीला
						चित्पोलन (चिता+पुलिन) नाव दिले. (ही स्कंदपुराणातील कथा
						आहे.) यावरून असा अर्थ काढता येतो की गलबतातून लांबचा
						प्रवास करून आलेली १४ माणसे मरणोन्मुख स्थितीत पश्चिम
						किनाऱ्याला लागली असावीत. परशुरामाने त्यांना अन्नपाणी
						देऊन वाचवले असेल आणि तिथेच वसाहत करायला मदत केली असेल.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> दुसरी</span> गक
						आख्यायिका ब्राह्मणोत्पत्ति मार्तंडात सापडते. चौदा
						ब्राह्मण सह्याद्रीच्या पश्चिमेस आपल्या धर्माप्रमाणे
						सकुटुंब राहात असताना समुद्रातून येणाऱ्या बर्बरादि
						म्लेंच्छांनी त्यांना पकडून नेले. अनेक वर्षेपर्यंत त्या
						लोकांबरोबर राहून ते पतित झाल्यामुळे परशुरामाने त्यांना
						प्रायश्चित्त देऊन शुद्ध (पावन) करून घेतले.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> कोकणातील</span>{" "}
						कुठल्याही स्थळाचा उल्लेख शालिवाहन शकापूर्वी आढळत नाही.
						त्यामुळे हा भूभाग निसर्गनिर्मित नसून मानवनिर्मित असावा
						या कल्पनेला पुष्टी मिळते. सह्याद्रीच्या डोंगरात
						समुद्रातले शंख - िंशपले - कालवे यांचे अवशेष सापडतात तसेच
						सह्याद्रीच्या पश्चिमेला आढळणारा दोन-अडीचशे मैल लांबीचा,
						पाण्याच्या समपातळीत तुटलेला काळ्या दगडांचा कडा
						समुद्रीलाटा आपटून झालेला असावा असे तज्ञांचे मत आहे.
						कोकणचा सर्व प्रदेश डोंगराळ आणि दगडांचा आहे. अनेकांच्या
						घरांच्या अंगणापासूनच डोंगरांची सुरुवात झालेली दिसते.
						तुलनेने सपाटीची आणि शेतीलायक जमीन थोडी आढळते, जी आहे ती
						कष्टांनी तयर करून घ्यावी लागली आहे असे दिसते. डोंगरावरून
						वाहणारे झरे अडवून त्यांचे पाणी पिण्यासाठी वापरले जाते.
						(अलीकडे शेतात विहिरी व पंपाची सोय केलेली आढळते).
						इसवीसनपूर्व तिसऱ्या व दुसऱ्या शतकात कोकणप्रांत
						मौर्यांच्या ताब्यात होता असा उल्लेख सापडतो. पुढे अनेक
						सत्ताबद्दल होत होत इसवीसन १२६० मधे देवगिरीचा राजा महादेव
						याने तो िंजकला. त्यावर पुढे १५व्या शतकाच्या अखेरीपर्यंत
						मुसलमानी सत्ता चालू होती. १६व्या शतकाच्या आरंभी तिथे
						पोर्तुगीजांना हटवून सिद्दी जोहारने आपला अंमल स्थापन
						केला. मराठी सत्तेचा लोप झाल्यानंतर कोकणची लक्षणीय अशी
						आर्थिक पीछेहाट झाली. याचे प्रमुख कारण म्हणजे सत्ताधारी
						पेशव्यांचा कोकणशी निकट संबंध होता. (पेशव्यांचे घराणे -
						भट - कोकणातून येऊनच पुण्यात स्थायिक झाले होते).
						पेशव्यांची सत्ता गेल्यावर कोकणच्या विकासाकडे लक्ष
						पुरविले गेले नाही. कोकणच्या शेतीमालाची मागणी घटली.
						अत्यंत डोंगराळ आणि उंच-सखल भागामुळे दळणवळणात प्रगती झाली
						नाही. आणखी गक महत्त्वाचे कारण म्हणजे मराठ्यांची
						सैन्यभरती अधिकांश कोकणातून झाली होती. मराठी राज्य
						गेल्यानंतर कमी केलेले लोक आपापल्या गावी परतून शेती करू
						लागले. जमिनीवरील बोजा वाढला आणि त्याउलट वाढीव उत्पन्नाला
						बाजारपेठ उपलब्ध होईना. या सर्वांच्या गकत्रित परिणामामुळे
						कोकणप्रांत आणि कोकणवासीयांची आर्थिक पीछेहाट होत राहिली.
						त्यातूनच गकमेकांना पाण्यात पाहणे, कडवटपणा, स्वभावातला
						तिरकसपणा आणि रोखठोक व्यवहारीपणा हे गुण कोकणवासीयांत मूळ
						धरू लागलेले दिसतात. दुर्दैवाने बाहेरील लोकांकडून त्यातील
						वाईट भाग सतत अधोरेखित केला गेला आणि त्यांच्यातील
						कष्टाळूपणा, बुद्धिमत्ता, चिकाटी आणि कौशल्य डावलले गेले.
						अर्थात यालाही अपवाद आहेत. रत्नागिरी गॅझेटियरमधे
						चित्पावनांचे खुलेपणे कौतुक केलेले आढळते. "Give barren
						land to a Chitpawan and he will turn it into Gold' -
						‘चित्पावनाला ओसाड जमीन जरी दिलीत तरी तो त्याची
						सोन्यासारखी बाग बनवेल.’
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">तिसऱ्या </span>
						आख्यायिकेप्रमाणे परशुराम स्नानासाठी समुद्रावर गेला असता
						चितास्थानी काही कोळी त्याला आढळले, त्यांना त्याने
						ब्राह्मण करून घेतले. या चौदा ब्राह्मणांपासूनच
						वंशविस्ताराने आज आपण कोकणस्थ म्हणतो तो समाज निर्माण झाला
						असे मानले जाते. (कोकणस्थांत १४ गोत्रे आहेत याचे कारण हेच
						असावे) फक्त कोकणस्थ हे नांव िंकवा हा शब्द इ.स.१७१५
						पूर्वी प्रचारात नव्हता असे दिसते. चित्पावन हा शब्द
						तेव्हा रूढ होता. चितास्थानी पावन झालेले ते चित्पावन ही
						व्युत्पत्ती वर दिलेल्या आख्यायिकेला धरून आली असावी.
						याखेरीज जमीन खणून स्वच्छ करणे या अर्थी क्षिति (जमीन)
						पावन हा शब्द असल्याने जमीन स्वच्छ करणारे (या लोकांनी
						समुद्रकाठची जमीन स्वच्छ करून वसाहतीला योग्य अशी केली) ते
						क्षितिपावन आणि त्याचा अपभ्रंश होऊन चित्पावन झाले असावे.
						हरिहरेश्वरमाहात्म्यात गक श्लोक सापडतो - अधुना नाम
						चित्तस्यपावनं क्षेत्रमस्तु तत्‌
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">या</span> चित्पावन
						ब्राह्मणांना पहिल्या पेशव्यापासून देशावरच्या लोकांनी
						कोकणस्थ म्हणायला सुरुवात केली. कोकणात मूळ राहणारे (पण
						आता देशावर आलेले) म्हणून कोकणस्थ असा त्याचा सरळ अर्थ
						होता. शिवाय कोकणातून आलेल्या या ब्राह्मणांची स्वत:ची अशी
						खास वैशिष्ट्ये होती. गोरा वर्ण, घारे डोळे, उंच िंकवा
						मध्यम बांधा, तरतरीत नाक अशी शारीरिक वैशिष्ट्ये असणारे ते
						कोकणातले ब्राह्मण अत्यंत कष्टाळू, काटकसरी, व्यवहारी आणि
						बुद्धिमान होते. राज्यकर्त्यांनाही त्यांचा धाक वाटत असे,
						असे पुरावे सापडतात. त्या गुणांचा उगम त्यांच्या
						पूर्वजांच्या गेतिहासिक आणि भौगोलिक वातावरणात सापडतो.
						कोकण प्रांतात आलेले ब्राह्मण परिश्रमांनी, स्वत:च्या
						कष्टांनी जमीन तयार करून त्या ठिकाणी उपजीविका
						चालवण्याच्या उद्देशाने आलेले होते. समुद्र हटवून िंकवा
						डोंगर फोडून जमीन तयार करणे, ती वसाहतयोग्य बनवणे या
						गोष्टी निश्चितच सोप्या नव्हेत. बुद्धिमत्ता आणि कष्ट या
						दोन्ही बाबतींत जेव्हा परिश्रम घेतले गेले - शारीरिक आणि
						बौद्धिक कष्टांची कसोटी लावली गेली, तेव्हाच ते शक्य झाले.
						त्यांचा तेव्हाचा कष्टाळू, धाडसी, मेहनती स्वभाव आणि
						व्यवहारीपणा पिढ्या न्‌ पिढ्या उतरत राहिलेला सापडतो.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">ही</span> चित्पावनांची
						वस्ती सर्वप्रथम गुहागर येथे झाली असावी आणि तिथून ते
						इतरत्र पसरले असावेत असा निष्कर्ष ना.गो.चापेकर यांनी
						आपल्या ‘चित्पावन‌’ या पुस्तकात काढलेला दिसतो. हा
						निष्कर्ष काढताना त्यांनी या मंडळींच्या
						कुलस्वामी-स्वामिनी, देवदिवाळीला वेगवेगळ्या देवतांना
						दाखवले जाणारे नैवेद्य, आडनावे यांचा अभ्यास केलेला आहे.
						या मंडळींची चित्पावनी ही स्वतंत्र बोलीभाषा होती. राजापूर
						तालुका, त्याच्या दक्षिणेस व जयगड खाडीच्या दक्षिणेकडील
						सर्व चित्पावन कोकणस्थांच्या घरात ही भाषा बोलली जात असे.
						१९१२ पर्यंत ही भाषा घरोघरी व शाळेतही बोलली, शिकवली जात
						असे. त्यावेळी युरोपियन साहेब लोकही मराठी भाषेची पोटभाषा
						म्हणून चित्पावनी भाषा समजून घेत असत.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">चित्पावन</span> ब्राह्मण
						१४ गोत्रांमध्ये विभागले गेले आहेत असे मानले जाते.
						छांदोग्योपनिषदामधे ‘कुटुंब‌’ या अर्थी गोत्र शब्द आलेला
						आहे. म्हणजे मूळच्या १४ कुटुंबांपासून पुढे चित्पावन
						जातीचा विस्तार झाला असे मानण्यास हरकत नसावी. गका
						गोत्रातले म्हणजे गकाच वंशातले लोक होत. गो म्हणजे भूमी
						आणि त्र म्हणजे संरक्षक या अर्थाने ठराविक भूमीचे संरक्षक
						कुटुंब म्हणजे गक गोत्र होय असा अर्थ असावा. याच
						कारणांमुळे सगोत्रविवाह निषिद्ध मानले गेले असून दुसऱ्या
						गोत्रातील मुलगी करण्यात पुरुषार्थ मानला जात असावा.
						जेव्हा गखाद्याला गकाच पुरुषापासून आपला अखंडित वंशवृक्ष
						सांगता येतो तेव्हा तो त्या गोत्रातील आहे असे म्हणतात.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> त्याकाळी</span> गोत्र
						म्हणजे गुरुपरंपरा असे मानले जाई. या परंपरेची व्याप्ती
						इतकी होती की, तिच्यामुळे मनुष्य ओळखण्यास सुलभ पडे.
						ब्राह्मणसमाजातील गोत्रांपैकी ३ गोत्रे फक्त चित्पावन
						समाजात आढळतात. त्यामुळे हा समाज इतर ब्राह्मणसमाजापासून
						स्वतंत्र असावा असे मानण्यास जागा आहे. चापेकरांनी
						चित्पावनांसंबंधी संशोधन करीत असताना कोणत्या गोत्राशी
						कोणते गोत्र जमत नाही याविषयीची प्रचलित कल्पना देणारा
						श्लोक मिळवलेला आहे. त्यावरून गोत्र म्हणजे गोत्रऋषी असे न
						समजता ‘कुल‌’ असा अर्थ घ्यावा हे अधिक सयुक्तिक ठरते.
						गोत्राप्रमाणे आडनावाचा शोध घेत गेल्यास मूळ आडनावांत
						जोगळेकर हे आडनाव सापडत नाही. १८३६ साली
						ब्राह्मणोत्पत्तिमार्तंड छापला गेला, त्यातील गका श्लोकात
						६० आडनावांचा उल्लेख सापडतो. त्या यादीत अत्रि गोत्रातील
						गक म्हणून जोगळेकर हे आडनाव सापडते. टिळक पंचांगातही अत्रि
						गोत्राच्या आडनावांच्या यादीत जोगळेकर हे नाव आहे. मात्र
						अत्रि गोत्राची जी मूळची प्रमुख आडनावे आहेत, त्यात
						जोगळेकर हे नाव नसून फडके, चितळे, चिपळूणकर आणि आठवले ही
						चार नावे आढळतात. चितळे आडनावापासूनच जोगळेकर निघाले आहेत
						असे गक मत आहे. चित्पावनांतील आडनावे धंद्यावरून िंकवा
						हद्द्यावरून पडली नसून ती कशावरून पडली असावीत हे अजून
						निश्चित झालेले नाही. काही आडनावे गावावरून पडली असावीत
						असे मानता येते. जोगळेकर हे नाव दापोली तालुक्यातील जोगळे
						या गावावरून पडले असावे असे मानले जाते.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> यज्ञसंस्थेविषयी</span>{" "}
						गवढे विस्ताराने लिहायचे कारण की यज्ञव्यवहारासारख्या
						अत्यंत कठीण आणि प्रतिष्ठेच्या विधीचे अधिकारपद
						मिळण्यामागे चित्पावनांची जी विद्वत्ता, बौद्धिक अधिकार
						दिसून येतो त्याचे अस्तित्व आजही शेकडो वर्षांच्या वारशाने
						चित्पावनांत दिसून येते. शेकडो वर्षे वेदाभ्यास व
						यज्ञक्रिया यांचा अव्याहत व्यासंग घडल्याने कोकणस्थांत
						अजूनही विद्येचे - वेदविद्येचे प्रेम, संस्कृतची आवड,
						सामाजिक जाणीव आणि त्यागबुद्धी हे गुण प्रकर्षाने आढळतात.
						मूलत: अत्यंत व्यक्तिनिष्ठ प्रवृत्तीची ही मंडळी
						सांस्कृतिक, सामाजिक व शैक्षणिक कार्यांत समरसतेने भाग
						घेतात. कार्यासाठी आजीव सेवकत्व ही प्रथा याच समाजात प्रथम
						अस्तित्वात आली. चित्पावनांना समाजात चांगला मान, गौरव
						आहे. त्यांना उ वैदिक ब्राह्मण मानले जाते. त्यांचा
						गौरवर्ण, देखणेपण, बुद्धिमत्ता, परिश्रम यांचे कौतुकही
						केले जाते. रणांगणावर, राजकारणात, समाजकारणात, अर्थकारणात
						सर्वच क्षेत्रांत अनेक चित्पावनांनी आपली कर्तबगारी
						दाखवलेली आहे. वैदिक, पंडित, ग्रंथकार, मुत्सद्दी, वकील,
						न्यायशास्त्री, व्यापारी, सावकार, सुभेदार, सरदार,
						किल्लेदार, फडणवीस अशी विविध अंगे सांभाळणारे चित्पावन
						अर्वाचीन युगातही सर्वच क्षेत्रांत आघाडीवर आहेत. आपली
						बुद्धिमत्ता आणि कर्तबगारी या गुणांनी मानाचे स्थान मिळवून
						आहेत.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> गुणांबरोबर</span> दोष हे
						असायचेच. काही ठिकाणी दोषही डोके वर काढताना दिसतातच. या
						संकलनाचा हेतू हाच आहे की, आपण आपल्या कुलपरंपरेचा
						गांभीर्याने विचार करून आपले गुण-दोष पारखून दोष दूर
						करावेत आणि शेकडो वर्षांच्या तेजस्वी परंपरेने मिळालेल्या
						गुणांची वृद्धी करावी. सत्कार्यासाठी कुलदैवते आपल्या
						पाठीशी नेहमीच उभी राहतील याची खात्री बाळगावी. शुभं भवतु
					</p>
					<div className="ml-20">
						<h1 className="md:ml-10">संकलन</h1>

						<p>
							डॉ. निवेदिता जोगळेकर
							<br />
							४२५/३३, टि.म.वि.कॉलनी
							<br />
							गुलटेकडी, पुणे - ४११ ०३७.
							<br />
						</p>
					</div>
				</div>

				<div
					onClick={scrollToTop}
					className="flex items-end justify-end my-5"
				>
					<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
				</div>
				<hr />
			</div>
		</div>
	);
};
