export const mockData = [
  {
    _id: "1",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Anil",
    lastName: "Joglekar",
    familyTreeName: "Pune-Joglekar-1",
    firstNameMarathi: "अनिल",
    lastNameMarathi: "जोगळेकर",
    dateOfBirth: "1995-05-20",
    age: 64,
    gender: "Male",
    bloodGroup: "B+",
    maritalStatus: "Unmarried",
    pinCode: "411001",
    city: "Pune",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+919876543210",
    email: "joglekaranil@example.com",
  },
  {
    _id: "2",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Tanmay",
    lastName: "Joglekar",
    familyTreeName: "Pune-Joglekar-2",
    firstNameMarathi: "तन्मय",
    lastNameMarathi: "जोगळेकर",
    dateOfBirth: "1993-08-15",
    age: 25,
    gender: "Male",
    bloodGroup: "O+",
    maritalStatus: "Married",
    pinCode: "411002",
    city: "Mumbai",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+919865432109",
    email: "tanmay.joglekar@example.com",
  },
  {
    _id: "3",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Ananya",
    lastName: "Deshpande",
    familyTreeName: "Pune-Deshpande-4",
    firstNameMarathi: "अनन्या",
    lastNameMarathi: "देशपांडे",
    dateOfBirth: "1988-11-10",
    age: 36,
    gender: "Female",
    bloodGroup: "A+",
    maritalStatus: "Unmarried",
    pinCode: "400001",
    city: "Thane",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+917564321098",
    email: "ananyadeshpande@example.com",
  },
  {
    _id: "4",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Rohan",
    lastName: "Kulkarni",
    familyTreeName: "Satara-Kulkarni-7",
    firstNameMarathi: "रोहन",
    lastNameMarathi: "कुलकर्णी",
    dateOfBirth: "1990-01-25",
    age: 34,
    gender: "Male",
    bloodGroup: "AB+",
    maritalStatus: "Married",
    pinCode: "415001",
    city: "Satara",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+919654321087",
    email: "khulkarni.rohan@example.com",
  },
  {
    _id: "5",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Sneha",
    lastName: "Patil",
    familyTreeName: "Kolhapur-Patil-3",
    firstNameMarathi: "स्नेहा",
    lastNameMarathi: "पाटील",
    dateOfBirth: "1997-03-18",
    age: 27,
    gender: "Female",
    bloodGroup: "B-",
    maritalStatus: "Unmarried",
    pinCode: "416001",
    city: "Kolhapur",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+918754321076",
    email: "sneha.patil@example.com",
  },
  {
    _id: "6",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Vikram",
    lastName: "Joshi",
    familyTreeName: "Nagpur-Joshi-5",
    firstNameMarathi: "विक्रम",
    lastNameMarathi: "जोशी",
    dateOfBirth: "1985-12-30",
    age: 39,
    gender: "Male",
    bloodGroup: "O-",
    maritalStatus: "Married",
    pinCode: "440001",
    city: "Nagpur",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+917643210987",
    email: "vikram.joshi@example.com",
  },
  {
    _id: "7",
    profileImg: "https://via.placeholder.com/60",
    firstName: "Pooja",
    lastName: "Shinde",
    familyTreeName: "Mumbai-Shinde-6",
    firstNameMarathi: "पूजा",
    lastNameMarathi: "शिंदे",
    dateOfBirth: "1992-06-22",
    age: 67,
    gender: "Female",
    bloodGroup: "A-",
    maritalStatus: "Unmarried",
    pinCode: "400002",
    city: "Mumbai",
    state: "Maharashtra",
    country: "India",
    mobileNumber: "+918643210976",
    email: "shindepooja@example.com",
  },
];


