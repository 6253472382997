import {Header} from "../components/Header";
import * as React from "react";
import {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {getUserById, updateProfilePicture} from "../service/accountApi";
import {AddProfessionForm} from "../components/AddProfessionForm";
import {AddEducationForm} from "../components/AddEducationForm";
import {AddAwardForm} from "../components/AddAwardForm";
import {EditProfileForm} from "../components/EditProfileForm";
import {t} from "i18next";
import {Modal} from "../components/Modal";
import {AccountContext} from "../context/AccountProvider";
import {AddHobbyForm} from "../components/AddHobbyForm";
import {AddLanguageForm} from "../components/AddLanguageForm";

export const PublicProfile = () => {
    const {id} = useParams();

    const {account} = useContext(AccountContext);

    const [accountDetails, setAccountDetails] = useState({});
    const [accountDetailsLoading, setAccountDetailsLoading] = useState(true);

    const updatePfpInputRef = useRef();
    const [updatedPfp, setUpdatedPfp] = useState(undefined);
    const [updatedPfpTempUrl, setUpdatedPfpTempUrl] = useState("");
    const [showUpdatePfpConfirmationDialog, setShowUpdatePfpConfirmationDialog] = useState(false);
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);

    const [showEditPersonalProfileForm, setShowEditPersonalProfileForm] = useState(false);
    const [showAddProfessionForm, setShowAddProfessionForm] = useState(false);
    const [showAddEducationForm, setShowAddEducationForm] = useState(false);
    const [showAddAwardForm, setShowAddAwardForm] = useState(false);
    const [showAddHobbyForm, setShowAddHobbyForm] = useState(false);
    const [showAddLanguagesKnownForm, setShowAddLanguagesKnownForm] = useState(false);

    function formatStartAndEndDate(dateString) {
        // console.log("Date String: ", dateString);
        const dateObject = new Date(dateString);
        const monthName = dateObject.toLocaleString('en-US', {month: 'short'});
        const year = dateObject.getFullYear();
        // console.log("Formatted Date: ", `${monthName} ${year}`);
        return `${monthName} ${year}`;
    }

    function formatBirthDate(dateString) {
        // Parse the ISO date string into a Date object
        const date = new Date(dateString);

        // Get the day of the month
        const day = date.getUTCDate();

        // Add the appropriate ordinal suffix to the day
        const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' :
            day % 10 === 2 && day !== 12 ? 'nd' :
                day % 10 === 3 && day !== 13 ? 'rd' : 'th');

        // Get the month as a short string
        const month = date.toLocaleString('en-US', {month: 'short'});

        // Get the year
        const year = date.getUTCFullYear();

        // Format the final output
        return `${dayWithSuffix} ${month}, ${year}`;
    }

    const updatePfp = async (file) => {
        const formData = new FormData();
        formData.append('user-pfp', file);
        formData.append('accountId', accountDetails._id);

        const updatePfpResponse = await updateProfilePicture(formData);

        console.log("Update PFP Response: ", updatePfpResponse);

        if (updatePfpResponse?.success) {
            setAccountDetails(updatePfpResponse.data);
            setUpdateButtonDisabled(false);
            setShowUpdatePfpConfirmationDialog(false);
        }
    }

    useLayoutEffect(() => {
        console.log("(PublicProfile.jsx) Inside PublicProfile useLayoutEffect");
        const fetchAccountDetails = async () => {
            console.log("(PublicProfile.jsx) Inside fetchAccountDetails useLayoutEffect");

            try {
                const response = await getUserById(id)

                console.log("Account Details Response: ", response);

                if (response.success) {
                    setAccountDetails(response.data);
                    setAccountDetailsLoading(false);
                }
            } catch (error) {
                console.log("(PublicProfile.jsx) Error in Fetching Account Details: ", error);
            }
        }

        fetchAccountDetails();
    }, []);

    useEffect(() => {
        console.log("Public Profile Data: ", accountDetails);
    }, []);

    const maritalStatusLabel = (maritalStatus) => {
        switch (maritalStatus) {
            case 'Single':
                return t("PROFILE_PAGE.MARITAL_STATUS.SINGLE");
            case 'Married':
                return t("PROFILE_PAGE.MARITAL_STATUS.MARRIED");
            case 'Widowed':
                return accountDetails.gender === "Male"
                    ? t("PROFILE_PAGE.MARITAL_STATUS.WIDOWER")
                    : t("PROFILE_PAGE.MARITAL_STATUS.WIDOWED");
            case 'Divorced':
                return t("PROFILE_PAGE.MARITAL_STATUS.DIVORCED");
            case 'Minor':
                return t("PROFILE_PAGE.MARITAL_STATUS.MINOR");
            default:
                return t("PROFILE_PAGE.MARITAL_STATUS.SINGLE");
        }
    }

    if (!accountDetails || accountDetailsLoading) {
        return (
            <div className={"w-[100vw] h-[100vh] bg-[#F7F7F7] flex items-center justify-center"}>
                <Header/>
                <h1 className={"text-[5vw] font-SOHNE_MONO_BOLD"}>
                    Loading...
                </h1>
            </div>
        )
    }

    return (
        <>
            {
                accountDetails.familyTreeAdminId === account._id && (
                    <>
                        {
                            accountDetails.deceased && (
                                <>
                                    <AddProfessionForm
                                        showAddProfessionForm={showAddProfessionForm}
                                        setShowAddProfessionForm={setShowAddProfessionForm}
                                        accountDetails={accountDetails}
                                        setAccountDetails={setAccountDetails}
                                    />

                                    <AddEducationForm
                                        showAddEducationForm={showAddEducationForm}
                                        setShowAddEducationForm={setShowAddEducationForm}
                                        accountDetails={accountDetails}
                                        setAccountDetails={setAccountDetails}
                                    />

                                    <AddAwardForm
                                        showAddAwardForm={showAddAwardForm}
                                        setShowAddAwardForm={setShowAddAwardForm}
                                        accountDetails={accountDetails}
                                        setAccountDetails={setAccountDetails}
                                    />

                                    <AddHobbyForm
                                        showAddHobbyForm={showAddHobbyForm}
                                        setShowAddHobbyForm={setShowAddHobbyForm}
                                        accountDetails={accountDetails}
                                        setAccountDetails={setAccountDetails}
                                    />

                                    <AddLanguageForm
                                        showAddLanguageForm={showAddLanguagesKnownForm}
                                        setShowAddLanguageForm={setShowAddLanguagesKnownForm}
                                        accountDetails={accountDetails}
                                        setAccountDetails={setAccountDetails}
                                    />
                                </>
                            )
                        }

                        <EditProfileForm
                            showEditPersonalProfileForm={showEditPersonalProfileForm}
                            setShowEditPersonalProfileForm={setShowEditPersonalProfileForm}
                            accountDetails={accountDetails}
                            setAccountDetails={setAccountDetails}
                        />
                    </>
                )
            }

            <div className={"w-[100vw] h-[100vh] bg-[#F7F7F7] flex items-center justify-center"}>
                <Header/>
                <div
                    className={`fixed pt-[6vw] pb-[1vw] px-[1vw] grid grid-cols-11 gap-[1vw] 
                            min-h-full max-h-full min-w-full max-w-full auto-rows-fr`}
                >
                    {/* PFP | Basic Details | Contact Details */}
                    <div className={"col-span-2 grid grid-cols-1 grid-rows-3 gap-[1vw]"}>

                        {/* PFP | Basic Details */}
                        <div
                            className={`relative p-[1.75vw] rounded-lg bg-white shadow-xl
                                row-span-3 flex flex-col justify-evenly overflow-x-clip`}
                        >
                            {/* Edit Button */}
                            {
                                accountDetails.familyTreeAdminId === account._id && (
                                    <button
                                        onClick={() => setShowEditPersonalProfileForm(true)}
                                        className={`absolute top-[2vh] right-[2vh] h-[1.75vw] w-[1.75vw] 
                                            flex items-center justify-center bg-[#D4D4D4] rounded-md shadow-lg 
                                            hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4] 
                                            transition duration-300 ease-out`}
                                    >
                                        <img
                                            src={"/assets/pencil-edit-icon.png"}
                                            className={"w-[75%] aspect-auto"}
                                            alt={"Edit Basic Details"}
                                            title={"Edit Basic Details"}
                                        />
                                    </button>
                                )
                            }

                            {/* PFP | Gender | Name | Family Tree Name */}
                            <div
                                className={`flex-1 overflow-x-clip gap-y-2 flex 
                                    flex-col items-center justify-between`}
                            >
                                {/* PFP */}
                                <div
                                    className={`relative shadow-xl z-10 h-[20vh] w-[20vh] rounded-full 
                                                flex items-center justify-center ${accountDetails.imageUrlBg} 
                                                border-4 border-white`}
                                >
                                    {
                                        accountDetails.imageUrl &&
                                        accountDetails.imageUrl.length <= 2
                                            ? (
                                                <p className={"text-[3vw] text-white font-SOHNE_MONO_BOLD"}>
                                                    {accountDetails.imageUrl.toUpperCase()}
                                                </p>

                                            ) : (
                                                <img
                                                    className="object-cover drop-shadow-2xl h-full w-full rounded-full"
                                                    src={accountDetails.imageUrl}
                                                    alt="User Profile Image"
                                                    referrerPolicy={"no-referrer"}
                                                />
                                            )
                                    }

                                    {/* Edit PFP Icon */}
                                    {
                                        accountDetails.familyTreeAdminId === account._id && (
                                            <form
                                                className={`absolute bottom-[5%] right-[5%] w-[2vw] h-[2vw] p-[0.25vh] 
                                                    cursor-pointer bg-white shadow-2xl shadow-pink-600 
                                                    rounded-lg rounded-tr-sm`}
                                                onClick={() => updatePfpInputRef.current.click()}
                                            >
                                                <input
                                                    ref={updatePfpInputRef}
                                                    hidden
                                                    type={"file"}
                                                    accept={"image/*"}
                                                    className={"edit-pfp"}
                                                    onFocus={(event) => console.log("File Input Focused Event: ", event)}
                                                    onChange={({target: {files}}) => {
                                                        console.log("Image File: ", files);
                                                        if (files.length > 0) {
                                                            setUpdatedPfp(files[0]);
                                                            setUpdatedPfpTempUrl(URL.createObjectURL(files[0]));
                                                            setShowUpdatePfpConfirmationDialog(true);
                                                        }
                                                    }}
                                                />
                                                <img
                                                    className="drop-shadow-2xl w-full h-full"
                                                    src={'/assets/edit-pfp.png'}
                                                    alt="User Profile Image"
                                                />
                                            </form>
                                        )
                                    }
                                </div>

                                {/* Gender | Name */}
                                <div className={"flex items-center justify-center mt-[1vh] gap-2"}>
                                    <div className={"w-full h-full flex-1 flex items-center justify-end"}>
                                        <img
                                            className={"w-[2vw] aspect-auto"}
                                            src={
                                                accountDetails.gender === "Male"
                                                    ? "/assets/male-gender-icon-bold.png"
                                                    : "/assets/female-gender-icon-bold.png"
                                            }
                                            alt={accountDetails.gender === "Male" ? "Male" : "Female"}
                                        />
                                    </div>
                                    <p className={"flex-1 leading-[1.75vw] text-[1.5vw] text-center font-SOHNE_BOLD"}>
                                        {accountDetails.firstName + ' ' + accountDetails.middleName}
                                    </p>
                                    <div className={"w-full flex-1 flex items-center justify-start"}>
                                        <div className={"w-[1.75vw] h-[1.75vw] bg-transparent"}/>
                                    </div>
                                </div>

                                {/* Family Tree Name */}
                                <p className={"text-[0.75vw] text-[#FF6600] font-SOHNE_MEDIUM"}>
                                    {'(' + accountDetails.familyTreeName + ')'}
                                </p>
                            </div>

                            {/* Divider - Header */}
                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>

                            {/* Basic Details */}
                            <div
                                className={`overflow-y-scroll py-[1vh] gap-1
                                    h-fit flex flex-col items-start`}
                            >
                                {/* Date Of Birth */}
                                <div className={"w-full flex items-start gap-1"}>
                                    <p className={"w-1/3 text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                        {t("PROFILE_PAGE.DOB")}
                                    </p>
                                    <p className={"flex-1 text-[#6D6D6D] text-[1.5vh] font-SOHNE_MEDIUM"}>
                                        {formatBirthDate(accountDetails.dateOfBirth)}
                                    </p>
                                </div>

                                {/* Date Of Death */}
                                {
                                    accountDetails.deceased && (
                                        <div className={"w-full flex items-start gap-1"}>
                                            <p className={"w-1/3 text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR text-wrap leading-tight"}>
                                                {t("PROFILE_PAGE.DOD")}
                                            </p>
                                            <p className={"h-full flex-1 flex items-center text-[#6D6D6D] text-[1.5vh] font-SOHNE_MEDIUM"}>
                                                {formatBirthDate(accountDetails.dateOfDeath)}
                                            </p>
                                        </div>
                                    )
                                }

                                {/* Blood Group */}
                                <div className={"w-full flex items-start gap-1"}>
                                    <div className={"w-1/3 flex gap-1"}>
                                        <img
                                            src={"/assets/blood-drop-icon.png"}
                                            className={"w-[1vw] aspect-auto"}
                                            alt={"Blood Group"}
                                            title={"Blood Group"}
                                        />
                                        <p className={"text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                            {t("PROFILE_PAGE.BLOOD_GROUP")}
                                        </p>
                                    </div>
                                    <p className={"flex-1 text-[#6D6D6D] text-[1.5vh] font-SOHNE_MEDIUM"}>
                                        {accountDetails.bloodGroup}
                                    </p>
                                </div>

                                {/* Marital Status */}
                                <div className={"w-full flex items-start gap-1"}>
                                    <div className={"w-1/3 flex gap-1"}>
                                        <img
                                            src={"/assets/marriage-icon.png"}
                                            className={"w-[1vw] aspect-auto"}
                                            alt={"Marriage"}
                                            title={"Marriage"}
                                        />
                                        <p className={"text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                            {t("PROFILE_PAGE.MARITAL_STATUS.LABEL")}
                                        </p>
                                    </div>
                                    <p className={"flex-1 text-[#6D6D6D] text-[1.5vh] font-SOHNE_MEDIUM"}>
                                        {maritalStatusLabel(accountDetails.maritalStatus)}
                                    </p>
                                </div>

                                {/* Biography */}
                                <div className={"w-full flex items-start gap-1"}>
                                    <p className={"w-1/3 text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                        {t("PROFILE_PAGE.BIOGRAPHY")}
                                    </p>
                                    <p
                                        className={`overflow-scroll no-scrollbar flex-1 
                                                text-left ${accountDetails.biography === "" ? 'text-transparent' : 'text-[#6D6D6D]'} 
                                                text-[1.5vh] font-SOHNE_MEDIUM leading-tight`}
                                    >
                                        {accountDetails.biography === "" ? 'Biography' : accountDetails.biography}
                                    </p>
                                </div>

                                {/* Side of Family | Name Before / After Marriage */}
                                {
                                    accountDetails.gender === "Female" && (
                                        <>
                                            {/* Side of Family */}
                                            <div className={"w-full flex items-start gap-1"}>
                                                <p className={"w-1/3 text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                    {t("PROFILE_PAGE.SIDE_OF_FAMILY.LABEL")}
                                                </p>
                                                <div className={"flex items-center flex-1 h-full"}>
                                                    <p
                                                        className={`overflow-scroll no-scrollbar flex-1 
                                                            text-[#6D6D6D] text-[1.5vh] font-SOHNE_MEDIUM
                                                            line-clamp-3 leading-tight`}
                                                    >
                                                        {accountDetails.sideOfFamily}
                                                    </p>
                                                </div>
                                            </div>

                                            {/* Name Before / After Marriage */}
                                            {
                                                ((accountDetails.sideOfFamily === "MaherVashin" &&
                                                        (accountDetails.firstNameAfterMarriage ||
                                                            accountDetails.spouseNameAfterMarriage ||
                                                            accountDetails.lastNameAfterMarriage)) ||
                                                    (accountDetails.sideOfFamily === "SasurVashin" &&
                                                        (accountDetails.firstNameBeforeMarriage ||
                                                            accountDetails.fathersNameBeforeMarriage ||
                                                            accountDetails.lastNameBeforeMarriage))) && (
                                                    <div className={"w-full flex items-start gap-1"}>
                                                        <div className={"w-1/3"}>
                                                            <p className={"text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                                {t("PROFILE_PAGE.SIDE_OF_FAMILY.NAME")}
                                                            </p>
                                                            <div className={"flex flex-row"}>
                                                                <p className={"text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                                    (
                                                                    {
                                                                        accountDetails.sideOfFamily === "SasurVashin"
                                                                            ? t("PROFILE_PAGE.SIDE_OF_FAMILY.BEFORE")
                                                                            : t("PROFILE_PAGE.SIDE_OF_FAMILY.AFTER")
                                                                    }
                                                                </p>
                                                                <img
                                                                    src={"/assets/marriage-icon.png"}
                                                                    className={"w-[1vw] aspect-auto"}
                                                                    alt={"Marriage"}
                                                                    title={"Marriage"}
                                                                />
                                                                <p className={"text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                                    )
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className={"flex items-center flex-1 h-full"}>
                                                            <p
                                                                className={`overflow-scroll no-scrollbar flex-1 
                                                            text-[#6D6D6D] text-[1.5vh] font-SOHNE_MEDIUM
                                                            line-clamp-2 text-ellipsis leading-tight`}
                                                            >
                                                                {
                                                                    accountDetails.sideOfFamily === "MaherVashin"
                                                                        ?
                                                                        accountDetails.firstNameAfterMarriage + " "
                                                                        + accountDetails.spouseNameAfterMarriage + " "
                                                                        + accountDetails.lastNameAfterMarriage
                                                                        :
                                                                        accountDetails.firstNameBeforeMarriage + " "
                                                                        + accountDetails.fathersNameBeforeMarriage + " "
                                                                        + accountDetails.lastNameBeforeMarriage
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>

                            {/* Contact Details | Deceased Icon */}
                            {
                                !accountDetails.deceased
                                    ? (
                                        // Contact Details
                                        <div className={"flex-1"}>
                                            {/* Divider - Basic Detail / Contact Details */}
                                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

                                            {/* Contact Details - Header */}
                                            <div className={"w-full flex items-center justify-between"}>
                                                <p className={"text-[#3D3D3D] text-[2.25vh] font-SOHNE_BOLD"}>
                                                    {t("PROFILE_PAGE.CONTACT_DETAILS")}
                                                </p>
                                            </div>

                                            {/* Divider - Header */}
                                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>

                                            {/* Contact Details - Content */}
                                            <div
                                                className={`overflow-y-scroll w-full py-[1vh]
                                                        flex-1 flex flex-col items-start gap-0.5`}
                                            >
                                                {/* Email ID */}
                                                <div className={"w-full flex items-start gap-1"}>
                                                    <p className={"w-[40%] text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                        {t("PROFILE_PAGE.EMAIL")}
                                                    </p>
                                                    <p
                                                        className={`flex-1 text-[1.5vh] font-SOHNE_MEDIUM 
                                                                overflow-scroll no-scrollbar leading-tight
                                                                ${accountDetails.email ? 'text-[#6D6D6D]' : 'text-transparent'}`}
                                                        style={{
                                                            userSelect: accountDetails.email ? "auto" : "none"
                                                        }}
                                                    >
                                                        {accountDetails.email ? accountDetails.email : 'Email ID'}
                                                    </p>
                                                </div>

                                                {/* Phone Number */}
                                                <div className={"w-full flex items-start gap-1"}>
                                                    <p className={"w-[40%] text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                        {t("PROFILE_PAGE.PHONE")}
                                                    </p>
                                                    <p
                                                        className={`flex-1 text-[1.5vh] font-SOHNE_MEDIUM 
                                                                ${accountDetails.phone?.number ? 'text-[#6D6D6D]' : 'text-transparent'}`}
                                                        style={{
                                                            userSelect: accountDetails.phone?.number ? "auto" : "none"
                                                        }}
                                                    >
                                                        {
                                                            accountDetails.phone?.number
                                                                ? (accountDetails.phone.dialingCode + ' ' + accountDetails.phone.number)
                                                                : '+91 1234567890'
                                                        }
                                                    </p>
                                                </div>

                                                {/* Home Phone Number */}
                                                <div className={"w-full flex items-start gap-1"}>
                                                    <p className={"w-[40%] text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                        {t("PROFILE_PAGE.HOME_PHONE")}
                                                    </p>

                                                    <p
                                                        className={`flex-1 text-[1.5vh] font-SOHNE_MEDIUM 
                                                                ${accountDetails.homePhone?.number ? 'text-[#6D6D6D]' : 'text-transparent'}`}
                                                        style={{
                                                            userSelect: accountDetails.homePhone?.number ? "auto" : "none"
                                                        }}
                                                    >
                                                        {
                                                            accountDetails.homePhone?.number
                                                                ? (accountDetails.homePhone.dialingCode + ' ' + accountDetails.homePhone.number)
                                                                : '+91 1234567890'
                                                        }
                                                    </p>
                                                </div>

                                                {/* Office Phone Number */}
                                                <div className={"w-full flex items-start gap-1"}>
                                                    <p className={"w-[40%] text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                        {t("PROFILE_PAGE.OFFICE_PHONE")}
                                                    </p>
                                                    <p
                                                        className={`flex-1 text-[1.5vh] font-SOHNE_MEDIUM 
                                                                ${accountDetails.officePhone?.number ? 'text-[#6D6D6D]' : 'text-transparent'}`}
                                                        style={{
                                                            userSelect: accountDetails.officePhone?.number ? "auto" : "none"
                                                        }}
                                                    >
                                                        {
                                                            accountDetails.officePhone?.number
                                                                ? (accountDetails.officePhone.dialingCode + ' ' + accountDetails.officePhone.number)
                                                                : '+91 1234567890'
                                                        }
                                                    </p>
                                                </div>

                                                {/* Address */}
                                                <div className={"w-full flex items-start gap-1"}>
                                                    <p className={"w-[40%] text-[#9B9B9B] text-[1.5vh] font-SOHNE_REGULAR"}>
                                                        {t("PROFILE_PAGE.ADDRESS")}
                                                    </p>
                                                    <p
                                                        className={`flex-1 text-[1.5vh] font-SOHNE_MEDIUM 
                                                                leading-tight overflow-scroll no-scrollbar
                                                                ${accountDetails.address?.addressLine1 ? 'text-[#6D6D6D]' : 'text-transparent'}`}
                                                        style={{
                                                            userSelect: accountDetails.address?.addressLine1 ? "auto" : "none"
                                                        }}
                                                    >
                                                        {
                                                            accountDetails.address?.addressLine1
                                                                ? `${accountDetails.address?.addressLine1}, ${accountDetails.address?.city}, ${accountDetails.address?.state}, ${accountDetails.address?.country} - ${accountDetails.address?.pincode}`
                                                                : 'Address'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // Deceased Icon
                                        <div className={"w-full flex-1 flex items-center justify-center"}>
                                            <img
                                                src={"/assets/deceased-icon-filled.svg"}
                                                className={"w-[10vw] aspect-auto"}
                                                alt={"Deceased"}
                                                title={"Deceased"}
                                            />
                                        </div>
                                    )
                            }

                            {/* Divider - Footer */}
                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full"}/>
                        </div>
                    </div>

                    {/* Profession | Education */}
                    <div className={"col-span-3 grid grid-cols-1 grid-rows-2 gap-[1vw]"}>

                        {/* Profession */}
                        <div
                            className={`px-[1.75vw] py-[1vw] rounded-lg bg-white shadow-xl
                            row-span-1 flex flex-col items-start`}
                        >
                            {/* Profession - Header */}
                            <div className={"w-full flex items-center justify-between"}>
                                <p className={"text-[#3D3D3D] text-[2.25vh] font-SOHNE_BOLD"}>
                                    {t("PROFILE_PAGE.PROFESSION")}
                                </p>

                                {
                                    accountDetails.familyTreeAdminId === account._id && accountDetails.deceased && (
                                        <button
                                            onClick={() => setShowAddProfessionForm(true)}
                                            className={`h-[1.5vw] w-[1.5vw] 
                                                flex items-center justify-center
                                                bg-[#D4D4D4] rounded-md shadow-lg 
                                                hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4] 
                                                transition duration-200 ease-out`}
                                        >
                                            <img
                                                src={"/assets/add-icon.png"}
                                                className={"w-[75%] aspect-auto"}
                                                alt={"Add Profession"}
                                                title={"Add Profession"}
                                            />
                                        </button>
                                    )
                                }
                            </div>

                            {/* Divider - Header */}
                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1vh]"}/>

                            {/* Profession List */}
                            <div
                                className={`w-full flex-1 flex flex-col items-start 
                                    gap-2 overflow-y-scroll no-scrollbar`}
                            >
                                {
                                    (!accountDetails.professionDetails || accountDetails.professionDetails?.length === 0) &&
                                    <div className={"h-full w-full flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[7.5vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                                {
                                    accountDetails.professionDetails?.map((profession, index) => (
                                        <div
                                            key={index}
                                            className={`h-fit w-full flex items-start gap-[0.75vw]
                                            p-[0.75vw] rounded-xl border border-[#ECECEC]`}
                                        >
                                            {/* Icon */}
                                            <div
                                                className={`min-w-[4vw] min-h-[4vw] max-w-[4vw] max-h-[4vw]
                                                bg-[#F5F5F5] flex items-center justify-center rounded-lg`}
                                            >
                                                <img
                                                    src={"/assets/profession-icon.png"}
                                                    className={"w-[80%] aspect-auto"}
                                                    alt={"Profession"}
                                                    title={"Profession"}
                                                />
                                            </div>

                                            {/*Details */}
                                            <div className={"w-full flex-1 flex flex-col items-start gap-1.5"}>
                                                <div className={"flex flex-col gap-0.5"}>
                                                    <p className={"text-[#838383] text-[1.25vh] leading-tight font-SOHNE_REGULAR"}>
                                                        {profession.employmentType}
                                                    </p>
                                                    <p className={"text-[#3D3D3D] text-[1.75vh] leading-none font-SOHNE_BOLD"}>
                                                        {profession.employmentTitle}
                                                    </p>
                                                </div>
                                                <div className={"w-full flex flex-col gap-1"}>
                                                    <p className={"text-[#3D3D3D] text-[1.5vh] leading-none font-SOHNE_REGULAR"}>
                                                        {profession.businessOrCompanyName}
                                                    </p>
                                                    <p className={"text-[#3D3D3D] text-[1.5vh] leading-none font-SOHNE_REGULAR"}>
                                                        {
                                                            formatStartAndEndDate(profession.startDate)
                                                            + ' - '
                                                            + (profession.isCurrentProfession ? 'Present' : formatStartAndEndDate(profession.endDate))
                                                        }
                                                    </p>
                                                </div>
                                                <p className={"text-[#838383] text-[1.25vh] leading-none font-SOHNE_REGULAR"}>
                                                    {`${profession.city}, ${profession.state}, ${profession.country}`}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    accountDetails.professionDetails?.length === 1 &&
                                    <div className={"w-full flex-1 flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[4vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Education */}
                        <div
                            className={`px-[1.75vw] py-[1vw] rounded-lg bg-white shadow-xl
                            row-span-1 flex flex-col items-start`}
                        >
                            {/* Education - Header */}
                            <div className={"w-full flex items-center justify-between"}>
                                <p className={"text-[#3D3D3D] text-[2.25vh] font-SOHNE_BOLD"}>
                                    {t("PROFILE_PAGE.EDUCATION")}
                                </p>

                                {
                                    accountDetails.familyTreeAdminId === account._id && accountDetails.deceased && (
                                        <button
                                            onClick={() => setShowAddEducationForm(true)}
                                            className={`h-[1.5vw] w-[1.5vw] 
                                                flex items-center justify-center
                                                bg-[#D4D4D4] rounded-md shadow-lg 
                                                hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4] 
                                                transition duration-200 ease-out`}
                                        >
                                            <img
                                                src={"/assets/add-icon.png"}
                                                className={"w-[75%] aspect-auto"}
                                                alt={"Add Education"}
                                                title={"Add Education"}
                                            />
                                        </button>
                                    )
                                }
                            </div>

                            {/* Divider - Header */}
                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1vh]"}/>

                            {/* Education List */}
                            <div
                                className={`w-full flex-1 flex flex-col items-start 
                                    gap-2 overflow-y-scroll no-scrollbar`}
                            >
                                {
                                    (!accountDetails.educationDetails || accountDetails.educationDetails?.length === 0) &&
                                    <div className={"h-full w-full flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[7.5vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                                {
                                    accountDetails.educationDetails?.map((education, index) => (
                                        <div
                                            key={index}
                                            className={`h-fit w-full flex items-start gap-[0.75vw]
                                            p-[0.75vw] rounded-xl border border-[#ECECEC]`}
                                        >
                                            {/* Icon */}
                                            <div
                                                className={`min-w-[4vw] min-h-[4vw] max-w-[4vw] max-h-[4vw]
                                                bg-[#F5F5F5] flex items-center justify-center rounded-lg`}
                                            >
                                                <img
                                                    src={"/assets/education-icon.png"}
                                                    className={"w-[80%] aspect-auto"}
                                                    alt={"Education"}
                                                    title={"Education"}
                                                />
                                            </div>

                                            {/*Details */}
                                            <div className={"w-full flex-1 flex flex-col items-start gap-1.5"}>
                                                <p className={"text-[#3D3D3D] text-[1.75vh] leading-none font-SOHNE_BOLD"}>
                                                    {education.schoolOrCollegeName}
                                                </p>
                                                <div className={"flex flex-col gap-1"}>
                                                    <p className={"text-[#3D3D3D] text-[1.5vh] leading-none font-SOHNE_REGULAR"}>
                                                        {`${education.degree}, ${education.courseOfStudy}`}
                                                    </p>
                                                    <p className={"text-[#3D3D3D] text-[1.5vh] leading-none font-SOHNE_REGULAR"}>
                                                        {`Result: ${education.result}`}
                                                    </p>
                                                </div>
                                                <div className={"w-full flex flex-col gap-1"}>
                                                    <p className={"text-[#838383] text-[1.25vh] leading-none font-SOHNE_REGULAR"}>
                                                        {
                                                            formatStartAndEndDate(education.startDate)
                                                            + ' - '
                                                            + (education.isCurrentEducation ? 'Present' : formatStartAndEndDate(education.endDate))
                                                        }
                                                    </p>
                                                    <p className={"text-[#838383] text-[1.25vh] leading-none font-SOHNE_REGULAR"}>
                                                        {`${education.city}, ${education.state}, ${education.country}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    accountDetails.educationDetails?.length === 1 &&
                                    <div className={"w-full flex-1 flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[4vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* Awards */}
                    <div
                        className={`px-[1.75vw] py-[1vw] rounded-lg bg-white shadow-xl 
                        col-span-3 flex flex-col items-start `}
                    >
                        {/* Awards - Header */}
                        <div className={"w-full flex items-center justify-between"}>
                            <p className={"text-[#3D3D3D] text-[2.25vh] font-SOHNE_BOLD"}>
                                {t("PROFILE_PAGE.AWARDS")}
                            </p>

                            {
                                accountDetails.familyTreeAdminId === account._id && accountDetails.deceased && (
                                    <button
                                        onClick={() => setShowAddAwardForm(true)}
                                        className={`h-[1.5vw] w-[1.5vw] 
                                        flex items-center justify-center
                                        bg-[#D4D4D4] rounded-md shadow-lg 
                                        hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4] 
                                        transition duration-200 ease-out`}
                                    >
                                        <img
                                            src={"/assets/add-icon.png"}
                                            className={"w-[75%] aspect-auto"}
                                            alt={"Add Awards"}
                                            title={"Add Awards"}
                                        />
                                    </button>
                                )
                            }
                        </div>

                        {/* Divider - Header */}
                        <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1vh]"}/>

                        {/* Awards List*/}
                        <div
                            className={"w-full flex-1 flex flex-col items-start gap-3 overflow-y-scroll no-scrollbar"}>
                            {
                                (!accountDetails.awardDetails || accountDetails.awardDetails?.length === 0) &&
                                <div className={"h-full w-full flex items-center justify-center"}>
                                    <img
                                        src={"/assets/ganeshji-logo.png"}
                                        className={"w-[7.5vw] aspect-auto"}
                                        alt={"GaneshJi"}
                                        title={"GaneshJi"}
                                    />
                                </div>
                            }
                            {
                                accountDetails.awardDetails?.map((award, index) => (
                                    <div
                                        key={index}
                                        className={`h-fit w-full flex items-start gap-[0.75vw]
                                            p-[0.75vw] rounded-xl border border-[#ECECEC]
                                            transition-all duration-500 ease-in-out hover:shadow-lg`}
                                    >
                                        {/* Icon */}
                                        <div
                                            className={`min-w-[4vw] min-h-[4vw] max-w-[4vw] max-h-[4vw]
                                                bg-[#F5F5F5] flex items-center justify-center rounded-lg`}
                                        >
                                            <img
                                                src={"/assets/award-icon.png"}
                                                className={"w-[80%] aspect-auto"}
                                                alt={"Award"}
                                                title={"Award"}
                                            />
                                        </div>

                                        {/*Details */}
                                        <div className={"w-full flex-1 flex flex-col items-start gap-1.5"}>
                                            <p className={"text-[#3D3D3D] text-[2vh] leading-none font-SOHNE_BOLD"}>
                                                {award.awardTitle}
                                            </p>
                                            <p className={"line-clamp-4 overflow-y-scroll no-scrollbar text-[#838383] text-[1.5vh] leading-tight font-SOHNE_REGULAR"}>
                                                {award.awardDescription}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                accountDetails.awardDetails?.length !== 0 && accountDetails.awardDetails?.length < 5 &&
                                <div className={"w-full flex-1 flex items-center justify-center"}>
                                    <img
                                        src={"/assets/ganeshji-logo.png"}
                                        className={"w-[5vw] aspect-auto"}
                                        alt={"GaneshJi"}
                                        title={"GaneshJi"}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    {/* Last Section */}
                    <div className={`col-span-3 grid grid-cols-1 grid-rows-2 gap-[1vw]`}>
                        {/* Hobbies */}
                        <div
                            className={`px-[1.75vw] py-[1vw] rounded-lg bg-white shadow-xl
                                    row-span-1 flex flex-col items-start`}
                        >
                            {/* Hobbies - Header */}
                            <div className={"w-full flex items-center justify-between"}>
                                <p className={"text-[#3D3D3D] text-[2.25vh] font-SOHNE_BOLD"}>
                                    {t("PROFILE_PAGE.HOBBY")}
                                </p>

                                {
                                    accountDetails.familyTreeAdminId === account._id && accountDetails.deceased && (
                                        <button
                                            onClick={() => setShowAddHobbyForm(true)}
                                            className={`h-[1.5vw] w-[1.5vw] 
                                        flex items-center justify-center
                                        bg-[#D4D4D4] rounded-md shadow-lg 
                                        hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4] 
                                        transition duration-200 ease-out`}
                                        >
                                            <img
                                                src={"/assets/add-icon.png"}
                                                className={"w-[75%] aspect-auto"}
                                                alt={"Add Profession"}
                                                title={"Add Profession"}
                                            />
                                        </button>
                                    )
                                }
                            </div>

                            {/* Divider - Header */}
                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1vh]"}/>

                            {/* Hobbies List */}
                            <div
                                className={"w-full flex-1 flex flex-col items-start gap-3 overflow-y-scroll no-scrollbar"}
                            >
                                {
                                    (!accountDetails.hobbyDetails || accountDetails.hobbyDetails?.length === 0) &&
                                    <div className={"h-full w-full flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[7.5vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                                {
                                    accountDetails.hobbyDetails?.map((hobby, index) => (
                                        <div
                                            key={index}
                                            className={`h-fit w-full flex items-start gap-[0.75vw]
                                                    p-[0.75vw] rounded-xl border border-[#ECECEC]
                                                    transition-all duration-500 ease-in-out hover:shadow-lg`}
                                        >
                                            {/* Icon */}
                                            <div
                                                className={`min-w-[4vw] min-h-[4vw] max-w-[4vw] max-h-[4vw]
                                                        bg-[#F5F5F5] flex items-center justify-center rounded-lg`}
                                            >
                                                <img
                                                    src={"/assets/hobbies-icon.png"}
                                                    className={"w-[80%] aspect-auto"}
                                                    alt={"Hobby"}
                                                    title={"Hobby"}
                                                />
                                            </div>

                                            {/* Details */}
                                            <div className={"w-full flex-1 flex flex-col items-start gap-1.5"}>
                                                <p className={"text-[#3D3D3D] text-[2vh] leading-none font-SOHNE_BOLD"}>
                                                    {hobby.hobbyTitle}
                                                </p>
                                                <p className={"line-clamp-4 overflow-y-scroll no-scrollbar text-[#838383] text-[1.5vh] leading-tight font-SOHNE_REGULAR"}>
                                                    {hobby.hobbyDescription}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    accountDetails.hobbyDetails?.length !== 0 && accountDetails.hobbyDetails?.length < 2 &&
                                    <div className={"w-full flex-1 flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[5vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Languages Known */}
                        <div
                            className={`px-[1.75vw] py-[1vw] rounded-lg bg-white shadow-xl
                                    row-span-1 flex flex-col items-start`}
                        >
                            {/* Languages Known - Header */}
                            <div className={"w-full flex items-center justify-between"}>
                                <p className={"text-[#3D3D3D] text-[2.25vh] font-SOHNE_BOLD"}>
                                    {t("PROFILE_PAGE.LANGUAGES_KNOWN")}
                                </p>

                                {
                                    accountDetails.familyTreeAdminId === account._id && accountDetails.deceased && (
                                        <button
                                            onClick={() => setShowAddLanguagesKnownForm(true)}
                                            className={`h-[1.5vw] w-[1.5vw] 
                                                flex items-center justify-center
                                                bg-[#D4D4D4] rounded-md shadow-lg 
                                                hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4] 
                                                transition duration-200 ease-out`}
                                        >
                                            <img
                                                src={"/assets/add-icon.png"}
                                                className={"w-[75%] aspect-auto"}
                                                alt={"Add Education"}
                                                title={"Add Education"}
                                            />
                                        </button>
                                    )
                                }
                            </div>

                            {/* Divider - Header */}
                            <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1vh]"}/>

                            {/* Languages Known List */}
                            <div
                                className={"w-full flex-1 flex flex-col items-start gap-3 overflow-y-scroll no-scrollbar"}
                            >
                                {
                                    (!accountDetails.languageKnownDetails || accountDetails.languageKnownDetails?.length === 0) &&
                                    <div className={"h-full w-full flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[7.5vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                                {
                                    accountDetails.languageKnownDetails?.map((language, index) => (
                                        <div
                                            key={index}
                                            className={`h-fit w-full flex items-start gap-[0.75vw]
                                                    p-[0.75vw] rounded-xl border border-[#ECECEC]
                                                    transition-all duration-500 ease-in-out hover:shadow-lg`}
                                        >
                                            {/* Icon */}
                                            <div
                                                className={`min-w-[2.5vw] min-h-[2.5vw] max-w-[2.5vw] max-h-[2.5vw]
                                                        bg-[#F5F5F5] flex items-center justify-center rounded-lg`}
                                            >
                                                <img
                                                    src={"/assets/language-icon.png"}
                                                    className={"w-[80%] aspect-auto"}
                                                    alt={"Language"}
                                                    title={"Language"}
                                                />
                                            </div>

                                            {/* Details */}
                                            <div className={"w-full flex-1 flex flex-col items-start gap-1.5"}>
                                                <p className={"text-[#3D3D3D] text-[2vh] leading-none font-SOHNE_BOLD"}>
                                                    {language.languageTitle}
                                                </p>
                                                <p className={"line-clamp-4 overflow-y-scroll no-scrollbar text-[#838383] text-[1.5vh] leading-tight font-SOHNE_REGULAR"}>
                                                    {language.languageProficiency}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    accountDetails.languageKnownDetails?.length !== 0 && accountDetails.languageKnownDetails?.length < 3 &&
                                    <div className={"w-full flex-1 flex items-center justify-center"}>
                                        <img
                                            src={"/assets/ganeshji-logo.png"}
                                            className={"w-[4vw] aspect-auto"}
                                            alt={"GaneshJi"}
                                            title={"GaneshJi"}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Update PFP Confirmation Dialog */}
            <Modal isOpen={showUpdatePfpConfirmationDialog}>
                <div
                    className={`px-[1.5vw] py-[2vh] bg-black/60 rounded-lg
                            transition-all duration-150 ease-out ring-2 ring-white`}
                >
                    {/* Header */}
                    <h2 className="font-SOHNE_MEDIUM text-xl text-white">
                        {t("PROFILE_PAGE.PFP.UPDATE.TITLE")}
                    </h2>

                    {/* Updated PFP */}
                    <div className={"relative w-full flex items-center justify-center py-[2vh]"}>
                        <img
                            className="object-cover drop-shadow-2xl w-[75%] aspect-square rounded-full border-4 border-white"
                            src={updatedPfpTempUrl}
                            alt="User Profile Image"
                        />
                        <button
                            className={"w-[2vw] h-[2vw] p-[0.25vh] absolute bottom-[17.5%] right-[17.5%] bg-white shadow-2xl shadow-pink-600 rounded-lg rounded-tr-sm"}
                            onClick={() => updatePfpInputRef.current.click()}
                        >
                            <img
                                className="drop-shadow-2xl w-full h-full"
                                src={'/assets/edit-pfp.png'}
                                alt="User Profile Image"
                            />
                        </button>
                    </div>

                    {/* Footer */}
                    <div className="mt-[1vh] flex flex-row items-center gap-x-[1vw]">
                        <button
                            disabled={updateButtonDisabled}
                            onClick={() => updatePfp(updatedPfp)}
                            className="font-SOHNE_MEDIUM inline-flex items-center arrow-button bg-[#D26A53] leading-tight hover:ring-white hover:ring-2 disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full pl-[1.25vw] pr-[0.75vw] py-[1vh] text-white transition-all duration-300 ease-in-out"
                        >
                            {t("PROFILE_PAGE.PFP.UPDATE.OPTION_1")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="white"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="white"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                        <button
                            disabled={updateButtonDisabled}
                            className="inline-flex items-center gap-2 rounded-full ring-inset ring-2 ring-white py-[1vh] px-[2vw] text-SM font-SOHNE_MEDIUM text-white focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white transition-all duration-300 ease-in-out"
                            onClick={() => {
                                updatePfpInputRef.current.value = "";
                                setShowUpdatePfpConfirmationDialog(false);
                                setUpdatedPfp(null);
                                setUpdatedPfpTempUrl("");
                            }}
                        >
                            {t("PROFILE_PAGE.PFP.UPDATE.OPTION_2")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
