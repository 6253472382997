import React, {useState} from 'react';
import {t} from "i18next";
import {Modal} from "../components/Modal";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Transition} from "@headlessui/react";
import {addProfession} from "../service/accountApi";

export const AddProfessionForm = (props) => {
    const {showAddProfessionForm, setShowAddProfessionForm, accountDetails, setAccountDetails} = props;

    const [error, setError] = useState({});

    const [isEmploymentTypeDropdownOpen, setIsEmploymentTypeDropdownOpen] = useState(false);
    const [isSelectingEmploymentType, setIsSelectingEmploymentType] = useState(false);

    const [isAddProfessionButtonDisabled, setIsAddProfessionButtonDisabled] = useState(false);

    const [professionDetails, setProfessionDetails] = useState({
        employmentTitle: '',
        businessOrCompanyName: '',

        employmentType: '',
        startDate: '',
        endDate: '',

        isCurrentProfession: false,

        city: '',
        state: '',
        country: '',
    });

    const [needAssistance, setNeedAssistance] = useState(false);
    const [needAssistanceDescription, setNeedAssistanceDescription] = useState('');

    const employmentTypeLabel = (employmentType) => {
        switch (employmentType) {
            case 'Service':
                return t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.SERVICE");
            case 'Business':
                return t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.BUSINESS");
            case 'Retired':
                return t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.RETIRED");
            default:
                return "";
        }
    }

    const handleEmploymentTypeSelect = (employmentType) => {
        setIsSelectingEmploymentType(false);
        setIsEmploymentTypeDropdownOpen(false);
        setProfessionDetails({
            ...professionDetails,
            employmentType: employmentType
        });

        setError({
            ...error,
            employmentType: ""
        })
    }

    const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

        setProfessionDetails({
            ...professionDetails,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ""
        });
    }

    const validate = () => {
        let err = {};

        if (professionDetails.employmentTitle === '') {
            err['employmentTitle'] = t("ADD_PROFESSION_FORM.EMPLOYMENT_TITLE.REQUIRED_ERROR");
        }

        if (professionDetails.businessOrCompanyName === '') {
            err['businessOrCompanyName'] = t("ADD_PROFESSION_FORM.BUSINESS_OR_COMPANY_NAME.REQUIRED_ERROR");
        }

        if (professionDetails.employmentType === '') {
            err['employmentType'] = t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.REQUIRED_ERROR");
        }

        if (professionDetails.startDate === '') {
            err['startDate'] = t("ADD_PROFESSION_FORM.START_DATE.REQUIRED_ERROR");
        }

        if (!professionDetails.isCurrentProfession) {
            if (professionDetails.endDate === '') {
                err['endDate'] = t("ADD_PROFESSION_FORM.END_DATE.REQUIRED_ERROR");
            }
        }

        if (professionDetails.city === '') {
            err['city'] = t("ADD_PROFESSION_FORM.CITY.REQUIRED_ERROR");
        }

        if (professionDetails.state === '') {
            err['state'] = t("ADD_PROFESSION_FORM.STATE.REQUIRED_ERROR");
        }

        if (professionDetails.country === '') {
            err['country'] = t("ADD_PROFESSION_FORM.COUNTRY.REQUIRED_ERROR");
        }

        return err;
    }

    const handleSubmit = async () => {
        console.log("Inside Handle Submit!");
        setIsAddProfessionButtonDisabled(true);
        const err = validate();

        if (Object.keys(err).length > 0) {
            setError(err);
            setIsAddProfessionButtonDisabled(false);
            return;
        }

        console.log("Profession Details: ", professionDetails);
        const professionData = {
            userAccountId: accountDetails._id,
            professionDetails: professionDetails,
        };
        const addProfessionDataResponse = await addProfession(professionData);

        console.log('(AddProfessionForm) Add Profession Data Response: ', addProfessionDataResponse);

        if (addProfessionDataResponse.success) {
            setAccountDetails(addProfessionDataResponse.data);
            setShowAddProfessionForm(false);
            setIsAddProfessionButtonDisabled(false);
            setProfessionDetails({
                schoolOrCollegeName: '',
                businessOrCompanyName: '',

                employmentType: '',
                startDate: '',
                endDate: '',

                isCurrentProfession: false,

                city: '',
                state: '',
                country: '',
            });
        }
    }

    return (
        <Modal isOpen={showAddProfessionForm}>
            <div className="flex flex-col justify-between max-h-[80vh] rounded-xl px-[1.5vw] py-[2vh] bg-white">
                {/* Header */}
                <div className={"max-h-[10vh]"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        {/* Form Title */}
                        <h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
                            {t("ADD_PROFESSION_FORM.TITLE")}
                        </h2>

                        <button
                            onClick={() => setShowAddProfessionForm(false)}
                            type="button"
                            data-drawer-hide="drawer-form"
                            aria-controls="drawer-form"
                            className="flex items-center justify-center rounded-lg w-[2vw] h-[2vw]
                            bg-transparent hover:bg-gray-200
                            text-sm text-gray-400 hover:text-gray-900"
                        >
                            <img
                                className={"w-[75%] h-[75%]"}
                                src={"/assets/close-icon.png"}
                                alt={"Close"}
                                title={"Close"}
                            />
                        </button>
                    </div>

                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
                </div>

                <div className={"flex-1 no-scrollbar overflow-y-scroll max-h-[60vh] py-[1.5vh]"}>
                    {/* Employment Title */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="employmentTitle"
                            >
                                {t("ADD_PROFESSION_FORM.EMPLOYMENT_TITLE.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <input
                                    id="employmentTitle"
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='employmentTitle'
                                    value={professionDetails.employmentTitle}
                                    onChange={handleFormData}
                                />
                                {
                                    error['employmentTitle'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['employmentTitle']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>

                    {/* Business or Company Name */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="businessOrCompanyName"
                            >
                                {t("ADD_PROFESSION_FORM.BUSINESS_OR_COMPANY_NAME.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <input
                                    id="businessOrCompanyName"
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='businessOrCompanyName'
                                    value={professionDetails.businessOrCompanyName}
                                    onChange={handleFormData}
                                />
                                {
                                    error['businessOrCompanyName'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['businessOrCompanyName']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>

                    {/* Employment Type | Start Date | End Date */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-row justify-between gap-4">
                            {/* Employment Type */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="employmentType"
                                >
                                    {t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.LABEL")}
                                </label>

                                <div className="relative w-full flex flex-col">
                                    <button
                                        onBlur={() => {
                                            if (!isSelectingEmploymentType) {
                                                setIsEmploymentTypeDropdownOpen(false);
                                            }
                                        }}
                                        className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                        onClick={() => setIsEmploymentTypeDropdownOpen(!isEmploymentTypeDropdownOpen)}
                                    >
                                        <p className={"flex-1 font-SOHNE_REGULAR text-[1vw] text-left"}>
                                            {employmentTypeLabel(professionDetails.employmentType)}
                                        </p>

                                        <ChevronDownIcon
                                            className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isEmploymentTypeDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
                                            aria-hidden="true"
                                            stroke="#292D32"
                                            strokeWidth={"0.1vh"}
                                        />
                                    </button>

                                    {/* Employment Type Dropdown */}
                                    <div className={"relative"}>
                                        <Transition show={isEmploymentTypeDropdownOpen}>
                                            <div
                                                className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
                                            >
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
                                                    onMouseDown={() => setIsSelectingEmploymentType(true)}
                                                    onClick={() => handleEmploymentTypeSelect("Service")}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.SERVICE")}
                                                    </p>
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
                                                    onMouseDown={() => setIsSelectingEmploymentType(true)}
                                                    onClick={() => handleEmploymentTypeSelect("Business")}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.BUSINESS")}
                                                    </p>
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
                                                    onMouseDown={() => setIsSelectingEmploymentType(true)}
                                                    onClick={() => handleEmploymentTypeSelect("Retired")}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("ADD_PROFESSION_FORM.EMPLOYMENT_TYPE.RETIRED")}
                                                    </p>
                                                </button>
                                            </div>
                                        </Transition>
                                    </div>

                                    {
                                        error['employmentType'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['employmentType']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* Start Date */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className='text-gray-600 text-[0.9vw] font-SOHNE_REGULAR'
                                    htmlFor='startDate'
                                >
                                    {t("ADD_PROFESSION_FORM.START_DATE.LABEL")}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type={"month"}
                                        name='startDate'
                                        required
                                        value={professionDetails.startDate}
                                        onChange={handleFormData}
                                        className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md text-[1vw]
                                            ${professionDetails.isCurrentProfession ? 'px-[0.75vw]' : 'px-[1vw]'}
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]`}
                                    />
                                    {
                                        error['startDate'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['startDate']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* End Date */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className='text-gray-600 text-[0.9vw] font-SOHNE_REGULAR'
                                    htmlFor='endDate'
                                >
                                    {t("ADD_PROFESSION_FORM.END_DATE.LABEL")}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        disabled={professionDetails.isCurrentProfession}
                                        type={"month"}
                                        name='endDate'
                                        value={professionDetails.endDate}
                                        onChange={handleFormData}
                                        className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md px-[0.75vw] text-[1vw]
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]"
                                    />
                                    {
                                        error['endDate'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['endDate']}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/* Current Profession CheckBox */}
                    <fieldset>
                        <div className={"mb-[2vh] flex flex-col gap-2"}>
                            {/*<label*/}
                            {/*    className="cursor-pointer min-h-[5vh] max-h-[5vh] w-fit flex flex-row items-center gap-2*/}
                            {/*    rounded-md px-[1vw] py-[1vh] border-2 border-gray-300">*/}
                            <label className="flex flex-row items-center gap-2">
                                <input
                                    type="checkbox"
                                    name='isCurrentProfession'
                                    checked={professionDetails.isCurrentProfession}
                                    onChange={(e) => {
                                        console.log("Is Current Profession: ", e.target.checked);
                                        setProfessionDetails({
                                            ...professionDetails,
                                            isCurrentProfession: e.target.checked
                                        });
                                    }}
                                />
                                <p className="font-SOHNE_REGULAR text-[1vw]">
                                    {t("ADD_PROFESSION_FORM.PRESENT")}
                                </p>
                            </label>
                        </div>
                    </fieldset>

                    {/* City | State | Country */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-row gap-4 flex-wrap">
                            {/* City */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="city"
                                >
                                    {t("ADD_PROFESSION_FORM.CITY.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="city"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='city'
                                        value={professionDetails.city}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['city'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['city']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* State */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="state"
                                >
                                    {t("ADD_PROFESSION_FORM.STATE.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="state"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='state'
                                        value={professionDetails.state}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['state'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['state']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* Country */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="country"
                                >
                                    {t("ADD_PROFESSION_FORM.COUNTRY.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="country"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='country'
                                        value={professionDetails.country}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['country'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['country']}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/*/!* Need Assistance *!/*/}
                    {/*<fieldset>*/}
                    {/*    <label className="flex flex-row items-center gap-2">*/}
                    {/*        <input*/}
                    {/*            type="checkbox"*/}
                    {/*            name='needAssistance'*/}
                    {/*            checked={needAssistance}*/}
                    {/*            onChange={() => setNeedAssistance(!needAssistance)}*/}
                    {/*        />*/}
                    {/*        <p className="font-SOHNE_REGULAR text-[1vw]">*/}
                    {/*            {t("CREATE_PROFILE_FORM.NEED_ASSISTANCE.LABEL")}*/}
                    {/*        </p>*/}
                    {/*    </label>*/}
                    {/*</fieldset>*/}

                    {/*/!* Assistance Description *!/*/}
                    {/*<fieldset>*/}
                    {/*    <div className="mt-[2vh] flex flex-col gap-2">*/}
                    {/*        <textarea*/}
                    {/*            disabled={!needAssistance}*/}
                    {/*            id="needAssistanceDescription"*/}
                    {/*            name='needAssistanceDescription'*/}
                    {/*            className="font-SOHNE_MEDIUM inline-flex rounded-md px-[1vw] py-[1vh] text-[1vw]*/}
                    {/*                    leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"*/}
                    {/*            placeholder={t("CREATE_PROFILE_FORM.NEED_ASSISTANCE.DESCRIPTION_PLACEHOLDER")}*/}
                    {/*            value={needAssistanceDescription}*/}
                    {/*            onChange={(e) => setNeedAssistanceDescription(e.target.value)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</fieldset>*/}
                </div>

                {/* Footer */}
                <div className={"max-h-[10vh]"}>
                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

                    <div className="flex items-center justify-end">
                        {/* Submit Button */}
                        <button
                            disabled={isAddProfessionButtonDisabled}
                            onClick={handleSubmit}
                            className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
                        >
                            {t("ADD_PROFESSION_FORM.SUBMIT")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
