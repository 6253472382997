import React, {useContext, useEffect, useRef, useState} from "react";

import {ReactFlowProvider} from "reactflow";
import "reactflow/dist/style.css";
import {Header} from "../components/Header";
import {AccountContext} from "../context/AccountProvider";
import {CreateProfile} from "../components/CreateProfile";
import {FamilyTreeBuilder} from "../components/FamilyTreeBuilder";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import {Modal} from "../components/Modal";
import {getUsers, getUsersInFamilyTree} from "../service/accountApi";
import {JoglekarKulMap} from "../components/JoglekarKulMap";
import {FamilyTreeListModal} from "../components/FamilyTreeListModal";

export const Kulavruksh = () => {
    const navigate = useNavigate();
    const {cookie, account, loading} = useContext(AccountContext);

    const [showOutsiderSearchModal, setShowOutsiderSearchModal] = useState(false);

    const searchInputRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const [showFamilyTreeListModal, setShowFamilyTreeListModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({
        label: "",
        value: ""
    });

    const handleClearButtonClick = () => {
        setSearchQuery("");
        setSearchResults([]);
        searchInputRef.current?.focus();
    };

    const handleNodeClick = (nodeId) => {
        console.log("Clicked Node ID: ", nodeId);

        // setShowSearchModal(false);
    }

    useEffect(() => {
        if (searchQuery === "") {
            setSearchResults([]);
        } else {
            console.log("Cookie: ", cookie);
            console.log("Account: ", account);
            if (!cookie || account?.outsider) {
                getUsers(searchQuery)
                    .then((response) => {
                        if (response.success) {
                            setSearchResults(response.data);
                        }
                    })
                    .catch((error) => {
                        console.log("Get Users Error: ", error);
                    });
            } else {
                getUsersInFamilyTree(account.familyTreeId, searchQuery)
                    .then((response) => {
                        if (response.success) {
                            setSearchResults(response.data);
                        }
                    })
                    .catch((error) => {
                        console.log("Get Users Error: ", error);
                    });
            }
        }
    }, [searchQuery]);

    useEffect(() => {
        console.log("showOutsiderSearchModal: ", showOutsiderSearchModal);
        if (showOutsiderSearchModal) {
            const timeout = setTimeout(() => {
                console.log("Search Input Ref: ", searchInputRef);
                searchInputRef.current?.focus();
            }, 0);

            return () => clearTimeout(timeout); // Cleanup timeout
        } else {
            const timeout = setTimeout(() => {
                console.log("Clearing Search Query and Results!");
                setSearchQuery("");
                setSearchResults([]);
            }, 250);

            return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [showOutsiderSearchModal]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if Ctrl + F (or Command + F on Mac) is pressed
            if ((event.ctrlKey || event.metaKey) && event.key === "f") {
                if (!showOutsiderSearchModal) {
                    event.preventDefault();
                    console.log("Open search modal triggered!");
                    setShowOutsiderSearchModal(true);
                }
            }

            // Check if Esc key is pressed
            if (event.key === "Escape") {
                console.log("Escape key pressed!");
                if (showOutsiderSearchModal) {
                    event.preventDefault();
                    console.log("Close search modal triggered!");
                    setShowOutsiderSearchModal(false);
                }
            }
        };

        // Add the event listener
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [showOutsiderSearchModal]);

    useEffect(() => {
        if (!cookie) {
            navigate("/login");
        }
    }, []);

    return (
        <>
            <div style={{width: "100vw", height: "100vh"}}>
                <Header
                    showSearchModal={showOutsiderSearchModal}
                    setShowSearchModal={setShowOutsiderSearchModal}
                />

                {
                    loading
                        ? (
                            <div className={"w-[100vw] h-[100vh] bg-[#F7F7F7] flex items-center justify-center"}>
                                <h1 className={"text-[5vw] font-SOHNE_MONO_BOLD"}>
                                    Loading...
                                </h1>
                            </div>
                        )
                        : (
                            !account?.outsider
                                ? (
                                    <ReactFlowProvider>
                                        {
                                            account.nodeId
                                                ? (
                                                    <FamilyTreeBuilder/>
                                                )
                                                : <CreateProfile/>
                                        }
                                    </ReactFlowProvider>
                                )
                                : (
                                    <>
                                        {/* Search Modal */}
                                        <Modal zIndex={"z-[100]"} isOpen={showOutsiderSearchModal}>
                                            <div
                                                className={`flex flex-col gap-y-3 max-h-[65vh] w-full bg-white rounded-xl
                                                    ${searchResults.length === 0 && 'pb-[3vh]'}
                                                `}
                                            >
                                                {/* Search Modal Header */}
                                                <div className={"mt-[2vh] mx-[3vh] flex flex-row items-center justify-between"}>
                                                    {/* Modal Title */}
                                                    <h2 className="text-[1.5vw] font-SOHNE_BOLD text-gray-900">
                                                        {t("SEARCH_MODAL.OUTSIDER_TITLE")}
                                                    </h2>

                                                    {/* Close Button */}
                                                    <button
                                                        onClick={() => setShowOutsiderSearchModal(false)}
                                                        className="w-[1.75vw] h-[1.75vw]
                                                            text-sm text-gray-400 hover:text-gray-900
                                                            flex items-center justify-center bg-[#EAEAEA] rounded-md shadow-lg
                                                            hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                                                            transition duration-300 ease-out"
                                                    >
                                                        <img
                                                            className={"w-[75%] h-[75%]"}
                                                            src={"/assets/close-icon.png"}
                                                            alt={"Close"}
                                                            title={"Close"}
                                                        />
                                                    </button>
                                                </div>

                                                {/* Search Bar */}
                                                <div
                                                    className={`h-[6vh] flex items-center justify-between gap-x-2
                                                        rounded-full p-[1vh] mx-[3vh] bg-white border border-[#EAEAEA]`}
                                                >
                                                    <div
                                                        className={`flex items-center justify-center rounded-full 
                                                            h-[2vw] w-[2vw] p-[0.5vh]`}
                                                    >
                                                        <img
                                                            className="h-full w-full"
                                                            src="/assets/search-icon-dark.svg"
                                                            alt="Search"
                                                        />
                                                    </div>

                                                    <input
                                                        ref={searchInputRef}
                                                        id="searchQueryInput"
                                                        className="w-full flex-1 h-full font-SOHNE_MEDIUM text-[1vw] leading-none outline-none"
                                                        name="Search Query"
                                                        placeholder={t("SEARCH_MODAL.SEARCH_PLACEHOLDER")}
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />

                                                    {/* Clear Button */}
                                                    {searchQuery && (
                                                        <button
                                                            onClick={handleClearButtonClick}
                                                            className={`flex items-center justify-center rounded-full 
                                                                h-[1.75vw] w-[1.75vw] p-[0.5vh] 
                                                                hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                                                                transition duration-300 ease-out`}
                                                        >
                                                            <img
                                                                className="h-full w-full"
                                                                src="/assets/close-icon.png"
                                                                alt="Clear Search"
                                                            />
                                                        </button>
                                                    )}
                                                </div>

                                                {/* Search Results */}
                                                <div
                                                    className={`flex flex-1 flex-wrap justify-between 
                                                        gap-2 overflow-y-scroll mr-[3vh] pl-[3vh] pr-0.5
                                                        transition duration-500 ease-in-out 
                                                        ${searchResults.length !== 0 ? 'pb-[3vh] opacity-100 translate-y-0' : 'opacity-0 -translate-y-5'}
                                                    `}
                                                >
                                                    {
                                                        searchResults.length !== 0 && (
                                                            searchResults.map((data, index) => (
                                                                <button
                                                                    className={`${(!cookie || account?.outsider) ? 'cursor-default' : 'cursor-pointer'}
                                                                        min-w-[49%] max-w-[49%] flex-1 flex items-center gap-x-2 rounded-xl 
                                                                        hover:shadow-lg px-[1vh] py-[1vh] border-2 border-[#EAEAEA] bg-white
                                                                        transition duration-150 ease-in-out`}
                                                                    key={index}
                                                                    onClick={() => handleNodeClick(data.nodeId)}
                                                                >
                                                                    {/* Profile Picture */}
                                                                    {data.imageUrl.length <= 2 ? (
                                                                        <div
                                                                            className={`z-10 w-[8vh] h-[8vh] overflow-hidden 
                                                                                flex items-center justify-center rounded-lg 
                                                                                shadow-xl border-2 border-[#F5F5F5] ${data.imageUrlBg}`}
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    "text-[1vw] text-white font-SOHNE_MONO_BOLD"
                                                                                }
                                                                            >
                                                                                {data.imageUrl.toUpperCase()}
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className="z-10 w-[8vh] h-[8vh] overflow-hidden flex items-center justify-center rounded-lg bg-slate-500 border-2 border-white">
                                                                            <img
                                                                                src={
                                                                                    !(data.imageUrl === "")
                                                                                        ? data.imageUrl
                                                                                        : "/assets/default-pfp.png"
                                                                                }
                                                                                referrerPolicy={"no-referrer"}
                                                                                alt="Member Profile Picture"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {/* Firstname | Middlename | Family Tree Name */}
                                                                    <div
                                                                        className="h-full flex-1 flex flex-col items-start justify-between">
                                                                        <p className="text-[1.75vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                                                                            {data.firstName + " " + data.middleName}
                                                                        </p>
                                                                        <p className="text-[1.5vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                                                                            {data.firstNameDevanagariScript +
                                                                                " " +
                                                                                data.middleNameDevanagariScript}
                                                                        </p>
                                                                        <div
                                                                            className="py-[0.25vh] px-[0.5vw] bg-[#F3BC50] rounded-full">
                                                                            <p className="text-[1.25vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                                                                                {data.familyTreeName}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Modal>

                                        {/* JoglekarKul Map */}
                                        <JoglekarKulMap
                                            isModal={false}
                                            setShowFamilyTreeListModal={setShowFamilyTreeListModal}
                                            setSelectedLocation={setSelectedLocation}
                                        />

                                        {/* Family Tree List Modal */}
                                        <FamilyTreeListModal
                                            showFamilyTreeListModal={showFamilyTreeListModal}
                                            setShowFamilyTreeListModal={setShowFamilyTreeListModal}
                                            selectedLocation={selectedLocation}
                                        />
                                    </>
                                )
                        )
                }
            </div>
        </>
    );
};
