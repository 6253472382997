import React from "react";
import { Navigate } from "react-router-dom";
import YoutubeCard from "../components/MyComp/YoutubeCard";
import detailcard from "../utils/detailcard";
import DetailCard from "../components/MyComp/DetailCard";
import { circleInfo1, circleInfo2 } from "../utils/circleInfo";
import Circles from "../components/MyComp/Circles";
import Carousel from "../components/MyComp/Carousel";
import { Header } from "../components/Header";
import Hero from "../components/MyComp/Hero";
import Footer from "../components/MyComp/Footer";

export const LandingPageScreen = () => {
	// const navigate = useNavigate();
	// const cookies = new Cookies();
	// const [locale, setLocale] = useState(cookies.get("locale") || "en");

	// const handleLanguageDropdown = (e) => {
	//   cookies.set("locale", e.target.value, { path: "/" });
	//   window.location.reload();
	// };

	return (
		<div className="bg-white">
			<Navigate to="/home" replace={true} />
			{/*<Heading/>*/}

			{/* Header */}
			<Header />

			<main className="isolate">
				{/* Hero section */}
				<Hero />

				{/* Youtube link section */}
				<YoutubeCard />

				{/* detail card section */}
				<div className="wave">
					<div className="bg-[#FFE5CF] py-28 relative">
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 p-4 md:px-10 items-stretch">
							{detailcard.map((detail) => (
								<DetailCard key={detail.id} info={detail} />
							))}
						</div>
					</div>
				</div>

				{/* carousel section */}
				<div className="wave bg-[#750534] py-10 md:-mt-10 -mt-[30px] ">
					<Carousel />
				</div>

				{/* circle icon section */}
				<div className="wave">
					<div className="bg-[#ffe5d2] py-10 md:-mt-10 -mt-[60px] ">
						<div className="flex flex-row justify-center gap-x-[6vw] m-10">
							{circleInfo1.map((data) => {
								return <Circles key={data.id} data={data} />;
							})}
						</div>
						<div className="flex flex-row justify-center gap-x-[6vw] m-10">
							{circleInfo2.map((data) => {
								return <Circles key={data.id} data={data} />;
							})}
						</div>
					</div>
				</div>

				{/* Footer */}
				<Footer />
			</main>
		</div>
	);
};
