import { Header } from "../components/Header";

export const Vyadeshwar = () => {
  return (
    <>
      <div className="w-full  flex flex-col items-center justify-center">
        <Header />

        <img
          src="/other_imgs/banner-dharmik-inside.jpg"
          alt="Information Banner"
          className="w-full h-[40vh] object-cover shadow-lg"
        />
      </div>
      <h1 className="text-center font-bold text-3xl mt-10 text-red-800">
        व्याडेश्वर, गुहागर
      </h1>

      <div className="flex justify-between items-center p-6">
        {/* Left Section - Image */}
        <div className="w-full lg:w-1/2 p-4">
          <div className="flex items-center justify-center">
            <img
              src="/other_imgs/thumbnail-dharmik-vyadeshwar.png"
              alt="Temple Idol"
              className=" shadow-md w-[550px]  h-[550px] object-cover"
            />
          </div>
          <p className="mt-10 leading-relaxed px-5 text-justify">
            श्री व्याडेश्वर मंदिर गुहागर येथील गस्‌.टी.स्टँडपासून अगदी जवळ असून,
            आजूबाजूला गुहागरची बाजारपेठ आहे. गुहागर हे गांव रत्नागिरी जिल्ह्यात
            असून तालुक्याचे ठिकाण आहे. चिपळूणपासून गुहागर सुमारे ४५ किलोमीटर
            अंतरावर आहे. गुहागर हे गाव इ.स.१२०० चे सुमारास वसले असले, तरी श्री
            व्याडेश्वर मंदिर सुमारे २०० / २५० वर्षांचे जुने आहे. व्याडेश्वर
            मंदिरातील शिविंलग बरेच प्राचीन असून स्वयंभू आहे. जमदग्नीपुत्र
            परशुरामाने कोकणभूमीची निर्मिती केल्यानंतर त्या भागात अनेक ऋषी आले व
            राहिले. त्यापैकी व्याड ऋषीने याची स्थापना केली म्हणून याला
            व्याडेश्वर असे संबोधले गेले. या मंदिराला चारी बाजूने तट असून उत्तर,
            दक्षिण व पूर्वेला प्रवेशद्वारे आहेत. मंदिर पूर्वाभिमुख असले तरी
            दक्षिणदिशेला असलेल्या प्रवेशद्वाराचा प्रामुख्याने वापर होतो. बहतेक
            सर्व चित्पावन कोकणस्थ ब्राह्मण घराण्याचा श्री व्याडेश्वर हा
            कुलस्वामी आहे. अनेक जोगळेकर कुटुम्बांचा हा व्याडेश्वर कुलस्वामी आहे.
            मंदिराजवळ गक तळे असून विठ्ठल-रखुमाईचे देवालय पण आहे. मंदिराच्या
            आवारात मोकळी जागा असून मंदिर पंचायतन पद्धतीचे आहे. श्री सूर्य, श्री
            गणपती, श्री अंबिका व श्री लक्ष्मी-विष्णु अशी छोटी छोटी मंदिरे, श्री
            व्याडेश्वर मंदिराचे चारी बाजूला आहेत. मंदिराचे उपाध्याय
            धर्मकार्यासाठी भक्तांना मदत करतात. कार्तिकी गकादशीला येथे विशेष
            उत्सव होत असतो. माघ महिन्यात पाच दिवसांचा व्याडेश्वराचा उत्सव असतो.
            उत्सवात कथा-कीर्तनाचे कार्यक्रम होतात. पालखीची मिरवणूक असते.
            चातुर्मासात व्याडेश्वर िंलगावर सतत रुद्राभिषेक असतो. आषाढी, कार्तिकी
            गकादशी व महाशिवरात्र या दिवशी मंदिरात शिवाची चांदीची मूर्ती ठेवितात.
            महाशिवरात्र, श्रावण व कार्तिक सोमवारी बरेच भक्तगण श्री
            व्याडेश्वराच्या दर्शनाला येतात
          </p>
        </div>

        {/* Right Section - Form and Buttons */}
        <div className="w-full lg:w-1/2 p-4">
          <div className="flex flex-col items-center mb-6">
            {/* LIVE DARSHAN Button */}
            <button className="bg-red-600 text-white font-bold text-xl px-6 py-2 rounded-xl mb-4 shadow hover:bg-red-700 flex items-center justify-center w-80 h-16">
              <img
                src="/other_imgs/icon-live-recording-dot.png"
                className="w-8 h-8 mr-16"
              />
              LIVE DARSHAN
            </button>

            {/* TEMPLE TIME TABLE Button */}
            <button className="bg-red-600 font-bold text-xl text-white  px-6 py-2 rounded-xl shadow hover:bg-red-700 flex items-center justify-center w-80 h-16">
              <img
                src="/other_imgs/icon-temple-timetable.png"
                className="w-10 h-10 mr-10 object-contain"
              />
              TEMPLE TIME TABLE
            </button>
          </div>
          {/* form detail = guruji detail */}
          <form className=" p-6 space-y-4">
            <div>
              <label className="block text-red-700 font-SOHNE_EXTRA_BOLD text-xl mb-2">
                Puja and Guruji Details
              </label>
              <div className="flex space-x-4">
                <select className="w-1/2 p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
                  <option>Select Guruji</option>
                </select>
                <select className="w-1/2 p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
                  <option>Pujas Available</option>
                </select>
              </div>
            </div>

            {/* form details */}
            <label className="block text-red-700 font-SOHNE_EXTRA_BOLD text-xl mb-2">
              Fill The Form Details
            </label>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Name"
                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              <input
                type="text"
                placeholder="Surname"
                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              <input
                type="text"
                placeholder="Gotra"
                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              <input
                type="text"
                placeholder="Mobile Number"
                className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              <div className="flex flex-col ">
                <label className="font-SOHNE_REGULAR text-lg  text-red-700 mb-3">
                  Booking Date
                </label>
                <input
                  type="date"
                  placeholder="Booking Date"
                  className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                />
              </div>
              <div className="flex flex-col">
                <label className="font-SOHNE_REGULAR text-lg  text-red-700 mb-3">
                  Booking Time
                </label>
                <input
                  type="time"
                  placeholder="Booking Time"
                  className="p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button className="bg-gradient-to-tr from-green-900 to-lime-600 font-bold text-xl mt-10 text-white px-6 py-2 rounded-xl shadow flex items-center justify-center w-80 h-16">
                <img
                  src="/other_imgs/icon-whatsapp.png"
                  className="w-10 h-10 mr-10 object-contain"
                />
                Send to Guruji
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
