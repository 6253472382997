import * as React from "react";
import {useEffect, useState} from "react";
import {Header} from "../components/Header";
import {getUsersByDateOfDeath} from "../service/accountApi";
import {t} from "i18next";
import Cookies from "universal-cookie";

export const RemembranceDay = () => {
    const cookies = new Cookies();
    const locale = cookies.get("locale");

    const [deathAnniversaryPeople, setDeathAnniversaryPeople] = useState([]);

    useEffect(() => {
        const getDeathAnniversaryData = async () => {
            const today = new Date().toISOString().slice(5, 10);
            console.log("Today: ", today);

            getUsersByDateOfDeath(today)
                .then((data) => {
                    console.log("Get Users by DOD: ", data);
                    if (data.success) {
                        setDeathAnniversaryPeople(data.data);
                    }
                })
                .catch((error) => {
                    console.log("Error while getting users by DOD: ", error);
                });
        }

        getDeathAnniversaryData();
    }, []);

    return (
        <div
            className={
                "w-[100vw] h-[100vh] bg-white flex flex-col items-center justify-center"
            }
        >
            <Header/>
            <img
                src="/banners/banner-smaran-din.jpg"
                alt="Death Anniversary"
                className="w-full h-full object-cover shadow-lg"
            />
            <div className="flex-1 w-full px-[1vw] py-[1vh] h-full">
                <h1 className="text-lg font-bold mb-4">{t("REMEMBRANCE_DAY_PAGE.HEADING")}</h1>
                <div
                    className="w-full max-h-[40vh] bg-white rounded-lg shadow-lg overflow-y-scroll">
                    <table className="w-full rounded-lg max-h-[50vh] overflow-y-scroll table-auto">
                        <thead className={""}>
                        <tr className="bg-gray-100 rounded-t-lg">
                            <th className="p-2 text-left">{t("REMEMBRANCE_DAY_PAGE.PHOTO")}</th>
                            <th className="p-2 text-left">{t("REMEMBRANCE_DAY_PAGE.FIRST_NAME")}</th>
                            <th className="p-2 text-left">{t("REMEMBRANCE_DAY_PAGE.MIDDLE_NAME")}</th>
                            <th className="p-2 text-left">{t("REMEMBRANCE_DAY_PAGE.FAMILY_TREE_NAME")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {deathAnniversaryPeople.length > 0 ? (
                            deathAnniversaryPeople.map((person, index) => (
                                <tr
                                    key={index}
                                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                                >
                                    <td className="p-2">
                                        {person.imageUrl ? (
                                            person.imageUrl.length <= 2 ? (
                                                <div
                                                    className={`z-10 h-[5vh] w-auto aspect-square rounded-full overflow-hidden 
                                                                    flex items-center justify-center
                                                                    ${person.imageUrlBg}`}
                                                >
                                                    <p className={"text-white font-SOHNE_MONO_BOLD"}>
                                                        {person.imageUrl.toUpperCase()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <img
                                                    className="h-[5vh] w-auto aspect-square rounded-full"
                                                    src={person.imageUrl}
                                                    alt="User Profile Image"
                                                    referrerPolicy={"no-referrer"}
                                                />
                                            )
                                        ) : (
                                            <img
                                                className="h-[5vh] w-auto aspect-square rounded-full bg-[#D26A53]"
                                                src={"/assets/default-pfp.png"}
                                                alt="Default PFP"
                                                referrerPolicy={"no-referrer"}
                                            />
                                        )}
                                    </td>
                                    <td className="p-2 font-SOHNE_REGULAR">{locale === "en" ? person.firstName : person.firstNameDevanagariScript}</td>
                                    <td className="p-2 font-SOHNE_REGULAR">{locale === "en" ? person.middleName : person.middleNameDevanagariScript}</td>
                                    <td className="p-2 font-SOHNE_MEDIUM">{person.familyTreeName}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan="4"
                                    className="p-[1vh] font-SOHNE_MONO_MEDIUM text-center text-gray-500"
                                >
                                    {t("REMEMBRANCE_DAY_PAGE.NO_DATA")}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
