import {Fragment, useState} from "react";
import {Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {BookOpenIcon, CakeIcon, HeartIcon, PhotoIcon, SquaresPlusIcon, UsersIcon,} from "@heroicons/react/24/outline";

import {t} from "i18next";
import {Link} from "react-router-dom";
import {IoIosMegaphone} from "react-icons/io";
import {LiaHandsHelpingSolid} from "react-icons/lia";
import {SparklesIcon} from "lucide-react";
import {GiRibbonMedal} from "react-icons/gi";

export const OtherMenu = () => {
    const optionsData = [
        {
            title: t("HEADER.OTHER_MENU.2010_GRANTH.TITLE"),
            icon: (
                <BookOpenIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: "https://jogkulstorageuat.blob.core.windows.net/siteassets/myFolder/joglekar_kulvrutant_2010.pdf",
        },
        {
            title: t("HEADER.OTHER_MENU.PHOTO_GALLARY.TITLE"),
            icon: (
                <SquaresPlusIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/photo_gallary'
        },
        {
            title: t("HEADER.OTHER_MENU.BEST_WISHES.TITLE"),
            icon: (
                <CakeIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/best_wishes'
        },
        {
            title: t("HEADER.OTHER_MENU.REMEMBBRANCE_DAY.TITLE"),

            icon: (
                <HeartIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/remembrance_day'
        },

        {
            title: t("HEADER.OTHER_MENU.PRIDE.TITLE"),
            icon: (
                <GiRibbonMedal
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/pride'
        },
        {
            title: t("HEADER.OTHER_MENU.MEMORIES.TITLE"),
            icon: (
                <PhotoIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/memories'
        },
        {
            title: t("HEADER.OTHER_MENU.ANUROOP_JODIDAR.TITLE"),
            icon: (
                <UsersIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/anuroop_jodidar'
        },
        {
            title: t("HEADER.OTHER_MENU.NEWS_EVENTS.TITLE"),
            icon: (
                <IoIosMegaphone
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/new-event'
        },
        {
            title: t("HEADER.OTHER_MENU.HAPPY_TO_SHARE.TITLE"),
            icon: (
                <SparklesIcon
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/happy_to_share'
        },
        {
            title: t("HEADER.OTHER_MENU.HELP_EACH_OTHER.TITLE"),
            icon: (
                <LiaHandsHelpingSolid
                    className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
                    aria-hidden="true"
                />
            ),
            url: '/help_each_other'
        },
    ];

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);

    return (
        <Popover className="relative">
            {({}) => {
                // To prevent the body from scrolling when the popover is open
                document.body.classList.toggle("overflow-y-hidden", open);

                return (
                    <>
                        <button
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            className={`inline-flex items-center gap-x-1 outline-none
                                    py-[1vh] text-[1vw] font-SOHNE_MEDIUM leading-6 text-gray-900`}
                        >
                            <span>{t("HEADER.OTHER_MENU.TITLE")}</span>
                            <ChevronDownIcon
                                className={`h-[1.25vw] w-[1.25vw] ${
                                    hover || open ? "rotate-180" : "rotate-0"
                                } transition-all duration-150 ease-in-out`}
                                aria-hidden="true"
                            />
                        </button>

                        <Transition
                            show={open || hover}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                onMouseEnter={() => setOpen(true)}
                                onMouseLeave={() => setOpen(false)}
                                className="absolute left-1/2 z-10 mt-[1vh] flex w-screen max-w-max -translate-x-1/2 rounded-3xl"
                            >
                                <div
                                    className={`bg-white w-fit min-w-[25vw] max-w-[25vw] 
                                            xl:min-w-[25vw] xl:max-w-[27.5vw] 
                                            flex-auto overflow-hidden shadow-lg  
                                            text-[0.75vw] leading-6 
                                            rounded-3xl xl:rounded-[2rem] 
                                            ring-2 ring-orange-300/5`}
                                >
                                    {/* Menu Options List*/}
                                    <div className="h-[40vh] no-scrollbar overflow-y-auto p-[0.5vw]">
                                        {optionsData.map((option, index) => (
                                            <div
                                                key={index}
                                                className="group relative flex gap-x-4 p-[1.5vh]
                                                        hover:bg-gray-200 rounded-2xl xl:rounded-3xl
                                                        transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]"
                                            >
                                                <div
                                                    className={`flex flex-none items-center justify-center 
                                                                h-[6vh] w-auto aspect-square bg-gray-50 p-[1.5vh]
                                                                rounded-xl xl:rounded-2xl group-hover:bg-orange-400 
                                                                transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]`}
                                                >
                                                    {option.icon}
                                                </div>
                                                <div
                                                    className={"flex flex-col"}
                                                >
                                                    <Link
                                                        to={option.url}
                                                        className="font-SOHNE_MEDIUM leading-normal text-[1vw] mt-2 text-gray-900"
                                                    >
                                                        {option.title}
                                                        <span className="absolute inset-0"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                );
            }}
        </Popover>
    );
};
