import * as React from "react";
import {useEffect, useState} from "react";
import {Header} from "../components/Header";
import {getUsersByDateOfBirth, getUsersByDateOfMarriage} from "../service/accountApi";
import {t} from "i18next";
import Cookies from "universal-cookie";

const peopleData = [
    {
        firstName: "Rahul",
        middleName: "Kumar",
        location: "Pune",
        birthday: "1990-01-01",
        anniversary: "2015-12-15",
        photo: "/profile_images/rahul.jpg",
    },
    {
        firstName: "Sneha",
        middleName: "Ramesh",
        location: "Mumbai",
        birthday: "1988-07-15",
        anniversary: "2018-06-25",
        photo: "/profile_images/sneha.jpg",
    },
    {
        firstName: "Amit",
        middleName: "Prakash",
        location: "Nagpur",
        birthday: "1992-03-10",
        anniversary: "2020-01-01",
        photo: "/profile_images/amit.jpg",
    },
    {
        firstName: "Priya",
        middleName: "Sanjay",
        location: "Pune",
        birthday: "1995-11-20",
        anniversary: "2021-01-01",
        photo: "/profile_images/priya.jpg",
    },
    {
        firstName: "Vikram",
        middleName: "Raj",
        location: "Mumbai",
        birthday: "1985-09-05",
        anniversary: "2010-08-10",
        photo: "/profile_images/vikram.jpg",
    },
];

export const BestWishes = () => {
    const cookies = new Cookies();
    const locale = cookies.get("locale");

    const [birthdayPeople, setBirthdayPeople] = useState([]);
    const [anniversaryPeople, setAnniversaryPeople] = useState([]);

    const renderTable = (people, label, no_data) => (
        <div className="flex-1 w-full p-4 h-full">
            <h1 className="text-lg font-bold mb-4">{label}</h1>
            <div className="w-full max-h-[40vh] bg-white rounded-lg shadow-lg overflow-y-scroll">
                <table className="rounded-lg w-full max-h-[50vh] overflow-y-scroll table-auto">
                    <thead className={""}>
                    <tr className="bg-gray-100 rounded-t-lg">
                        <th className="p-2 font-SOHNE_REGULAR text-left">{t("BEST_WISHES_PAGE.PHOTO")}</th>
                        <th className="p-2 font-SOHNE_REGULAR text-left">{t("BEST_WISHES_PAGE.FIRST_NAME")}</th>
                        <th className="p-2 font-SOHNE_REGULAR text-left">{t("BEST_WISHES_PAGE.MIDDLE_NAME")}</th>
                        <th className="p-2 font-SOHNE_REGULAR text-left">{t("BEST_WISHES_PAGE.LOCATION")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {people.length > 0 ? (
                        people.map((person, index) => (
                            <tr
                                key={index}
                                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                            >
                                <td className="p-2">
                                    {person.imageUrl ? (
                                        person.imageUrl.length <= 2 ? (
                                            <div
                                                className={`z-10 h-[5vh] w-auto aspect-square rounded-full overflow-hidden 
                                                                    flex items-center justify-center
                                                                    ${person.imageUrlBg}`}
                                            >
                                                <p className={"text-white font-SOHNE_MONO_BOLD"}>
                                                    {person.imageUrl.toUpperCase()}
                                                </p>
                                            </div>
                                        ) : (
                                            <img
                                                className="h-[5vh] w-auto aspect-square rounded-full"
                                                src={person.imageUrl}
                                                alt="User Profile Image"
                                                referrerPolicy={"no-referrer"}
                                            />
                                        )
                                    ) : (
                                        <img
                                            className="h-[5vh] w-auto aspect-square rounded-full bg-[#D26A53]"
                                            src={"/assets/default-pfp.png"}
                                            alt="Default PFP"
                                            referrerPolicy={"no-referrer"}
                                        />
                                    )}
                                </td>
                                <td className="p-2 font-SOHNE_REGULAR">{locale === "en" ? person.firstName : person.firstNameDevanagariScript}</td>
                                <td className="p-2 font-SOHNE_REGULAR">{locale === "en" ? person.middleName : person.middleNameDevanagariScript}</td>
                                <td className="p-2 font-SOHNE_REGULAR">{person.address?.city ? person.address?.city : ''}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan="4"
                                className="p-2 text-center text-gray-500"
                            >
                                {no_data}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );

    useEffect(() => {
        const getBirthdayAndAnniversaryData = async () => {
            const today = new Date().toISOString().slice(5, 10);
            console.log("Today: ", today);

            getUsersByDateOfBirth(today)
                .then((data) => {
                    console.log("Get Users by DOB: ", data);
                    if (data.success) {
                        setBirthdayPeople(data.data);
                    }
                })
                .catch((error) => {
                    console.log("Error while getting users by DOB: ", error);
                });

            getUsersByDateOfMarriage(today)
                .then((data) => {
                    console.log("Get Users by DOM: ", data);
                    if (data.success) {
                        setAnniversaryPeople(data.data);
                    }
                })
                .catch((error) => {
                    console.log("Error while getting users by DOM: ", error);
                });
        }

        getBirthdayAndAnniversaryData();
    }, []);

    return (
        <div
            className="w-full h-full bg-white flex flex-col items-center justify-center overflow-y-scroll no-scrollbar">
            <Header/>
            <img
                src="/banners/banner-birthday-wish.jpg"
                alt=""
                className="w-full h-full object-cover shadow-lg"
            />

            <div className={"w-full relative"}>
                {/* Send Message Wish on WhatsApp */}
                <div className="absolute h-[45vh] w-full flex items-center justify-center">
                    <img
                        src="/other_imgs/img_click to send message.png"
                        className="w-56 h-56"
                        alt={"Send WhatsApp Message"}
                    />
                </div>

                {/* Anniversary Section | Birthday Section */}
                <div className="w-full flex items-start justify-center bg-green-600">
                    {/* Birthday section with full background */}
                    <div
                        className="h-[100vh] flex-1 flex flex-col items-center
                        bg-gradient-to-b from-stone-50 to-sky-300"
                    >
                        <div className="flex-1 flex items-center justify-center">
                            <img
                                src="/other_imgs/birthday card.png"
                                alt="birthday_thumbnail"
                                className="w-[35vh] aspect-square my-[5vh]"
                            />
                        </div>
                        {renderTable(birthdayPeople, t("BEST_WISHES_PAGE.BIRTHDAY"), t("BEST_WISHES_PAGE.NO_BIRTHDAY_TODAY"))}
                    </div>

                    {/* Anniversary section with full background */}
                    <div
                        className="h-[100vh] flex-1 flex flex-col items-center
                        bg-gradient-to-b from-stone-50 to-red-200"
                    >
                        <div className="flex-1 flex items-center justify-center">
                            <img
                                src="/other_imgs/anniversary thumbnail.png"
                                className="w-[35vh] aspect-square my-[5vh]"
                                alt={"Anniversary Thumbnail"}
                            />
                        </div>
                        {renderTable(anniversaryPeople, t("BEST_WISHES_PAGE.ANNIVERSARY"), t("BEST_WISHES_PAGE.NO_ANNIVERSARY_TODAY"))}
                    </div>
                </div>
            </div>

        </div>
    );
};
