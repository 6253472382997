const youtube = [
  {
    id: 1,
    title: "मनोगत",
    img: "/kulvratant_landingPage/video-thumbnail.png",
    url: "https://www.youtube.com/embed/DRmHbInoFUQ", 
  },
  {
    id: 2,
    title: "ध्येय",
    img: "/kulvratant_landingPage/video-thumbnail.png",
    url: "", 
  },
  {
    id: 3,
    title: "वाटचाल",
    img: "/kulvratant_landingPage/video-thumbnail.png",
    url: "", 
  },
];
export default youtube;
