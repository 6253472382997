import axios from 'axios';
import {BASE_URL} from '../utils/config'

const URL = BASE_URL;//"http://localhost:3001";


export const addMember = async (member) => {
    try {
        const response = await axios.post(`${URL}/member/add`, member);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getMemberById = async (id) => {
    try {
        const response = await axios.get(`${URL}/member/getMember/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
export const findRelative = async (id, relationshipType) => {
    try {
        const response = await axios.get(`${URL}/member/findPeople/${id}`, {
            params: {
                relationshipType: relationshipType
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getFamilyTreeById = async (familyTreeId) => {
    try {
        const response = await axios.get(`${URL}/member/getFamilyTree/${familyTreeId}`);
        console.log("Get Family Tree Response: ", response);
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
}


export const updateMember = async (id, member) => {
    try {
        const response = await axios.put(`${URL}/member/updateMember/${id}`, member);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updateMultipleMembers = async (updatedMembers) => {
    try {
        const response = await axios.put(`${URL}/member/updateMultipleMembers`, updatedMembers);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const deleteMember = async (id) => {
    try {
        const response = await axios.delete(`${URL}/member/deleteMember/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


