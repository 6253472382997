import {Link} from "react-router-dom";
import {Header} from "../components/Header";


export const Mantropchar = () => {
    const mantropcharData = [
        {
            title: "गद्य श्रीदेव्यपराधक्षमापनस्तोत्रम् (गुरुजी) १र स्तोत्रे",
            img: "/other_imgs/thumbnail-mantropchar.jpg",
        },
        {
            title: "गद्य श्रीदेव्यपराधक्षमापनस्तोत्रम् (गुरुजी) १र स्तोत्रे",
            img: "/other_imgs/thumbnail-mantropchar.jpg",
        },
        {
            title: "श्री योगेश्वरी सहस्त्रनाम ",
            img: "/other_imgs/thumbnail-mantropchar.jpg",
        },
        {title: "सहस्त्रनामे ", img: "/other_imgs/thumbnail-mantropchar.jpg"},
        {title: "सहस्त्रनामे ", img: "/other_imgs/thumbnail-mantropchar.jpg"},
        {
            title: "अष्टक गद्य किंवा पद्य ( गीत रूपांतर )",
            img: "/other_imgs/thumbnail-mantropchar.jpg",
        },
        {
            title: "अथ श्री चतुःषष्ठी योगिनीनाम",
            img: "/other_imgs/thumbnail-mantropchar.jpg",
        },
        {
            title: "अथ श्री देव्यष्टोतरशत नामावली ",
            img: "/other_imgs/thumbnail-mantropchar.jpg",
        },
    ];
    return (
        <>
            <div className="w-full  flex flex-col items-center justify-center">
                <Header/>

                <img
                    src="/other_imgs/banner-dharmik-mantropchar.jpg"
                    alt="Information Banner"
                    className="w-full h-[70vh] object-cover shadow-lg"
                />
            </div>
            <ul className="grid gap-4 md:grid-cols-2 lg:grid-cols-2 my-10 mx-20">
                {mantropcharData.map((mantropchar, index) => (
                    <li
                        key={index}
                        className="flex items-center border-2  shadow-lg rounded-lg p-6
                        bg-whtie h-[130px] w-full max-w-[450px] hover:scale-105 transition-all duration-300"
                    >
                        <Link to={mantropchar.link} className="flex items-center w-full">
                            <img
                                src={mantropchar.img}
                                alt={mantropchar.title}
                                className="w-28 h-28 object-cover rounded-md mr-10"
                            />
                            <div className="text-lg font-semibold text-maroon ">
                                {mantropchar.title}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
};
