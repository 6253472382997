import * as React from "react";
import {Header} from "../components/Header";

export const Membership = () => {
    return (
       <>
        <div className="w-full h-[100vh] bg-white flex flex-col items-center justify-center">
        <Header />
        <img
            src="/banners/banner-membership.jpg"
            alt="Information Banner"
            className="w-full h-full object-cover shadow-lg"
        />
    </div>
          <div className='mx-24 my-10'>
          <img src="/other_imgs/thumbnail-joglekarkul-nondnikara.png"/>
          </div>
           <div className="flex items-center justify-between text-center gap-6 mt-10 md:px-36 mb-16">
                <div>
                    <img src="/other_imgs/thumbnail-membership-dan-kara.png" alt="dana_kara" className="w-80 h-80" />
                    <h2 className=" font-SOHNE_BOLD mt-5 text-red-700 text-2xl">उदारपणे दान करा </h2>
                </div>
                <div>
                    <img src="/other_imgs/thumbnail-membership-sadasya-yadi.png" alt="yadi" className="w-80 h-80" />
                    <h2 className=" font-SOHNE_BOLD mt-5 text-red-700 text-2xl">विधमान सदस्यांची यादी  </h2>
                </div>
                <div>
                    <img src="/other_imgs/thumbnail-membership-sadasya-shoda.png" alt="shoda" className="w-80 h-80" />
                    <h2 className=" font-SOHNE_BOLD mt-5 text-red-700 text-2xl"> सदस्य शोध </h2>
                </div>
            </div>
       </>
      
    )
}