import React, { useState } from "react";
import { Header } from "../components/Header";
import { Modal } from "../components/Modal";
import { IoMdClose } from "react-icons/io";

export const Arti = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const aartiData = [
    {
      title: "आरती योगेश्वरीची ",
      img: "/other_imgs/thumbnail-aarti.jpg",
      url: "https://www.youtube.com/embed/-ZZGM8_yeB4",
    },
    {
      title: "बोडणाची आरती (लोकगीत आधारीत ) ",
      img: "/other_imgs/thumbnail-aarti.jpg",
      url: "",
    },
    {
      title: "रूप तुझे काय वर्णू ( जगदंबेची स्तुतीगीत)",
      img: "/other_imgs/thumbnail-aarti.jpg",
      url: "https://www.youtube.com/embed/EJZclBtKKrc",
    },
    {
      title: "अहो दक्षिण देशामधे (तुळजाभवानी चा महिमा) ",
      img: "/other_imgs/thumbnail-aarti.jpg",
      url: "",
    },
  ];

  const openModal = (url, title) => {
    if (url) {
      setVideoUrl(url);
      setModalTitle(title);
      setModalOpen(true);
    } else {
      alert("Video not available");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setVideoUrl("");
    setModalTitle("");
  };

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center">
        <Header />
        <img
          src="/other_imgs/banner-dharmik-aarti.jpg"
          alt="Information Banner"
          className="w-full h-[70vh] object-cover shadow-lg"
        />
      </div>
      <ul className="grid gap-2 md:grid-cols-2 lg:grid-cols-2 mb-10 mx-20">
        {aartiData.map((aarti, index) => (
          <li
            key={index}
            className="flex items-center border-2 mt-10 shadow-xl rounded-lg p-6
                        bg-white h-[130px] w-full max-w-[550px] hover:scale-105 transition-all duration-300"
            onClick={() => openModal(aarti.url, aarti.title)}
          >
            <div className="flex items-center w-full cursor-pointer">
              <img
                src={aarti.img}
                alt={aarti.title}
                className="w-28 h-28 object-cover rounded-md mr-10"
              />
              <div className="text-lg font-semibold text-maroon">
                {aarti.title}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="relative w-full h-full p-4 bg-white rounded-lg">
          <button
            className="absolute top-2 right-2 text-gray-700 rounded-full p-2 shadow-md "
            onClick={closeModal}
          >
            <IoMdClose />
          </button>
          <h2 className="text-center text-2xl font-bold text-red-600 mb-4">
            {modalTitle}
          </h2>
          {videoUrl ? (
            <iframe
              width="100%"
              height="350"
              src={videoUrl}
              title="YouTube video"
              frameBorder="0"
              className="rounded-lg"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <div className="text-center text-lg font-semibold">
              Video not available
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
