import { Header } from "../components/Header"
import { useEffect } from 'react';
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";

export const Chapter20 = () => {
    // Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
    useEffect(() => {
            // Scroll to top when the page is rendered
            window.scrollTo(0, 0);
        }, []);
    return (
        <>
            <div>
                <Header />
                <div className="mt-16 mb-10 font-SOHNE_REGULAR px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
                    <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
                        जोगळेकर कुलातील वैदिक विद्वानांचा परिचय
                    </h1>
                    <div className=" p-4 md:p-8">
                        <div className="max-w-4xl mx-auto  p-6">
                            <h1 className="text-2xl md:text-3xl font-SOHNE_BOLD text-center mb-4">वैदिक विद्वानांचा परिचय</h1>
                            <h2 className="text-lg md:text-xl font-SOHNE_BOLD  mb-2">
                                संपादक - सी.वि.केळकर या ग्रंथाच्या आधारे
                            </h2>
                            <div className="text-sm md:text-base leading-relaxed">
                                <p className="mb-4 mt-5 ">
                                    <strong className="text-xl"> १) वे. गणेश पद्माकर जोगळेकर/ पुणे -</strong>
                                </p>
                                <p className="mb-4 text-lg">
                                    यांचा जन्म शके १७४७ (इ.स.१८२५) चे सुमारास झाला. यांचे मूळगाव
                                    रत्नागिरी जिल्ह्यातील रीळ हे होय. तेथून काही दिवस गणपती-पुळे येथे
                                    वास्तव्य करून वे. गणेशभटजी पुण्यास आले. मोदीच्या श्रीगणपतीच्या
                                    संस्थानची नोकरी करून ते देवळातच राहात असत, व वेदाध्ययन करीत.
                                    श्री.लोलूअण्णा व हरिभाऊ भट यांचा त्यावेळी त्यांना आश्रय असे.
                                    ऋग्वेदाच्या दशग्रंथांचे व याज्ञिकाचे अध्ययन पूर्ण झाल्यानंतर, वे.
                                    गणेशभटजी यांनी मंदिरातच अध्यापनाचे कार्य चालू ठेवले व अनेक
                                    विद्यार्थ्यांना पढविले.
                                </p>
                                <p className="mb-4 text-lg">
                                    वे. गणेशभटजींच्या पत्नीचे नाव यमुनाबाई. या पुणे येथील विष्णू
                                    रामचंद्र गाडगीळ यांच्या कन्या होत. यांना:
                                </p>
                                <ul className="list-disc list-inside mb-4 text-md">
                                    <li>काशी भ्र. पुरुषोत्तम वामन रिसबुड, न्हावरें</li>
                                    <li>गोदावरी भ्र पुरुषोत्तम कृष्ण जोशी, पुणे</li>
                                    <li>कृष्णा भ्र. काशिनाथ कोंडो पेंडसे</li>
                                    <li>अंबु भ्र. कृष्णाजी गोिंवद पाटणकर, पुणे</li>
                                    <li>वेणू भ्र. गणेश रामचंद्र काणे, अमरावती</li>
                                </ul>
                                <p className="mb-4 text-lg">
                                    अशा कन्या व डॉ.िंचतामण गणेश जोगळेकर, इंदूर हे पुत्र आहेत.
                                </p>
                                <p className="mb-4 text-lg">वे. गणेशभटजी शके १८१९ (इ.स.१८९७) मध्ये वारले.</p>
                            </div>
                            <div className="container mx-auto p-4">
                                <p className="mb-4 mt-5 ">
                                    <strong className="text-xl"> २). वे.गोिंवद गणेश जोगळेकर  /  पुणे -</strong>
                                </p>
                                <p className="text-lg">
                                    यांचा जन्म सन १८३२ चे सुमारास झाला. यांचे वडील गणेशभट केशव जोगळेकर हे पुण्यास भिक्षुकी करीत. वे. गोिंवदभटजींनी त्यांचेजवळ ऋग्वेदाच्या दशग्रंथांचे अध्ययन केले. बडोद्यास वेदाची परीक्षा दिली. तेथून त्यांना प्रतिवर्षी ते जीवमान असेपर्यंत दक्षिणा मिळत असे. वे. जयरामभट करंबेळकर, वे. सखारामभट गुर्जर व वे. गोपालभट करंबेळकर यांनी यांचेजवळ काही दिवस वेदाध्ययन केले होते. वे. गोविंदभट यांचे घराण्यात चार पिढ्या वैदिकी चालत आली होती. पेशवाईत यांचे पूर्वजांना योगक्षेमासाठी वर्षासन मिळत असे, ते इंग्रजी अमदानीतही वे. गोिंवदभटजींना प्रतिवर्षी रु. १९२ प्रमाणे मिळत होते.
                                </p>
                                <p className="text-lg mt-4">
                                    वे. गोिंवदभटजी यांच्या पत्नीचे नाव राधाबाई पिता रामचंद्र बल्लाळ आगाशे, पुणे. यांना पाच मुलगे झाले. पैकी अनंतराव व त्र्यबंककराव हे पुण्यास असतात.
                                </p>
                                <p className="text-lg mt-4">
                                    वे. गोिंवदभटजी सन १९०७ साली वारले.
                                </p>
                            </div>
                            <div className="max-w-4xl mx-auto p-6 ">
                                <p className="mb-4 mt-5 ">
                                    <strong className="text-xl"> ३). वे.नरहर विनायक जोगळेकर   / जमखंडी-</strong>
                                </p>
                                <p className="mb-4">
                                    यांचा जन्म शके 1785 (इ.स.1863) मध्ये झाला. वे.विनायकभटजी हे वैदिक असून श्री.जमखंडीकरांचे आश्रित होते. मातेचे नाव सरस्वतीबाई.
                                    वे.नरहरभटजी नी ःग्वेदाचे घनांत अध्ययन जमखंडीस वे.केशवभटजी केळकर व वे.गणेशभटजी आठवले यांचेजवळ केले.
                                    नंतर कोल्हापूर येथील वेदशास्त्राभ्यासाभिनंदिनी सभेत व जमखंडीस घनाच्या परीक्षा दिल्ल्या.
                                    दक्षिण हैद्राबादेस श्री.हजूरी यांचेकडे नी घनाचे पारायण केले. सांगलीच्या वेदशाळेत व पुण्यच्या वेदशास्त्रोत्तेजक सभेत ते काही वर्षे परीक्षक होते.
                                    सांगलीच्या प्रख्यात वैदिक वे.काशीनाथभटजी केळकर यांनी सांगलीस शके 1844 (इ.स.1922) साली घनाचे पारायण केले,
                                    त्यावेळी श्रवणाकरता वे.नरहरभटजींची योजना झाली होती. अनेक ठिकाणच्या स्वाहाकारात व जमखंडीच्या नवरात्राच्या उत्सवात काशी
                                    इत्यादी ऐकाणचे मोठी वैदिक मंडळी जमत, त्यातीही वे.नरहरभटजींनी आपला प्रभाव पाडला होता. अलीकडच्या काळात जे मोठी वैदिक महाराष्टात
                                    झाले, त्यांत नरहरभटजींची गणना होते.
                                </p>

                                <h2 className="text-xl font-semibold mb-2">वे. नरहरभटजींच्या शिष्यपरिवार</h2>
                                <ul className="list-disc pl-6 mb-4">
                                    <li>जमखंडीचे वे.विष्णूभटजी जोशी</li>
                                    <li>वे.हनुमंतभट परांजपे, सावळगी</li>
                                    <li>वे.गणेशभटजी ताम्हनकर, हरिपूर</li>
                                    <li>वे.केशवभट बंकापूर, तेरदाळ</li>
                                    <li>वे.रामभट साबडे, रामतीर्थ</li>
                                    <li>वे.नारायणभटजी जोगळेकर</li>
                                    <li>वे.गजाननभटजी जोगळेकर</li>
                                </ul>

                                <p className="mb-4">
                                    वे.नरहरभटजींच्या पत्नीचे नाव गोपिकाबाई. या परशुराम गंगाधर गाडगीळ, ंलबगोवे यांची कन्या होत. यांना 1) नारायण 2) कृष्ण 3) गजानन असे तिघे पुत्र आहेत.
                                </p>

                                <p>
                                    वे.नरहरभटजी शके 1863 (इ.स.1941) या वर्षी वारले.
                                </p>
                            </div>
                            <div className="container mx-auto p-4">
                                <p className="mb-4 mt-5 ">
                                    <strong className="text-xl"> ४).वे.बाळंभटजी पद्माकर जोगळेकर /   रीळ-</strong>
                                </p>
                                <p className="text-lg">
                                    यांचा जन्म सन १८३४ चे सुमारास झाला. यांचे वडील पद्माकर िंचतामण हे रत्नागिरी जिल्ह्यातील रीळ या गावी भिक्षुकी करीत. मातेचें नाव लक्ष्मीबाई.
                                    वे. बाळंभटजी यांनी रीळ व नंतर पुणे येथे ऋग्वेदाच्या दशग्रंथांचे अध्ययन पूर्ण केले. याज्ञिकही म्हटले होते. त्यांचे वास्तव्य पुढे पुण्यास पुष्कळ वर्षे होते.
                                    उत्तरवयात यांच्या दृष्टीस मंदत्व आल्याने ते पुणे सोडून रीळ येथे राहावयास गेले.
                                    तेथे यांनी ४/५ विद्यार्थ्यांस वेदाध्यापन केले. त्यापैकी मुंबईस असलेले वे. सदाशिव रामचंद्र पाटणकर हे गक आहेत.
                                </p>
                                <p className="text-lg mt-4">
                                    वे. बाळंभटजींच्या पत्नीचे नाव गंगाबाई. या नांदिवड्याच्या बिवलकर घराण्यातील होत्या. यांना प्रभाकर (रावजी), मोरेश्वर व विद्याधर असे तिघे पुत्र होते. वे. बाळंभटजी सन १९११ साली वारले.
                                </p>
                            </div>
                            <div className="container mx-auto p-4">
                                <p className="mb-4 mt-5 ">
                                    <strong className="text-xl"> ५).वे.यज्ञेश्वर वासुदेव जोगळेकर 	/   गोकर्ण-</strong>
                                </p>
                                <p className="text-lg">
                                    यांचा जन्म शके १७९० (इ.स. १८६८) चे सुमारास झाला. यांचे वडील वे. वासुदेव भटजी हे गोकर्णास अग्निहोत्री होते. मातेचे नाव अन्नपूर्णाबाई. वे. यज्ञेश्वर भटजी यांनी ऋग्वेदाच्या दशग्रंथांचे अध्ययन प्रथम वे.
                                    महाबळेश्वरभटजी जोगळेकर यांचेकडे व नंतर सांगलीच्या वेदशाळेत केले. श्रौतविद्येचाही त्यांनी उत्तम अभ्यास केला. शृंगेरीच्या जगद्गुरूंच्या सान्निध्यात झालेल्या यज्ञात त्यांनी आपल्या श्रौताच्या ज्ञानाचा सर्वास परिचय करून दिला.
                                    दक्षिण कानडा जिल्ह्यातील मुंडाजे येथे श्री. पद्मनाभ खाडिलकर यांच्याकडे झालेल्या स्वाहाकारात आचार्यत्व करून त्यांनी कीर्ती संपादन केली. यांनी वे. वेंकटरमण चंद्रशेखर प्रभृति अनेक वैदिक पढवून तयार केले.
                                </p>
                                <p className="text-lg mt-4">
                                    यांच्या पत्नीचे नाव लक्ष्मीबाई पिता धोंडो नारायण पोंक्षे, रामदुर्ग यांना वे. सीताराभटजी या नावाचे वैदिक व श्रौतविद्यापारंगत असे पुत्र होते.
                                </p>
                                <p className="text-lg mt-4">
                                    वे. यज्ञेश्वरभटजी शके १८३८ (इ.स. १९१६) मध्ये वारले.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
					onClick={scrollToTop}
					className="flex items-end justify-end my-5"
				>
					<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
				</div>
				<hr />
                </div>
            </div>
        </>
    )
}