import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { useEffect } from 'react';



export const Chapter14 = () => {
  	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	useEffect(() => {
			// Scroll to top when the page is rendered
			window.scrollTo(0, 0);
		}, []);
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					चित्पावनांची कुलनामे - उपनावे - आडनावे
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8">क्षेत्रोपाध्याय,</span>{" "}
						देवस्थानाला दर्शन घेण्याप्रीत्यर्थ आलेल्या दर्शनार्थीचे
						कुलनाम, गोत्र, प्रवर वगैरेचा नामनिर्देश करून त्यांच्या
						चोपड्यामध्ये त्याचा लेख लिहीत. ही प्रथा बरीच प्राचीन
						असूनही अजूनही ही प्रथा चालूच आहे. फार पूर्वी नाव,
						वडिलांचे नाव व गोत्र, मुळ गाव गवढीच माहिती लेखामध्ये
						नमूद केलेली असे. हळू हळू जसा जसा समाज वाढून मोठा झाला
						तशी तशी कुलनावे/ उपनावे / आडनावे यांचाही उल्लेख होऊ
						लागला. स्कंद पुराणात, चित्पावनांची १४ गोत्रे व साठ
						उपनावांचा उल्लेख सापडतो. १८५५ साली श्री वामन बाळकृष्ण
						जोशी ऊर्फ गद्रे व सदाशिव व बाळकृष्ण अमलापूर यांनी गक
						लहानशी पुस्तिका मुंबईला ज्ञानदर्पण छापखान्यात
						शिलाछपाईयंत्रावर छापून प्रसिद्ध केली. त्यातील माहिती
						खाली देत आहोत. कुलाचा नामनिर्देश करता यावा या दृष्टीने
						कुलनाम / उपनाव िंकवा अडनावांचा उपयोग होऊ लागला हे
						निश्चित.
					</p>
					<div className="flex items-center justify-center mt-6">
						<table className="table-auto w-[650px] text-center">
							<thead>
								<tr className="">
									<td className="px-4 py-3">ऋषी</td>
									<td className="px-4 py-3">गोत्र</td>
									<td className="px-4 py-3">
										आडनाव संख्या
									</td>
									<td className="px-4 py-3">आडनावे</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="px-4 py-3">काश्यप</td>
									<td className="px-4 py-3">काश्यप</td>
									<td className="px-4 py-3">६</td>
									<td className="px-4 py-3">
										लेले, गानू, जोग, लघाटे, गोखले, सोमण
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"></td>
									<td className="px-4 py-3">
										शांडिल्य
									</td>
									<td className="px-4 py-3">६</td>
									<td className="px-4 py-3">
										गांगल, भाटे, गणपुले, दामले, जोशी, परचुरे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3">
										वासिष्ठ
									</td>
									<td className="px-4 py-3 ">
										वासिष्ठ
									</td>
									<td className="px-4 py-3 ">१२</td>
									<td className="px-4 py-3 ">
										साठे, बोडस, ओक, बापट, बागुल, धारु,
										गोगटे, पोंक्षे, िंवझे, साठ्ये, गोवंडे,
										भाभे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"></td>
									<td className="px-4 py-3">
										कौण्डिण्य
									</td>
									<td className="px-4 py-3 ">२</td>
									<td className="px-4 py-3 ">
										पटवर्धन, फणसे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3">अंगिरस</td>
									<td className="px-4 py-3">
										विष्णुवृद्धन
									</td>
									<td className="px-4 py-3 ">४</td>
									<td className="px-4 py-3 ">
										किडमिडे, नेने, परांजपे, मेहेंदळे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3 "></td>
									<td className="px-4 py-3 ">
										नित्युंदन
									</td>
									<td className="px-4 py-3">२</td>
									<td className="px-4 py-3">
										वैशंपायन, भाडबोके
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3 ">
										भारद्वाज
									</td>
									<td className="px-4 py-3 ">
										भारद्वाज
									</td>
									<td className="px-4 py-3 ">६</td>
									<td className="px-4 py-3 ">
										आचवल, टेणे, दुर्वे, गांधारे, घांगुर्डे,
										रानडे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3 "></td>
									<td className="px-4 py-3 ">
										गार्ग्य
									</td>
									<td className="px-4 py-3">५</td>
									<td className="px-4 py-3">
										कर्वे, गाडगीळ, लोंढे, माटे, दाबके
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"></td>
									<td className="px-4 py-3">कपि</td>
									<td className="px-4 py-3">४</td>
									<td className="px-4 py-3">
										लिमये, खांबेटे, जाईल, माईल
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3">भृगू</td>
									<td className="px-4 py-3">
										जामदग्नी
							</td>
									<td className="px-4 py-3">२</td>
									<td className="px-4 py-3">
										पेंडसे, कुंटे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3 "></td>
									<td className="px-4 py-3 ">वत्स</td>
									<td className="px-4 py-3 ">१</td>
									<td className="px-4 py-3 ">मालशे</td>
								</tr>
								<tr>
									<td className="px-4 py-3">
										विश्वामित्र
									</td>
									<td className="px-4 py-3 ">
										ब्राभ्रव्य
									</td>
									<td className="px-4 py-3">२</td>
									<td className="px-4 py-3">
										बाळ, बेहरे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"></td>
									<td className="px-4 py-3">कौशिक</td>
									<td className="px-4 py-3">५</td>
									<td className="px-4 py-3">
										गद्रे, बाम, भावे, वाड, आपटे
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3">अत्रि</td>
									<td className="px-4 py-3">अत्रि</td>
									<td className="px-4 py-3">३</td>
									<td className="px-4 py-3">
										चितळे, आठवले, भाडबोळे
									</td>
								</tr>
							</tbody>
						</table>
					</div>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr/>
				</div>
			</div>
		</div>
	);
};
