import axios from 'axios';

import {BASE_URL} from '../utils/config'

const URL = BASE_URL;//"http://localhost:3001";

export const addRelations = async (relation) => {
    try {
        const response = await axios.post(`${URL}/relationship/add`, relation);
        console.log(response);
        if (response.status === 201) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
    }
}

export const deleteRelation = async (id) => {
    try {
        const response = await axios.delete(`${URL}/relationship/delete/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const deleteRelationOnMemberDelete = async (source) => {
    try {
        const response = await axios.delete(`${URL}/relationship/deleteOnNodeDelete/${source}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
