import {Header} from "../components/Header"
import {t} from "i18next";


export const Kulchar = () => {
    return (
        <>
            <div className="w-full  flex flex-col items-center justify-center">
                <Header/>

                <img
                    src="/other_imgs/banner-dharmik-inside.jpg"
                    alt="Information Banner"
                    className="w-full h-[40vh] object-cover shadow-lg"
                />
            </div>
            <h1 className={"text-[5vw] font-SOHNE_MONO_BOLD text-center"}>{t("CONSTANTS.COMING_SOON")}</h1>
        </>
    )
}
