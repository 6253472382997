// Card Component
const ProfileCard = ({
	profileImg,
	firstName,
	lastName,
	familyTreeName,
	firstNameMarathi,
	lastNameMarathi,
}) => {
	return (
		<div className="flex items-center p-4 border border-gray-300 rounded-2xl shadow-lg bg-white w-[400px] ">
			<img
				src={profileImg || "/assets/userImg.png"}
				alt="profile"
				className="w-28 h-28 rounded-lg object-cover shadow-md"
			/>
			<div className="ml-8">
				<h3 className="text-xl font-bold mb-3    text-gray-800">
					{firstName} {lastName}
				</h3>
                {/* marathi name */}
				<p className="text-md mb-3 font-bold text-black-600">
					{firstNameMarathi} {lastNameMarathi}
				</p>
				<div className="mt-2">
					<span className="inline-block text-md font-bold text-gray-800 bg-yellow-400 px-3 py-1  rounded-full shadow-sm">
						{familyTreeName}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ProfileCard;
